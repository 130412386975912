import React, {Component} from "react";
import {connect} from "react-redux";
import localizedData from "./../Localization/Localization";
import {surveyBegin, retrieveStaffPointHistory, rewardPrizeTickets, findStaffByName, restartPatientSurvey, beginNewSurvey, getDeptStaff, findStaffByDept, cachedResults, executePersonalSurvey, updateSurvey, createPatient, getUserDetails} from "../../survey-actions";
import {Form, Modal, Button} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import ReactTurntable from "react-turntable"
import "react-turntable/assets/index.css"
import GithubMenuItem from './GitHubMenuItem.js';
import {asyncContainer, Typeahead} from 'react-bootstrap-typeahead';
import Switch from "react-switch";
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';
import dateFormat from "dateformat";
import "./../ServeSurvey.scss";
import InfiniteScroll from 'react-infinite-scroll-component';
const AsyncTypeahead = asyncContainer(Typeahead);


class ServeHRJourneySurvey extends Component {

		constructor(props){
				super(props);

				let language = 'EN';
				if(props.location && props.location.query && props.location.query.lang!==null && props.location.query.lang !== undefined){
					if(props.location.query.lang.toUpperCase()==='ES' || props.location.query.lang.toUpperCase() === 'EN' || props.location.query.lang.toUpperCase() === 'SWH'){
						localizedData.setLanguage(props.location.query.lang.toUpperCase());
						language = localizedData.getLanguage();
					}
				}

				var prizes = ['1 Raffle Entry', '2 Raffle Entries', '1 Raffle Entry', '2 Raffle Entries', '3 Raffle Entries', '2 Raffle Entries', '2 Raffle Entries','1 Raffle Entry'];
				this.saveSurveyStatus = this.saveSurveyStatus.bind(this);
				this.executeSurvey = this.executeSurvey.bind(this);
				this.handleAnonChange = this.handleAnonChange.bind(this);
				this.getPointHistory = this.getPointHistory.bind(this);

				this.state = {
					isAnon: false,
					language: language,
					isLoading: false,
					showNextButton: true,
					hasNoFeedback: false,
					pointHistoryToggle: false,
					currentQuestion: 0,
					showDepartmentJourneyScreen: false,
					showStaffJourneyScreen: false,
					selectedDepartments: [],
					selectedStaff: [],
					wrappingNewSurvey: false,
					currentStaffNumber: null,
					currentStaffDetail: {},
					currentDeptNumber: null,
					currentDeptDetail: {},
					currentDeptStaffList: null,
					currentDepartmentShown: '',
					completedSurvey: false,
					confirmOpen: false,
					cachedStaffByDepartment: [],
					notYourSurvey: false,
					staff: null,
					restartedSurvey: false,
					executed: false,
					cyclingStaff: false,
					cyclingDept: false,
					showingDepartments: [],
					cachedStaffRequests : [],
					fullyLoaded: false,
					syncSurvey: false,
					newSurvey: false,
					showStaffFromSearchScreen: false,
					searchPerson: false,
					searchDepartment: false,
					spun: false,
					searchoptions: [],
					pointHistoryPage: 1,
					prizeWinnings: null,
					showReviewPage: false,
					savedSurvey: { selectedDay: null, departments: [], staff: [], completed: false},
					errors: {},
					options: {
							prizes,
							width: 500,
							height: 500,
							primaryColor: "#145a8c",
							secondaryColor: "#3a8390",
							fontStyle:{
									color:"#fff",
									size:"15px",
									fontVertical:true,
									fontWeight:"bold",
									fontFamily:"sans-serif"
							},
							speed: 800,
							duration: 5000,
							clickText:"Spin"
					}


				};
				this.props.surveyBegin(this.props.tvClient, this.props.match.params.survey_url).then(() => {
						if(this.props.surveyData.existingSurvey!==undefined){
								this.setState({showingDepartments: this.props.surveyData.existingSurvey.departments});
								if(this.props.surveyData.language!==undefined&&this.props.surveyData.language!==null){
									localizedData.setLanguage(this.props.surveyData.language);
								}
								this.setState({ savedSurvey: this.props.surveyData.existingSurvey, fullyLoaded: true });
						} else{
								this.setState({showingDepartments: this.props.surveyData.departments});
								var newLanguage = this.props.surveyData.urlInfo[0].survey_lang;
								if(newLanguage !== undefined&& newLanguage !== null){
									localizedData.setLanguage(newLanguage);
									this.setState({language: newLanguage});
								}
								if(this.props.surveyData.recentlyCompleted===true){
									this.setState({ completedSurvey: true });
								} else if(this.props.surveyData.notYourSurvey===true){
									this.setState({ notYourSurvey: true });
								}else{
									var currentSurvey = this.state.savedSurvey;
									currentSurvey.selectedDay = new Date(this.props.surveyData.urlInfo[0].created);
									currentSurvey.departments = this.props.surveyData.departments;
									var isNewSurvey = (this.props.surveyData.newSurvey===true) ? true : false;
									this.setState({ fullyLoaded: true, newSurvey: isNewSurvey, savedSurvey: currentSurvey });
								}

						}
						this.getPointHistory();
				});

		}

		startPrizeDrawing(){
			var els = document.getElementsByClassName('react-turntable-section-btn');
			for(var i = 0; i<els.length; i++){
				els[i].classList.add('removeItem');
			}
			return true;
		}

		finishPrizeDrawing(prize){

		}

		executeSurvey(){
			if(this.state.executed===false){
				this.props.executePersonalSurvey(this.props.tvClient, this.props.surveyData.urlInfo[0]).then(()=>{
					this.setState({executed: true });
				});
			}
		}

		saveSurveyStatus(){
				if(this.state.syncSurvey){
						var currSurvey = this.state.savedSurvey;
						//currSurvey.departments = [];
						var newSurveyBool = false;
						if(currSurvey.completed!==undefined && (currSurvey.completed===true || currSurvey.completed==='true')){
							newSurveyBool = true;
						}
						// TODO: departments need to be saved for existing survey refreshes - need to fix that.  a lot to store for no reason.
						this.props.updateSurvey(this.props.tvClient, currSurvey, this.props.surveyData.urlInfo[0], this.state.currentQuestion, newSurveyBool, localizedData.getLanguage());
						this.setState({syncSurvey: false, newSurvey: newSurveyBool});
				}
		}

	 updateLocalizedWheelText(){
		if(localizedData.getLanguage()==='ES'){
			// Update the Spin Button Text
			var spinButton = document.getElementsByClassName('react-turntable-section-btn');
			for(var i=0; i<spinButton.length; i++){
				spinButton[i].innerText='Girar';
				spinButton[i].innerHTML='Girar';
			}
		}
	 }

	 restartSurvey(e){
		this.props.restartPatientSurvey(this.props.tvClient, this.props.surveyData.urlInfo[0], this.props.surveyData).then( () => {
			this.setState({ restartedSurvey: true, fullyLoaded: true });
		});
	 }

		retrieveUserDetails(){
				this.props.getUserDetails(this.props.surveyData).then(() => {
						var theQuestion = this.props.surveyData.questionInfo.length-2;
						this.setState({ fullyLoaded: true, currentQuestion: theQuestion });
				});
		}

		localizeDepartment(selectedDept){
			let finalName = selectedDept.name;
			if(localizedData.getLanguage()==='ES'){
				if(selectedDept.name_locale_es!==null && selectedDept.name_locale_es!==undefined){
					finalName = selectedDept.name_locale_es;
				}
			} else if(localizedData.getLanguage()==='SWH'){
				if(selectedDept.localization_key!==undefined && selectedDept.localization_key!==null){
					finalName = localizedData[selectedDept.localization_key];
				}
			}

			return finalName;
		}

		findAllDeptKeys(dept){
			var keys = '';
			for(var i=0; i < dept.subs.length; i++){
				keys += dept.subs[i].dept.key + ',';
				if(dept.subs[i].subs.length>0){
					keys += this.findAllDeptKeys(dept.subs[i]);
				}
			}
			return keys;
		}

		selectDepartment(selectedDept, e){
			if(selectedDept.subs.length===0){
				if(this.state.cachedStaffByDepartment[selectedDept.dept.key]!==undefined){
					// we have people to show already
					var staffList = this.state.cachedStaffByDepartment[selectedDept.dept.key];
					this.setState({currentDeptStaffList: staffList,
													showDepartmentJourneyScreen: true,
													currentDepartmentShown: selectedDept.dept,
													surveyData: this.findNextPage(),
													currentQuestion: this.state.currentQuestion+1});
				} else{
					var deptKeyList = this.findAllDeptKeys(selectedDept);
					deptKeyList += selectedDept.dept.key;
					this.props.findStaffByDept(this.props.tvClient,deptKeyList).then(()=>{
						var cachedStaff = this.state.cachedStaffByDepartment;
						cachedStaff[selectedDept.dept.key] = this.props.staffData;
						this.setState({cachedStaffByDepartment: cachedStaff,
													currentDeptStaffList:
													cachedStaff[selectedDept.dept.key],
													showDepartmentJourneyScreen: true,
													currentDepartmentShown: selectedDept.dept,
													surveyData: this.findNextPage(),
													currentQuestion: this.state.currentQuestion+1 });
					})
				}
			} else{
				this.setState({ showingDepartments: selectedDept.subs });
			}
		}

		chooseDepartments(selectedDept, e){
				 e.stopPropagation()
				var currentDepartments = this.state.savedSurvey.departments;
				var originalDepts = this.state.savedSurvey.departments.slice();
				let sync = false;

				var foundIt = false;
				for (var i = 0, l = currentDepartments.length; i < l; i++) {
						if(currentDepartments[i].key === selectedDept.key) {
							foundIt=true;
							e.target.classList.remove("activeDept");
							e.target.parentElement.classList.remove("addCheckMark");
							currentDepartments.splice(i, 1);
							break;
						}
				}

				if(!foundIt){
						e.target.classList.add("activeDept");
						e.target.parentElement.classList.add("addCheckMark");
						currentDepartments.push(selectedDept);
				}

				if(currentDepartments!==originalDepts){
						sync = true;
				}

				var currentSurvey = this.state.savedSurvey;
				currentSurvey.departments = this.props.surveyData.departments;
				this.setState({
						savedSurvey: currentSurvey,
						syncSurvey: sync
				});

		}

		chooseStaff(selectedStaff, e){
				e.stopPropagation();
				var originalStaff= this.state.savedSurvey.staff.slice();
				let sync = false;
				var currentStaff = this.state.savedSurvey.staff;

				var foundIt = false;
				for (var i = 0, l = currentStaff.length; i < l; i++) {
						if(currentStaff[i].staff_id === selectedStaff.staff_id) {
							foundIt=true;
							e.target.classList.remove("activeDept");
							e.target.parentElement.classList.remove("addCheckMark");
							currentStaff.splice(i, 1);
							break;
						}
				}

				if(!foundIt){

						currentStaff = [];
						selectedStaff.badges = [];
						currentStaff.push(selectedStaff);
						e.target.classList.add("activeDept");
						e.target.parentElement.classList.add("addCheckMark");
					 // currentStaff.push(selectedStaff);
				}

				if(currentStaff!==originalStaff){
						sync = true;
				}

				var currentSurvey = this.state.savedSurvey;
				currentSurvey.staff = currentStaff;
				currentSurvey.departments = this.props.surveyData.departments;

				this.setState({
						savedSurvey: currentSurvey,
						syncSurvey: sync
				});

		}

		chooseStaffEndorsement(selectedEndorsement, person, e){
				e.stopPropagation();
				let originalStaffEndorsements = this.state.savedSurvey.staff.splice();

				if(!this.state.showStaffFromSearchScreen){
						for (var i = this.state.savedSurvey.staff.length - 1; i >= 0; i--) {
									if(this.state.savedSurvey.staff[i].staff_id===person.staff_id){
											if(person.badges!==undefined){
													var foundOne = false;
													for (var j = person.badges.length - 1; j >= 0; j--) {
															if(person.badges[j].endorsement_id===selectedEndorsement.endorsement_id){
																	// remove it
																	foundOne = true;
																	person.badges.splice(j, 1);
																	this.setState({ currentStaffDetail: person });
																	e.target.classList.remove("activeDept");
																	e.target.parentElement.classList.remove("addCheckMark");
																	break;
															}
													}
													if(!foundOne){
															e.target.classList.remove("activeDept");
															e.target.parentElement.classList.remove("addCheckMark");
															person.badges=[];
															person.badges.push(selectedEndorsement);// .push(selectedEndorsement);
															this.setState({ currentStaffDetail: person });
															e.target.classList.add("activeDept");
															e.target.parentElement.classList.add("addCheckMark");
													}

											}
											else{
													person.badges=[];
													person.badges.push(selectedEndorsement);
													this.setState({ currentStaffDetail: person });
													e.target.classList.add("activeDept");
													e.target.parentElement.classList.add("addCheckMark");
											}
											break;
									}
							}
				}
				else{
					if(person.badges!==undefined){
							var foundOne = false;
							for (var j = person.badges.length - 1; j >= 0; j--) {
									if(person.badges[j].endorsement_id===selectedEndorsement.endorsement_id){
											// remove it
											foundOne = true;
											person.badges.splice(j, 1);
											this.setState({ currentStaffDetail: person });
											e.target.classList.remove("activeDept");
											e.target.parentElement.classList.remove("addCheckMark");
											break;
									}
							}
							if(!foundOne){
									e.target.classList.remove("activeDept");
									e.target.parentElement.classList.remove("addCheckMark");
									person.badges=[];
									person.badges.push(selectedEndorsement);
									this.setState({ currentStaffDetail: person });
									e.target.classList.add("activeDept");
									e.target.parentElement.classList.add("addCheckMark");
							}
					}
					else{
							person.badges=[];
							person.badges.push(selectedEndorsement);
							this.setState({ currentStaffDetail: person });
							e.target.classList.add("activeDept");
							e.target.parentElement.classList.add("addCheckMark");
					}
				}


				if(originalStaffEndorsements!==this.state.savedSurvey.staff){
						this.setState({ syncSurvey: true })
				}
		}

		createNewSurvey(){
				this.props.beginNewSurvey(this.props.surveyData, this.state.savedSurvey.staff).then(() => {
						var newSurveyState = { selectedDay: null, departments: [], staff: [], completed: false};
						this.setState({ fullyLoaded: true, currentStaffDetail: {}, savedSurvey: newSurveyState, newSurvey: true,showStaffFromSearchScreen: false, showStaffJourneyScreen: false,  wrappingNewSurvey: true, showDepartmentJourneyScreen: false, showStaffJourneyScreen: false, searchDepartment: false, searchPerson: false });
				});
		}

		chooseDepartmentEndorsement(selectedEndorsement, department, e){
				e.stopPropagation();
				let originalDeptEndorsements = this.state.savedSurvey.departments.splice();
				for (var i = this.state.savedSurvey.departments.length - 1; i >= 0; i--) {
						if(this.state.savedSurvey.departments[i].key===department.key){
								if(department.badges!==undefined){
										var foundOne = false;
										for (var i = department.badges.length - 1; i >= 0; i--) {
												if(department.badges[i].endorsement_id===selectedEndorsement.endorsement_id){
														// remove it
														foundOne = true;
														department.badges.splice(i, 1);
														this.setState({ currentDeptDetail: department });
														e.target.classList.remove("activeDept");
														e.target.parentElement.classList.remove("addCheckMark");
														break;
												}
										}
										if(!foundOne){
												department.badges.push(selectedEndorsement);
												this.setState({ currentDeptDetail: department });
												e.target.classList.add("activeDept");
												e.target.parentElement.classList.add("addCheckMark");
										}

								}
								else{
										department.badges=[];
										department.badges.push(selectedEndorsement);
										this.setState({ currentDeptDetail: department });
										e.target.classList.add("activeDept");
										e.target.parentElement.classList.add("addCheckMark");
								}
								break;
						}
				}
				if(originalDeptEndorsements!==this.state.savedSurvey.departments){
						this.setState({ syncSurvey: true })
				}
		}


		notForParent(department){
			var departmentList = this.state.showingDepartments;
			var returnValue = department;

			if(this.state.showStaffFromSearchScreen){
				// Need to search down into the subs to find the parent.  this only goes one layer deep.
				for(var i=0; i<departmentList.length;i++){
					if(departmentList[i].dept.key.toString()===department){
						if(departmentList[i].dept.parent_dept!==null && departmentList[i].dept.parent_dept!==undefined){
								returnValue = departmentList[i].dept.parent_dept;
								break;
						}
					}
					if(departmentList[i].subs.length>0){
						for(var j=0; j<departmentList[i].subs.length;j++){
							if(departmentList[i].subs[j].dept.key.toString()===department){
								if(departmentList[i].subs[j].dept.parent_dept!==null && departmentList[i].subs[j].dept.parent_dept!==undefined){
										returnValue = departmentList[i].subs[j].dept.parent_dept;
										break;
								}
							}
						}
					}
				}
			} else{
				for(var i=0; i<departmentList.length;i++){
					if(departmentList[i].dept.key.toString()===department){
						if(departmentList[i].dept.parent_dept!==null && departmentList[i].dept.parent_dept!==undefined){
								returnValue = departmentList[i].dept.parent_dept;
								break;
						}
					}
				}
			}

			// this allows inheritance if returning returnValue
			return returnValue;
		}

		generateClassNames(endorsement, entity){
				if(entity.badges!==undefined){
						var foundIt = false;
						for (var i = entity.badges.length - 1; i >= 0; i--) {
								if(entity.badges[i].endorsement_id===endorsement.endorsement_id){
										foundIt=true;
										break;
								}
						}
						if(foundIt){
								return true;
						}
						else{
								return false;
						}
				} else{
						return false;
				}
		}


		findNextPage(){
				let newSurveyData = this.props.surveyData;

				for (var i = this.props.surveyData.questionInfo.length - 1; i >= 0; i--) {
						if(this.props.surveyData.questionInfo[i].page_id === newSurveyData.currentPage){
								newSurveyData.currentPage = this.props.surveyData.questionInfo[i+1].page_id;
								newSurveyData.firstPage = false;
								break;
						}
				}

				return newSurveyData;
		}

		findPreviousPage(){
				let newSurveyData = this.props.surveyData;

				for (var i = this.props.surveyData.questionInfo.length - 1; i >= 0; i--) {
						if(this.props.surveyData.questionInfo[i].page_id === newSurveyData.currentPage){
								newSurveyData.currentPage = this.props.surveyData.questionInfo[i-1].page_id;
								if(this.props.surveyData.questionInfo[i-2]===undefined){
										newSurveyData.firstPage = true;
										this.setState({ showDepartmentJourneyScreen: false, showStaffJourneyScreen: false })
								}
								break;
						}
				}

				return newSurveyData;
		}

		handleStaffFeedbackChange(event){
				//event.stopPropagation();
				if(event.target.value!==undefined && event.target.value!==null){
						var person = this.state.currentStaffDetail;
						person.feedback = event.target.value;
						this.setState({ currentStaffDetail: person, syncSurvey: true });
				}
		}

		handleDeptFeedbackChange(event){
				//event.stopPropagation();
				if(event.target.value!==undefined && event.target.value!==null){
						var dept = this.state.currentDeptDetail;
						dept.feedback = event.target.value;
						this.setState({ currentDeptDetail: dept, syncSurvey: true });
				}
		}

		isDepartmentSelected(dept){
				var isSelected = false;
				for (var i = this.state.savedSurvey.departments.length - 1; i >= 0; i--) {
					 if(this.state.savedSurvey.departments[i].key === dept){
						isSelected=true;
					 }
				}
				return isSelected;
		}

		isStaffSelected(staff){
				var isSelected = false;
				for (var i = this.state.savedSurvey.staff.length - 1; i >= 0; i--) {
					 if(this.state.savedSurvey.staff[i].staff_id === staff){
						isSelected=true;
					 }
				}
				return isSelected;
		}

		staffCached(){
				var strDepts = '';
				for (var i = this.state.savedSurvey.departments.length - 1; i >= 0; i--) {
						strDepts += this.state.savedSurvey.departments[i].key;
				}

				var foundIt = false;

				for (var j = this.state.cachedStaffRequests.length - 1; j >= 0; j--) {
						if(this.state.cachedStaffRequests[j].depts===strDepts){
								foundIt = true;
								break;
						}
				}

				return foundIt;
		}

		backwardDepartmentJourney(currentStep, from){

					if(from==='staff'){
						this.setState({
								showDepartmentJourneyScreen: true,
								showStaffJourneyScreen: false,
								wrappingNewSurvey: false
						});
					} else{
						this.setState({
								showDepartmentJourneyScreen: false,
								wrappingNewSurvey: false,
								surveyData: this.findPreviousPage(),
								currentQuestion: this.state.currentQuestion-1
						});
					}

		}

		advanceDepartmentJourney(currentStep){

					var orderedDepts = this.props.surveyData.urlInfo[0].dept_list;
					var departmentDetails = this.props.surveyData.departments;
					//Need to configure the state for current details

					if(this.state.currentDeptStaffList===null || this.state.surveyData.firstPage===true){
							// First time and first element
							var theStaffList = [];
							var i = 0;
							for(var j=0; j<departmentDetails.length; j++){
								if(departmentDetails[j].key.toString()===orderedDepts[i].toString()){
									this.props.surveyData.staffForDepartments[orderedDepts[i]].details = departmentDetails[j];
									break;
								}
							}

							this.setState({ surveyData: this.findNextPage(),
															currentQuestion: this.state.currentQuestion+1,
															'showDepartmentJourneyScreen': true,
															'showStaffJourneyScreen': false,
															'currentDeptStaffList': this.props.surveyData.staffForDepartments[orderedDepts[i]] });
							this.executeSurvey();

					}
					else{
						// Find where we are in the list
						var currentJourneyDept = this.state.currentDeptStaffList.key;
						var currentOrderedPos = 0;
						for(var i=0; i<orderedDepts.length; i++){
							if(currentJourneyDept.toString()===orderedDepts[i].toString()){
								currentOrderedPos = i;
								break;
							}
						}

						if(currentOrderedPos+1 < orderedDepts.length){

							var deptStaff = [];
							if(this.props.surveyData.staffForDepartments[orderedDepts[currentOrderedPos+1]] !== undefined){
									// no staff are listed for this department


								for(var j=0; j<departmentDetails.length; j++){
									if(departmentDetails[j].key.toString()===orderedDepts[currentOrderedPos+1].toString()){
										this.props.surveyData.staffForDepartments[orderedDepts[currentOrderedPos+1]].details = departmentDetails[j];
										break;
									}
								}

								deptStaff = this.props.surveyData.staffForDepartments[orderedDepts[currentOrderedPos+1]];
								this.setState({ 'showDepartmentJourneyScreen': true, 'showStaffJourneyScreen': false, 'currentDeptStaffList': deptStaff });
							}
							else{
								deptStaff = [];
								for(var j=0; j<departmentDetails.length; j++){
									if(departmentDetails[j].key.toString()===orderedDepts[currentOrderedPos+1].toString()){
										deptStaff.details = departmentDetails[j];
										break;
									}
								 }

								 if(deptStaff.details.display){

											var currentDepartments = this.state.savedSurvey.departments;
											var originalDepts = this.state.savedSurvey.departments.slice();
											let sync = false;

											if(currentDepartments.length===0){
												sync = true;
												currentDepartments.push(deptStaff.details);
											}

											var currentSurvey = this.state.savedSurvey;
											currentSurvey.departments = currentDepartments;

										 this.setState({
												currentDeptDetail: deptStaff.details,
												showStaffJourneyScreen: false,
												showDepartmentJourneyScreen: false,
												savedSurvey: currentSurvey,
												syncSurvey: sync,
												surveyData: this.findNextPage(),
												currentQuestion: this.state.currentQuestion+1});

								 }
							}

						}
						this.saveSurveyStatus();
					}

		}

	handleClose(e) {
		this.setState({ confirmOpen: false, hasNoFeedback: false });
	}

	handleConfirm(e){
			let survey = this.state.savedSurvey;
			let sync = true;
			survey.completed = true;
			survey.anonymous = this.state.isAnon;

			if(this.state.showStaffFromSearchScreen){
					var originalStaff= this.state.savedSurvey.staff.slice();
					var currentStaff = [];
					currentStaff.push(this.state.currentStaffDetail);
					survey.staff = currentStaff;
			}

			var staffWithActions = [];
			for(var i=0; i<survey.staff.length;i++){
				if(survey.staff[i].badges!==undefined && survey.staff[i].badges.length>0){
					staffWithActions.push(survey.staff[i]);
				} else if(survey.staff[i].feedback!==undefined && survey.staff[i].feedback.trim().length>0){
					staffWithActions.push(survey.staff[i]);
				}
			}

			if(staffWithActions.length>0){
				this.setState({ syncSurvey: sync, savedSurvey: survey, hasNoFeedback: false, confirmOpen: false }, () => { this.saveSurveyStatus(); this.createNewSurvey(); });
			} else{
				this.setState({hasNoFeedback: true});
			}
	}

		advanceStaffEndorsementsJourney(currentStep, from){

				var originalStaff= this.state.savedSurvey.staff.slice();
				var currentStaffIs = this.state.currentStaffDetail;
				var currStaffOrderNumber = -1;

				var allDepartmentStaff = [];
				if(!this.state.showStaffFromSearchScreen){
					for(var i=0; i<originalStaff.length; i++){
						if(originalStaff[i].department_id.toString()===this.state.currentDeptStaffList[0].department_id){
							allDepartmentStaff.push(originalStaff[i]);
						}
					}
				}

				for(var j=0; j<allDepartmentStaff.length; j++){
					if(currentStaffIs.staff_id!==undefined){
							if(currentStaffIs.staff_id===allDepartmentStaff[j].staff_id){
								currStaffOrderNumber = j+1;
							}
						}
				}

				if(from==='first' && allDepartmentStaff.length!==0){
						this.setState({ currentStaffDetail: allDepartmentStaff[0], showDepartmentJourneyScreen: false, showStaffJourneyScreen: true });
				} else if(currStaffOrderNumber>=allDepartmentStaff.length || allDepartmentStaff.length===0 || this.state.showStaffFromSearchScreen){
					// This is the submission scenario
					// Pop up a modal and if it is good to go submit
					this.setState({confirmOpen: true});
				}
				else{
					var newStaffToShow = (currentStaffIs.staff_id!==undefined) ? allDepartmentStaff[currStaffOrderNumber] : allDepartmentStaff[0];
					this.setState({ currentStaffDetail: newStaffToShow, showDepartmentJourneyScreen: false, showStaffJourneyScreen: true });
					this.saveSurveyStatus();
				}

				window.scrollTo(0,0);
		}

		backwardToSearch(currentStep, from){
			this.setState({showStaffFromSearchScreen: false, showStaffJourneyScreen: false, searchPerson: true, surveyData: this.findPreviousPage()});
		}

		backwardStaffEndorsementJourney(currentStep, from){

				if(from==='facility'){
					this.setState({
						surveyData: this.findPreviousPage(),
						currentQuestion: this.state.currentQuestion-1
					})
				}

				var originalStaff= this.state.savedSurvey.staff.slice();
				var currentStaffIs = this.state.currentStaffDetail;
				var currStaffOrderNumber = -1;

				var allDepartmentStaff = [];
				for(var i=0; i<originalStaff.length; i++){
					if(originalStaff[i].department_id.toString()===this.state.currentDeptStaffList[0].department_id){
						allDepartmentStaff.push(originalStaff[i]);
					}
				}

				for(var j=0; j<allDepartmentStaff.length; j++){
					 if(currentStaffIs.staff_id!==undefined){
							if(currentStaffIs.staff_id===allDepartmentStaff[j].staff_id){
								currStaffOrderNumber = j-1;
							}
						}
				}

				if( (from==='last' || from==='facility') && allDepartmentStaff.length!==0){
					this.setState({ currentStaffDetail: allDepartmentStaff[allDepartmentStaff.length-1], showDepartmentJourneyScreen: false, showStaffJourneyScreen: true });
				} else if(currStaffOrderNumber<0 || allDepartmentStaff.length===0){
					// Means that we need to go to the last department
					this.backwardDepartmentJourney(currentStep, 'staff');
				}
				else{
					var newStaffToShow = (currentStaffIs.staff_id!==undefined) ? allDepartmentStaff[currStaffOrderNumber] : allDepartmentStaff[0];
					this.setState({ currentStaffDetail: newStaffToShow, showDepartmentJourneyScreen: false, showStaffJourneyScreen: true });
				}
				window.scrollTo(0,0);
		}


		advanceSurveyFrom(currentStep, requirements){

				if(requirements==='staff'){
						var strDepts = '';
						for (var i = this.state.savedSurvey.departments.length - 1; i >= 0; i--) {
								strDepts += this.state.savedSurvey.departments[i].key;
						}

						if(!this.staffCached()){
								this.props.getDeptStaff(this.props.tvClient, this.state.savedSurvey.departments).then((response)=> {
										var newRequest = { 'depts': strDepts, 'staff': this.props.staffData };
										var currStaffRequests = this.state.cachedStaffRequests;
										currStaffRequests.push(newRequest);
										this.setState({ cachedStaffRequests: currStaffRequests });
								});
						}
						else{
								for (var i = this.state.cachedStaffRequests.length - 1; i >= 0; i--) {
										if(this.state.cachedStaffRequests[i].depts === strDepts){
												this.props.cachedResults(this.state.cachedStaffRequests[i].staff);
										}
								}
						}

				}

				this.setState({
						surveyData: this.findNextPage(),
						currentQuestion: this.state.currentQuestion+1
				});

				if(requirements!=="submit"){
						this.saveSurveyStatus();
				}

		}

		handleSearch = (query) => {
				this.setState({isLoading: true});
				this.props.findStaffByName(this.props.tvClient, query).then((staff)=>{
					if(this.props.surveyData!==undefined){
						if(this.props.surveyData.urlInfo[0].person_id!==undefined){
							for (var i = staff.length - 1; i >= 0; i--) {
								if(staff[i].staff_id===this.props.surveyData.urlInfo[0].person_id){
									staff.splice(i,1);
								}
							}
						}
					}

					this.setState({isLoading: false, searchoptions: staff });
				});
		}

		selectStaffFromSearch = (person) => {
			this.setState({ showStaffFromSearchScreen: true, showStaffJourneyScreen: true, currentStaffDetail: person[0], searchPerson: false, surveyData: this.findNextPage() })
		}

		backwardSurveyFrom(currentStep){
				this.setState({
						surveyData: this.findPreviousPage(),
						currentQuestion: this.state.currentQuestion-1
				});
		}

		changeLocale(ev, param){
				localizedData.setLanguage(param);
				this.setState({language: param});
		}

		backToLandingPage(ev){
			ev.preventDefault();
			if(this.state.searchDepartment && this.state.showingDepartments[0].dept.parent_dept!==null){
				// need to track history of department structure this is only good for one level deep
					this.setState({ showingDepartments: this.props.surveyData.departments });
			} else{
					this.setState({ searchPerson: false, searchDepartment: false});
			}

		}

		handleSearchButton(kind, ev){

			if(kind===1){
				this.setState({ searchPerson: true, searchDepartment: false });
			} else if(kind===2){
				this.setState({ searchPerson: false, searchDepartment: true });
			} else{
				this.setState({ searchPerson: false, searchDepartment: false });
			}

		}

		handleAnonChange(ev){
			this.setState({ isAnon: !this.state.isAnon});
		}

		getPointHistory(){
			this.props.retrieveStaffPointHistory(this.props.tvClient, this.props.match.params.survey_url, this.state.pointHistoryPage).then(()=>{
				this.setState({pointHistoryPage: this.state.pointHistoryPage+1});
			});
		}

		handlePointHistoryToggle(){
			this.setState({ pointHistoryToggle: !this.state.pointHistoryToggle});
		}

		render() {
				return  <div className="container actualSurvey">

			{this.props.surveyData && (this.state.fullyLoaded && !this.state.pointHistoryToggle && !this.state.showDepartmentJourneyScreen && this.props.surveyData.currentPage==="page-1" && !this.props.surveyData.invalidEmployee) ?  <div className="headerArea"> <div className="navbar row text-center hrTop">
				{this.props.surveyData && !this.props.surveyData.firstPage ? <a href onClick={this.backwardSurveyFrom.bind(this)} className="backButton">&lt; {localizedData.back}</a> : null }
				{((this.state.searchPerson || this.state.searchDepartment) && this.props.surveyData.firstPage) ? <button onClick={this.backToLandingPage.bind(this)} className="simulateAnchor backButton">&lt; {localizedData.back}</button> : null }
			 </div>          <div className={'companyHero ' + process.env.CLIENT_NAME}><div><p>{localizedData.recognitionMatters}</p></div></div>
			<div className="row cardholder serveSurvey">
			{(!this.state.searchPerson && !this.state.searchDepartment && this.state.wrappingNewSurvey) && <p className="addMoreFeedback"><b>{localizedData.thank_you}</b>  {localizedData.forgotSomeone}<br/>  {localizedData.leaveMoreFeedback}</p>}
			{(!this.state.searchPerson && !this.state.searchDepartment) && <div className="buttonContainer">
					<p>{localizedData.howToFeedback}</p>
			<label className="anonymousContainer">
				<span>Anonymous Feedback: </span>
				<Switch onChange={this.handleAnonChange} checked={this.state.isAnon} className="react-switch" />
			</label>
					<button className="btn landingPageOption" onClick={this.handleSearchButton.bind(this, 1)}>{localizedData.searchByName}</button>
					<button className="btn landingPageOption" onClick={this.handleSearchButton.bind(this, 2)}>{localizedData.searchByDept}</button>
				</div>
			}
				 {this.state.searchPerson && <div className="searchContainer">
						<div className="searchContainerSub">
							<label htmlFor="searchByNameInput">{localizedData.recognizeByName}</label>

					 <AsyncTypeahead
					 id="searchByNameInput"
						isLoading={this.state.isLoading}
						 labelKey="name"
						onSearch={this.handleSearch}
						onChange={this.selectStaffFromSearch}
						options={this.state.searchoptions}
						placeholder={localizedData.search}
						renderMenuItemChildren={(option, props) => (
							<GithubMenuItem key={option.staff_id} user={option} />
						)}
					/>
					<p className='peerToPeerSearchLine'>{localizedData.beginTypingName}</p>
						</div>
					</div>
				}
				{this.state.searchDepartment && <div className="departmentList">
						<p className="departmentListHeader">{localizedData.findByDeptTeam}</p>
						<ul className="listOfDepartments">
										{
												this.state.showingDepartments.map(c => {
															if(c.dept.name!=='Facility' && c.dept.name!=='Telehealth' && c.dept.name!=='Overall Staff Impression' && c.dept.name!=='Overall Meals Impression' && c.dept.name!=='Room Readiness'){
																return  <li key={c.dept.key} onClick={(ev) => this.selectDepartment(c, ev) } ><p className="departmentImage"><img className={"img-responsive"} alt={c.dept.name} src={c.dept.icon.startsWith('http') ? c.dept.icon : process.env.PUBLIC_URL + '/department_assets/' + c.dept.icon} /></p><p className="departmentName">{this.localizeDepartment(c.dept)}</p></li>
															}
												})
										}
						</ul>
					</div>
					}
					<br/>
					{(this.props.surveyData && this.props.surveyData.totalPoints!==undefined) && <p className='pointBalance text-center'><button className="simulateAnchor" onClick={this.handlePointHistoryToggle.bind(this)}>{localizedData.pointBalance} <b><u>{this.props.surveyData.totalPoints}</u></b></button></p>}
					<br/>
					{(window.location.href.indexOf("aristacare") > -1) && <p className="text-center"><a href="https://drive.google.com/file/d/1P7eCNtVsA3Favfpx9FJpW9F5_veqvjRG/view?usp=drive_link" target="_blank">View Merchandise Catalog </a></p>}
					<div className="row cardInstructions text-right" ><p>Language: <button className="simulateAnchor" onClick={(ev) => this.changeLocale(this, 'EN')}>English</button> / <button className="simulateAnchor" onClick={(ev) => this.changeLocale(this, 'ES')}>Español</button></p></div>
			 </div>

			 </div> : null
	 }

	 {this.props.surveyData && (this.state.fullyLoaded && this.state.pointHistoryToggle) ? <div className="headerArea"> <div className="navbar row text-center hrTop">
				<button onClick={this.handlePointHistoryToggle.bind(this)} className="simulateAnchor backButton">&lt; {localizedData.back}</button>
			 </div>          <div className={'companyHero ' + process.env.CLIENT_NAME}><div><p>{localizedData.recognitionMatters}</p></div></div>
			<div className="row cardholder serveSurvey">

				<div   id="scrollableDiv"
						style={{
							height: 1000,
							overflow: 'auto'
						}}>
						<p><b>Scroll to load more</b></p>
					<InfiniteScroll
						dataLength={this.props.staffPointHistory.pointHistory.length}
						next={this.getPointHistory}
						hasMore={this.props.staffPointHistory.more} // Replace with a condition based on your data source
						loader={<p>Loading...</p>}
						scrollThreshold={0.4}
						endMessage={<p>No more data to load.</p>}
					>
			<table className="case-list table">
							<thead>
									<tr>
											<th>Date</th>
											<th>Reason</th>
											<th className="text-center">Points</th>
									</tr>
							</thead>
							<tbody>
							{
									this.props.staffPointHistory.pointHistory.map((c, i) => {
											return <tr className={(i % 2)===0 ? "surveyRow statDeptContainer reportBadgeContainer intakeListRow" : "surveyRow intakeListRow altRowColor statDeptContainer reportBadgeContainer"}  key={i}>
													<td>{dateFormat(c.created, "shortDate")}</td>
													<td>{c.reason}</td>
													<td className="text-center">{c.points}</td>
											</tr>
									})
							}
							</tbody>
					</table>


					</InfiniteScroll>
					{this.props.staffPointHistory.error && <p>There was an error retrieving your recognition history.</p>}
				</div>

			</div>
			</div> : null

		}

			{this.props.surveyData && (this.state.fullyLoaded && this.state.showDepartmentJourneyScreen) ?  <div> <div className="navbar row text-center hrTop">
				{this.props.surveyData && !this.props.surveyData.firstPage ? <button onClick={this.backwardDepartmentJourney.bind(this, null)} className="simulateAnchor backButton">&lt; {localizedData.back}</button> : null }
				<p></p>
			</div><div className="peertopeer departmentList staffDeptHeader">
<ul className="listOfDepartments">
									 <li><p className="departmentImage"><img className={"img-responsive"} alt={this.state.currentDepartmentShown.name} src={this.state.currentDepartmentShown.icon.startsWith('http') ? this.state.currentDepartmentShown.icon : process.env.PUBLIC_URL + '/department_assets/' + this.state.currentDepartmentShown.icon} /></p><p className="departmentName">{this.localizeDepartment(this.state.currentDepartmentShown)}</p></li>
						</ul>
						<p className="recognitionStatement">{localizedData.recognitionStatement}</p>
			</div><div className="row cardholder staffList">

						{ (this.state.currentDeptStaffList!==null) && this.state.currentDeptStaffList.map(c => {
														if(c.staff_id.toString()===this.props.surveyData.urlInfo[0].person_id){
															return;
														} else{
															 return <div className="nopad col-xs text-center" key={c.staff_id}>
																				<label className={"image-checkbox" + (this.isStaffSelected(c.staff_id) ? ' addCheckMark' : '')}>
																					<img alt={c.name} onClick={(ev) => this.chooseStaff(c, ev) } className={"img-responsive" + (this.isStaffSelected(c.staff_id) ? ' activeDept' : '')} src={c.staffimageid.startsWith('http') ? c.staffimageid : process.env.PUBLIC_URL + '/staff_assets/' + c.staffimageid} />
																					<p className="sDeptName">{c.title === 'Dr.' ? c.title : '' } {c.name}</p>
																				</label>
																			</div>
														}

												})
							}

					<div className="navbar fixed-bottom">  {this.props.surveyData && (this.state.showNextButton) ? <button type="button" onClick={ (ev) => this.advanceStaffEndorsementsJourney(this, 'first')} disabled={(this.state.savedSurvey.staff.length===0) ? true : false} className="btn btn-primary nextBtn">{localizedData.next}></button> : null } </div>
					<br/><br/>

			 </div></div> : null
	 }

{this.props.surveyData && (this.state.showStaffJourneyScreen && this.state.fullyLoaded) ?   <div className="innerContent"> <div className="navbar row text-center hrTop">
				{this.props.surveyData && !this.props.surveyData.firstPage ? <button onClick={this.state.showStaffFromSearchScreen ? this.backwardToSearch.bind(this, null) : this.backwardStaffEndorsementJourney.bind(this, null)} className="simulateAnchor backButton">&lt; {localizedData.back}</button> : null }
				<p></p>
			</div>
			<h5 className="surveyPageTitle separator">{localizedData.formatString(localizedData.how_did, this.state.currentStaffDetail.name)}</h5>
				<div id="staffList">
						<div className="staffHeader">
							<div className="staffImage">

								<img className="img-responsive" alt={this.state.currentStaffDetail.name} src={this.state.currentStaffDetail.staffimageid.startsWith('http') ? this.state.currentStaffDetail.staffimageid : process.env.PUBLIC_URL + '/staff_assets/' + this.state.currentStaffDetail.staffimageid} />
								<div className="staffDetails">
									<p className="name">{this.state.currentStaffDetail.name}, {this.state.currentStaffDetail.title}</p>
									<p className="department">{this.state.currentStaffDetail.bio}</p>
									<div className="mostFrequentBadge">
										<div className="theBadge"></div>
										<div className="badgeDetails">
											<p className="badgeTitle"><span className="totalNumberEndorsements"></span>{localizedData.endorsements}</p>
											<p className="badgeDescription">{localizedData.badge_instruction_staff}</p>
										</div>
									</div>
								</div>

							</div>
						</div>
						<div className="menu">
							<p>{localizedData.choose_badges_hr}</p>
						</div>
						<div className="badgeSelector">
										{

												this.props.surveyData.staffEndorsements.map(c => {
													var departmentCheck = (this.state.showStaffFromSearchScreen) ? this.state.currentStaffDetail.department.toString() : this.state.currentStaffDetail.department_id.toString();

													if(c.staff!==null && c.staff!==undefined && c.staff!==''){
														if(c.staff.toString() !== this.state.currentStaffDetail.staff_id.toString()){
															return;
														}
													}
													if(c.department!==undefined && c.department!==null && c.department!=='' && (c.department.toString()!==departmentCheck) && (c.department.toString()!==this.notForParent(departmentCheck))){
														return;
													} else {
														var localeValue = localizedData[c.localization_key];
														if(c.localization_options!==undefined && c.localization_options!==null){
															let tempValue = c.localization_options[this.state.language];
															if(tempValue!==undefined){
																localeValue = tempValue;
															}
														}

														var skipThisOne = false;
														for(var i=0; i<this.props.surveyData.previousBadges.length;i++){
															if(this.props.surveyData.previousBadges[i].staff_id.toString()===this.state.currentStaffDetail.staff_id.toString()){
																if(this.props.surveyData.previousBadges[i].badges!==undefined){
																	for(var j=0; j<this.props.surveyData.previousBadges[i].badges.length;j++){
																		if((this.props.surveyData.previousBadges[i].badges[j]!==undefined) && (this.props.surveyData.previousBadges[i].badges[j].endorsement_id.toString()===c.endorsement_id.toString())){
																			skipThisOne=true;
																			break;
																		}
																	}
																}
															}
														}

														if(!skipThisOne){
																	return <div className="nopad isBadge text-center" key={c.endorsement_id}>
																		<label className={"badge-checkbox" + (this.generateClassNames(c, this.state.currentStaffDetail) ? ' addCheckMark' : '')}>
																			<img onClick={(ev) => this.chooseStaffEndorsement(c, this.state.currentStaffDetail, ev) } className={"img-responsive" + (this.generateClassNames(c, this.state.currentStaffDetail) ? ' activeDept' : '')} src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
																			<p>{localeValue}</p>
																		</label>
																	</div>
														}


													}

												})
										}
						</div>

						<div className="menu">
							<p>{localizedData.write_feedback}</p>
						</div>
						<textarea placeholder={localizedData.feedback_placeholder} id="staffFeedback" onChange={this.handleStaffFeedbackChange.bind(this)} value={this.state.currentStaffDetail.feedback!==undefined ? this.state.currentStaffDetail.feedback : ''} />
						<div className="navbar fixed-bottom"> {this.props.surveyData && (this.state.showNextButton || this.state.savedSurvey.departments.length>0) ? <button type="button" onClick={this.advanceStaffEndorsementsJourney.bind(this, null)} className="btn btn-primary nextBtn">{localizedData.next}></button> : null }</div>
				</div>

			</div> : null
	 }
			<Modal size="sm" show={this.state.confirmOpen} onHide={this.handleClose.bind(this)}>
					<Modal.Header className="modalPadding" >
						<Modal.Title>{localizedData.confirmHRFeedback}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{!this.state.hasNoFeedback && <p>{localizedData.confirmHRSentence}?</p>}
						{this.state.hasNoFeedback && <p className='alert alert-danger'>Please select a badge or provide written feedback to submit.</p>}
					</Modal.Body>
					<Modal.Footer>
					{!this.state.hasNoFeedback && <div>
						<Button  onClick={this.handleClose.bind(this)}>
							{localizedData.confirmNo}
						</Button>
						<Button onClick={this.handleConfirm.bind(this)}>
							{localizedData.confirmYes}
						</Button> </div> }
						{this.state.hasNoFeedback && <div>
						<Button  onClick={this.handleClose.bind(this)}>
							{localizedData.confirmOkay}
						</Button>
						</div>}
					</Modal.Footer>
				</Modal>


		{this.props.surveyData && (this.state.fullyLoaded && this.props.surveyData.currentPage==="page-3") ?   <div className="innerContent"> <div className="navbar row text-center hrTop">
				{this.props.surveyData && !this.props.surveyData.firstPage ? <a href="javascript:void(0);" onClick={(ev)=> this.backwardStaffEndorsementJourney(ev, 'facility')} className="backButton">&lt; {localizedData.back}</a> : null }
				<p>{process.env.CLIENT_NAME}</p>
			</div>
			<h5 className="surveyPageTitle separator">{localizedData.how_was} {this.localizeDepartment(this.state.currentDeptDetail)}?</h5>
				<div id="staffList">
						<div className="staffHeader">
							<div className="staffImage">

								<img className="img-responsive deptImageFix" alt={this.state.currentDeptDetail.name} src={this.state.currentDeptDetail.icon.startsWith('http') ? this.state.currentDeptDetail.icon : process.env.PUBLIC_URL + '/department_assets/' + this.state.currentDeptDetail.icon} />
								<div className="staffDetails">
									<p className="name">{this.localizeDepartment(this.state.currentDeptDetail)}</p>
									<div className="mostFrequentBadge">
										<div className="theBadge"></div>
										<div className="badgeDetails">
											<p className="badgeTitle"><span className="totalNumberEndorsements"></span>{localizedData.endorsements}</p>
											<p className="badgeDescription">{localizedData.badge_instruction_dept}</p>
										</div>
									</div>
								</div>

							</div>
						</div>
						<div className="menu">
							<p>{localizedData.choose_badges_hr}</p>
						</div>
						<div className="badgeSelector">
										{
												this.props.surveyData.deptEndorsements.map(c => {
													if(c.department!==undefined && c.department!==null && c.department!=='' && (c.department.toString()!==this.state.currentDeptDetail.key.toString())){
														return;
													} else {
														var localeValue = localizedData[c.localization_key];
														if(c.localization_options!==undefined && c.localization_options!==null){
															let tempValue = c.localization_options[this.state.language];
															if(tempValue!==undefined){
																localeValue = tempValue;
															}
														}

														return <div className="nopad isBadge text-center" key={c.endorsement_id}>
																		<label className={"badge-checkbox" + (this.generateClassNames(c, this.state.currentDeptDetail) ? ' addCheckMark' : '')}>
																			<img alt="" onClick={(ev) => this.chooseDepartmentEndorsement(c, this.state.currentDeptDetail, ev) } className={"img-responsive" + (this.generateClassNames(c, this.state.currentDeptDetail) ? ' activeDept' : '')} src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
																			<p>{localeValue}</p>
																		</label>
																	</div>
													}
												})
										}

						</div>

						<div className="menu">
							<p>{localizedData.write_feedback}</p>
						</div>
						<textarea placeholder={localizedData.feedback_placeholder} id="deptFeedback" onChange={this.handleDeptFeedbackChange.bind(this)} value={this.state.currentDeptDetail.feedback!==undefined ? this.state.currentDeptDetail.feedback : ''} />
						<div className="navbar fixed-bottom">{this.props.surveyData && (this.state.showNextButton || this.state.savedSurvey.departments.length>0) ? <button type="button" onClick={this.advanceSurveyFrom.bind(this)} className="btn btn-primary nextBtn">{localizedData.next}></button> : null }</div>
				</div>

			</div> : null
	 }


 {this.props.surveyData && (this.props.surveyData.currentPage==="page-5" && this.state.fullyLoaded && !this.state.showReviewPage && !this.state.completedSurvey) ?   <div> <div className="navbar row text-center hrTop">
				<p>{process.env.CLIENT_NAME}</p>
			</div><div className="row cardholder">
					<h5 className="surveyPageTitle">{localizedData.spinWheel}</h5>
					 <div className="prizeSpinContainer">
						<div className="prizeTicker"/>
						<ReactTurntable {...this.state.options} onStart={this.startPrizeDrawing.bind(this)} onComplete={this.finishPrizeDrawing.bind(this)}/>
					</div>
					<a className="ruleLink hidden hide" href="javascript:void(0);" target="_blank">{localizedData.viewrules}</a>
			 </div></div> : null
	 }

 {this.props.surveyData && (this.props.surveyData.currentPage==="page-5" && this.state.fullyLoaded && this.state.showReviewPage) ?   <div> <div className="navbar row text-center hrTop">
				<p>{process.env.CLIENT_NAME}</p>
			</div><div className="row cardholder">
					<h5 className="surveyPageTitle">{localizedData.you_won} {this.state.prizeWinnings}!</h5>
					{(!this.props.surveyData.urlInfo[0].google_id && !this.props.surveyData.urlInfo[0].yelp_id) && <h5 className="surveyPageTitle">{localizedData.thank_you}</h5>}
					{(this.props.surveyData.urlInfo[0].google_id || this.props.surveyData.urlInfo[0].yelp_id) && <p className="surveyPageInstruction text-center">{localizedData.reviewPromptText}</p>}
					{this.props.surveyData.urlInfo[0].google_id && <a href={'https://search.google.com/local/writereview?placeid=' + this.props.surveyData.urlInfo[0].google_id} className="reviewSite" ><img src="./google-reviews.png" /></a>}
					{this.props.surveyData.urlInfo[0].yelp_id &&  <a href={this.props.surveyData.urlInfo[0].yelp_id} className="reviewSite" ><img src="./yelp_fullcolor.png" /></a> }

			 </div></div> : null
	 }

 {this.props.surveyData && (this.props.surveyData.invalidEmployee) ?   <div> <div className="row cardholder serveSurvey">
					{(this.props.surveyData.urlInfo[0].logo===null || this.props.surveyData.urlInfo[0].logo===undefined) ? <img className="inSurveyLogo" src="/newlogo.png" alt="Logo"/> : <img className="inSurveyLogo" src={this.props.surveyData.urlInfo[0].logo} alt="Logo"/>}
			 </div></div> : null
	 }

 {this.props.surveyData && (this.state.notYourSurvey && !this.state.restartedSurvey) ?   <div> <div className="row cardholder serveSurvey">
					{(this.props.surveyData.urlInfo[0].logo===null || this.props.surveyData.urlInfo[0].logo===undefined) ? <img className="inSurveyLogo" src="/newlogo.png" alt="Logo"/> : <img className="inSurveyLogo" src={this.props.surveyData.urlInfo[0].logo} alt="Logo"/>}
					<h5 className="surveyPageTitle">{localizedData.another_device}</h5>
					<button className="btn btn-primary centerButton" onClick={this.restartSurvey.bind(this)}>{localizedData.start}</button>
			 </div></div> : null
	 }

		</div>

		}
}

const mapStateToProps = (state) => {
		return {
				surveyData: state.survey.surveyData,
				existingSurvey: state.survey.existingSurvey,
				tvClient: state.login.tvClient,
				staffData: state.staff.staffData,
				staffPointHistory: state.staffPointHistory
		};
};

const mapDispatchToProps = (dispatch) => {
		return {
			surveyBegin: (...params) => dispatch(surveyBegin(...params)),
			getDeptStaff: (...params) => dispatch(getDeptStaff(...params)),
			findStaffByDept: (...params) => dispatch(findStaffByDept(...params)),
			cachedResults: (...params) => dispatch(cachedResults(...params)),
			updateSurvey: (...params) => dispatch(updateSurvey(...params)),
			createPatient: (...params) => dispatch(createPatient(...params)),
			beginNewSurvey: (...params) => dispatch(beginNewSurvey(...params)),
			getUserDetails: (...params) => dispatch(getUserDetails(...params)),
			executePersonalSurvey: (...params) => dispatch(executePersonalSurvey(...params)),
			rewardPrizeTickets: (...params) => dispatch(rewardPrizeTickets(...params)),
			restartPatientSurvey: (...params) => dispatch(restartPatientSurvey(...params)),
			findStaffByName: (...params) => dispatch(findStaffByName(...params)),
			retrieveStaffPointHistory: (...params) => dispatch(retrieveStaffPointHistory(...params))
		};
};

export default connect(mapStateToProps, mapDispatchToProps)(ServeHRJourneySurvey);
