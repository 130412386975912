import React, {Component} from "react";
import {
		Table,
		Dropdown
} from "react-bootstrap";
import { viewSurvey, listAllDeptFeedbackReport, listLocations } from "../../../actions";
import "./../ReportsPage/ReportsPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import dateFormat from "dateformat";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import PaginationArea from "./../../Utilities/PaginationArea";
import ExportDeptCSV from './../../Utilities/ExportDeptCSV';
// Include the locale utils designed for moment
import MomentLocaleUtils, {formatDate, parseDate,} from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import queryString from 'query-string';
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';


class WrittenDeptFeedbackReport extends Component {

		constructor(props) {
				super(props);

				this.state = {
						perpage: 20,
						filter: '',
						sort: null,
						sortDirection: 'desc',
						currentFilter: 8,
						activeKey: 1,
						dateFilter: 'thismonth',
						locationFilter: 0,
						currentFilterName: '  This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ',
						currentFilterLocation: ' All Locations ',
						showCustomDate: false,
						showLocations: true,
						reportFrom: null,
						reportTo: null
				};

				this.handleFromChange = this.handleFromChange.bind(this);
				this.handleToChange = this.handleToChange.bind(this);
				this.runReport = this.runReport.bind(this);

				this.props.listLocations(this.props.tvClient).then(()=>{
					if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
							if(this.props.roleLocations!==undefined){
									var newLocation = null;
									var newLocationName = '';
									for(var i=0; i<this.props.roleLocations.length;i++){
											newLocation = this.props.roleLocations[i];
											break;
									}
									if(newLocation!==null){
											for (var i = this.props.locations.length - 1; i >= 0; i--) {
												if(this.props.locations[i].location_id.toString()===newLocation.toString()){
															newLocationName = ' ' + this.props.locations[i].name + ' ';
															break;
												}
											}
									}
									if(newLocation!==null && newLocationName!==''){
											this.setState({ locationFilter: newLocation, currentFilterLocation: newLocationName }, ()=>{
													this.changeListPage(1);
											});
									}
							}
						}
				});
		}


		handleFromChange(from) {
			this.setState({ reportFrom: from });
		}
		handleToChange(to) {
			this.setState({ reportTo: to });
		}

		viewSurvey(id){
				this.props.viewSurvey(this.props.tvClient, id, 'reports/deptfeedback', this.state.dateFilter, this.state.locationFilter);
		}

		runReport(){
			this.setState({ dateFilter: 'custom', currentFilter: 6, currentFilterName: ' Date Range (' + dateFormat(this.state.reportFrom, "shortDate") + ' to ' + dateFormat(this.state.reportTo, "shortDate") + ') ' });
			//this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
			this.props.listAllDeptFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
		}

		handleLocationFilter(selectedKey){
			if(parseInt(selectedKey)===0){
				this.setState({ locationFilter: 0, currentFilterLocation: ' All Locations '});
				if(this.state.dateFilter==='custom'){
						this.props.listAllDeptFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, 0, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
				} else{
						this.props.listAllDeptFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, 0, this.state.dateFilter, null, null);
				}

			}
			else{
				for (var i = this.props.locations.length - 1; i >= 0; i--) {
					if(this.props.locations[i].location_id===parseInt(selectedKey)){
						this.setState({ locationFilter: selectedKey, currentFilterLocation: ' ' + this.props.locations[i].name + ' ' });
						if(this.state.dateFilter==='custom'){
								this.props.listAllDeptFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, selectedKey, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
						} else{
								this.props.listAllDeptFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, selectedKey, this.state.dateFilter, null, null);
						}

						break;
					}
				}

			}
		}

		handleFilter(selectedKey) {
			if(selectedKey==1){
				this.setState({ dateFilter: 'alltime', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' All Time ' }, () => {
					this.props.listAllDeptFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
				});
			} else if(selectedKey==2){
				this.setState({ dateFilter: 'thismonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
					this.props.listAllDeptFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
				});
			} else if(selectedKey==3){
				this.setState({ dateFilter: 'lastmonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Month (' + new Date(new Date().getFullYear(), new Date().getMonth()-1).toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
					this.props.listAllDeptFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null,null);
				});
			} else if(selectedKey==4){
				this.setState({ dateFilter: 'yeartodate', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Year To Date (' + new Date().toLocaleString('en-us', { year: 'numeric' }) + ') ' }, () => {
					this.props.listAllDeptFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
				});
			} else if(selectedKey==5){
				this.setState({ dateFilter: 'lastyear', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Year (' + new Date(new Date().getFullYear()-1, 1).toLocaleString('en-us', { year: 'numeric' }) + ') ' } , () => {
					this.props.listAllDeptFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
				});
			} else if(selectedKey==6){
				this.setState({ showCustomDate: true });
			}
			if(selectedKey==8){
				this.setState({ dateFilter: 'alltime', showCustomDate: false, currentFilterName: ' All Time ', currentFilter: selectedKey }, () => {
					this.props.listAllDeptFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
				});

			}
		}

		componentDidMount() {
			window.scrollTo(0,0);
			const queryStringValues = queryString.parse(this.props.location.search);
			if(queryStringValues.refresh!==null && queryStringValues.refresh!==undefined){
					if(queryStringValues.refresh==1){
							this.changeListPage(1);
					} else {
							if(this.props.deptFeedbackData.length===0){
									this.changeListPage(1);
							} else{

									if(queryStringValues.dateFilter=='alltime'){
										this.setState({ dateFilter: 'alltime', showCustomDate: false, currentFilter: queryStringValues.dateFilter, currentFilterName: ' All Time ' });
									} else if(queryStringValues.dateFilter=='thismonth'){
										this.setState({ dateFilter: 'thismonth', showCustomDate: false, currentFilter: queryStringValues.dateFilter, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' });
									} else if(queryStringValues.dateFilter=='lastmonth'){
										this.setState({ dateFilter: 'lastmonth', showCustomDate: false, currentFilter: queryStringValues.dateFilter, currentFilterName: ' Last Month (' + new Date(new Date().getFullYear(), new Date().getMonth()-1).toLocaleString('en-us', { month: 'long' }) + ') ' });
									} else if(queryStringValues.dateFilter=='yeartodate'){
										this.setState({ dateFilter: 'yeartodate', showCustomDate: false, currentFilter: queryStringValues.dateFilter, currentFilterName: ' Year To Date (' + new Date().toLocaleString('en-us', { year: 'numeric' }) + ') ' });
									} else if(queryStringValues.dateFilter=='lastyear'){
										this.setState({ dateFilter: 'lastyear', showCustomDate: false, currentFilter: queryStringValues.dateFilter, currentFilterName: ' Last Year (' + new Date(new Date().getFullYear()-1, 1).toLocaleString('en-us', { year: 'numeric' }) + ') ' });
									} else if(queryStringValues.dateFilter=='alltime'){
										this.setState({ dateFilter: 'alltime', showCustomDate: false, currentFilterName: ' All Time ', currentFilter: queryStringValues.dateFilter });
									}

									if(parseInt(queryStringValues.locationFilter)===0){
										this.setState({ locationFilter: 0, currentFilterLocation: ' All Locations '});
									} else{
											for (var i = this.props.locations.length - 1; i >= 0; i--) {
												if(this.props.locations[i].location_id===parseInt(queryStringValues.locationFilter)){
													this.setState({ locationFilter: queryStringValues.locationFilter, currentFilterLocation: ' ' + this.props.locations[i].name + ' ' });
													break;
												}
											}
									}



							}
					}
			} else{
				this.changeListPage(1);
			}

		}

		changeListPage(page) {
				if(this.state.dateFilter==='custom'){
						this.props.listAllDeptFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, page, this.state.perpage, this.state.locationFilter, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
				} else{
						this.props.listAllDeptFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, page, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
				}

		}

		render() {
			 return <div>
						<h1 className="page-header">
								Written Facility Feedback
										<div className="float-right">

												 <Dropdown
													title='Filter Report By Date'
													id='filterSurvey'
													drop="down" onSelect={this.handleFilter.bind(this)}
												>
													<Dropdown.Toggle className="btn-default" id="dropdown-basic">
														{this.state.currentFilterName}
													</Dropdown.Toggle>
													<Dropdown.Menu>
													<Dropdown.Item eventKey="1">All Time</Dropdown.Item>
													<Dropdown.Item eventKey="2">This Month</Dropdown.Item>
													<Dropdown.Item eventKey="3">Last Month</Dropdown.Item>
													<Dropdown.Item eventKey="4">YTD</Dropdown.Item>
													<Dropdown.Item eventKey="5">Last Year</Dropdown.Item>
													<Dropdown.Item eventKey="6">Custom</Dropdown.Item>
													<Dropdown.Divider />
													<Dropdown.Item eventKey="8">Reset Filter</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</div>

							{this.state.showCustomDate && <div className="customDateRange">
									<p className="prizeDrawTitle">Select the range to run the report for:</p>
									<label className="dayPickerLabel">From:</label><DayPickerInput formatDate={formatDate} id="fromDate" name="fromDate" onDayChange={this.handleFromChange}
										dayPickerProps={{
											selectedDays: this.state.selectedDay,
											disabledDays: {
												after: new Date(),
											},
										}} />

								<label className="dayPickerLabel">To:</label><DayPickerInput formatDate={formatDate} id="toDate" name="toDate" onDayChange={this.handleToChange}
										dayPickerProps={{
											selectedDays: this.state.selectedDay,
											disabledDays: {
												after: new Date(),
												before: this.state.prizeFrom
											},
										}} />

							<button className="btn btn-primary" onClick={this.runReport}>{this.props.runningReport ? 'Running...' : 'Run Report'}</button>
							</div>
						}
						{(this.state.showLocations && this.props.locations) && <div className="customLocationRange">


												 <Dropdown
													title='Choose a Location'
													id='filterSurvey'
													drop="down" onSelect={this.handleLocationFilter.bind(this)}
												>
													<Dropdown.Toggle className="btn-default" id="dropdown-basic">
														{this.state.currentFilterLocation}
													</Dropdown.Toggle>
													<Dropdown.Menu>
													{(!this.props.isLocationOnly && !this.props.isSurveysReportsOnly) && <Dropdown.Item eventKey={0}>All Locations</Dropdown.Item> }
													{Object.values(this.props.locations).map(d => {
														if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
															if(this.props.roleLocations!==undefined){
																	for(var i=0; i<this.props.roleLocations.length;i++){
																			if(this.props.roleLocations[i].toString()===d.location_id.toString()){
																					return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
																			}
																	}
															} else{
																	return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
															}
													} else{
															return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
													}
											})}
												</Dropdown.Menu>
										</Dropdown>
						</div> }
						</h1>
						<p className="case-list-container stand-out-line float-right">Showing data for <span className="text-underline">{this.state.currentFilterName}</span> at <span className="text-underline">{this.state.currentFilterLocation}</span></p>
						<div className="case-list-container">

								{  this.props.deptFeedbackData && <Table className="case-list sortable reportBadgeContainer table-hover">
										<thead>
										<tr className="reportBadgeContainer table-headings">
												<th>Name</th>
												<th>Feedback</th>
												<th className="text-center">Badge Count</th>
												<th className="text-center">Survey Date</th>
												<th className="text-center">Survey Link</th>
										</tr>
										</thead>
										<tbody>
										{
												this.props.deptFeedbackData.map((c,index) => {
														return <tr className="surveyRow statDeptContainer reportBadgeContainer" onClick={()=> this.viewSurvey(c.survey_id)} key={index}>
																<td>{c.response.name}</td>
																<td>{c.response.feedback}</td>
																<td className="text-center">{c.response.badges!==undefined ? c.response.badges.length : '0'}</td>
																<td className="text-center">{dateFormat(c.created, "shortDate")}</td>
																<td className="text-center totalTdValue">View Survey</td>
														</tr>
												})
										}
										</tbody>
								</Table> }
								{this.props.exportDeptFeedbackData.length>0 && <div className="float-right">
									<ExportDeptCSV csvData={this.props.exportDeptFeedbackData} fileName='WrittenOverallFeedback.csv' />
							</div>}
								{(!this.props.deptFeedbackDataLoading && this.props.deptFeedbackData.length===0) && <p className="text-center">No Results Found</p>}
								{this.props.deptFeedbackDataLoading && <Spinner/>}
								<PaginationArea currentPage={this.props.paginationInfo.current_page} parentRefresh={this.changeListPage.bind(this)} numberOfPages={this.props.paginationInfo.num_pages} items={this.props.paginationItems} />

						</div>
				</div>
		}
}

const mapStateToProps = state => {
		return {
				tvClient: state.login.tvClient,
				accessToken: state.login.tvClient.accessToken,
				deptFeedbackDataLoading: state.deptFeedbackData.loading,
				deptFeedbackData: state.deptFeedbackData.deptFeedbackData,
				exportDeptFeedbackData: state.deptFeedbackData.exportDeptFeedbackData,
				paginationInfo: state.deptFeedbackData.paginationInfo,
				paginationItems: state.deptFeedbackData.paginationInfo.pageList,
				locations: state.locationList.locations,
				isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
				isSurveysReportsOnly: state.login.user && state.login.user.attributes.role === 'surveysreportsonly',
				roleLocations: state.login.user.attributes.locations
		};
};

const mapDispatchToProps = dispatch => {
		return {
				listLocations: (...params) => dispatch(listLocations(...params)),
				listAllDeptFeedbackReport: (...params) => dispatch(listAllDeptFeedbackReport(...params)),
				viewSurvey: (...params) => dispatch(viewSurvey(...params))
		};
};

export default connect(mapStateToProps, mapDispatchToProps)(WrittenDeptFeedbackReport);
