import React, {Component} from "react";
import ReactDOM from "react-dom";
import {connect} from "react-redux";
import {Button, Form, FormGroup, InputGroup, Overlay, Popover} from "react-bootstrap";
import {requestTheirPhoto} from "../../actions";

class RequestStaffPhotoOverlay extends Component {

		constructor(props) {
				super(props);
				this.state = {show: false};
		}

		requestThePhoto(e) {
				e.preventDefault();
				this.props.requestTheirPhoto(this.props.tvClient, this.props.staffDetail.staff_id, this.hide.bind(this));
		}

		hide() {
				this.setState({show: false});
				this.props.parentRefresh();
		}

		hide2(){
				this.setState({show:false});
		}

		render() {
				return <div>
						<button className="simulateAnchor requestStaffButton" ref="target" onClick={e => this.setState({show: true})}>Request Staff Photo</button>

						<Overlay show={this.state.show} rootClose target={props => ReactDOM.findDOMNode(this.refs.target)}
										 onHide={this.hide2.bind(this)} placement="top">
								<Popover id="invite-patient" title="Request Staff Photo" className="invite-patient-overlay">
										<Popover.Title>Request Staff Photo</Popover.Title>
										<Popover.Content>
												<Form onSubmit={this.requestThePhoto.bind(this)}>
														<FormGroup controlId="requestphoto">
																<InputGroup>
																		<InputGroup.Prepend>
																				<p>This will send <b>{this.props.staffDetail.name}</b> a request to upload their own photo. Are you sure you want to proceed?</p>
																				<Button type="submit" className="popoverButton" variant="danger">
																						Yes
																				</Button>
																		</InputGroup.Prepend>
																</InputGroup>
														</FormGroup>
												</Form>
										</Popover.Content>
								</Popover>
						</Overlay>
				</div>
		}
}

const mapStateToProps = state => {
		return {
				tvClient: state.login.tvClient
		};
};

export default connect(mapStateToProps, {requestTheirPhoto})(RequestStaffPhotoOverlay);
