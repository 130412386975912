import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    FormLabel,
    Form,
    FormControl,
    FormGroup
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {updateAdmin, listLocations, viewAdmin, getAdminLocations } from "../../actions";
import "./EditAdmin.scss";

class EditAdmin extends Component {

    componentDidMount() { 
        return this.props.viewAdmin(this.props.tvClient, this.props.match.params.documentId).then(()=>{
            if(this.props.adminData!==undefined && this.props.adminData.role==='locationonly'){
                // Need to find this users locations from TV
                this.props.getAdminLocations(this.props.tvClient, this.props.adminData.tv_id);
                this.setState({showLocations: true});
            } else if(this.props.adminData!==undefined && this.props.adminData.role==='surveysreportsonly'){
              // Need to find this users locations from TV
              this.props.getAdminLocations(this.props.tvClient, this.props.adminData.tv_id);
              this.setState({showSimpleLocations: true});
          }
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            fields: { 'role': null },
            errors: { 'name': false, 'email': false, 'mobile': false, 'role': false },
            editLoading: true,
            primaryLocation: 0,
            primarySimpleLocation: 0,
            showLocations: false,
            showSimpleLocations: false
        };
        
         this.props.listLocations(this.props.tvClient).then(() => {
            let defaultValue = [];
            for (var i = this.props.locations.length - 1; i >= 0; i--) {
                defaultValue.push(this.props.locations[i].location_id);
            }
            this.setState({
              primaryLocation: defaultValue,
              primarySimpleLocation: defaultValue
            });
        });
    

        this.handleFormChange = this.handleFormChange.bind(this);

    }  

    handleLocationList(e){
        e.preventDefault();

        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
        }   

        this.setState({
            primaryLocation: value
        });
    }       

    handleFormChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;

      let showLocations = this.state.showLocations;
      let showSimpleLocations = this.state.showSimpleLocations;

      if(e.target.value==='locationonly'){
        showLocations = true;
        showSimpleLocations = false;
      } else if(e.target.value==='surveysreportsonly'){
        showSimpleLocations = true;
        showLocations = false;
      } else{
        showLocations = false;
        showSimpleLocations = false;
      }

      this.setState({
        fields: fields,
        showLocations: showLocations,
        showSimpleLocations: showSimpleLocations
      });
    }    

  handleSimpleLocationList(e){
      e.preventDefault();

      var options = e.target.options;
      var value = [];
      for (var i = 0, l = options.length; i < l; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
      }   

      this.setState({
          primarySimpleLocation: value
      });
  } 

    editAdmin(e) {
        e.preventDefault();
        var theRole = this.state.fields['role'];
        if(theRole===null){
            theRole = this.props.adminData.role;
        }
        this.props.updateAdmin(
            this.props.tvClient,
            this.staffName.value,
            this.staffPhone.value,
            this.props.adminData.admin_id,
            theRole,
            this.state.primaryLocation,
            this.state.primarySimpleLocation
        );                
    }

    render() {
        return <div>
            <h1 className="page-header">
                Edit Admin
                <small>Please update any of the following information</small>
                <small className="backLink"><button className="simulateAnchor" onClick={() => window.history.back()} >Go Back</button></small>
            </h1>
            
            {this.state.viewingAdmin && <Spinner/>}

            {this.props.adminData && <Form onSubmit={this.editAdmin.bind(this)} className="new-case-form">

                {this.props.viewAdminError && <Alert variant="danger">
                    {this.props.viewAdminError.message}
                </Alert>}

                <FormGroup controlId="staffname">
                    <FormLabel>Name *</FormLabel>
                    <FormControl type="text" placeholder="John Doe" defaultValue={this.props.adminData.name} ref={ref => this.staffName = ref}/>
                    {this.state.errors.name && <Alert variant="danger">
                        Name is required.
                    </Alert>}
                </FormGroup>          

                <FormGroup controlId="staffPhone">
                    <FormLabel>Phone</FormLabel>
                    <FormControl type="text" placeholder="555-555-5555" defaultValue={this.props.adminData.mobile} ref={ref => this.staffPhone = ref}/>
                </FormGroup>
              <div className="form-group">
                <label htmlFor="role">Role: <span className="text-danger">*</span></label>      
                <div className="radio">
                  <label>
                    <input type="radio" name="role" value="admin" defaultChecked={this.props.adminData.role==='admin' ? true : false} onChange={this.handleFormChange}/>
                    Full Admin
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="role" value="frontdesk" defaultChecked={this.props.adminData.role==='frontdesk' ? true : false} onChange={this.handleFormChange}/>
                    SMS Only
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="role" value="viewonly" defaultChecked={this.props.adminData.role==='viewonly' ? true : false} onChange={this.handleFormChange}/>
                    View Only
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="role" value="eventsonly" defaultChecked={this.props.adminData.role==='eventsonly' ? true : false} onChange={this.handleFormChange}/>
                    Addressable Events Only
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="role" value="surveysreportsonly" defaultChecked={this.props.adminData.role==='surveysreportsonly' ? true : false} onChange={this.handleFormChange}/>
                    Surveys & Reports Only
                  </label>
                  </div>     
                                <div className="errorMsg">{this.state.errors.role}</div>
                    {this.props.adminLocationLoading && <div><p>Loading previously selected locations.  Please wait.</p><br/><Spinner/></div>}
                  {(this.state.showSimpleLocations && !this.props.adminLocationLoading) &&  <FormGroup controlId="primaryLocation" className="adminLocationSelection">
                        <FormLabel>Choose Locations *</FormLabel>
                        <FormControl as="select" placeholder="select" defaultValue={this.props.adminLocations} onChange={this.handleSimpleLocationList.bind(this)} multiple>
                            {Object.values(this.props.locations).map(d => {
                                return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                            })}
                        </FormControl>                    


                        {this.state.errors.primarySimpleLocation && <Alert variant="danger">
                            A location is required.
                        </Alert>}
                    </FormGroup>
                  }
                <div className="radio">
                  <label>
                    <input type="radio" name="role" value="locationonly" defaultChecked={this.props.adminData.role==='locationonly' ? true : false} onChange={this.handleFormChange}/>
                    Location Specific
                  </label>
                </div>                
                <div className="errorMsg">{this.state.errors.role}</div>
                    {this.props.adminLocationLoading && <div><p>Loading previously selected locations.  Please wait.</p><br/><Spinner/></div>}
                  {(this.state.showLocations && !this.props.adminLocationLoading) &&  <FormGroup controlId="primaryLocation" className="adminLocationSelection">
                        <FormLabel>Choose Locations *</FormLabel>
                        <FormControl as="select" placeholder="select" defaultValue={this.props.adminLocations} onChange={this.handleSimpleLocationList.bind(this)} multiple>
                            <option value="0">All Locations</option>
                            {Object.values(this.props.locations).map(d => {
                                return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                            })}
                        </FormControl>                    


                        {this.state.errors.primaryLocation && <Alert variant="danger">
                            A location is required.
                        </Alert>}
                    </FormGroup>
                  }
              </div>


                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.viewingAdmin} className="float-right">
                        Update
                    </Button>
                </FormGroup>
            </Form>}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        viewingAdmin: state.viewAdmin.viewingAdmin,
        viewAdminError: state.viewAdmin.viewAdminError,
        tvClient: state.login.tvClient,
        adminData: state.viewAdmin.adminData,
        locations: state.locationList.locations,
        adminLocationLoading: state.editAdminLocations.loading,
        adminLocations: state.editAdminLocations.locations
    };
};

const mapDispatchToProps = dispatch => {
    return {
        viewAdmin: (...params) => dispatch(viewAdmin(...params)),
        updateAdmin: (...params) => dispatch(updateAdmin(...params)),
        listLocations: (...params) => dispatch(listLocations(...params)),
        getAdminLocations: (...params) => dispatch(getAdminLocations(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAdmin);

