/**
 * This file contains "actions", which are global methods used to modify global application state. This
 * pattern strikes a balance between the convenience of a single source of global state and avoiding the
 * risk of globally mutating global state. Suppose a particular Component wants to add a flash message.
 * It doesn't directly update the global state. Instead, it uses the `displayFlashMessage` action. That
 * method dispatches an action, which is handled by reducers.js.
 *
 * For more details about this pattern, refer to the [Actions](http://redux.js.org/docs/basics/Actions.html) and
 * [Reducers](http://redux.js.org/docs/basics/Reducers.html) sections of the [redux documentation](http://redux.js.org/).
 * Note that we also use the [redux action helpers](https://github.com/acdlite/redux-actions) to reduce the amount
 */
import {createAction} from "redux-actions";
import {push, replace} from "connected-react-router";
import TrueVaultClient from "truevault";
import ApiHelper from "./api-helpers.js";
import PublicHelper from "./public-helpers.js"
import InternalApiClient from "../src/internal-api-client.js";
import localizedDashboardData from "./components/Localization/Dashboard/DashboardLocalization";

export const internalApiClient = new InternalApiClient(process.env.REACT_APP_API_DOMAIN);
export const publicHelpers = new PublicHelper('');
export const apiHelpers = new ApiHelper('');

const removeFlashMessage = createAction('REMOVE_FLASH_MESSAGE');
let flashIdCounter = 0;
let adminLanguage = 'EN';
let currentIntakes = [];
let sseSource = undefined;
localizedDashboardData.setLanguage(adminLanguage);

function displayFlashMessage(style, message) {
		const action = createAction('DISPLAY_FLASH_MESSAGE');
		return dispatch => {
				const key = `flash-${flashIdCounter++}`;
				dispatch(action({style, message, key}));
				setTimeout(() => dispatch(removeFlashMessage(flashIdCounter)), 5000);
		};
}

const loginStart = createAction('LOGIN_START');
const loginSuccess = createAction('LOGIN_SUCCESS');
const loginFailure = createAction('LOGIN_FAILURE');
/**
 * Logs in to the application by calling the TrueVault login API Endpoint. Note that this doesn't interact with the
 * Internal API for authentication, it is all done through TrueVault directly from the browser. TrueVault credentials
 * shouldn't pass through the server if you can avoid it.
 * @param username The username, stored in TrueVault
 * @param password The password, validated by TrueVault
 * @returns {function(*)}
 */
export function login(username, password, mfa, newPassword, newPasswordConfirm) {
		return async dispatch => {
				dispatch(loginStart());
				try {
						var validPwd = true,
								resetPwd = false,
								reviewFlag = false,
								reputationFlag = false,
								intakeFlag = false;


						if(newPassword!==null || newPasswordConfirm!==null){
								resetPwd = true;
								var upperRegex = new RegExp("(?=.*[A-Z])"),
								lowerRegex = new RegExp("(?=.*[a-z])"),
								numberRegex = new RegExp("(?=.*[0-9])");
								// eslint-disable-next-line
								var specialRegex = new RegExp("(?=.[!@#\$%\^&\(\))\?\*\+])"),
								errorType = 'PWD_RESET_REQ';

								// This means they have reset it and they have logged in successfully
								if(newPassword!==newPasswordConfirm){
										validPwd = false;
										dispatch(loginFailure({type: errorType, message: localizedDashboardData.passwordsMatch }));
								} else if(newPassword.length < process.env.REACT_APP_PWD_MIN_LENGTH ){
										validPwd = false;
										//{localizedData.formatString(localizedData.how_did, this.state.currentStaffDetail.name)}
										//'Your password must be at least '+ process.env.REACT_APP_PWD_MIN_LENGTH +' characters long'
										dispatch(loginFailure({type: errorType, message: localizedDashboardData.formatString(localizedDashboardData.passwordsMinLength, process.env.REACT_APP_PWD_MIN_LENGTH)}));
								}
								else if(process.env.REACT_APP_PWD_CONTAIN_UC.toString()==='true' && !upperRegex.test(newPassword)){
										validPwd = false;
										dispatch(loginFailure({type: errorType, message: localizedDashboardData.passwordsCapital}));
								}
								else if(process.env.REACT_APP_PWD_CONTAIN_LC.toString()==='true' && !lowerRegex.test(newPassword)){
										validPwd = false;
										dispatch(loginFailure({type: errorType, message: localizedDashboardData.passwordsLowercase}));
								}
								else if(process.env.REACT_APP_PWD_CONTAIN_NUMBER.toString()==='true' && !numberRegex.test(newPassword)){
										validPwd = false;
										dispatch(loginFailure({type: errorType, message: localizedDashboardData.passwordsNumber}));
								}
								else if(process.env.REACT_APP_PWD_CONTAIN_SPECIAL.toString()==='true' && !specialRegex.test(newPassword)){
										validPwd = false;
										dispatch(loginFailure({type: errorType, message: localizedDashboardData.passwordsSpecialChar}));
								}
						}

						if(validPwd){

								const tvClient = await TrueVaultClient.login(process.env.REACT_APP_ACCOUNT_ID, username, password);
								// The login endpoint doesn't return user attributes. We need those to get the user's name and role, so load the full user object.
								const user = await tvClient.readCurrentUser();
								
								try{
										const reviewsEnabled = await internalApiClient.getReviewsEnabledFlag(tvClient);
										const intakeEnabled = await internalApiClient.getIntakeEnabledFlag(tvClient);
										if(reviewsEnabled!==undefined && (reviewsEnabled.reviews==='true' || reviewsEnabled.reviews===true)){
												reviewFlag = true;
										}
										if(reviewsEnabled!==undefined && (reviewsEnabled.reputation==='true' || reviewsEnabled.reputation===true)){
												reputationFlag = true;
										}
										if(intakeEnabled!==undefined && (intakeEnabled.intake==='true' || intakeEnabled.intake===true)){
												intakeFlag = true;
										}
								} catch(e){
										reviewFlag = false;
								}

								// A properly created user will always have name and role attributes. However, a user could conceivably be
								// edited in the console or created incorrectly. We check to make sure the attributes are properly configured,
								// to avoid UI errors down the line.
								const role = user.attributes.role;
								if (role !== 'admin' && role !== 'patient' && role !== 'frontdesk' && role !== 'viewonly' && role != 'eventsonly' && role !== 'locationonly' && role !=='surveysreportsonly') {
										throw Error(`Invalid user; role attribute must be admin| but was ${role}`);
								}

								const name = user.attributes.name;
								if (!name) {
										throw Error('Invalid user; name attribute must be present');
								}

								const groups = user.group_ids;
								var validGroup = false;
								for (var i = groups.length - 1; i >= 0; i--) {
										if(groups[i]===process.env.REACT_APP_PATIENTS_GROUP_ID){
												validGroup = true;
										}
								}

								if(!validGroup){
										throw Error('Invalid user; no access to this account.');
								}

								if(resetPwd){
										const updatedadmin = await internalApiClient.updateAdminReset(tvClient, user.id);
										const updateduser = await tvClient.updateUserPassword(user.id, newPassword);
										throw Error(localizedDashboardData.resetPasswordSuccess);
								} else{
										// Password Change Check
										// Check to see when this admin user last changed their password
										const staffDocument = await internalApiClient.getAdminByTv(tvClient, user.id);
										if(staffDocument.length>0){
												var adminDetails = staffDocument[0];
												var lastDate = new Date(adminDetails.last_pwd_reset).getTime();
												var today = new Date().getTime();
												var daysBetween = Math.ceil((today - lastDate) / (1000*60*60*24));
												var resetLimit = process.env.REACT_APP_PWD_CHANGE_DAYS ? process.env.REACT_APP_PWD_CHANGE_DAYS : 365;

												if(parseInt(daysBetween) >= parseInt(resetLimit)){
														dispatch(loginFailure({type: 'PWD_RESET_REQ', message: localizedDashboardData.passwordExpired}));
												} else{
														var twoFactorEnabled = process.env.REACT_APP_TWO_FACTOR ? process.env.REACT_APP_TWO_FACTOR : false;
														var twoFactorMax = process.env.REACT_APP_TWO_FACTOR_MAX ? process.env.REACT_APP_TWO_FACTOR_MAX : 60;
														var lastMfaDate = adminDetails.last_mfa;
														var mfaDaysBetween = 60;

														if(lastMfaDate!==undefined && lastMfaDate!==null){
																mfaDaysBetween = Math.ceil((today - new Date(lastMfaDate).getTime()) / (1000*60*60*24));
														}

														if(twoFactorEnabled.toString() === 'true' && role==='admin'){
																// does the current ip not match the last one OR has the date been more than 30 days?
																if((adminDetails.currentIp.toString() !== adminDetails.last_ip) || adminDetails.last_ip===null){

																		if(adminDetails.mfa_code!==null && adminDetails.mfa_code!=='null'){

																				// Is the MFA Code already set and if so does it match?
																				if(mfa === adminDetails.mfa_code){
																						// TODO: Need ot update the DB with the date and set the code to null
																						var mfaStop = internalApiClient.validatedMfa(tvClient, user.id);
																						dispatch(loginSuccess({tvClient, user, reviewFlag, reputationFlag, intakeFlag}));
																				} else{
																						var mfaReq = internalApiClient.generateMfaReq(tvClient, user.id, adminDetails.mobile);
																						dispatch(loginFailure({type: 'USER.MFA_CODE_REQUIRED', message: localizedDashboardData.mfaCodeError }));
																				}

																		} else{
																				// We need to generate a code and SMS it
																				var mfaReq = internalApiClient.generateMfaReq(tvClient, user.id, adminDetails.mobile);
																				// Then fail the login gracefully and request the code texted
																				dispatch(loginFailure({type: 'USER.MFA_CODE_REQUIRED', message: localizedDashboardData.newMfaCode}));
																		}

																} else if(parseInt(mfaDaysBetween) >= parseInt(twoFactorMax)){

																		if(adminDetails.mfa_code!==null && adminDetails.mfa_code!=='null'){

																				// Is the MFA Code already set and if so does it match?
																				if(mfa === adminDetails.mfa_code){
																						// TODO: Need ot update the DB with the date and set the code to null
																						var mfaStop = internalApiClient.validatedMfa(tvClient, user.id);
																						dispatch(loginSuccess({tvClient, user, reviewFlag, reputationFlag, intakeFlag}));
																				} else{
																						var mfaReq = internalApiClient.generateMfaReq(tvClient, user.id, adminDetails.mobile);
																						dispatch(loginFailure({type: 'USER.MFA_CODE_REQUIRED', message: localizedDashboardData.mfaCodeError}));
																				}

																		} else{
																				// We need to generate a code and SMS it
																				var mfaReq = internalApiClient.generateMfaReq(tvClient, user.id, adminDetails.mobile);
																				// Then fail the login gracefully and request the code texted
																				dispatch(loginFailure({type: 'USER.MFA_CODE_REQUIRED', message: localizedDashboardData.verifyDeviceMfa }));
																		}

																} else{
																		// if everything is good proceed
																		dispatch(loginSuccess({tvClient, user, reviewFlag, reputationFlag, intakeFlag}));
																}
														} else{
																// No 2FA Required
																dispatch(loginSuccess({tvClient, user, reviewFlag, reputationFlag, intakeFlag}));
														}
												}
										} else{
												// TODO PJH - should this fail?
												dispatch(loginSuccess({tvClient, user, reviewFlag, reputationFlag, intakeFlag}));
										}
								}
						}

				} catch (e) {
						dispatch(loginFailure(e));
				}
		};
}

const logoutAction = createAction('LOGOUT');
/**
 * Logout temporarily doesn't call TrueVault's logout endpoint, but it could for added security. Calling the TrueVault
 * logout endpoint invalidates the provided AccessToken, which is a good security practice
 * @returns {function(*)}
 */
export function logout(tvClient) {

		tvClient.logout();
		if(internalApiClient.getIntakeInstance()!==null){
			clearInterval(internalApiClient.getIntakeInstance());
			internalApiClient.setIntakeInstance(null);
		}
		

		return dispatch => {
				dispatch(push('/login'));
				dispatch(logoutAction());
		};
}

const statsViewStart = createAction('STATS_VIEW_START');
const statsViewError = createAction('STATS_VIEW_ERROR');
const statsViewSuccess = createAction('STATS_VIEW_SUCCESS');
const statsViewSurveyLoadStart = createAction('STATS_SURVEYS_LOAD_START');
const statsViewSurveyLoadSuccess = createAction('STATS_SURVEYS_LOAD_SUCCESS');
const statsViewNpsLoadStart = createAction('STATS_NPS_LOAD_START');
const statsViewNpsLoadSuccess = createAction('STATS_NPS_LOAD_SUCCESS');
const statsViewOverviewLoadStart = createAction('STATS_OVERVIEW_LOAD_START');
const statsViewOverviewLoadSuccess = createAction('STATS_OVERVIEW_LOAD_SUCCESS');
const statsViewLocationOverviewLoadStart = createAction('STATS_LOCATION_OVERVIEW_LOAD_START');
const statsViewLocationOverviewLoadSuccess = createAction('STATS_LOCATION_OVERVIEW_LOAD_SUCCESS');
const statsViewEndorsementsLoadStart = createAction('STATS_ENDORSEMENTS_LOAD_START');
const statsViewEndorsementsLoadSuccess = createAction('STATS_ENDORSEMENTS_LOAD_SUCCESS');
const statsViewSpecificEndorsementsLoadStart = createAction('STATS_SPECIFIC_BADGE_LOAD_START');
const statsViewSpecificEndorsementsLoadSuccess = createAction('STATS_SPECIFIC_BADGE_LOAD_SUCCESS');
const statsViewSpecificEndorsementsLoadError = createAction('STATS_SPECIFIC_BADGE_ERROR');
const statsViewDeptEngagementsLoadStart = createAction('STATS_DEPT_ENGAGEMENTS_LOAD_START');
const statsViewDeptEngagementsLoadSuccess = createAction('STATS_DEPT_ENGAGEMENTS_LOAD_SUCCESS');
const statsViewStaffEngagementsLoadStart = createAction('STATS_STAFF_ENGAGEMENTS_LOAD_START');
const statsViewStaffEngagementsLoadSuccess = createAction('STATS_STAFF_ENGAGEMENTS_LOAD_SUCCESS');
const statsViewActionableEventsLoadStart = createAction('STATS_ACTIONABLE_EVENTS_LOAD_START');
const statsViewActionableEventsLoadSuccess = createAction('STATS_ACTIONABLE_EVENTS_LOAD_SUCCESS');

/**
 * This action doesn't interact with TrueVault directly from the client, it loads aggregate analytics data from the
 * Internal API Server. This demonstrates how you can easily add your own server side data processing for de-identified
 * data. In this case, we perform relatively simple analytics but you could perform complex data science algorithms in
 * your application.
 * @param tvAccessToken For Internal API authentication. Use the same TrueVault AccessToken that you
 *      would send to TrueVault. The NodeJS server can use it to validate the user is who they claim to be, and
 *      authorize actions accordingly.
 * @returns {function(*)}
 */
export function viewAdminDashboardStats(tvAccessToken) {
		return async dispatch => {
				try {
						dispatch(statsViewStart());
						const dashboardStats = await internalApiClient.getAdminDashboardStats(tvAccessToken);
						dispatch(statsViewSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}

export function viewActionableEvents(tvAccessToken){
		return async dispatch => {
				try {
						dispatch(statsViewActionableEventsLoadStart());
						const dashboardStats = await internalApiClient.getAdminDashboardActionableEvents(tvAccessToken);
						dispatch(statsViewActionableEventsLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}

export function viewAdminDashboardSurveys(tvAccessToken, locationList=null){
		return async dispatch => {
				try {
						dispatch(statsViewSurveyLoadStart());
						const dashboardStats = await internalApiClient.getAdminDashboardSurveys(tvAccessToken, locationList);
						dispatch(statsViewSurveyLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}

export function viewAdminDashboardNps(tvAccessToken, filter, businessLine = null, journey = null, locationList = null){
		return async dispatch => {
				try {
						dispatch(statsViewNpsLoadStart());
						const dashboardStats = await internalApiClient.getAdminDashboardNps(tvAccessToken, filter, businessLine, journey, locationList);

						if(dashboardStats.npsData){
							dispatch(statsViewNpsLoadSuccess(dashboardStats.npsData));
						}else{
							dispatch(statsViewNpsLoadSuccess(dashboardStats));    
						}
						
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}

export function viewAdminDashboardOnlyNps(tvAccessToken, filter, businessLine = null, journey = null, locationList=null){
		return async dispatch => {
				try {
						dispatch(statsViewNpsLoadStart());
						const dashboardStats = await internalApiClient.getAdminDashboardOnlyNps(tvAccessToken, filter, businessLine, journey, locationList);
						dispatch(statsViewNpsLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}

export function viewCustomAdminDashboardNps(tvAccessToken, fromDate, toDate, businessLine = null, journey = null, locationList=null){
		return async dispatch => {
				try {
						dispatch(statsViewNpsLoadStart());
						const dashboardStats = await internalApiClient.getCustomAdminDashboardNps(tvAccessToken, fromDate, toDate, businessLine, journey, locationList);
						dispatch(statsViewNpsLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}

const statsViewStaffNpsLoadStart = createAction('STATS_STAFF_NPS_LOAD_START');
const statsViewStaffNpsLoadSuccess = createAction('STATS_STAFF_NPS_LOAD_SUCCESS');

export function viewStaffNpsData(tvAccessToken, filter, staffId, departmentId, locationId, fromDate, toDate){
		return async dispatch => {
				try {
						dispatch(statsViewStaffNpsLoadStart());
						const dashboardStats = await internalApiClient.viewStaffNpsData(tvAccessToken, filter, staffId, departmentId, locationId, fromDate, toDate);
						dispatch(statsViewStaffNpsLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}



const statsViewLocationNpsLoadStart = createAction('STATS_LOCATION_NPS_LOAD_START');
const statsViewLocationNpsLoadSuccess = createAction('STATS_LOCATION_NPS_LOAD_SUCCESS');

export function viewLocationNpsData(tvAccessToken, filter, businessLine = null, journey = null, locationList=null){
		return async dispatch => {
				try {
						dispatch(statsViewLocationNpsLoadStart());
						const dashboardStats = await internalApiClient.getReportsLocationNps(tvAccessToken, filter, businessLine, journey, locationList);
						dispatch(statsViewLocationNpsLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}

export function viewCustomLocationNpsData(tvAccessToken, fromDate, toDate, businessLine = null, journey=null, locationList=null){
		return async dispatch => {
				try {
						dispatch(statsViewLocationNpsLoadStart());
						const dashboardStats = await internalApiClient.getCustomReportsLocationNps(tvAccessToken, fromDate, toDate, businessLine, journey, locationList);
						dispatch(statsViewLocationNpsLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}

export function viewCustomLocationNpsDataByLocation(tvAccessToken, fromDate, toDate, locationId){
		return async dispatch => {
				try {
						dispatch(statsViewLocationNpsLoadStart());
						const dashboardStats = await internalApiClient.getCustomReportsLocationNpsByLocation(tvAccessToken, fromDate, toDate, locationId);
						dispatch(statsViewLocationNpsLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}

const statsViewEmployerSurveyOverviewLoadStart = createAction('STATS_EMPLOYER_SURVEY_OVERVIEW_LOAD_START');
const statsViewEmployerSurveyOverviewLoadSuccess = createAction('STATS_EMPLOYER_SURVEY_OVERVIEW_LOAD_SUCCESS');
export function viewCustomEmployerSurveyOverviewData(tvAccessToken, campaignId, employer, dateFilter, fromDate, toDate){
		return async dispatch => {
			try {
						dispatch(statsViewEmployerSurveyOverviewLoadStart());
						const dashboardStats = await internalApiClient.getCustomReportsEmployerSurveyOverviewData(tvAccessToken, campaignId, employer, dateFilter, fromDate, toDate);
						dispatch(statsViewEmployerSurveyOverviewLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}

const statsViewEmployerNpsLoadStart = createAction('STATS_EMPLOYER_NPS_LOAD_START');
const statsViewEmployerNpsLoadSuccess = createAction('STATS_EMPLOYER_NPS_LOAD_SUCCESS');

export function viewCustomEmployerNpsData(tvAccessToken, campaignId, employer, dateFilter, fromDate, toDate){
		return async dispatch => {
				try {
						dispatch(statsViewEmployerNpsLoadStart());
						const dashboardStats = await internalApiClient.getCustomReportsEmployerNpsData(tvAccessToken, campaignId, employer, dateFilter, fromDate, toDate);
						dispatch(statsViewEmployerNpsLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}

const statsViewEmployerEndorsementsLoadStart = createAction('STATS_EMPLOYER_ENDORSEMENT_LOAD_START');
const statsViewEmployerEndorsementsLoadSuccess = createAction('STATS_EMPLOYER_ENDORSEMENT_LOAD_SUCCESS');

export function viewCustomEmployerEndorsementData(tvAccessToken, campaignId, employer, dateFilter, fromDate, toDate){
		return async dispatch => {
				try {
						dispatch(statsViewEmployerEndorsementsLoadStart());
						const dashboardStats = await internalApiClient.getCustomReportsEmployerEndorsementData(tvAccessToken, campaignId, employer, dateFilter, fromDate, toDate);
						dispatch(statsViewEmployerEndorsementsLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}

export function viewAdminDashboardOverview(tvAccessToken, filter, businessLine = null, journey = null, locationList=null){
		return async dispatch => {
				try {
						dispatch(statsViewOverviewLoadStart());
						const dashboardStats = await internalApiClient.getAdminDashboardOverview(tvAccessToken, filter, businessLine, journey, locationList);
						dispatch(statsViewOverviewLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}

export function viewAdminDashboardOnlyOverview(tvAccessToken, filter, businessLine = null, journey = null, locationList=null){
		return async dispatch => {
				try {
						dispatch(statsViewOverviewLoadStart());
						const dashboardStats = await internalApiClient.getAdminDashboardOnlyOverview(tvAccessToken, filter, businessLine, journey, locationList);
						dispatch(statsViewOverviewLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}

export function viewAdminDashboardOverviewCustom(tvAccessToken, filter, fromDate, toDate, businessLine = null, journey=null, locationList=null){
		return async dispatch => {
				try {
						dispatch(statsViewOverviewLoadStart());
						const dashboardStats = await internalApiClient.getAdminDashboardOverviewCustom(tvAccessToken, filter, fromDate, toDate, businessLine, journey, locationList);
						dispatch(statsViewOverviewLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}

export function viewAdminLocationOverviewData(tvAccessToken, filter, location, fromDate, toDate){
		return async dispatch => {
				try {
						dispatch(statsViewLocationOverviewLoadStart());
						const dashboardStats = await internalApiClient.getAdminLocationOverviewData(tvAccessToken, filter, location, fromDate, toDate);
						dispatch(statsViewLocationOverviewLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}


const viewPeerLocationSummaryStart = createAction('VIEW_PEER_LOCATION_SUMMARY_START');
const viewPeerLocationSummarySuccess = createAction('VIEW_PEER_LOCATION_SUMMARY_SUCCESS');
const viewPeerLocationSummaryError= createAction('VIEW_PEER_LOCATION_SUMMARY_ERROR');
export function viewPeerSummaryByLocation(tvAccessToken, dateFilter, startDate=undefined, endDate=undefined){
		return async dispatch => {
				try {
						dispatch(viewPeerLocationSummaryStart());
						const peerLocationDetails = await internalApiClient.getPeerSummaryByLocation(tvAccessToken, dateFilter, startDate, endDate);
						dispatch(viewPeerLocationSummarySuccess(peerLocationDetails));
				} catch (error) {
						dispatch(viewPeerLocationSummaryError(error));
				}
		};
}


export function viewAdminDashboardEndorsements(tvAccessToken){
		 return async dispatch => {
				try {
						dispatch(statsViewEndorsementsLoadStart());
						const dashboardStats = await internalApiClient.getAdminDashboardBadgeData(tvAccessToken);
						dispatch(statsViewEndorsementsLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}


export function viewAdminDashboardEndorsementsReports(tvAccessToken, location, filter, fromDate, toDate, businessLine = null, locationList=null){
		 return async dispatch => {
				try {
						dispatch(statsViewEndorsementsLoadStart());
						const dashboardStats = await internalApiClient.getAdminDashboardBadgeDataReports(tvAccessToken, location, filter, fromDate, toDate, businessLine, locationList);
						dispatch(statsViewEndorsementsLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};
}

export function viewSpecificEndorsementReport(tvAccessToken, location, filter, fromDate, toDate, endorsementId){
		 return async dispatch => {
				try {
						dispatch(statsViewSpecificEndorsementsLoadStart());
						const specificBadgeStats = await internalApiClient.getSpecificEndorsementReport(tvAccessToken, location, filter, fromDate, toDate, endorsementId);
						dispatch(statsViewSpecificEndorsementsLoadSuccess(specificBadgeStats));
				} catch (error) {
						dispatch(statsViewSpecificEndorsementsLoadError(error));
				}
		};
}

export function viewAdminDashboardDeptEngagements(tvAccessToken){
		 return async dispatch => {
				try {
						dispatch(statsViewDeptEngagementsLoadStart());
						const dashboardStats = await internalApiClient.getAdminDashboardDeptEngagement(tvAccessToken);
						dispatch(statsViewDeptEngagementsLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};

}

export function viewAdminDashboardStaffEngagements(tvAccessToken){
		 return async dispatch => {
				try {
						dispatch(statsViewStaffEngagementsLoadStart());
						const dashboardStats = await internalApiClient.getAdminDashboardStaffEngagement(tvAccessToken);
						dispatch(statsViewStaffEngagementsLoadSuccess(dashboardStats));
				} catch (error) {
						dispatch(statsViewError(error));
				}
		};

}

const staffAddStart = createAction('STAFF_ADD_START');
const staffAddProgress = createAction('STAFF_ADD_PROGRESS');
const staffAddError = createAction('STAFF_ADD_ERROR');
const staffAddSuccess = createAction('STAFF_ADD_SUCCESS');

/**
 * Returns an array of Promises to upload the given blobs. When all of the returned promises resolve, the given
 * files have uploaded successfully. Progress events are fired via the STAFF_ADD_PROGRESS event defined above.
 * @param tvClient TrueVaultClient
 * @param staffFiles Array of HTML5 File instances. See https://developer.mozilla.org/en-US/docs/Web/API/File for information
 * on the HTML5 File interface
 * @param dispatch Method for dispatching a redux action; presumably, [store.dispatch](http://redux.js.org/docs/api/Store.html#dispatch)
 * @returns {Array} Array of Promise
 */
const prepareStaffBlobPromises = function (tvClient, staffFiles, dispatch) {

		let createStaffBlobPromises = [];
		if (staffFiles.length === 0) {
				dispatch(staffAddProgress({bytesTotal: 0}));
		} else {
				const totalFileSize = staffFiles.map(staffFile => staffFile.size).reduce((acc, val) => acc + val);

				// Use a parallel array of File object to bytes loaded because we cannot use Files or object URLs as object keys
				const staffFileBytesUploaded = staffFiles.map(() => 0);
				createStaffBlobPromises = staffFiles.map(staffFile => {
						return tvClient.createBlobWithProgress(process.env.REACT_APP_STAFF_VAULT_ID, staffFile, (pe) => {
								const staffFileIndex = staffFiles.indexOf(staffFile);
								staffFileBytesUploaded[staffFileIndex] = pe.loaded;
								const totalBytesLoaded = staffFileBytesUploaded.reduce((acc, val) => acc + val);
								dispatch(staffAddProgress({bytesLoaded: totalBytesLoaded, bytesTotal: totalFileSize}));
						});
				});
		}
		return createStaffBlobPromises;
};


const tempStaffAddStart = createAction('TEMP_STAFF_ADD_START');
const tempStaffAddProgress = createAction('TEMP_STAFF_ADD_PROGRESS');
const tempStaffAddError = createAction('TEMP_STAFF_ADD_ERROR');
const tempStaffAddSuccess = createAction('TEMP_STAFF_ADD_SUCCESS');

export function createStaffFromTemp(tvClient, tempId, departments, locations,staffFiles,requestPhoto=false) {
		return async dispatch => {
				dispatch(tempStaffAddStart());

				try {
						const response = await internalApiClient.createStaffFromTemp(tvClient.accessToken, tempId,departments, locations, staffFiles[0],requestPhoto);
						dispatch(tempStaffAddSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.staffAdded));
				} catch (error) {
						dispatch(tempStaffAddError(error));
				}
		};
}

/**
 * This method does a lot of work under the covers to de-identify the patient data and store the non-PII in our Internal
 * API and the PII (or possible PII like images and free-text) in TrueVault. See the Api Helper by the same name
 * for details on the approach.
 */

export function createStaff(tvClient, staffName, staffTitle, staffEmail, staffPhone, staffBio, departments, locations,
													 staffFiles, approverId,active, approved, staffGoogleId, hireDate=null, birthDate=null, requestPhoto=false) {
		return async dispatch => {
				dispatch(staffAddStart());

				try {
						const response = await internalApiClient.createStaff(tvClient.accessToken, staffFiles[0], staffName, staffTitle, staffEmail, staffPhone, staffBio, departments, locations, approverId, active, approved, new Date(), staffGoogleId, hireDate, birthDate,requestPhoto);
						dispatch(staffAddSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.staffAdded));
						dispatch(push('/staff'));
				} catch (error) {
						dispatch(staffAddError(error));
				}
		};
}

const endorsementAddStart = createAction('ENDORSEMENT_ADD_START');
const endorsementAddError = createAction('ENDORSEMENT_ADD_ERROR');
const endorsementAddSuccess = createAction('ENDORSEMENT_ADD_SUCCESS');
export function createEndorsement(tvClient, badgeName, badgeType, badgePriority, badgeLocalization, departments, endorsementFiles, points=1) {
		return async dispatch => {
				dispatch(endorsementAddStart());

				try {
						const response = await internalApiClient.createEndorsement(tvClient.accessToken, badgeName, badgeType, badgePriority, badgeLocalization, departments, endorsementFiles[0], points);
						dispatch(endorsementAddSuccess());
						dispatch(displayFlashMessage('success', 'Added'));
						dispatch(push('/settings?active=2'));
				} catch (error) {
						dispatch(endorsementAddError(error));
				}
		};
}

const locationAddStart = createAction('LOCATION_ADD_START');
const locationAddError = createAction('LOCATION_ADD_ERROR');
const locationAddSuccess = createAction('LOCATION_ADD_SUCCESS');

export function createLocation(tvClient, locationName, locationAddress1, locationAddress2, locationCity, locationState, locationZip, locationPhone, locationNotificationPhone, locationNotificationEmail1,locationNotificationEmail2, google_id, yelp_id, facebook_id,healthgrades_id) {
		return async dispatch => {
				dispatch(locationAddStart());

				try {
						await internalApiClient.createLocation(tvClient.accessToken, locationName, locationAddress1, locationAddress2, locationCity, locationState, locationZip, locationPhone, locationNotificationPhone,locationNotificationEmail1,locationNotificationEmail2, google_id, yelp_id, facebook_id, healthgrades_id);
						dispatch(locationAddSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.locationAdded));
						dispatch(push('/settings?active=3'));
				} catch (error) {
						dispatch(locationAddError(error));
				}
		};
}


const customEventAddStart = createAction('CUSTOM_EVENT_ADD_START');
const customEventAddError = createAction('CUSTOM_EVENT_ADD_ERROR');
const customEventAddSuccess = createAction('CUSTOM_EVENT_ADD_SUCCESS');

export function createCustomEvent(tvClient, patientName, locationId, eventDetails, phoneNumber, adminAttr, category,staffId=null) {
		return async dispatch => {
				dispatch(customEventAddStart());
				try {
						let adminName = 'Administrator'
						if(adminAttr!==undefined && adminAttr!==null){
								adminName = adminAttr.name;
								if(adminName===undefined){
										adminName = 'Admininstrator';
								}
						}

						await internalApiClient.createCustomEvent(tvClient.accessToken, patientName, locationId, eventDetails, phoneNumber, adminName, category, staffId);
						dispatch(customEventAddSuccess());
						dispatch(displayFlashMessage('success','Event Added'));
						dispatch(push('/reports/events'));
				} catch (error) {
						dispatch(customEventAddError(error));
				}
		};
}



const campaignAddStart = createAction('CAMP_ADD_START');
const campaignAddError = createAction('CAMP_ADD_ERROR');
const campaignAddSuccess = createAction('CAMP_ADD_SUCCESS');

export function createCampaign(tvClient, name, group) {
		return async dispatch => {
				dispatch(campaignAddStart());

				try {
						await internalApiClient.createCampaign(tvClient.accessToken, name, group);
						dispatch(campaignAddSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.campaignAdded));
						dispatch(push('/sms?active=5'));
				} catch (error) {
						dispatch(campaignAddError(error));
				}
		};
}

const groupAddStart = createAction('GROUP_ADD_START');
const groupAddError = createAction('GROUP_ADD_ERROR');
const groupAddSuccess = createAction('GROUP_ADD_SUCCESS');

export function createGroup(tvClient, locationName) {
		return async dispatch => {
				dispatch(groupAddStart());

				try {
						await internalApiClient.createGroup(tvClient.accessToken, locationName);
						dispatch(groupAddSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.groupAdded));
						dispatch(push('/sms?active=4'));
				} catch (error) {
						dispatch(groupAddError(error));
				}
		};
}

const journeyAddStart = createAction('JOURNEY_ADD_START');
const journeyAddError = createAction('JOURNEY_ADD_ERROR');
const journeyAddSuccess = createAction('JOURNEY_ADD_SUCCESS');

export function createJourney(tvClient, journeyName, journeyDepartments, journeyImg, groupOnly) {
		if(journeyImg!==undefined){
				journeyImg = journeyImg[0];
		}

		return async dispatch => {
				dispatch(journeyAddStart());
				try {
						await internalApiClient.createJourney(tvClient.accessToken, journeyName, journeyDepartments, journeyImg, groupOnly);
						dispatch(journeyAddSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.journeyCreated));
						dispatch(push('/departments?active=2'));
				} catch (error) {
						dispatch(journeyAddError(error));
				}
		};
}

const locationMappingAddStart = createAction('LOCATION_MAPPING_ADD_START');
const locationMappingAddError = createAction('LOCATION_MAPPING__ADD_ERROR');
const locationMappingAddSuccess = createAction('LOCATION_MAPPING_ADD_SUCCESS');

export function createLocationMapping(tvClient, emrCode, locationCode, locationGroup) {

		return async dispatch => {
				dispatch(locationMappingAddStart());
				try {
						await internalApiClient.createLocationMapping(tvClient.accessToken, emrCode, locationCode, locationGroup);
						dispatch(locationMappingAddSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.locationMappingCreated));
						//dispatch(push('/departments?active=3'));
				} catch (error) {
						dispatch(locationMappingAddError(error));
				}
		};
}

const journeyMappingAddStart = createAction('JOURNEY_MAPPING_ADD_START');
const journeyMappingAddError = createAction('JOURNEY_MAPPING__ADD_ERROR');
const journeyMappingAddSuccess = createAction('JOURNEY_MAPPING_ADD_SUCCESS');

export function createJourneyMapping(tvClient, apptCode, journeyCode, frequencyCode, locationCode=null) {
		return async dispatch => {
				dispatch(journeyMappingAddStart());
				try {
						await internalApiClient.createJourneyMapping(tvClient.accessToken, apptCode, journeyCode, frequencyCode, locationCode);
						dispatch(journeyMappingAddSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.journeyMappingCreated));
						//dispatch(push('/departments?active=3'));
				} catch (error) {
						dispatch(journeyMappingAddError(error));
				}
		};
}


const endorsementEditStart = createAction('ENDORSEMENT_UPDATE_START');
const endorsementEditError = createAction('ENDORSEMENT_UPDATE_ERROR');
const endorsementEditSuccess = createAction('ENDORSEMENT_UPDATE_SUCCESS');
export function updateEndorsement(tvClient, badgeId, badgeName, badgeType, badgePriority, badgeLocalization, departments, newFiles, endorsementFiles, points=1) {
		return async dispatch => {
				dispatch(endorsementEditStart());
				if(!newFiles){
						endorsementFiles = undefined;
				} else{
						endorsementFiles = endorsementFiles[0];
				}

				try {
						const response = await internalApiClient.updateEndorsement(tvClient.accessToken, badgeId, badgeName, badgeType, badgePriority, badgeLocalization, departments, endorsementFiles,points);
						dispatch(endorsementEditSuccess());
						dispatch(displayFlashMessage('success', 'Updated'));
						dispatch(push('/settings?active=2'));
				} catch (error) {
						dispatch(endorsementEditError(error));
				}
		};
}



const staffUpdateStart = createAction('STAFF_UPDATE_START');
const staffUpdateProgress = createAction('STAFF_UPDATE_PROGRESS');
const staffUpdateError = createAction('STAFF_UPDATE_ERROR');
const staffUpdateSuccess = createAction('STAFF_UPDATE_SUCCESS');

/**
 * This method does a lot of work under the covers to de-identify the  data and store the non-PII in our Internal
 * API and the PII (or possible PII like images and free-text) in TrueVault. See the Api Helper by the same name
 * for details on the approach.
 */

export function updateStaff(tvClient, staffId, newFiles, staffName, staffTitle, staffEmail, staffPhone, staffBio, primaryDepartment, primaryLocation,
													 staffFiles, approverId,active, approved, created, staffGoogleId, hireDate=null, birthDate=null) {
		return async dispatch => {
				dispatch(staffUpdateStart());

				if(newFiles){
						try{
								const response = await internalApiClient.updateStaff(
										tvClient.accessToken,
										staffId,
										staffFiles[0],
										staffName,staffTitle, staffEmail, staffPhone, staffBio, primaryDepartment, primaryLocation, approverId,active, approved, staffGoogleId, hireDate, birthDate);
								dispatch(staffUpdateSuccess());
								dispatch(displayFlashMessage('success', staffName +' Updated'));
								dispatch(push('/staff?refresh=0'));
						} catch (error) {
								dispatch(staffUpdateError(error));
						}
				}
				else{
						try{
								const response = await internalApiClient.updateStaff(
										tvClient.accessToken,
										staffId,
										undefined,
										staffName,staffTitle, staffEmail, staffPhone, staffBio, primaryDepartment, primaryLocation, approverId,active, approved, staffGoogleId, hireDate, birthDate);
								dispatch(staffUpdateSuccess());
								dispatch(displayFlashMessage('success', staffName +' Updated'));
								dispatch(push('/staff?refresh=0'));
						} catch (error) {
								dispatch(staffUpdateError(error));
						}
				}
		};
}


const addMultipleLocationStart = createAction('MULTIPLE_LOCATION_ADD_START');
const addMultipleLocationError = createAction('MULTIPILE_LOCATION_ADD_ERROR');
const addMultipleLocationSuccess = createAction('MULTIPLE_LOCATION_ADD_SUCCESS');
const addMultipleLocationProgress = createAction('MULTIPLE_LOCATION_ADD_PROGRESS');

export function addMultipleLocations(tvClient, locations){
 return async dispatch => {
				dispatch(addMultipleLocationStart());

				try {
						var count = 0;

						for(var i=0; i<locations.length; i++){
								if(i==0) { continue; }
								else{
										var currLocation = locations[i];
										if(currLocation.length>0){
												var address2 = (currLocation[3]===undefined) ? '' : currLocation[3];
												var officePhone = (currLocation[7]===undefined) ? '' : currLocation[7];
												if(currLocation[0]!==undefined && currLocation[0]!==undefined && currLocation[0]!==''){
														// need to do the mapping too
														await internalApiClient.createMappedLocation(tvClient.accessToken, currLocation[0], currLocation[1], currLocation[2], address2, currLocation[4], currLocation[5], currLocation[6], officePhone, currLocation[8]===undefined ? '' : currLocation[8],currLocation[9]===undefined ? '' : currLocation[9],currLocation[10]===undefined ? '' : currLocation[10], currLocation[11]===undefined ? '' : currLocation[11], currLocation[12]===undefined ? '' : currLocation[12], currLocation[13]===undefined ? '' : currLocation[13], currLocation[14]===undefined ? '' : currLocation[14]);
														count++;
														dispatch(addMultiplePersonProgress({ locationsAdded: count, totalLocations: locations.length }));

												} else{
														await internalApiClient.createLocation(tvClient.accessToken, currLocation[1], currLocation[2], address2, currLocation[4], currLocation[5], currLocation[6], officePhone, currLocation[8]===undefined ? '' : currLocation[8],currLocation[9]===undefined ? '' : currLocation[9],currLocation[10]===undefined ? '' : currLocation[10], currLocation[11]===undefined ? '' : currLocation[11], currLocation[12]===undefined ? '' : currLocation[12], currLocation[13]===undefined ? '' : currLocation[13], currLocation[14]===undefined ? '' : currLocation[14]);
														count++;
														dispatch(addMultiplePersonProgress({ locationsAdded: count, totalLocations: locations.length }));
												}
										}
								}
						}

						dispatch(addMultipleLocationSuccess());
						dispatch(displayFlashMessage('success', count + ' Locations Added'));
				} catch (error) {
						dispatch(addMultipleLocationError(error));
				}
		};
}

function ExcelDateToJSDate(dateIn) {
 var date = new Date(Math.round((dateIn - (25567 + 2)) * 86400 * 1000));
		var converted_date = date.toISOString().split('T')[0];
		return converted_date;

}

const addMultipleStaffStart = createAction('MULTIPLE_STAFF_ADD_START');
const addMultipleStaffError = createAction('MULTIPILE_STAFF_ADD_ERROR');
const addMultipleStaffSuccess = createAction('MULTIPLE_STAFF_ADD_SUCCESS');
const addMultipleStaffProgress = createAction('MULTIPLE_STAFF_ADD_PROGRESS');

export function addMultipleStaff(tvClient, staff){
 return async dispatch => {
				dispatch(addMultipleStaffStart());
				try {
						var count = 0;

						for(var i=0; i<staff.length; i++){
								if(i==0) { continue; }
								else{
										var currStaff = staff[i];
										if(currStaff.length>0){
												var staffUid = (currStaff[0]===undefined) ? '' : currStaff[0];
												var staffEmail = (currStaff[3]===undefined) ? '' : currStaff[3];
												var staffMobile = (currStaff[4]===undefined) ? '' : currStaff[4];
												var hireDate = (currStaff[5]===undefined) ? '' : ExcelDateToJSDate(currStaff[5]);
												var birthDate = (currStaff[6]===undefined) ? '' : ExcelDateToJSDate(currStaff[6]);
												var departments = (currStaff[7]===undefined) ? '' : currStaff[7];
												var locations = (currStaff[8]===undefined) ? '' : currStaff[8];
												var requestPhoto = (currStaff[9]===undefined) ? 'N' : currStaff[9];
												await internalApiClient.createMultipleStaff(tvClient.accessToken, staffUid, currStaff[1], currStaff[2], staffEmail, staffMobile, hireDate, birthDate, departments, locations,requestPhoto);
												count++;
												dispatch(addMultipleStaffProgress({ staffAdded: count, totalStaff: staff.length }));
										}
								}
						}

						dispatch(addMultipleStaffSuccess());
						dispatch(displayFlashMessage('success', count + ' Staff Added'));
				} catch (error) {
						dispatch(addMultipleStaffError(error));
				}
		};
}

const addMultipleMappingsStart = createAction('MULTIPLE_MAPPINGS_ADD_START');
const addMultipleMappingsError = createAction('MULTIPILE_MAPPINGS_ADD_ERROR');
const addMultipleMappingsSuccess = createAction('MULTIPLE_MAPPINGS_ADD_SUCCESS');
const addMultipleMappingsProgress = createAction('MULTIPLE_MAPPINGS_ADD_PROGRESS');

export function addMultipleMappings(tvClient, mappings, existingJourneys){
 return async dispatch => {
				dispatch(addMultipleMappingsStart());
				try {
						var count = 0;
						for(var i=0; i<mappings.length; i++){
								if(i==0) { continue; }
								else{
										var currMapping = mappings[i];
										if(currMapping.length>0){
												var validMapping = true;

												var appointmentCode = (currMapping[0]===undefined) ? '' : currMapping[0];
												var journeyId = (currMapping[1]===undefined) ? '' : currMapping[1];
												var locationId = (currMapping[2]===undefined) ? '' : currMapping[2];
												var frequency = (currMapping[3]===undefined) ? '' : currMapping[3];

												for(var z=0; z<existingJourneys.length; z++){
														if(existingJourneys[z].appointment_code.toString()===appointmentCode.toString()){
																if(existingJourneys[z].location_id!==null){
																		if(existingJourneys[z].location_id.toString()===locationId.toString()){
																				validMapping=false;
																				break;
																		}
																} else {
																		if(existingJourneys[z].location_id===null && (locationId.toString().trim().length===0 || locationId.toString().trim()==='0')){
																				validMapping=false;
																				break;
																		}
																}
														}
												}

												if(validMapping){
														await internalApiClient.createMultipleMappings(tvClient.accessToken, appointmentCode, journeyId, locationId, frequency);
														count++;
												}
												dispatch(addMultipleMappingsProgress({ mappingsAdded: count, totalMappings: mappings.length }));
										}
								}
						}

						dispatch(addMultipleMappingsSuccess());
						dispatch(displayFlashMessage('success', count + ' Mappings Added'));
				} catch (error) {
						dispatch(addMultipleMappingsError(error));
				}
		};
}


const addMultiplePersonStart = createAction('MULTIPLE_PERSON_ADD_START');
const addMultiplePersonError = createAction('MULTIPILE_PERSON_ADD_ERROR');
const addMultiplePersonSuccess = createAction('MULTIPLE_PERSON_ADD_SUCCESS');
const addMultiplePersonProgress = createAction('MULTIPLE_PEOPLE_ADD_PROGRESS');

export function addMultiplePeopleToGroup(tvClient, people, group){
 return async dispatch => {
				dispatch(addMultiplePersonStart());
				var patientPhone = '';
				var email = '';
				var orgName = '';
				var tv_id = 'employer';
				var name = '';
				var count = 0;
				try {

						if(people.length>1){
								for(var i=1; i<people.length; i++){
										name = people[i][1];
										orgName = people[i][0];
										email = people[i][2];
										if(people[i][3]!==null && people[i][3]!==undefined && people[i][3]!==''){
												patientPhone = people[i][3].toString().trim().replace(/\D/g,'');
										}

										if(email!==undefined && email!==null){
												try {
														email = email.toString().trim();
														if(orgName!==undefined && orgName !== null){
																orgName = orgName.toString().trim();
														}
														if(name!==undefined && name !== null){
																name = name.toString().trim();
														}
										var patientIdentifier = email===null ? patientPhone : email;
										//var tv_id = 'attendee';
										// Search to see if the user exists with their phone number already and if so don't create
										const userExists = await internalApiClient.checkForUser(patientIdentifier+process.env.REACT_APP_CLIENT_TOKEN, tvClient);

										if(userExists.documents.length>0){
												var currUser = userExists.documents[0];
												tv_id = currUser.user_id
												//await internalApiClient.addPersonToGroup(tvClient.accessToken, name, number);

										} else{

												const user = await tvClient.createUser(patientIdentifier+process.env.REACT_APP_CLIENT_TOKEN, null, {
														mobile: patientPhone,
														email: email,
														role: 'employer',
														name: name,
														lang: 'EN'
												});

												tv_id = user.id
										}

											const addUserToPatientsGroupRequest = tvClient.addUsersToGroup(process.env.REACT_APP_PATIENTS_GROUP_ID, [tv_id]);
											await Promise.all([addUserToPatientsGroupRequest]);

														var addedPerson = await internalApiClient.addSinglePersonToGroup(tvClient.accessToken, name, email, patientPhone, group, orgName, tv_id);
														if(addedPerson !== undefined && addedPerson.code !== undefined && addedPerson.code === 'DuplicateEmail'){
																dispatch(displayFlashMessage('warning', name + ' already exists in the group with email ' + email));
														}  else{
																count++;
														}
														dispatch(addMultiplePersonProgress({ peopleAdded: count, totalPeople: people.length }));
												} catch(e){
														console.log(e);
												}
										} else{
												dispatch(displayFlashMessage('warning', 'Email was not provided for an entry: ' + name));
										}
								}
						}

						dispatch(addMultiplePersonSuccess());
						dispatch(displayFlashMessage('success', count + ' People Added to Group'));
				} catch (error) {
						dispatch(addMultiplePersonError(error));
				}
		};
}

const addSinglePersonStart = createAction('PERSON_TO_GROUP_ADD_START');
const addSinglePersonError = createAction('PERSON_TO_GROUP_ADD_ERROR');
const addSinglePersonSuccess = createAction('PERSON_TO_GROUP_ADD_SUCCESS');

export function addSinglePersonToGroup(tvClient, name, email, number, orgName, group){
 return async dispatch => {
				dispatch(addSinglePersonStart());
				try {
						var patientPhone = null;
						var tv_id = 'employer';
						if(number!==null){
								patientPhone = number.replace(/\D/g,'');
								patientPhone = patientPhone.toString().trim();
						}

						if(email!==undefined && email !== null){
								email = email.toString().trim();
						} if(orgName!==undefined && orgName !== null){
								orgName = orgName.toString().trim();
						}
						if(name!==undefined && name !== null){
								name = name.toString().trim();
						}

						var patientIdentifier = email===null ? patientPhone : email;

						//var tv_id = 'attendee';
						// Search to see if the user exists with their phone number already and if so don't create
						const userExists = await internalApiClient.checkForUser(patientIdentifier+process.env.REACT_APP_CLIENT_TOKEN, tvClient);

						if(userExists.documents.length>0){
								var currUser = userExists.documents[0];
								tv_id = currUser.user_id
								//await internalApiClient.addPersonToGroup(tvClient.accessToken, name, number);
						} else{

								const user = await tvClient.createUser(patientIdentifier+process.env.REACT_APP_CLIENT_TOKEN, null, {
										mobile: patientPhone,
										email: email,
										role: 'employer',
										name: name,
										lang: 'EN'
								});

								const addUserToPatientsGroupRequest = tvClient.addUsersToGroup(process.env.REACT_APP_PATIENTS_GROUP_ID, [user.id]);
								await Promise.all([addUserToPatientsGroupRequest]);

								tv_id = user.id
						}

						var addedPerson = await internalApiClient.addSinglePersonToGroup(tvClient.accessToken, name, email, patientPhone, group, orgName, tv_id);
						if(addedPerson !== undefined && addedPerson.code !== undefined && addedPerson.code === 'DuplicateEmail'){
								dispatch(displayFlashMessage('warning', localizedDashboardData.personAlreadyExists));
						}  else{
								dispatch(addSinglePersonSuccess());
								dispatch(displayFlashMessage('success', localizedDashboardData.personToGroup));
						}

				} catch (error) {
						dispatch(addSinglePersonError(error));
				}
		};
}

const campaignUpdateStart = createAction('CAMP_UPDATE_START');
const campaignUpdateError = createAction('CAMP_UPDATE_ERROR');
const campaignUpdateSuccess = createAction('CAMP_UPDATE_SUCCESS');

export function updateCampaign(tvClient, campaignId, name, group) {
		return async dispatch => {
				dispatch(campaignUpdateStart());

				try {
						await internalApiClient.updateCampaign(tvClient.accessToken, campaignId, name, group);
						dispatch(campaignUpdateSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.campaignUpdated));
						dispatch(push('/sms?active=5'));
				} catch (error) {
						dispatch(campaignUpdateError(error));
				}
		};
}

const groupUpdateStart = createAction('GROUP_UPDATE_START');
const groupUpdateError = createAction('GROUP_UPDATE_ERROR');
const groupUpdateSuccess = createAction('GROUP_UPDATE_SUCCESS');

export function updateGroup(tvClient, groupId, groupName) {
		return async dispatch => {
				dispatch(groupUpdateStart());

				try {
						await internalApiClient.updateGroup(tvClient.accessToken, groupId, groupName);
						dispatch(groupUpdateSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.groupUpdated));
						dispatch(push('/sms?active=4'));
				} catch (error) {
						dispatch(groupUpdateError(error));
				}
		};
}

const sendStaffFeedbackIndStart = createAction('SEND_STAFF_FEEDBACK_IND_START');
const sendStaffFeedbackIndError = createAction('SEND_STAFF_FEEDBACK_IND_ERROR');
const sendStaffFeedbackIndSuccess = createAction('SEND_STAFF_FEEDBACK_IND_SUCCESS');
const sendStaffFeedbackDeptStart = createAction('SEND_STAFF_FEEDBACK_DEPT_START');
const sendStaffFeedbackDeptError = createAction('SEND_STAFF_FEEDBACK_DEPT_ERROR');
const sendStaffFeedbackDeptSuccess = createAction('SEND_STAFF_FEEDBACK_DEPT_SUCCESS');

export function sendIndFeedback(tvAccessToken, staffId){
	return async dispatch => {
				dispatch(sendStaffFeedbackIndStart());

				try {
						var howMany = await internalApiClient.sendIndFeedback(tvAccessToken, staffId);
						if(howMany === 0){
								howMany = '';
						}
						dispatch(sendStaffFeedbackIndSuccess());
						dispatch(displayFlashMessage('success', howMany + ' ' + localizedDashboardData.textMessageSent));
				} catch (error) {
						dispatch(sendStaffFeedbackIndError(error));
				}
		};
}

export function sendIndFeedbackReminder(tvAccessToken, staffId){
	return async dispatch => {
				dispatch(sendStaffFeedbackIndStart());

				try {
						var howMany = await internalApiClient.sendIndFeedbackReminder(tvAccessToken, staffId);
						if(howMany === 0){
								howMany = '';
						}
						dispatch(sendStaffFeedbackIndSuccess());
						dispatch(displayFlashMessage('success', howMany + ' ' + localizedDashboardData.textMessageSent));
				} catch (error) {
						dispatch(sendStaffFeedbackIndError(error));
				}
		};
}

export function sendAllFeedback(tvAccessToken){
	return async dispatch => {
				dispatch(sendStaffFeedbackDeptStart());

				try {
						var howMany = await internalApiClient.sendAllFeedback(tvAccessToken);
						if(howMany === 0){
								howMany = '';
						}
						dispatch(sendStaffFeedbackDeptSuccess());
						dispatch(displayFlashMessage('success', howMany + ' ' + localizedDashboardData.textMessageSent));
				} catch (error) {
						dispatch(sendStaffFeedbackDeptError(error));
				}
		};
}

export function sendAllFeedbackReminder(tvAccessToken){
	return async dispatch => {
				dispatch(sendStaffFeedbackDeptStart());

				try {
						var howMany = await internalApiClient.sendAllFeedbackReminder(tvAccessToken);
						if(howMany === 0){
								howMany = '';
						}
						dispatch(sendStaffFeedbackDeptSuccess());
						dispatch(displayFlashMessage('success', howMany + ' ' + localizedDashboardData.textMessageSent));
				} catch (error) {
						dispatch(sendStaffFeedbackDeptError(error));
				}
		};
}


export function sendDeptFeedback(tvAccessToken, deptId){
	return async dispatch => {
				dispatch(sendStaffFeedbackDeptStart());

				try {
						var howMany = await internalApiClient.sendDeptFeedback(tvAccessToken, deptId);
						if(howMany === 0){
								howMany = '';
						}
						dispatch(sendStaffFeedbackDeptSuccess());
						dispatch(displayFlashMessage('success', howMany + ' ' + localizedDashboardData.textMessageSent));
				} catch (error) {
						dispatch(sendStaffFeedbackDeptError(error));
				}
		};
}

export function sendDeptFeedbackReminder(tvAccessToken, deptId){
	return async dispatch => {
				dispatch(sendStaffFeedbackDeptStart());

				try {
						var howMany = await internalApiClient.sendDeptFeedbackReminder(tvAccessToken, deptId);
						if(howMany === 0){
								howMany = '';
						}
						dispatch(sendStaffFeedbackDeptSuccess());
						dispatch(displayFlashMessage('success', howMany + ' ' + localizedDashboardData.textMessageSent));
				} catch (error) {
						dispatch(sendStaffFeedbackDeptError(error));
				}
		};
}

export function backToGroupList(){
		return async dispatch => {
				try{
						dispatch(push('/sms?active=4'));
				} catch(error){
						console.log(error);
				}

		}
}

const reviewReplyStart = createAction('REVIEW_REPLY_START');
const reviewReplyError = createAction('REVIEW_REPLY_ERROR');
const reviewReplySuccess = createAction('REVIEW_REPLY_SUCCESS');
export function sendReviewReply(tvAccessToken, reviewId, reply){
		return async dispatch => {
				dispatch(reviewReplyStart());

				try {
						await internalApiClient.sendReviewReply(tvAccessToken.accessToken, reviewId, reply);
						dispatch(reviewReplySuccess());
						dispatch(displayFlashMessage('success', 'Review Updated'));
						dispatch(push('/reviews'));
				} catch (error) {
						dispatch(reviewReplyError(error));
				}
		};
}

const locationUpdateStart = createAction('LOCATION_UPDATE_START');
const locationUpdateError = createAction('LOCATION_UPDATE_ERROR');
const locationUpdateSuccess = createAction('LOCATION_UPDATE_SUCCESS');

export function updateLocation(tvClient, locationId, locationName, locationAddress1, locationAddress2, locationCity, locationState, locationZip, locationPhone, locationNotificationPhone, locationNotificationEmail1,locationNotificationEmail2, google_id, yelp_id, facebook_id, healthgrades_id) {
		return async dispatch => {
				dispatch(locationUpdateStart());

				try {
						await internalApiClient.updateLocation(tvClient.accessToken, locationId, locationName, locationAddress1, locationAddress2, locationCity, locationState, locationZip, locationPhone, locationNotificationPhone,locationNotificationEmail1,locationNotificationEmail2, google_id, yelp_id, facebook_id, healthgrades_id);
						dispatch(locationUpdateSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.locationUpdated));
						dispatch(push('/settings?active=3'));
				} catch (error) {
						dispatch(locationUpdateError(error));
				}
		};
}

const journeyUpdateStart = createAction('JOURNEY_UPDATE_START');
const journeyUpdateError = createAction('JOURNEY_UPDATE_ERROR');
const journeyUpdateSuccess = createAction('JOURNEY_UPDATE_SUCCESS');

export function updateJourney(tvClient, journeyId, journeyName, departments, newFile) {
		return async dispatch => {
				dispatch(journeyUpdateStart());
				if(newFile!==undefined){
						newFile = newFile[0];
				}

				try {
						await internalApiClient.updateJourney(tvClient.accessToken, journeyId, journeyName, departments, newFile);
						dispatch(journeyUpdateSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.journeyUpdated));
						dispatch(push('/departments?active=2'));
				} catch (error) {
						dispatch(journeyUpdateError(error));
				}
		};
}

const adminResetPasswordStart = createAction('ADMIN_RESET_PASSWORD_START');
const adminResetPasswordError = createAction('ADMIN_RESET_PASSWORD_ERROR');
const adminResetPasswordSuccess = createAction('ADMIN_RESET_PASSWORD_SUCCESS');
export function updateAdminPassword(tvClient, pass1, pass2, tv_id) {
 return async dispatch => {
				dispatch(adminResetPasswordStart());
				try {
						if(pass1===pass2){
								const user = await tvClient.updateUserPassword(tv_id, pass1);
								dispatch(adminResetPasswordSuccess());
								dispatch(displayFlashMessage('success', localizedDashboardData.adminPasswordUpdated));
								dispatch(push('/settings?active=4'));
						}

				} catch (error) {
						dispatch(adminResetPasswordError(error));
				}
		};
}

export function updateMyProfilePassword(tvClient, pass1, pass2, tv_id) {
 return async dispatch => {
				dispatch(adminResetPasswordStart());
				try {
						if(pass1===pass2){
								const user = await tvClient.updateUserPassword(tv_id, pass1);
								dispatch(adminResetPasswordSuccess());
								dispatch(displayFlashMessage('success', localizedDashboardData.profilePasswordUpdated));
								//dispatch(push('/sms'));
						}

				} catch (error) {
						dispatch(adminResetPasswordError(error));
				}
		};
}

const adminUpdateStart = createAction('ADMIN_UPDATE_START');
const adminUpdateError = createAction('ADMIN_UPDATE_ERROR');
const adminUpdateSuccess = createAction('ADMIN_UPDATE_SUCCESS');

export function updateAdmin(tvClient, name, mobile, id, role, locations=[], simpleLocations=[]){
		return async dispatch => {
				dispatch(adminUpdateStart());
				
				if(role==='surveysreportsonly'){
					locations=simpleLocations;
				}

				try {
						await internalApiClient.updateAdmin(tvClient.accessToken, name, mobile, id, role, locations);
						dispatch(adminUpdateSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.adminUpdated));
						dispatch(push('/settings?active=4'));
				} catch (error) {
						dispatch(adminUpdateError(error));
				}
		};
}

const ehrMappingUpdateStart = createAction('EHRMAPPING_UPDATE_START');
const ehrMappingUpdateError = createAction('EHRMAPPING_UPDATE_ERROR');
const ehrMappingUpdateSuccess = createAction('EHRMAPPING_UPDATE_SUCCESS');

export function updateEhrMapping(tvClient, journeyId, frequency, id, locationCode=null){
		return async dispatch => {
				dispatch(ehrMappingUpdateStart());

				try {
						await internalApiClient.updateEhrMapping(tvClient.accessToken, journeyId, frequency, id, locationCode);
						dispatch(ehrMappingUpdateSuccess());
						dispatch(displayFlashMessage('success', 'Mapping Updated.'));
						dispatch(push('/departments?active=3'));
				} catch (error) {
						dispatch(ehrMappingUpdateError(error));
				}
		};
}


const departmentAddStart = createAction('DEPARTMENT_ADD_START');
const departmentAddProgress = createAction('DEPARTMENT_ADD_PROGRESS');
const departmentAddError = createAction('DEPARTMENT_ADD_ERROR');
const departmentAddSuccess = createAction('DEPARTMENT_ADD_SUCCESS');

/**
 * This method does a lot of work under the covers to de-identify the case data and store the non-PII in our Internal
 * API and the PII (or possible PII like images and free-text) in TrueVault. See the Api Helper by the same name
 * for details on the approach.
 */
export function createDepartment(tvClient, name, esname, departmentFiles, parentDepartment) {
		return async dispatch => {
				dispatch(departmentAddStart());

				try {

						const response = await internalApiClient.createDepartment(
								tvClient.accessToken,
								name,
								esname,
							 departmentFiles[0], parentDepartment);

						dispatch(departmentAddSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.departmentAdded));
						dispatch(push('/departments'));
				} catch (error) {
						dispatch(departmentAddError(error));
				}
		};
}

const departmentEditStart = createAction('DEPARTMENT_EDIT_START');
const departmentEditProgress = createAction('DEPARTMENT_EDIT_PROGRESS');
const departmentEditError = createAction('DEPARTMENT_EDIT_ERROR');
const departmentEditSuccess = createAction('DEPARTMENT_EDIT_SUCCESS');

/**
 * This method does a lot of work under the covers to de-identify the case data and store the non-PII in our Internal
 * API and the PII (or possible PII like images and free-text) in TrueVault. See the Api Helper by the same name
 * for details on the approach.
 */
export function editDepartment(tvClient, name, esname, departmentFiles, departmentId, primaryDepartment) {
		return async dispatch => {
				dispatch(departmentEditStart());

				try {

						const response = await internalApiClient.editDepartment(
								tvClient.accessToken,
								name,
								esname,
							 departmentFiles[0], departmentId, primaryDepartment);

						dispatch(departmentEditSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.departmentUpdated));
						dispatch(push('/departments'));
				} catch (error) {
						dispatch(departmentEditError(error));
				}
		};
}


const locationMappingUpdateStart = createAction('LOCATION_MAPPING_UPDATE_START');
const locationMappingUpdateError = createAction('LOCATION_MAPPING_UPDATE_ERROR');
const locationMappingUpdateSuccess = createAction('LOCATION_MAPPING_UPDATE_SUCCESS');

export function updateLocationMapping(tvClient, mappingId, emrCode, locationGroup, locationId){
		return async dispatch => {
				dispatch(locationMappingUpdateStart());

				try {

						const response = await internalApiClient.updateLocationMapping(
								tvClient.accessToken,
								mappingId,
								emrCode,
								locationGroup, locationId);

						dispatch(locationMappingUpdateSuccess());
						dispatch(displayFlashMessage('success', 'Location Mapping Updated.'));
						dispatch(push('/settings'));
				} catch (error) {
						dispatch(locationMappingUpdateError(error));
				}
		};
}

const settingsViewStart = createAction('SETTINGS_VIEW_START');
const settingsViewError = createAction('SETTINGS_VIEW_ERROR');
const settingViewSuccess = createAction('SETTINGS_VIEW_SUCCESS');
const settingViewEndorsementSuccess = createAction('SETTINGS_VIEW_ENDORSEMENT_SUCCESS');

export function settingsPage(tvClient) {
		return async dispatch => {
				try {
						dispatch(settingsViewStart());
						var settings = await internalApiClient.getClientSettings(tvClient.accessToken);
						dispatch(settingViewSuccess(
								{ settings }));
				} catch (e) {
						dispatch(settingsViewError(e));
				}
		};
}

export function refreshSettingEndorsements(tvClient, deptId){
		return async dispatch => {
				try {

						var endorsements = await internalApiClient.getAllEndorsements(tvClient.accessToken, deptId);
						dispatch(settingViewEndorsementSuccess({ endorsements }));
				} catch (e) {
						console.log(e);
				}
		}
}

const deptViewStart = createAction('DEPT_VIEW_START');
const deptViewError = createAction('DEPT_VIEW_ERROR');
const deptViewSuccess = createAction('DEPT_VIEW_SUCCESS');

export function viewDept(tvClient, getDepartmentRequest) {
		return async dispatch => {
				dispatch(deptViewStart());

				try {
						const department = await getDepartmentRequest;

						dispatch(deptViewSuccess({
								department
						}));
				} catch (error) {
						dispatch(deptViewError(error));
				}
		};

}

const deleteDepartmentStart = createAction('DELETE_DEPARTMENT_START');
const deleteDepartmentError = createAction('DELETE_DEPARTMENT_ERROR');
const deleteDepartmentSuccess = createAction('DELETE_DEPARTMENT_SUCCESS');

export function deleteDepartment(tvClient, departmentId, successCallback) {
		return async dispatch => {
				try {
						dispatch(deleteDepartmentStart());
						const deleteDepartmentRequest = await internalApiClient.deleteDepartment(tvClient.accessToken, departmentId);

						if (successCallback) {
								successCallback();
						}
						dispatch(deleteDepartmentSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.departmentRemoved));
						dispatch(push('/departments'));
				} catch (e) {
						dispatch(deleteDepartmentError(e));
				}
		};
}

const deleteAdminStart = createAction('DELETE_ADMIN_START');
const deleteAdminError = createAction('DELETE_ADMIN_ERROR');
const deleteAdminSuccess = createAction('DELETE_ADMIN_SUCCESS');

export function deleteAdmin(tvClient, adminId, tvId, successCallback) {
		return async dispatch => {
				try {
						dispatch(deleteAdminStart());
						const user = await tvClient.deleteUser(tvId);

						if(user.status==='DEACTIVATED'){
								const deleteStaffRequest = await internalApiClient.deleteAdmin(tvClient.accessToken, adminId);
								if (successCallback) {
										successCallback();
								}
								dispatch(deleteAdminSuccess());
								dispatch(displayFlashMessage('success', localizedDashboardData.adminRemoved));
								dispatch(push('/settings?active=4'));
						}

				} catch (e) {
						dispatch(deleteAdminError(e));
				}
		};
}

const deleteStaffStart = createAction('DELETE_STAFF_START');
const deleteStaffError = createAction('DELETE_STAFF_ERROR');
const deleteStaffSuccess = createAction('DELETE_STAFF_SUCCESS');

export function deleteStaff(tvClient, staffId, successCallback) {
		return async dispatch => {
				try {
						dispatch(deleteStaffStart());

						const deleteStaffRequest = await internalApiClient.deleteStaff(tvClient.accessToken, staffId);

						if (successCallback) {
							successCallback();
							dispatch(deleteStaffSuccess());
							dispatch(displayFlashMessage('success', localizedDashboardData.personRemoved));
						} else{
							dispatch(deleteStaffSuccess());
							dispatch(displayFlashMessage('success', localizedDashboardData.personRemoved));
							dispatch(push('/staff'));    
						}
				} catch (e) {
						dispatch(deleteStaffError(e));
				}
		};
}

const requestStaffPhotoStart = createAction('REQUEST_STAFF_PHOTO_START');
const requestStaffPhotoError = createAction('REQUEST_STAFF_PHOTO_ERROR');
const requestStaffPhotoSuccess = createAction('REQUEST_STAFF_PHOTO_SUCCESS');

export function requestTheirPhoto(tvClient, staffId, successCallback){
		 return async dispatch => {
				try {
						dispatch(requestStaffPhotoStart());

						const requestStaffPhoto = await internalApiClient.requestTheirPhoto(tvClient.accessToken, staffId);

						if(requestStaffPhoto!==undefined && requestStaffPhoto.error!==undefined){
								if(requestStaffPhoto.error==='1' || requestStaffPhoto.error===1){
										dispatch(requestStaffPhotoSuccess());
										dispatch(displayFlashMessage('danger', 'Staff Member did not have an email or mobile number'));
								} else{
										if (successCallback) {
												successCallback();
										}
										dispatch(requestStaffPhotoSuccess());
										dispatch(displayFlashMessage('success', 'Photo Request Sent'));
								}
						} else{
								if (successCallback) {
										successCallback();
								}
								dispatch(requestStaffPhotoSuccess());
								dispatch(displayFlashMessage('success', 'Photo Request Sent'));
						}

				} catch (e) {
						dispatch(requestStaffPhotoError(e));
				}
		};
}


export function deleteTempStaff(tvClient, staffId, successCallback) {
		return async dispatch => {
				try {
						dispatch(deleteStaffStart());

						const deleteTempStaffRequest = await internalApiClient.deleteTempStaff(tvClient.accessToken, staffId);

						if (successCallback) {
								successCallback();
						}
						dispatch(deleteStaffSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.personRemoved));
				} catch (e) {
						dispatch(deleteStaffError(e));
				}
		};
}


const deleteCampaignStart = createAction('DELETE_CAMP_START');
const deleteCampaignError = createAction('DELETE_CAMP_ERROR');
const deleteCampaignSuccess = createAction('DELETE_CAMP_SUCCESS');

export function deleteCampaign(tvClient, campaignId) {
		return async dispatch => {
				try {
						dispatch(deleteCampaignStart());

						const deleteCampaignRequest = await internalApiClient.deleteCampaign(tvClient.accessToken, campaignId);

						dispatch(deleteCampaignSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.campaignRemoved));
						dispatch(push('/sms?active=5'));
				} catch (e) {
						dispatch(deleteCampaignError(e));
				}
		};
}

const deleteGroupStart = createAction('DELETE_GROUP_START');
const deleteGroupError = createAction('DELETE_GROUP_ERROR');
const deleteGroupSuccess = createAction('DELETE_GROUP_SUCCESS');

export function deleteGroup(tvClient, groupId) {
		return async dispatch => {
				try {
						dispatch(deleteGroupStart());

						const deleteGroupRequest = await internalApiClient.deleteGroup(tvClient.accessToken, groupId);

						dispatch(deleteGroupSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.groupRemoved));
						dispatch(push('/sms?active=4'));
				} catch (e) {
						dispatch(deleteGroupError(e));
				}
		};
}

const deleteGroupMemberStart = createAction('DELETE_GROUP_MEMBER_START');
const deleteGroupMemberError = createAction('DELETE_GROUP_MEMBER_ERROR');
const deleteGroupMemberSuccess = createAction('DELETE_GROUP_MEMBER_SUCCESS');

export function deleteGroupMember(tvClient, groupId, memberId, successCallback) {
		return async dispatch => {
				try {
						dispatch(deleteGroupMemberStart());

						const deleteGroupMemberRequest = await internalApiClient.deleteGroupMember(tvClient.accessToken, groupId, memberId);

						if (successCallback) {
								successCallback();
						}

						dispatch(deleteGroupMemberSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.groupMemberRemoved));
						dispatch(replace('/group/edit/'+groupId));
				} catch (e) {
						dispatch(deleteGroupMemberError(e));
				}
		};
}

const deleteLocationStart = createAction('DELETE_LOCATION_START');
const deleteLocationError = createAction('DELETE_LOCATION_ERROR');
const deleteLocationSuccess = createAction('DELETE_LOCATION_SUCCESS');

export function deleteLocation(tvClient, locationId) {
		return async dispatch => {
				try {
						dispatch(deleteLocationStart());

						const deleteLocationRequest = await internalApiClient.deleteLocation(tvClient.accessToken, locationId);

						dispatch(deleteLocationSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.locationRemoved));
						dispatch(push('/settings?active=3'));
				} catch (e) {
						dispatch(deleteLocationError(e));
				}
		};
}

const deleteEndorsementStart = createAction('DELETE_ENDORSEMENT_START');
const deleteEndorsementError = createAction('DELETE_ENDORSEMENT_ERROR');
const deleteEndorsementSuccess = createAction('DELETE_ENDORSEMENT_SUCCESS');

export function deleteEndorsement(tvClient, endorsementId) {
		return async dispatch => {
				try {
						dispatch(deleteEndorsementStart());

						const deleteEndorsementRequest = await internalApiClient.deleteEndorsement(tvClient.accessToken, endorsementId);

						dispatch(deleteEndorsementSuccess());
						dispatch(displayFlashMessage('success', 'Endorsement removed'));
						dispatch(push('/settings?active=2'));
				} catch (e) {
						dispatch(deleteEndorsementError(e));
				}
		};
}

const deleteJourneyStart = createAction('DELETE_JOURNEY_START');
const deleteJourneyError = createAction('DELETE_JOURNEY_ERROR');
const deleteJourneySuccess = createAction('DELETE_JOURNEY_SUCCESS');

export function deleteJourney(tvClient, journeyId) {
		return async dispatch => {
				try {
						dispatch(deleteJourneyStart());

						const deleteJourneyRequest = await internalApiClient.deleteJourney(tvClient.accessToken, journeyId);

						dispatch(deleteJourneySuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.journeyRemoved));
						dispatch(push('/departments?active=2'));
				} catch (e) {
						dispatch(deleteJourneyError(e));
				}
		};
}

const deleteJourneyMappingStart = createAction('DELETE_JOURNEY_MAPPING_START');
const deleteJourneyMappingError = createAction('DELETE_JOURNEY_MAPPING_ERROR');
const deleteJourneyMappingSuccess = createAction('DELETE_JOURNEY_MAPPING_SUCCESS');
export function deleteJourneyMapping(tvClient, journeyMappingId) {
		return async dispatch => {
				try {
						dispatch(deleteJourneyMappingStart());
						const deleteJourneyRequest = await internalApiClient.deleteJourneyMapping(tvClient.accessToken, journeyMappingId);

						dispatch(deleteJourneyMappingSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.journeyMappingRemoved));
						dispatch(push('/departments?active=3'));
				} catch (e) {
						dispatch(deleteJourneyMappingError(e));
				}
		};
}

const deleteLocationMappingStart = createAction('DELETE_LOCATION_MAPPING_START');
const deleteLocationMappingError = createAction('DELETE_LOCATION_MAPPING_ERROR');
const deleteLocationMappingSuccess = createAction('DELETE_LOCATION_MAPPING_SUCCESS');
export function deleteLocationMapping(tvClient, locationMappingId) {
		return async dispatch => {
				try {
						dispatch(deleteLocationMappingStart());
						const deleteLocationRequest = await internalApiClient.deleteLocationMapping(tvClient.accessToken, locationMappingId);

						dispatch(deleteLocationMappingSuccess());
						dispatch(displayFlashMessage('success', localizedDashboardData.locationMappingRemoved));
						dispatch(push('/settings?active=6'));
				} catch (e) {
						dispatch(deleteLocationMappingError(e));
				}
		};
}

const campaignViewStart = createAction('CAMP_VIEW_START');
const campaignViewError = createAction('CAMP_VIEW_ERROR');
const campaignViewSuccess = createAction('CAMP_VIEW_SUCCESS');

export function viewCampaign(tvClient, campaignId) {
	 return async dispatch => {
				dispatch(campaignViewStart());

				try {
						const campaignDocument = await internalApiClient.getCampaign(tvClient, campaignId);
						dispatch(campaignViewSuccess({
								campaignData: {
										...campaignDocument
								}
						}));
				} catch (error) {
						dispatch(campaignViewError(error));
				}
		};
}

const groupViewStart = createAction('GROUP_VIEW_START');
const groupViewError = createAction('GROUP_VIEW_ERROR');
const groupViewSuccess = createAction('GROUP_VIEW_SUCCESS');

export function viewGroup(tvClient, groupDetail) {
	 return async dispatch => {
				dispatch(groupViewStart());

				try {
						const groupDocument = await internalApiClient.getGroup(tvClient, groupDetail);
						dispatch(groupViewSuccess({
								groupData: {
										...groupDocument
								}
						}));
				} catch (error) {
						dispatch(groupViewError(error));
				}
		};
}

const locationViewStart = createAction('LOCATION_VIEW_START');
const locationViewError = createAction('LOCATION_VIEW_ERROR');
const locationViewSuccess = createAction('LOCATION_VIEW_SUCCESS');

export function viewLocation(tvClient, locationDetail) {
	 return async dispatch => {
				dispatch(locationViewStart());

				try {
						const locationDocument = await internalApiClient.getLocation(tvClient, locationDetail);
						dispatch(locationViewSuccess({
								locationData: {
										...locationDocument
								}
						}));
				} catch (error) {
						dispatch(locationViewError(error));
				}
		};
}


const locationReviewViewStart = createAction('LOCATION_REVIEW_VIEW_START');
const locationReviewViewError = createAction('LOCATION_REVIEW_VIEW_ERROR');
const locationReviewViewSuccess = createAction('LOCATION_REVIEW_VIEW_SUCCESS');

export function viewLocationReview(tvClient, reviewId) {
	 return async dispatch => {
				dispatch(locationReviewViewStart());

				try {
						const reviewDocument = await internalApiClient.getLocationReview(tvClient, reviewId);
						dispatch(locationReviewViewSuccess({
								reviewData: reviewDocument
						}));
				} catch (error) {
						dispatch(locationReviewViewError(error));
				}
		};
}


const journeyViewStart = createAction('JOURNEY_VIEW_START');
const journeyViewError = createAction('JOURNEY_VIEW_ERROR');
const journeyViewSuccess = createAction('JOURNEY_VIEW_SUCCESS');

export function viewJourney(tvClient, journeyDetail) {
	 return async dispatch => {
				dispatch(journeyViewStart());

				try {

						const journeyDocument = await internalApiClient.getSpecificJourney(tvClient, journeyDetail);

//            console.log(staffDocument);
						dispatch(journeyViewSuccess({
								journeyData: {
										...journeyDocument
								}
						}));
				} catch (error) {
						dispatch(journeyViewError(error));
				}
		};
}

const ehrMappingViewStart = createAction('EHRMAPPING_VIEW_START');
const ehrMappingadminViewError = createAction('EHRMAPPING_VIEW_ERROR');
const ehrMappingViewSuccess = createAction('EHRMAPPING_VIEW_SUCCESS');

/**
 * This method does a lot of work under the covers to de-identify the case data and store the non-PII in our Internal
 * API and the PII (or possible PII like images and free-text) in TrueVault. See the Api Helper by the same name
 * for details on the approach.
 */
export function viewEhrMapping(tvClient, mappingDetail) {
	 return async dispatch => {
				dispatch(ehrMappingViewStart());

				try {

						const mappingDocument = await internalApiClient.getEhrMapping(tvClient, mappingDetail);
						dispatch(ehrMappingViewSuccess({
								mappingInfo: {
										...mappingDocument
								}
						}));
				} catch (error) {
						dispatch(ehrMappingadminViewError(error));
				}
		};
}


const adminViewStart = createAction('ADMIN_VIEW_START');
const adminViewError = createAction('ADMIN_VIEW_ERROR');
const adminViewSuccess = createAction('ADMIN_VIEW_SUCCESS');

/**
 * This method does a lot of work under the covers to de-identify the case data and store the non-PII in our Internal
 * API and the PII (or possible PII like images and free-text) in TrueVault. See the Api Helper by the same name
 * for details on the approach.
 */
export function viewAdmin(tvClient, adminDetail) {
	 return async dispatch => {
				dispatch(adminViewStart());

				try {

						const staffDocument = await internalApiClient.getAdmin(tvClient, adminDetail);
						dispatch(adminViewSuccess({
								adminData: {
										...staffDocument
								},
								pwdRestrictions: {
										lowercasereq: process.env.REACT_APP_PWD_CONTAIN_LC,
										uppercasereq: process.env.REACT_APP_PWD_CONTAIN_UC,
										numberreq: process.env.REACT_APP_PWD_CONTAIN_NUMBER,
										specialreq: process.env.REACT_APP_PWD_CONTAIN_SPECIAL,
										minlengthreq: process.env.REACT_APP_PWD_MIN_LENGTH
								}
						}));
				} catch (error) {
						dispatch(adminViewError(error));
				}
		};
}

const editAdminLocationStart = createAction('EDIT_ADMIN_LOCATION_START');
const editAdminLocationError = createAction('EDIT_ADMIN_LOCATION_ERROR');
const editAdminLocationSuccess = createAction('EDIT_ADMIN_LOCATION_SUCCESS');

export function getAdminLocations(tvClient, tv_id){
		return async dispatch => {
				dispatch(editAdminLocationStart());

				try {

						var user = await tvClient.readUser(tv_id);
						dispatch(editAdminLocationSuccess({userAttributes: user.attributes}));

				} catch (error) {
						dispatch(editAdminLocationError(error));
				}
		};
}

const pwdRstViewStart = createAction('PWD_RESTRICTION_VIEW_START');
const pwdRstViewError = createAction('PWD_RESTRICTION_VIEW_ERROR');
const pwdRstViewSuccess = createAction('PWD_RESTRICTION_VIEW_SUCCESS');
export function getPwdRestrictions(tvClient){
 return async dispatch => {
		dispatch(pwdRstViewStart());

				try {
						dispatch(pwdRstViewSuccess({
								pwdRestrictions: {
										lowercasereq: process.env.REACT_APP_PWD_CONTAIN_LC,
										uppercasereq: process.env.REACT_APP_PWD_CONTAIN_UC,
										numberreq: process.env.REACT_APP_PWD_CONTAIN_NUMBER,
										specialreq: process.env.REACT_APP_PWD_CONTAIN_SPECIAL,
										minlengthreq: process.env.REACT_APP_PWD_MIN_LENGTH
								}
						}));
				} catch (error) {
						dispatch(pwdRstViewError(error));
				}
		};
}

const adminFlagsStart = createAction('ADMIN_FLAGS_START');
const adminFlagsError = createAction('ADMIN_FLAGS_ERROR');
const adminFlagsSuccess = createAction('ADMIN_FLAGS_SUCCESS');
export function getAdminFlags(tvClient){
 return async dispatch => {
		dispatch(adminFlagsStart());
				try {

						const envFlags = await internalApiClient.getEnvFlags(tvClient);
						var employerFlagVal = false;
						if(envFlags.EMPLOYER_FLAG!==undefined && envFlags.EMPLOYER_FLAG!==null ){
										employerFlagVal = (envFlags.EMPLOYER_FLAG==="true" || envFlags.EMPLOYER_FLAG===true);
						}
						var peerReviewVal = false;
						if(envFlags.PEER_REVIEW!==undefined && envFlags.PEER_REVIEW!==null ){
										peerReviewVal = (envFlags.PEER_REVIEW==="true" || envFlags.PEER_REVIEW===true);
						}
						var enableReviewsVal = false;
						if(envFlags.ENABLE_REVIEWS!==undefined && envFlags.ENABLE_REVIEWS!==null ){
										enableReviewsVal = (envFlags.ENABLE_REVIEWS==="true" || envFlags.ENABLE_REVIEWS===true);
						}
						var enableReputationVal = false;
						if(envFlags.ENABLE_REPUTATION!==undefined && envFlags.ENABLE_REPUTATION!==null ){
										enableReputationVal = (envFlags.ENABLE_REPUTATION==="true" || envFlags.ENABLE_REPUTATION===true);
						}

						dispatch(adminFlagsSuccess({
								adminFlags: {
										employerFlag: employerFlagVal,
										peerReview: peerReviewVal,
										reviewsEnabled: enableReviewsVal,
										reputationEnabled: enableReputationVal
								}
						}));
				} catch (error) {
						dispatch(adminFlagsError(error));
				}
		};
}

const saveWinnerStart = createAction('SAVE_WINNER_START');
const saveWinnerError = createAction('SAVE_WINNER_ERROR');
const saveWinnerSuccess = createAction('SAVE_WINNER_SUCCESS');

/**
 * This method does a lot of work under the covers to de-identify the case data and store the non-PII in our Internal
 * API and the PII (or possible PII like images and free-text) in TrueVault. See the Api Helper by the same name
 * for details on the approach.
 */
export function saveWinner(tvClient, id) {
	 return async dispatch => {
				dispatch(saveWinnerStart());

				try {
						const saved = await internalApiClient.saveWinningSurvey(tvClient.accessToken, id);
						dispatch(saveWinnerSuccess({}));
				} catch (error) {
						dispatch(saveWinnerError(error));
				}
		};
}

export function findStaffByName(tvAccessToken, query){
		return async dispatch => {
				try {
						//dispatch(staffSearchStart());
						// Does the survey url ID actually exist?  If not redirect to the generic page for this organization
						const staffList = await internalApiClient.findStaffByName(tvAccessToken, query);
						return staffList;
						//dispatch(staffSearchSuccess({ staffList }));

				} catch (error) {
						console.log(error);
						//dispatch(staffSearchError(error));
				}
		};
}

export function findStaffByNameOnly(tvAccessToken, query){
		return async dispatch => {
				try {
						//dispatch(staffSearchStart());
						// Does the survey url ID actually exist?  If not redirect to the generic page for this organization
						const staffList = await internalApiClient.findStaffByNameOnly(tvAccessToken, query);
						return staffList;
						//dispatch(staffSearchSuccess({ staffList }));

				} catch (error) {
						console.log(error);
						//dispatch(staffSearchError(error));
				}
		};
}

export function getStaffExport(tvAccessToken, locationId) {
		return async dispatch => {
				try {
						const staffList = await internalApiClient.getStaffExport(tvAccessToken, locationId);
						return staffList;
						//dispatch(staffSearchSuccess({ staffList }));

				} catch (error) {
						console.log(error);
						return [];
						//dispatch(staffSearchError(error));
				}
		};
}

export function getActivePeerStaffExport(tvAccessToken, locationId, dateFilter){
		return async dispatch => {
				try {
						const staffList = await internalApiClient.getActivePeerStaffExport(tvAccessToken, locationId, dateFilter);
						return staffList;
						//dispatch(staffSearchSuccess({ staffList }));

				} catch (error) {
						console.log(error);
						return [];
						//dispatch(staffSearchError(error));
				}
		};
}

export function getRecognizedPeerStaffExport(tvAccessToken, locationId, dateFilter){
		return async dispatch => {
				try {

						const staffList = await internalApiClient.getPaginatedPeerStaffEngagementReport(tvAccessToken, 'desc', '', 1, 1000, locationId, dateFilter, null, null, 0);
						//const staffList = await internalApiClient.getRecognizedPeerStaffExport(tvAccessToken, locationId, dateFilter);
						return staffList;
						//dispatch(staffSearchSuccess({ staffList }));

				} catch (error) {
						console.log(error);
						return [];
						//dispatch(staffSearchError(error));
				}
		};
}

const drawWinnerStart = createAction('DRAW_WINNER_START');
const drawWinnerError = createAction('DRAW_WINNER_ERROR');
const drawWinnerSuccess = createAction('DRAW_WINNER_SUCCESS');

/**
 * This method does a lot of work under the covers to de-identify the case data and store the non-PII in our Internal
 * API and the PII (or possible PII like images and free-text) in TrueVault. See the Api Helper by the same name
 * for details on the approach.
 */
export function findWinner(tvClient, fromDate, toDate, locationId=0) {
	 return async dispatch => {
				dispatch(drawWinnerStart());

				try {
						const surveys = await internalApiClient.getWinningSurvey(tvClient.accessToken, fromDate, toDate, locationId);
						var totalTickets = surveys.length;
						var winningTicket = {};
						if(totalTickets===0){
								// No Winner Scenario
						} else {
							var winningNumber = Math.floor((Math.random() * totalTickets));
							winningTicket  = surveys[winningNumber];
							const surveyPerson = await tvClient.getDocuments(process.env.REACT_APP_PATIENT_VAULT_ID, [winningTicket.tv_survey_id]);
							winningTicket.person = surveyPerson[0].document.name;
						}

						dispatch(drawWinnerSuccess({
								winner: {
										...winningTicket
								}
						}));
				} catch (error) {
						dispatch(drawWinnerError(error));
				}
		};
}

export function viewStaffFromSearch(staffId){
	 return async dispatch => {
				try{
						dispatch(push('/staff/edit/'+staffId));
				} catch(error){
						console.log('could not find staff from search');
				}
		};
}

const staffViewStart = createAction('STAFF_VIEW_START');
const staffViewProgress = createAction('STAFF_VIEW_PROGRESS');
const staffViewError = createAction('STAFF_VIEW_ERROR');
const staffViewSuccess = createAction('STAFF_VIEW_SUCCESS');
const staffViewUpdatePoints = createAction('STAFF_UPDATE_POINTS');

/**
 * This method does a lot of work under the covers to de-identify the case data and store the non-PII in our Internal
 * API and the PII (or possible PII like images and free-text) in TrueVault. See the Api Helper by the same name
 * for details on the approach.
 */
export function viewStaff(tvClient, staffDetail) {
	 return async dispatch => {
				dispatch(staffViewStart());

				try {
						const staffDocument = await internalApiClient.getPerson(tvClient, staffDetail);
						dispatch(staffViewSuccess({
								staffData: {
										...staffDocument
								}
						}));
				} catch (error) {
						dispatch(staffViewError(error));
				}
		};
}

export function creditStaffPoints(tvClient, staff_id, points, reason) {
		return async dispatch => {

				try {
						const staffDocument = await internalApiClient.creditPoints(tvClient.accessToken, staff_id, points, reason);
						dispatch(staffViewUpdatePoints({
										staffData: {
												...staffDocument
										}
								}));
						dispatch(displayFlashMessage('success', ''+points+' have been credited.'));
				} catch (error) {
						console.log(error);
				}
		};
}

export function redeemStaffPoints(tvClient, staff_id, points, reason) {
		return async dispatch => {

				try {
						const staffDocument = await internalApiClient.redeemPoints(tvClient.accessToken, staff_id, points, reason);
						dispatch(staffViewUpdatePoints({
										staffData: {
												...staffDocument
										}
								}));
						dispatch(displayFlashMessage('success', ''+points+' have been redeemed.'));
				} catch (error) {
						console.log(error);
				}
		};
}


const badgeViewStart = createAction('ENDORSEMENT_VIEW_START');
const badgeViewError = createAction('ENDORSEMENT_VIEW_ERROR');
const badgeViewSuccess = createAction('ENDORSEMENT_VIEW_SUCCESS');

/**
 * This method does a lot of work under the covers to de-identify the case data and store the non-PII in our Internal
 * API and the PII (or possible PII like images and free-text) in TrueVault. See the Api Helper by the same name
 * for details on the approach.
 */
export function viewEndorsement(tvClient, badgeDetail) {
	 return async dispatch => {
				dispatch(badgeViewStart());

				try {
						const endorsementDocument = await internalApiClient.getEndorsement(tvClient, badgeDetail);
						dispatch(badgeViewSuccess({
								badgeData: {
										...endorsementDocument
								}
						}));
				} catch (error) {
						dispatch(badgeViewError(error));
				}
		};
}


const createAdminStart = createAction('CREATE_ADMIN_START');
const createAdminError = createAction('CREATE_ADMIN_ERROR');
const createAdminSuccess = createAction('CREATE_ADMIN_SUCCESS');

export function createAdmin(tvClient, name, email, mobile, role, pass1, pass2, locations=[], simpleLocations=[]){
		 return async dispatch => {
				try {
						dispatch(createAdminStart());

								if(role==='surveysreportsonly'){
									locations = simpleLocations;
								}
								const user = await tvClient.createUser(email, process.env.REACT_APP_CLIENT_TOKEN, {
										mobile: mobile,
										email: email,
										role: role,
										name: name,
										locations: locations
								});

								// Add user to the group for this client
								const addUserToPatientsGroupRequest = tvClient.addUsersToGroup(process.env.REACT_APP_PATIENTS_GROUP_ID, [user.id]);
								const addUserToAdminGroupRequest = tvClient.addUsersToGroup(process.env.REACT_APP_ADMIN_GROUP_ID, [user.id]);

								await Promise.all([addUserToPatientsGroupRequest, addUserToAdminGroupRequest]);

								// Now create the admin record
								await internalApiClient.createAdminUser(tvClient.accessToken, user.id, name, email, mobile, role);

								// Now we need to assign the password as if we were updating them
								if(pass1!==null && pass1!==undefined && pass1===pass2){
										const user2 = await tvClient.updateUserPassword(user.id, pass1);
								}

								// User has been created so now we need to create it in our own database to show on settings
								dispatch(displayFlashMessage('success', localizedDashboardData.userCreated));
								dispatch(push('/settings?active=4'));
								dispatch(createAdminSuccess());
				}
				catch(e){
						dispatch(displayFlashMessage('warning', localizedDashboardData.issueCreatingUser));
						dispatch(createAdminError(e));
				}
		}
}

const emailPatientStart = createAction('EMAIL_PATIENT_START');
const emailPatientError = createAction('EMAIL_PATIENT_ERROR');
const emailPatientSuccess = createAction('EMAIL_PATIENT_SUCCESS');

export function emailPatient(tvClient, adminAttr, patientName, patientEmail, language, successCallback, locationId, journey, emailMessage=null) {
		return async dispatch => {
				try {
						dispatch(emailPatientStart());

						localizedDashboardData.setLanguage(language);
						let message = localizedDashboardData.formatString(localizedDashboardData.emailMessaging, process.env.REACT_APP_CLIENT_NAME);
						if(emailMessage!==null){
								message = emailMessage;
						}
						localizedDashboardData.setLanguage("EN");
						// if journey is null it means that there was none

						// Search to see if the user exists with their phone number already and if so don't create
						const userExists = await internalApiClient.checkForUser(patientEmail+process.env.REACT_APP_CLIENT_TOKEN, tvClient);
						// User doesn't exist so let's ceate it

						let adminName = 'Admin ' + locationId;
						if(adminAttr!==undefined && adminAttr!==null){
								adminName = adminAttr.name;
								if(adminName===undefined){
										adminName = 'Admin ' + locationId;
								}
						}

						if(userExists.documents.length>0){

							 var currUser = userExists.documents[0];

								// PJH Have the user so we need to create the survey_url
								const survey_url = await internalApiClient.createPatientSurvey(tvClient.accessToken, locationId, language, journey.journey_id, adminName);
								var patientUrl =  'https://' + window.location.host + '/#/j/' + survey_url.url_id;
								var tvPatientId = null;
								// Now create the patient record in TV
								try {
										tvPatientId = await publicHelpers.createPatientNoCallback(tvClient, {}, survey_url.url_id, currUser.attributes.name, currUser.attributes.email, null, 'Patient', currUser.attributes.lang);
										// create the step one survey entry locally
										await internalApiClient.createSpecificPatientSurvey(tvClient.accessToken, survey_url.url_id, survey_url.questions, language, locationId, tvPatientId);
										// Send the survey_url link in the text
										const sentEmailMsg = internalApiClient.sendEmailMessage(currUser.user_id, currUser.attributes.email, message, patientUrl, tvClient, locationId, survey_url.url_id, journey.journey_id, language);
										dispatch(displayFlashMessage('success', localizedDashboardData.emailSent));
										dispatch(emailPatientSuccess({patientUserId: currUser.user_id}));

								} catch (error) {
										dispatch(emailPatientError(error));
								}
						}
						else{

								const user = await tvClient.createUser(patientEmail+process.env.REACT_APP_CLIENT_TOKEN, null, {
										email: patientEmail,
										role: 'patient',
										name: patientName,
										lang: language
								});
								// Add user to the group for this client
								const addUserToPatientsGroupRequest = tvClient.addUsersToGroup(process.env.REACT_APP_PATIENTS_GROUP_ID, [user.id]);
								await Promise.all([addUserToPatientsGroupRequest]);
								// PJH Have the user so we need to create the survey_url
								const survey_url = await internalApiClient.createPatientSurvey(tvClient.accessToken, locationId, language, journey.journey_id);
								var patientUrl =  'https://' + window.location.host + '/#/j/' + survey_url.url_id;
								var tvPatientId = null;
								// Now create the patient record in TV
								try {
										tvPatientId = await publicHelpers.createPatientNoCallback(tvClient, {}, survey_url.url_id, patientName, patientEmail, null, 'Patient', language);
										// create the step one survey entry locally
										await internalApiClient.createSpecificPatientSurvey(tvClient.accessToken, survey_url.url_id, survey_url.questions, language, locationId, tvPatientId);
										// Send the survey_url link in the text
										const sentEmailMsg = internalApiClient.sendEmailMessage(user.id, patientEmail, message, patientUrl, tvClient, locationId, survey_url.url_id, journey.journey_id,language);
										dispatch(displayFlashMessage('success', localizedDashboardData.emailSent));
										dispatch(emailPatientSuccess({patientUserId: user.id}));

								} catch (error) {
										dispatch(emailPatientError(error));
								}
						}

				} catch (e) {
						dispatch(emailPatientError(e));
				}
		};
}

const groupEmailPatientStart = createAction('GROUP_EMAIL_PATIENT_START');
const groupEmailPatientError = createAction('GROUP_EMAIL_PATIENT_ERROR');
const groupEmailPatientSuccess = createAction('GROUP_EMAIL_PATIENT_SUCCESS');

export function groupEmailPatient(tvClient, campaignId, journey, language, locationId, adminAttr) {
		return async dispatch => {
				try {
						dispatch(groupEmailPatientStart());
						let adminName = 'Admin ';
						if(adminAttr!==undefined && adminAttr!==null){
								adminName = adminAttr.name;
								if(adminName===undefined){
										adminName = 'Admin';
								}
						}
						try {
								var message = await internalApiClient.sendGroupEmailMessage(tvClient.accessToken, campaignId, journey.journey_id, language, locationId, adminName);
								dispatch(displayFlashMessage('success', localizedDashboardData.emailsHaveSent));
								dispatch(groupEmailPatientSuccess());
						} catch (error) {
								dispatch(groupEmailPatientError(error));
						}
				} catch (e) {
						dispatch(groupEmailPatientError(e));
				}
		};
}

export function sendEmployerReminder(tvClient, sentId) {
		return async dispatch => {
				try {
						var message = await internalApiClient.sendEmployerReminder(tvClient.accessToken, sentId);
				} catch (e) {
						console.log(e);
				}
		};
}

const groupTextPatientStart = createAction('GROUP_TEXT_PATIENT_START');
const groupTextPatientError = createAction('GROUP_TEXT_PATIENT_ERROR');
const groupTextPatientSuccess = createAction('GROUP_TEXT_PATIENT_SUCCESS');

export function groupTextPatient(tvClient, campaignId, journey, language, locationId ) {
		return async dispatch => {
				try {
						dispatch(groupTextPatientStart());

						// if journey is null it means that there was none
						let message = "Start your feedback for " + process.env.REACT_APP_CLIENT_NAME + " now. Visit: ";
						if(language==='ES'){
								message = "Comience su retroalimentaci�n para " + process.env.REACT_APP_CLIENT_NAME + " ahora. Visita: ";
						}

						try {
								var groupMessage = await internalApiClient.beginGroupText(tvClient.accessToken, message, campaignId, journey.journey_id, language, locationId);
								dispatch(displayFlashMessage('success', groupMessage.success));
								dispatch(groupTextPatientSuccess());
						} catch (error) {
								dispatch(groupTextPatientError(error));
						}

				} catch (e) {
						dispatch(groupTextPatientError(e));
				}
		};
}


const directEmpSurveyStart = createAction('DIRECT_EMP_SURVEY_START');
const directEmpSurveyError = createAction('DIRECT_EMP_SURVEY_ERROR');
const directEmpSurveySuccess = createAction('DIRECT_EMP_SURVEY_SUCCESS');

export function directEmpSurvey(tvClient, isEmail, username, mobile, email, campaignId, journeyId, language, locationId, consent, adminAttr) {
		return async dispatch => {
				try {
						dispatch(directEmpSurveyStart());

						try {
								// Search to see if the user exists with their phone number already and if so don't create
								var patientIdentifier = isEmail===false ? mobile : email;

								var tv_id = 'employer';
								// Search to see if the user exists with their phone number already and if so don't create
								const userExists = await internalApiClient.checkForUser(patientIdentifier+process.env.REACT_APP_CLIENT_TOKEN, tvClient);

								if(userExists.documents.length>0){
										var currUser = userExists.documents[0];
										tv_id = currUser.user_id
										//await internalApiClient.addPersonToGroup(tvClient.accessToken, name, number);
								} else{

										const user = await tvClient.createUser(patientIdentifier+process.env.REACT_APP_CLIENT_TOKEN, null, {
												mobile: mobile,
												email: email,
												role: 'employer',
												name: username,
												lang: language
										});

										const addUserToPatientsGroupRequest = tvClient.addUsersToGroup(process.env.REACT_APP_PATIENTS_GROUP_ID, [user.id]);
										await Promise.all([addUserToPatientsGroupRequest]);

										tv_id = user.id
								}

								// if journey is null it means that there was none
								let message = "Start your feedback for " + process.env.REACT_APP_CLIENT_NAME + " now. Visit: ";
								if(language==='ES'){
										message = "Comience su retroalimentaci�n para " + process.env.REACT_APP_CLIENT_NAME + " ahora. Visita: ";
								}


								let adminName = 'Admin ' + locationId;
								if(adminAttr!==undefined && adminAttr!==null){
										adminName = adminAttr.name;
										if(adminName===undefined){
												adminName = 'Admin ' + locationId;
										}
								}

								var directEmployerMsg = await internalApiClient.sendDirectEmpSurvey(tvClient.accessToken, isEmail, message, campaignId, journeyId, language, locationId, adminName, tv_id, username, mobile, email);
								dispatch(displayFlashMessage('success', directEmployerMsg.success));
								dispatch(directEmpSurveySuccess());
						} catch (error) {
								dispatch(directEmpSurveyError(error));
						}

				} catch (e) {
						dispatch(directEmpSurveyError(e));
				}
		};
}



const bulkSendMessagesStart = createAction('BULK_SEND_MESSAGES_START');
const bulkSendMessagesError = createAction('BULK_SEND_MESSAGES_ERROR');
const bulkSendMessagesSuccess = createAction('BULK_SEND_MESSAGES_SUCCESS');
const bulkSendMessagesProgress = createAction('BULK_SEND_MESSAGES_PROGRESS');

export function bulkSendMessages(tvClient, messages){
 return async dispatch => {
				dispatch(bulkSendMessagesStart());
				try {
						var count = 0;
						var errorRecords = [];

						// Retrieve the appointment to journey mapping
						const journeyMapping = await internalApiClient.getJourneyMappings(tvClient);
						// Retrieve the text and email messaging from the client settings
						var settings = await internalApiClient.getClientSettings(tvClient.accessToken);
						var currentAppointment = null;
						var currentJourney = null;

						for(var i=0; i<messages.length; i++){
								if(i==0) { continue; }
								else{
										var currMessage = messages[i];
										if(currMessage.length>0){
												try{
														var patientName = (currMessage[0]===undefined) ? '' : currMessage[0];
														var patientMobile = (currMessage[1]===undefined) ? '' : currMessage[1];
														var patientEmail = (currMessage[2]===undefined) ? '' : currMessage[2];
														var patientLocation = (currMessage[3]===undefined) ? '' : currMessage[3];
														var patientAppointment = (currMessage[4]===undefined) ? '' : currMessage[4];
														var patientLanguage = (currMessage[5]===undefined) ? 'EN' : currMessage[5];
														var patientConsent = (currMessage[6]===undefined) ? '' : currMessage[6];
														var patientProvider = (currMessage[7]===undefined) ? '' : currMessage[7];
														var patientDob = (currMessage[8]===undefined) ? '' : ExcelDateToJSDate(currMessage[8]);
														var textMessage = settings.clientSettings[0].text_message[patientLanguage];;
														var emailMessage = settings.clientSettings[0].email_message[patientLanguage];

														// validate the mobile number
														if(patientMobile.length>0){
																var mobileNoTest = patientMobile;
																var replacement = "-";
																var dashRegEx = new RegExp(replacement, "g");
																mobileNoTest = mobileNoTest.replace(dashRegEx, '');
																mobileNoTest = mobileNoTest.replace('(', '');
																mobileNoTest = mobileNoTest.replace(')', '');
																replacement = " ";
																dashRegEx = new RegExp(replacement, "g");
																mobileNoTest = mobileNoTest.replace(dashRegEx, '');

																if(!mobileNoTest.startsWith("+")){
																	if (!mobileNoTest.match(/^\+?[0-9]{10}$/) && !mobileNoTest.match(/^\+?[0-9]{11}$/) && !mobileNoTest.match(/^\+?[0-9]{12}$/)) {
																		currMessage.push('Invalid mobile');
																		throw Error('Invalid mobile');
																	}
																} else {
																	if(mobileNoTest.length<10){
																	 currMessage.push('Invalid mobile');
																	 throw Error('Invalid mobile');
																	} else{
																		patientMobile = mobileNoTest;
																	}
																}
														}

														// validate the email
														if (patientEmail.length>0) {
															//regular expression for email validation
															var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
															if (!pattern.test(patientEmail)) {
																 currMessage.push('Invalid email');
																 throw Error('Invalid email');
															}
														}

														if(patientLocation.length===0){
																currMessage.push('Location is required');
																throw Error('Invalid Location');
														} else if(patientAppointment.length===0){
																currMessage.push('Appointment is required');
																throw Error('Invalid Appointment');
														}

														if((currentAppointment===null) || (currentAppointment!==patientAppointment)){
																currentAppointment=patientAppointment;
																for(var j=0; j<journeyMapping.length;j++){
																		if(journeyMapping[j].appointment_code===patientAppointment){
																				currentJourney=journeyMapping[j].journey_id;
																		}
																}
																if(currentJourney===null){
																		currMessage.push('No journeys for that appointment');
																		throw Error('No journeys for appointment');
																}
														}

														// Check consent and mobile and text otherwise email if there is an email
														if((patientConsent==='Y' || patientConsent==='Yes' || patientConsent==='true') && (patientMobile.toString().length>0)){
															 await dispatch(textPatient(tvClient, null, patientName, patientMobile.toString(), patientLanguage, null, patientLocation, {'journey_id': currentJourney}, textMessage));
														} else{
																if(patientEmail.length>0){
																		await dispatch(emailPatient(tvClient, null, patientName, patientEmail.toString(), patientLanguage, null, patientLocation, {'journey_id': currentJourney}, emailMessage));
																} else{
																		currMessage.push('No consent and no email');
																		throw Error('Invalid consent and email');
																}
														}
														count++;
												} catch(e){
														errorRecords.push(currMessage);
												}

												dispatch(bulkSendMessagesProgress({ messagesSent: count, totalMessages: messages.length-1, errors: errorRecords }));
										}
								}
						}

						dispatch(bulkSendMessagesSuccess());
						dispatch(displayFlashMessage('success', count + ' Messages Sent'));
				} catch (error) {
						dispatch(bulkSendMessagesError(error));
				}
		};
}

const textPatientStart = createAction('TEXT_PATIENT_START');
const textPatientError = createAction('TEXT_PATIENT_ERROR');
const textPatientSuccess = createAction('TEXT_PATIENT_SUCCESS');

export function textPatient(tvClient, adminAttr, patientName, patientPhone, language, successCallback, locationId, journey, textMessage=null) {
		return async dispatch => {
				try {
						dispatch(textPatientStart());

						localizedDashboardData.setLanguage(language);
						let message = localizedDashboardData.formatString(localizedDashboardData.emailMessaging, process.env.REACT_APP_CLIENT_NAME);
						if(textMessage!==null){
								message = textMessage;
						}
						localizedDashboardData.setLanguage("EN");

						// Search to see if the user exists with their phone number already and if so don't create
						const userExists = await internalApiClient.checkForUser(patientPhone+process.env.REACT_APP_CLIENT_TOKEN, tvClient);
						// User doesn't exist so let's ceate it

						let adminName = 'Admin ' + locationId;
						if(adminAttr!==undefined && adminAttr!==null){
								adminName = adminAttr.name;
								if(adminName===undefined){
										adminName = 'Admin ' + locationId;
								}
						}

						if(userExists.documents.length>0){

							 var currUser = userExists.documents[0];

								// PJH Have the user so we need to create the survey_url
								const survey_url = await internalApiClient.createPatientSurvey(tvClient.accessToken, locationId, language, journey.journey_id, adminName);
								var patientUrl =  'https://' + window.location.host + '/#/j/' + survey_url.url_id;
								var tvPatientId = null;
								// Now create the patient record in TV
								try {
										tvPatientId = await publicHelpers.createPatientNoCallback(tvClient, {}, survey_url.url_id, currUser.attributes.name, null, currUser.attributes.mobile, 'Patient', currUser.attributes.lang);
										// create the step one survey entry locally
										await internalApiClient.createSpecificPatientSurvey(tvClient.accessToken, survey_url.url_id, survey_url.questions, language, locationId, tvPatientId);
										// Send the survey_url link in the text
										const sentTextMsg = internalApiClient.sendTextMessage(currUser.user_id, message + patientUrl, tvClient, locationId, survey_url.url_id, journey.journey_id, language);
										dispatch(displayFlashMessage('success', localizedDashboardData.textMessageSent));
										dispatch(textPatientSuccess({patientUserId: currUser.user_id}));

								} catch (error) {
										dispatch(textPatientError(error));
								}

						}
						else{

								const user = await tvClient.createUser(patientPhone+process.env.REACT_APP_CLIENT_TOKEN, null, {
										mobile: patientPhone,
										role: 'patient',
										name: patientName,
										lang: language
								});
								// Add user to the group for this client
								const addUserToPatientsGroupRequest = tvClient.addUsersToGroup(process.env.REACT_APP_PATIENTS_GROUP_ID, [user.id]);
								await Promise.all([addUserToPatientsGroupRequest]);
								// PJH Have the user so we need to create the survey_url
								const survey_url = await internalApiClient.createPatientSurvey(tvClient.accessToken, locationId, language, journey.journey_id, adminName);
								var patientUrl =  'https://' + window.location.host + '/#/j/' + survey_url.url_id;
								var tvPatientId = null;
								// Now create the patient record in TV
								try {
										tvPatientId = await publicHelpers.createPatientNoCallback(tvClient, {}, survey_url.url_id, patientName, null, patientPhone, 'Patient', language);
										// create the step one survey entry locally
										await internalApiClient.createSpecificPatientSurvey(tvClient.accessToken, survey_url.url_id, survey_url.questions, language, locationId, tvPatientId);
										// Send the survey_url link in the text
										const sentTextMsg = internalApiClient.sendTextMessage(user.id, message + patientUrl, tvClient, locationId, survey_url.url_id, journey.journey_id, language);
										dispatch(displayFlashMessage('success', localizedDashboardData.textMessageSent));
										dispatch(textPatientSuccess({patientUserId: user.id}));

								} catch (error) {
										dispatch(textPatientError(error));
								}
								//dispatch(textPatientSuccess({patientUserId: user.id}));
						}

				} catch (e) {
						dispatch(textPatientError(e));
				}
		};
}

const deptListStart = createAction('DEPT_LIST_START');
const deptListError = createAction('DEPT_LIST_ERROR');
const deptListSuccess = createAction('DEPT_LIST_SUCCESS');

/**
 * When we list the departments we do not need to store those in TrueVault.  All of this can come from the internal API.
 *
 *
 * @param tvClient TrueVaultClient
 * @param filterType 'and' or 'or', how to evaluate the multiple filters
 * @param filter an object describing how to filter results
 * @param sort an object describing multi-sort order
 * @param page which page in the result set
 * @param perPage how many results per page
 * @returns {function(*)}
 */
export function listDepartments(tvClient, filterType, filter, sort, page, perPage) {
		return async dispatch => {
				dispatch(deptListStart());

				try {
						const departmentRecords = await internalApiClient.getDepartments(tvClient.accessToken, sort, page, perPage);
						var pagination = {};

						if(departmentRecords.length>0){
								pagination.num_pages = Math.ceil(departmentRecords[0].full_count / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(departmentRecords.length / perPage);
						}
						pagination.current_page = page;

						dispatch(deptListSuccess({departments: departmentRecords, info: pagination}));
				} catch (error) {
						dispatch(deptListError(error));
				}
		};
}



const reviewTokenStart = createAction('REVIEW_TOKEN_START');
const reviewTokenError = createAction('REVIEW_TOKEN_ERROR');
const reviewTokenSuccess = createAction('REVIEW_TOKEN_SUCCESS');

export function getAuthTokens(tvClient) {
		return async dispatch => {
				dispatch(reviewTokenStart());

				try {
						const tokenRecord = await internalApiClient.getAuthTokens(tvClient.accessToken);

						dispatch(reviewTokenSuccess({token: tokenRecord}));
				} catch (error) {
						dispatch(reviewTokenError(error));
				}
		};
}

const reviewJwtStart = createAction('REVIEW_JWT_START');
const reviewJwtError = createAction('REVIEW_JWT_ERROR');
const reviewJwtSuccess = createAction('REVIEW_JWT_SUCCESS');

export function getSsoJwt(tvClient) {
		return async dispatch => {
				dispatch(reviewJwtStart());

				try {
						const token = await internalApiClient.getSsoJwt(tvClient.accessToken);

						dispatch(reviewJwtSuccess({jwt: token}));
				} catch (error) {
						dispatch(reviewJwtError(error));
				}
		};
}

const reviewLocationListStart = createAction('REVIEW_LOCATION_LIST_START');
const reviewLocationListError = createAction('REVIEW_LOCATION_LIST_ERROR');
const reviewLocationListSuccess = createAction('REVIEW_LOCATION_LIST_SUCCESS');

export function getReviewLocations(tvClient, type, accountId){
		return async dispatch => {
				dispatch(reviewLocationListStart());

				try {
						const locationList = await internalApiClient.getReviewLocations(tvClient.accessToken, type, accountId);
						dispatch(reviewLocationListSuccess({locations: locationList}));
				} catch (error) {
						dispatch(reviewLocationListError(error));
				}
		};
}

export function refreshGoogleAccountLocations(tvClient, type, accountId){
		return async dispatch => {
				dispatch(reviewLocationListStart());

				try {
						const locationList = await internalApiClient.getRefreshedReviewLocations(tvClient.accessToken, type, accountId);
						dispatch(reviewLocationListSuccess({locations: locationList}));
				} catch (error) {
						dispatch(reviewLocationListError(error));
				}
		};
}

const reviewAccountListStart = createAction('REVIEW_ACCOUNT_LIST_START');
const reviewAccountListError = createAction('REVIEW_ACCOUNT_LIST_ERROR');
const reviewAccountListSuccess = createAction('REVIEW_ACCOUNT_LIST_SUCCESS');

export function getReviewAccounts(tvClient, type){
		return async dispatch => {
				dispatch(reviewAccountListStart());

				try {
						const accountList = await internalApiClient.getReviewAccounts(tvClient.accessToken, type);
						dispatch(reviewAccountListSuccess({accounts: accountList}));
				} catch (error) {
						dispatch(reviewAccountListError(error));
				}
		};
}

export function refreshGoogleAccounts(tvClient, type){
		return async dispatch => {
				dispatch(reviewAccountListStart());

				try {
						const accountList = await internalApiClient.getRefreshedReviewAccounts(tvClient.accessToken, type);
						dispatch(reviewAccountListSuccess({accounts: accountList}));
				} catch (error) {
						dispatch(reviewAccountListError(error));
				}
		};
};


const locationReviewListStart = createAction('LOCATION_REVIEW_LIST_START');
const locationReviewListError = createAction('LOCATION_REVIEW_LIST_ERROR');
const locationReviewListSuccess = createAction('LOCATION_REVIEW_LIST_SUCCESS');

export function getLocationReviews(tvClient, locationId, type, currPage, maxPerPage){
		return async dispatch => {
				dispatch(locationReviewListStart());

				try {
						const reviewList = await internalApiClient.getLocationReviews(tvClient.accessToken, locationId, type, currPage, maxPerPage);

						var pagination = {};
						if(reviewList.length>0){
								pagination.num_pages = Math.ceil(reviewList[0].full_count / maxPerPage);
						}
						else{
								pagination.num_pages = Math.ceil(reviewList[0].length / maxPerPage);
						}
						pagination.current_page = currPage;

						dispatch(locationReviewListSuccess({reviews: reviewList, info: pagination}));
				} catch (error) {
						dispatch(locationReviewListError(error));
				}
		};
};

export function refreshGoogleLocationReviews(tvClient, locationId, type, currPage, maxPerPage){
		return async dispatch => {
				dispatch(locationReviewListStart());

				try {
						const reviewList = await internalApiClient.refreshGoogleLocationReviews(tvClient.accessToken, locationId, type, currPage, maxPerPage);

						var pagination = {};
						if(reviewList.length>0){
								pagination.num_pages = Math.ceil(reviewList[0].full_count / maxPerPage);
						}
						else{
								pagination.num_pages = Math.ceil(reviewList[0].length / maxPerPage);
						}
						pagination.current_page = currPage;

						dispatch(locationReviewListSuccess({reviews: reviewList, info: pagination}));
				} catch (error) {
						dispatch(locationReviewListError(error));
				}
		};
};


const campaignListStart = createAction('CAMP_LIST_START');
const campaignListError = createAction('CAMP_LIST_ERROR');
const campaignListSuccess = createAction('CAMP_LIST_SUCCESS');

export function listCampaigns(tvClient) {
		return async dispatch => {
				dispatch(campaignListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const campaigns = await internalApiClient.getCampaigns(tvClient.accessToken);

						dispatch(campaignListSuccess({campaigns: campaigns}));
				} catch (error) {
						dispatch(campaignListError(error));
				}
		};
}

const groupListStart = createAction('GROUP_LIST_START');
const groupListError = createAction('GROUP_LIST_ERROR');
const groupListSuccess = createAction('GROUP_LIST_SUCCESS');

export function listGroups(tvClient) {
		return async dispatch => {
				dispatch(groupListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const groups = await internalApiClient.getGroups(tvClient.accessToken);

						dispatch(groupListSuccess({groups: groups}));
				} catch (error) {
						dispatch(groupListError(error));
				}
		};
}

const locationListStart = createAction('LOCATION_LIST_START');
const locationListError = createAction('LOCATION_LIST_ERROR');
const locationListSuccess = createAction('LOCATION_LIST_SUCCESS');

export function listLocations(tvClient) {
		return async dispatch => {
				dispatch(locationListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const locations = await internalApiClient.getOfficeLocations(tvClient.accessToken);

						dispatch(locationListSuccess({locations: locations}));
				} catch (error) {
						dispatch(locationListError(error));
				}
		};
}

const locationMappingGroupListStart = createAction('LOCATION_MAPPING_GROUP_LIST_START');
const locationMappingGroupListError = createAction('LOCATION_MAPPING_GROUP_LIST_ERROR');
const locationMappingGroupListSuccess = createAction('LOCATION_MAPPING_GROUP_LIST_SUCCESS');

export function listLocationMappingGroups(tvClient) {
		return async dispatch => {
				dispatch(locationMappingGroupListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const mappingGroups = await internalApiClient.getLocationMappingGroups(tvClient);

						dispatch(locationMappingGroupListSuccess({locationMappingGroups: mappingGroups}));
				} catch (error) {
						dispatch(locationMappingGroupListError(error));
				}
		};
}


const listRecentSurveysStart = createAction('LIST_RECENT_SURVEYS_START');
const listRecentSurveysError = createAction('LIST_RECENT_SURVEYS_ERROR');
const listRecentSurveysSuccess = createAction('LIST_RECENT_SURVEYS_SUCCESS');

export function listRecentSurveys(tvClient) {
		return async dispatch => {
				dispatch(listRecentSurveysStart());

				try {
						const recentSurveys = await internalApiClient.getRecentSurveys(tvClient);

						dispatch(listRecentSurveysSuccess({recentSurveys: recentSurveys}));
				} catch (error) {
						dispatch(listRecentSurveysError(error));
				}
		};
}

const listEmployersStart = createAction('LIST_EMPLOYERS_START');
const listEmployersError = createAction('LIST_EMPLOYERS_ERROR');
const listEmployersSuccess = createAction('LIST_EMPLOYERS_SUCCESS');

export function listEmployers(tvClient) {
		return async dispatch => {
				dispatch(listEmployersStart());

				try {
						const employerList = await internalApiClient.getEmployers(tvClient);

						dispatch(listEmployersSuccess({employers: employerList}));
				} catch (error) {
						dispatch(listEmployersError(error));
				}
		};
}

const journeyListStart = createAction('JOURNEY_LIST_START');
const journeyListError = createAction('JOURNEY_LIST_ERROR');
const journeyListSuccess = createAction('JOURNEY_LIST_SUCCESS');

export function listJourneys(tvClient) {
		return async dispatch => {
				dispatch(journeyListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const journeys = await internalApiClient.getJourneys(tvClient);

						dispatch(journeyListSuccess({journeys: journeys}));
				} catch (error) {
						dispatch(journeyListError(error));
				}
		};
}

const pccEnableStart = createAction('PCC_ENABLE_START');
const pccEnableError = createAction('PCC_ENABLE_ERROR');
const pccEnableSuccess = createAction('PCC_ENABLE_SUCCESS');

export function enablePCCFunctionality(tvClient, uuid) {
		return async dispatch => {
				dispatch(pccEnableStart());
				try {
						const pccEnabled = await internalApiClient.enablePCCFunctionality(tvClient, uuid);
						dispatch(pccEnableSuccess({pccEnabled: pccEnabled}));
				} catch (error) {
						dispatch(pccEnableError(error));
				}
		};
}

const runKeywordCommandStart = createAction('RUN_KEYWORD_COMMAND_ENABLE_START');
const runKeywordCommandError = createAction('RUN_KEYWORD_COMMAND_ENABLE_ERROR');
const runKeywordCommandSuccess = createAction('RUN_KEYWORD_COMMAND_ENABLE_SUCCESS');

export function runKeywordCommand(tvClient, commandId){
			return async dispatch => {
				dispatch(runKeywordCommandStart());
				try {
						const commandResponse = await internalApiClient.runKeywordCommand(tvClient, commandId);
						dispatch(runKeywordCommandSuccess({commandResponse: commandResponse}));
				} catch (error) {
						dispatch(runKeywordCommandError(error));
				}
		};
}

const journeyMappingListStart = createAction('JOURNEY_MAPPING_LIST_START');
const journeyMappingListError = createAction('JOURNEY_MAPPING_LIST_ERROR');
const journeyMappingListSuccess = createAction('JOURNEY_MAPPING_LIST_SUCCESS');

export function listJourneyMappings(tvClient) {
		return async dispatch => {
				dispatch(journeyMappingListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const journeyMapping = await internalApiClient.getJourneyMappings(tvClient);

						dispatch(journeyMappingListSuccess({journeyMapping: journeyMapping}));
				} catch (error) {
						dispatch(journeyMappingListError(error));
				}
		};
}


const locationMappingListStart = createAction('LOCATION_MAPPING_LIST_START');
const locationMappingListError = createAction('LOCATION_MAPPING_LIST_ERROR');
const locationMappingListSuccess = createAction('LOCATION_MAPPING_LIST_SUCCESS');

export function listLocationMappings(tvClient) {
		return async dispatch => {
				dispatch(locationMappingListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const locationMapping = await internalApiClient.getLocationMappings(tvClient);

						dispatch(locationMappingListSuccess({locationMapping: locationMapping}));
				} catch (error) {
						dispatch(locationMappingListError(error));
				}
		};
}

const journeyGroupListStart = createAction('JOURNEY_GROUP_LIST_START');
const journeyGroupListError = createAction('JOURNEY_GRUOP_LIST_ERROR');
const journeyGroupListSuccess = createAction('JOURNEY_GROUP_LIST_SUCCESS');

export function listGroupJourneys(tvClient) {
		return async dispatch => {
				dispatch(journeyGroupListStart());
				try {
						const journeys = await internalApiClient.getGroupJourneys(tvClient);
						dispatch(journeyGroupListSuccess({journeys: journeys}));
				} catch (error) {
						dispatch(journeyGroupListError(error));
				}
		};
}

const tempStaffListStart = createAction('TEMP_STAFF_LIST_START');
const tempStaffListError = createAction('TEMP_STAFF_LIST_ERROR');
const tempStaffListSuccess = createAction('TEMP_STAFF_LIST_SUCCESS');

export function listTempStaff(tvClient, page, perPage) {
		return async dispatch => {
				dispatch(tempStaffListStart());

				try {
						const localStaffRecords = await internalApiClient.getTempStaff(tvClient.accessToken, page, perPage);
						const result = { };
						var pagination = {};

						if(localStaffRecords.staff.length>0){
								pagination.num_pages = Math.ceil(localStaffRecords.staff[0].full_count / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(localStaffRecords.staff.length / perPage);
						}
						pagination.current_page = page;

						dispatch(tempStaffListSuccess({staff: localStaffRecords.staff, info: pagination }));
				} catch (error) {
						dispatch(tempStaffListError(error));
				}
		};
}


const staffListStart = createAction('STAFF_LIST_START');
const staffListError = createAction('STAFF_LIST_ERROR');
const staffListSuccess = createAction('STAFF_LIST_SUCCESS');

export function listStaff(tvClient, filterType, filter, sort, page, perPage, locationId) {
		return async dispatch => {
				dispatch(staffListStart());

				try {
						const localStaffRecords = await internalApiClient.getStaff(tvClient.accessToken, sort, filter, page, perPage,locationId);
						const result = { };
						var pagination = {};

						if(localStaffRecords.staff.length>0){
								pagination.num_pages = Math.ceil(localStaffRecords.staff[0].full_count / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(localStaffRecords.staff.length / perPage);
						}
						pagination.current_page = page;

						dispatch(staffListSuccess({staff: localStaffRecords.staff, info: pagination, showPeerReview: localStaffRecords.showPeerReview}));
				} catch (error) {
						dispatch(staffListError(error));
				}
		};
}

const actionableEventsListStart = createAction('ACTIONABLE_EVENTS_LIST_START');
const actionableEventsListError = createAction('ACTIONABLE_EVENTS_LIST_ERROR');
const actionableEventsListSuccess = createAction('ACTIONABLE_EVENTS_LIST_SUCCESS');

export function searchEventsByPatient(tvClient, username, page, perPage, sendAll){
		return async dispatch => {
				dispatch(actionableEventsListStart());
				try{
						var currentToken = process.env.REACT_APP_CLIENT_TOKEN;
						// configuration error in dynamo requires a client check here
						if(currentToken==='excelsior'){ currentToken = 'exclesior'};
						const userExists = await internalApiClient.checkForUser(username+currentToken, tvClient);
						// User doesn't exist so let's ceate it
						var theUserId = '5555';
						if(userExists.documents.length>0){
								var currUser = userExists.documents[0];
								theUserId = currUser.user_id;
						}
								const actionableEvents = await internalApiClient.getPaginatedActionableEventsByPatient(tvClient.accessToken, theUserId, username, page, perPage, sendAll)
								var pagination = {};
								//var offset = perPage * (page-1);

							 if(actionableEvents.combinedData.length>0){
										pagination.num_pages = Math.ceil(actionableEvents.combinedData[0].full_count / perPage);
								}
								else{
										pagination.num_pages = Math.ceil(actionableEvents.combinedData.length / perPage);
								}
								pagination.current_page = page;
								var combinedData = actionableEvents.combinedData;

								var tvList = [];

								for (var i = 0; i < combinedData.length; i++) {
										if(combinedData[i].event_type==='NPS'){
												if(combinedData[i].tv_survey_id!==null){
														tvList.push(combinedData[i].tv_survey_id);
												}
										}
								}

								if(tvList.length>0){
										let uniqueTvDocs = [...new Set(tvList)];
										var tvReply = await tvClient.getDocuments(actionableEvents.id, uniqueTvDocs);

										for(var z=0; z<combinedData.length; z++){
												for(var j=0; j<tvReply.length;j++){
														if(combinedData[z].tv_survey_id===tvReply[j].id){
																combinedData[z].name = tvReply[j].document.name;
																break;
														}
												}
										}
								}

								dispatch(actionableEventsListSuccess({allData: combinedData, eventListData: combinedData, info: pagination }));


				} catch(error){
						dispatch(actionableEventsListError(error));
				}
		};
}

export function listAllActionableEvents(tvClient, filterType, filter, sort, page, perPage, showNames=false, sendAll=false, locationList=null) {
		return async dispatch => {
				dispatch(actionableEventsListStart());

				try {

						// Download non-PHI data (approver, reviewer) from server
						const actionableEvents = await internalApiClient.getPaginatedActionableEvents(tvClient.accessToken, sort, filter, page, perPage, false, false, locationList);
						var pagination = {};
						//var offset = perPage * (page-1);

					 if(actionableEvents.combinedData.length>0){
								pagination.num_pages = Math.ceil(actionableEvents.combinedData[0].full_count / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(actionableEvents.combinedData.length / perPage);
						}
						pagination.current_page = page;
						var combinedData = actionableEvents.combinedData;

						var tvList = [];

						for (var i = 0; i < combinedData.length; i++) {
								if(combinedData[i].event_type==='NPS'){
										if(combinedData[i].tv_survey_id!==null){
												tvList.push(combinedData[i].tv_survey_id);
										}
								}
						}

						if(tvList.length>0){
								let uniqueTvDocs = [...new Set(tvList)];
								var tvReply = await tvClient.getDocuments(actionableEvents.id, uniqueTvDocs);

								for(var z=0; z<combinedData.length; z++){
										for(var j=0; j<tvReply.length;j++){
												if(combinedData[z].tv_survey_id===tvReply[j].id){
														combinedData[z].name = tvReply[j].document.name;
														break;
												}
										}
								}
						}


						if(showNames && sendAll){
								var tvReply = [];
								var tvList = [];
								var allData = actionableEvents.allData;
								for (var i = 0; i < allData.length; i++) {
										if(allData[i].event_type==='NPS'){
												if(allData[i].tv_survey_id!==null){
														tvList.push(allData[i].tv_survey_id);
												}
										}
								}

								if(tvList.length>0){
										let uniqueTvDocs = [...new Set(tvList)];

										if(uniqueTvDocs.length>90){
												// there is a maximum of 100 users allowed in a request
												const finalUserListofLists = [];
												for (let i = 0; i < uniqueTvDocs.length; i += 90) {
														const chunk = uniqueTvDocs.slice(i, i + 90);
														finalUserListofLists.push(chunk);
												}
												for(var j=0; j<finalUserListofLists.length;j++){
														var tv2Reply = await tvClient.getDocuments(actionableEvents.id, finalUserListofLists[j]);
														tvReply = tvReply.concat(tv2Reply);
												}
										} else{
												tvReply = await tvClient.getDocuments(actionableEvents.id, uniqueTvDocs);
										}

										for(var z=0; z<allData.length; z++){
												for(var j=0; j<tvReply.length;j++){
														if(allData[z].tv_survey_id===tvReply[j].id){
																allData[z].name = tvReply[j].document.name;
																break;
														}
												}
										}

								}

						}

						dispatch(actionableEventsListSuccess({allData: actionableEvents.allData, eventListData: combinedData, info: pagination }));
				} catch (error) {
						dispatch(actionableEventsListError(error));
				}
		};
}

const deptFeedbackListStart = createAction('DEPT_FEEDBACK_LIST_START');
const deptFeedbackListError = createAction('DEPT_FEEDBACK_LIST_ERROR');
const deptFeedbackListSuccess = createAction('DEPT_FEEDBACK_LIST_SUCCESS');

export function listAllDeptFeedbackReport(tvClient, filterType, filter, sort, page, perPage,location, dateFilter, fromDate, toDate) {
		return async dispatch => {
				dispatch(deptFeedbackListStart());

				try {

						// Download non-PHI data (approver, reviewer) from server
						const deptFeedbackRecords = await internalApiClient.getPaginatedDeptFeedbackReport(tvClient.accessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate);
						var pagination = {};
						var offset = perPage * (page-1);

						if(deptFeedbackRecords.length>0){
								pagination.num_pages = Math.ceil(deptFeedbackRecords.length / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(deptFeedbackRecords.length / perPage);
						}
						pagination.current_page = page;

						dispatch(deptFeedbackListSuccess({deptFeedbackData: deptFeedbackRecords.slice(offset, offset+perPage), info: pagination, exportDeptFeedbackData: deptFeedbackRecords }));
				} catch (error) {
						dispatch(deptFeedbackListError(error));
				}
		};
}

const campaignResultsListStart = createAction('CAMPAIGN_RESULTS_LIST_START');
const campaignResultsListError = createAction('CAMPAIGN_RESULTS_LIST_ERROR');
const campaignResultsListSuccess = createAction('CAMPAIGN_RESULTS_LIST_SUCCESS');

export function listCampaignResultsReport(accessToken, campaignId) {
		return async dispatch => {
				dispatch(campaignResultsListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const data = await internalApiClient.getCampaignResultList(accessToken, campaignId);
						dispatch(campaignResultsListSuccess({results: data }));
				} catch (error) {
						dispatch(campaignResultsListError(error));
				}
		};
}

const employerWrittenFeedbackListStart = createAction('EMPLOYER_WRITTEN_FEEDBACK_LIST_START');
const employerWrittenFeedbackListError = createAction('EMPLOYER_WRITTEN_FEEDBACK_LIST_ERROR');
const employerWrittenFeedbackListSuccess = createAction('EMPLOYER_WRITTEN_FEEDBACK_LIST_SUCCESS');

export function listEmployerWrittenFeedbackReport(accessToken, campaignId, employer, dateFilter, fromDate, toDate) {
		return async dispatch => {
				dispatch(employerWrittenFeedbackListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const writtenFeedbackRecords = await internalApiClient.getEmployerWrittenFeedbackReport(accessToken, campaignId, employer, dateFilter, fromDate, toDate);
						dispatch(employerWrittenFeedbackListSuccess({writtenFeedbackData: writtenFeedbackRecords }));
				} catch (error) {
						dispatch(employerWrittenFeedbackListError(error));
				}
		};
}

const staffFeedbackListStart = createAction('STAFF_FEEDBACK_LIST_START');
const staffFeedbackListError = createAction('STAFF_FEEDBACK_LIST_ERROR');
const staffFeedbackListSuccess = createAction('STAFF_FEEDBACK_LIST_SUCCESS');

export function listAllStaffFeedbackReport(tvClient, filterType, filter, sort, page, perPage,location, dateFilter, fromDate, toDate, businessLine=null) {
		return async dispatch => {
				dispatch(staffFeedbackListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const staffFeedbackRecords = await internalApiClient.getPaginatedStaffFeedbackReport(tvClient.accessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate, businessLine);
						var pagination = {};
						var offset = perPage * (page-1);

						if(staffFeedbackRecords.staffFeedback.length>0){
								pagination.num_pages = Math.ceil(staffFeedbackRecords.staffFeedback.length / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(staffFeedbackRecords.staffFeedback.length / perPage);
						}
						pagination.current_page = page;

						dispatch(staffFeedbackListSuccess({staffFeedbackData: staffFeedbackRecords.staffFeedback.slice(offset, offset+perPage), info: pagination, exportData: staffFeedbackRecords.staffFeedback }));
				} catch (error) {
						dispatch(staffFeedbackListError(error));
				}
		};
}

const staffFeedbackWithDetailsListStart = createAction('STAFF_FEEDBACK_LIST_WITH_DETAILS_START');
const staffFeedbackWithDetailsListError = createAction('STAFF_FEEDBACK_LIST_WITH_DETAILS_ERROR');
const staffFeedbackWithDetailsListSuccess = createAction('STAFF_FEEDBACK_LIST_WITH_DETAILS_SUCCESS');

export function listAllStaffFeedbackWithDetailsReport(tvClient, tvClientAccessToken, filterType, filter,location, dateFilter, fromDate, toDate, businessLine=null) {
    return async dispatch => {
        dispatch(staffFeedbackWithDetailsListStart());

        try {
            // Download non-PHI data (approver, reviewer) from server
            var staffFeedbackRecords = await internalApiClient.getPaginatedStaffFeedbackWithDetailsReport(tvClientAccessToken, filter, location, dateFilter, fromDate, toDate, businessLine);
            
                var tvReply = [];
                var tvList = [];
                                     
                for (var i = 0; i < staffFeedbackRecords.staffFeedback.length; i++) {
                        if(staffFeedbackRecords.staffFeedback[i].tv_survey_id!==null){
                            tvList.push(staffFeedbackRecords.staffFeedback[i].tv_survey_id);       
                        }
                }                

                if(tvList.length>0){
                    let uniqueTvDocs = [...new Set(tvList)];

                    if(uniqueTvDocs.length>90){
                        // there is a maximum of 100 users allowed in a request
                        const finalUserListofLists = [];
                        for (let i = 0; i < uniqueTvDocs.length; i += 90) {
                            const chunk = uniqueTvDocs.slice(i, i + 90);
                            finalUserListofLists.push(chunk);
                        }
                        for(var j=0; j<finalUserListofLists.length;j++){
                            var tv2Reply = await tvClient.getDocuments(staffFeedbackRecords.id, finalUserListofLists[j]);
                            tvReply = tvReply.concat(tv2Reply);
                        }
                    } else{
                        tvReply = await tvClient.getDocuments(staffFeedbackRecords.id, uniqueTvDocs);
                    }
                    
                    for(var z=0; z<staffFeedbackRecords.staffFeedback.length; z++){
                        for(var j=0; j<tvReply.length;j++){
                            if(staffFeedbackRecords.staffFeedback[z].tv_survey_id===tvReply[j].id){
                                staffFeedbackRecords.staffFeedback[z].patientName = tvReply[j].document.name;
                                staffFeedbackRecords.staffFeedback[z].patientEmail = tvReply[j].document.email;
                                staffFeedbackRecords.staffFeedback[z].patientPhone = tvReply[j].document.phone;
                                break;
                            }
                        }
                    } 

                }

            dispatch(staffFeedbackWithDetailsListSuccess({staffFeedbackData: staffFeedbackRecords.staffFeedback }));
        } catch (error) {
            dispatch(staffFeedbackWithDetailsListError(error));
        }
    };
}

export function listIndividualStaffFeedbackReport(tvClient, filterType, filter, department, sort, page, perPage,location, dateFilter, fromDate, toDate) {
		return async dispatch => {
				dispatch(staffFeedbackListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const staffFeedbackRecords = await internalApiClient.getPaginatedIndividualStaffFeedbackReport(tvClient.accessToken, sort, filter, department, page, perPage, location, dateFilter, fromDate, toDate);
						var pagination = {};
						var offset = perPage * (page-1);

						if(staffFeedbackRecords.staffFeedback.length>0){
								pagination.num_pages = Math.ceil(staffFeedbackRecords.staffFeedback.length / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(staffFeedbackRecords.staffFeedback.length / perPage);
						}
						pagination.current_page = page;

						dispatch(staffFeedbackListSuccess({overallWrittenFeedbackScore: staffFeedbackRecords.overallWrittenFeedbackScore, staffFeedbackData: staffFeedbackRecords.staffFeedback.slice(offset, offset+perPage), info: pagination, exportData: staffFeedbackRecords.staffFeedback }));
				} catch (error) {
						dispatch(staffFeedbackListError(error));
				}
		};
}


const staffPeerFeedbackListStart = createAction('STAFF_PEER_FEEDBACK_LIST_START');
const staffPeerFeedbackListError = createAction('STAFF_PEER_FEEDBACK_LIST_ERROR');
const staffPeerFeedbackListSuccess = createAction('STAFF_PEER_FEEDBACK_LIST_SUCCESS');

export function listAllPeerStaffFeedbackReport(tvClient, filterType, filter, sort, page, perPage,location, dateFilter, fromDate, toDate) {
		return async dispatch => {
				dispatch(staffPeerFeedbackListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const staffFeedbackRecords = await internalApiClient.getPaginatedPeerStaffFeedbackReport(tvClient.accessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate);
						var pagination = {};
						var offset = perPage * (page-1);

						if(staffFeedbackRecords.length>0){
								pagination.num_pages = Math.ceil(staffFeedbackRecords.length / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(staffFeedbackRecords.length / perPage);
						}
						pagination.current_page = page;

						dispatch(staffPeerFeedbackListSuccess({staffFeedbackData: staffFeedbackRecords.slice(offset, offset+perPage), info: pagination, exportData: staffFeedbackRecords }));
				} catch (error) {
						dispatch(staffPeerFeedbackListError(error));
				}
		};
}

const listIntakeStatsStart = createAction('LIST_INTAKE_STATS_START');
const listIntakeStatsError = createAction('LIST_INTAKE_STATS_ERROR');
const listIntakeStatsSuccess = createAction('LIST_INTAKE_STATS_SUCCESS');

export function listAllIntakeStats(tvClient, filterType, filter, sort, page, perPage,location, dateFilter, fromDate, toDate, journey=null, dept=null, businessLine=null) {
		return async dispatch => {
				dispatch(listIntakeStatsStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const intakeStatData = await internalApiClient.getAllIntakeStats(tvClient.accessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate, journey, dept, businessLine);

						var pagination = {};
						var offset = perPage * (page-1);

						if(intakeStatData.intakeSurveys.length>0){
								pagination.num_pages = Math.ceil(intakeStatData.intakeSurveys[0].full_count / perPage);
						}
						else{
								pagination.num_pages = 1;
						}
						pagination.current_page = page;
						dispatch(listIntakeStatsSuccess({overallData: intakeStatData.overallStats, badgeDetails: intakeStatData.badgeDetails, surveyList: intakeStatData.intakeSurveys, info: pagination }));
				} catch (error) {
						dispatch(listIntakeStatsError(error));
				}
		};
}


const staffEngagementListStart = createAction('STAFF_ENGAGEMENT_LIST_START');
const staffEngagementListError = createAction('STAFF_ENGAGEMENT_LIST_ERROR');
const staffEngagementListSuccess = createAction('STAFF_ENGAGEMENT_LIST_SUCCESS');

export function listAllStaffEngagementsReport(tvClient, filterType, filter, sort, page, perPage,location, dateFilter, fromDate, toDate, dept, businessLine=null, locationList=null) {
		return async dispatch => {
				dispatch(staffEngagementListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const staffEngagementRecords = await internalApiClient.getPaginatedStaffEngagementReport(tvClient.accessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate, dept, businessLine, locationList);
						var pagination = {};
						var offset = perPage * (page-1);

						if(staffEngagementRecords.length>0){
								pagination.num_pages = Math.ceil(staffEngagementRecords.length / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(staffEngagementRecords.length / perPage);
						}
						pagination.current_page = page;

						dispatch(staffEngagementListSuccess({staffEngagementData: staffEngagementRecords.slice(offset, offset+perPage), info: pagination, exportStaffEngagementData: staffEngagementRecords }));
				} catch (error) {
						dispatch(staffEngagementListError(error));
				}
		};
}

const listPublicReviewReportStart = createAction('PUBLIC_REVIEW_REPORT_START');
const listPublicReviewReportError = createAction('PUBLIC_REVIEW_REPORT_ERROR');
const listPublicReviewReportSuccess = createAction('PUBLIC_REVIEW_REPORT_SUCCESS');

export function listPublicReviewReport(tvAccessToken, type, filter, fromDate, toDate) {
		 return async dispatch => {
				dispatch(listPublicReviewReportStart());

				try {
						const publicReviewRecords = await internalApiClient.listPublicReviewReportStart(tvAccessToken, type, filter, fromDate, toDate);
						dispatch(listPublicReviewReportSuccess({ reviewRecords: publicReviewRecords.finalResults, aggregateResult: publicReviewRecords.aggregateResult }));
				} catch (error) {
						dispatch(listPublicReviewReportError(error));
				}
		};
}

const individualStaffEngagementListStart = createAction('INDIVIDUAL_STAFF_ENGAGEMENT_LIST_START');
const individualStaffEngagementListError = createAction('INDIVIDUAL_STAFF_ENGAGEMENT_LIST_ERROR');
const individualStaffEngagementListSuccess = createAction('INDIVIDUAL_STAFF_ENGAGEMENT_LIST_SUCCESS');

export function listIndividualStaffEngagementsReport(tvClient, filterType, filter, sort, page, perPage,location, dateFilter, fromDate, toDate, dept, staff) {
		return async dispatch => {
				dispatch(individualStaffEngagementListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const staffEngagementRecords = await internalApiClient.getPaginatedIndividualStaffEngagementReport(tvClient.accessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate, dept, staff);
						var pagination = {};
						var offset = perPage * (page-1);
						if(staffEngagementRecords.staffEngagement.length>0){
								pagination.num_pages = Math.ceil(staffEngagementRecords.staffEngagement.length / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(staffEngagementRecords.staffEngagement.length / perPage);
						}
						pagination.current_page = page;

						dispatch(individualStaffEngagementListSuccess({badgeWeightedValue: staffEngagementRecords.badgeWeightedValue,staffEngagementData: staffEngagementRecords.staffEngagement.slice(offset, offset+perPage), info: pagination, exportStaffEngagementData: staffEngagementRecords.staffEngagement }));
				} catch (error) {
						dispatch(individualStaffEngagementListError(error));
				}
		};
}


const staffPeerEngagementListStart = createAction('STAFF_PEER_ENGAGEMENT_LIST_START');
const staffPeerEngagementListError = createAction('STAFF_PEER_ENGAGEMENT_LIST_ERROR');
const staffPeerEngagementListSuccess = createAction('STAFF_PEER_ENGAGEMENT_LIST_SUCCESS');

export function listAllPeerStaffEngagementsReport(tvClient, filterType, filter, sort, page, perPage,location, dateFilter, fromDate, toDate, dept) {
		return async dispatch => {
				dispatch(staffPeerEngagementListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const staffEngagementRecords = await internalApiClient.getPaginatedPeerStaffEngagementReport(tvClient.accessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate, dept);
						var pagination = {};
						var offset = perPage * (page-1);

						if(staffEngagementRecords.length>0){
								pagination.num_pages = Math.ceil(staffEngagementRecords.length / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(staffEngagementRecords.length / perPage);
						}
						pagination.current_page = page;

						dispatch(staffPeerEngagementListSuccess({staffEngagementData: staffEngagementRecords.slice(offset, offset+perPage), info: pagination, exportStaffEngagementData: staffEngagementRecords }));
				} catch (error) {
						dispatch(staffPeerEngagementListError(error));
				}
		};
}


const staffPeerPointListStart = createAction('STAFF_PEER_POINT_LIST_START');
const staffPeerPointListError = createAction('STAFF_PEER_POINT_LIST_ERROR');
const staffPeerPointListSuccess = createAction('STAFF_PEER_POINT_LIST_SUCCESS');

export function listAllStaffPeerPointReport(tvClient, filterType, filter, sort, page, perPage,location, dateFilter, fromDate, toDate, dept) {
		return async dispatch => {
				dispatch(staffPeerPointListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const staffPointRecords = await internalApiClient.getPaginatedStaffPeerPointReport(tvClient.accessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate, dept);
						var pagination = {};
						var offset = perPage * (page-1);

						if(staffPointRecords.length>0){
								pagination.num_pages = Math.ceil(staffPointRecords.length / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(staffPointRecords.length / perPage);
						}
						pagination.current_page = page;

						dispatch(staffPeerPointListSuccess({staffPointRecords: staffPointRecords.slice(offset, offset+perPage), info: pagination, exportStaffPointData: [] }));
				} catch (error) {
						dispatch(staffPeerPointListError(error));
				}
		};
}

export function listAllStaffEngagements(tvClient, filterType, filter, sort, page, perPage) {
		return async dispatch => {
				dispatch(staffEngagementListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const staffEngagementRecords = await internalApiClient.getPaginatedStaffEngagement(tvClient.accessToken, sort, filter, page, perPage);
						var pagination = {};
						var offset = perPage * (page-1);

						if(staffEngagementRecords.length>0){
								pagination.num_pages = Math.ceil(staffEngagementRecords.length / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(staffEngagementRecords.length / perPage);
						}
						pagination.current_page = page;

						dispatch(staffEngagementListSuccess({staffEngagementData: staffEngagementRecords.slice(offset, offset+perPage), info: pagination }));
				} catch (error) {
						dispatch(staffEngagementListError(error));
				}
		};
}

const employerDeptEngagementListStart = createAction('EMPLOYER_DEPT_ENGAGEMENT_LIST_START');
const employerDeptEngagementListError = createAction('EMPLOYER_DEPT_ENGAGEMENT_LIST_ERROR');
const employerDeptEngagementListSuccess = createAction('EMPLOYER_DEPT_ENGAGEMENT_LIST_SUCCESS');

export function employerDeptEngagementsReport(tvAccessToken, campaignId, employer, dateFilter, fromDate, toDate) {
		return async dispatch => {
				dispatch(employerDeptEngagementListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const deptEngagementRecords = await internalApiClient.getEmployerDeptEngagementReport(tvAccessToken, campaignId, employer, dateFilter, fromDate, toDate);

						dispatch(employerDeptEngagementListSuccess({deptEngagementData: deptEngagementRecords }));
				} catch (error) {
						dispatch(employerDeptEngagementListError(error));
				}
		};
}

const deptEngagementListStart = createAction('DEPT_ENGAGEMENT_LIST_START');
const deptEngagementListError = createAction('DEPT_ENGAGEMENT_LIST_ERROR');
const deptEngagementListSuccess = createAction('DEPT_ENGAGEMENT_LIST_SUCCESS');

export function listAllDeptEngagementsReport(tvClient, filterType, filter, sort, page, perPage, location, dateFilter, fromDate, toDate, locationList=null) {
		return async dispatch => {
				dispatch(deptEngagementListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const deptEngagementRecords = await internalApiClient.getPaginatedDeptEngagementReport(tvClient.accessToken, sort, filter, page, perPage,location, dateFilter, fromDate, toDate, locationList);
						var pagination = {};
						var offset = perPage * (page-1);

						if(deptEngagementRecords.length>0){
								pagination.num_pages = Math.ceil(deptEngagementRecords.length / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(deptEngagementRecords.length / perPage);
						}
						pagination.current_page = page;

						dispatch(deptEngagementListSuccess({deptEngagementData: deptEngagementRecords.slice(offset, offset+perPage), info: pagination }));
				} catch (error) {
						dispatch(deptEngagementListError(error));
				}
		};
}

const deptPeerEngagementListStart = createAction('DEPT_PEER_ENGAGEMENT_LIST_START');
const deptPeerEngagementListError = createAction('DEPT_PEER_ENGAGEMENT_LIST_ERROR');
const deptPeerEngagementListSuccess = createAction('DEPT_PEER_ENGAGEMENT_LIST_SUCCESS');

export function listAllPeerDeptEngagementsReport(tvClient, filterType, filter, sort, page, perPage, location, dateFilter, fromDate, toDate) {
		return async dispatch => {
				dispatch(deptPeerEngagementListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const deptEngagementRecords = await internalApiClient.getPaginatedPeerDeptEngagementReport(tvClient.accessToken, sort, filter, page, perPage,location, dateFilter, fromDate, toDate);
						var pagination = {};
						var offset = perPage * (page-1);

						if(deptEngagementRecords.length>0){
								pagination.num_pages = Math.ceil(deptEngagementRecords.length / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(deptEngagementRecords.length / perPage);
						}
						pagination.current_page = page;

						dispatch(deptPeerEngagementListSuccess({deptEngagementData: deptEngagementRecords.slice(offset, offset+perPage), info: pagination }));
				} catch (error) {
						dispatch(deptPeerEngagementListError(error));
				}
		};
}

export function listAllDeptEngagements(tvClient, filterType, filter, sort, page, perPage) {
		return async dispatch => {
				dispatch(deptEngagementListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const deptEngagementRecords = await internalApiClient.getPaginatedDeptEngagement(tvClient.accessToken, sort, filter, page, perPage);
						var pagination = {};
						var offset = perPage * (page-1);

						if(deptEngagementRecords.length>0){
								pagination.num_pages = Math.ceil(deptEngagementRecords.length / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(deptEngagementRecords.length / perPage);
						}
						pagination.current_page = page;

						dispatch(deptEngagementListSuccess({deptEngagementData: deptEngagementRecords.slice(offset, offset+perPage), info: pagination }));
				} catch (error) {
						dispatch(deptEngagementListError(error));
				}
		};
}

const messageListStart = createAction('MESSAGE_LIST_START');
const messageListError = createAction('MESSAGE_LIST_ERROR');
const messageListSuccess = createAction('MESSAGE_LIST_SUCCESS');

export function listSentMessages(tvClient, filterType, filter, sort, page, perPage, locationFilter, campaignFilter, intake=false) {
		return async dispatch => {
				dispatch(messageListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const localMessageRecords = await internalApiClient.getMessages(tvClient.accessToken, filterType, filter, sort, page, perPage, locationFilter, campaignFilter);
						var pagination = {};
						var offset = perPage * (page-1);

						if(intake!==undefined && (intake===true || intake==='true')){
								var tvList = [];
								for(var i=0; i<localMessageRecords.length; i++){
										if(tvList.indexOf(localMessageRecords[i].tv_survey_id) === -1) {
												tvList.push(localMessageRecords[i].tv_survey_id);
										}
								}
								let uniqueTvDocs = [...new Set(tvList)];

								var tvReply = await tvClient.getDocuments(process.env.REACT_APP_PATIENT_VAULT_ID, uniqueTvDocs);

								for(var z=0; z<localMessageRecords.length; z++){
										for(var j=0; j<tvReply.length;j++){
												if(localMessageRecords[z].tv_survey_id===tvReply[j].id){
														localMessageRecords[z].name = tvReply[j].document.name;
														break;
												}
										}
								}
						}


						if(localMessageRecords.length>0){
								pagination.num_pages = Math.ceil(localMessageRecords.length / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(localMessageRecords.length / perPage);
						}
						pagination.current_page = page;

						dispatch(messageListSuccess({messages: localMessageRecords.slice(offset, offset+perPage), info: pagination }));
				} catch (error) {
						dispatch(messageListError(error));
				}
		};
}

const surveyListStart = createAction('SURVEY_LIST_START');
const surveyListError = createAction('SURVEY_LIST_ERROR');
const surveyListSuccess = createAction('SURVEY_LIST_SUCCESS');

export function listSurveys(tvClient, filterType, filter, sort, page, perPage, locationId) {
		return async dispatch => {
				dispatch(surveyListStart());

				try {
						// Download non-PHI data (approver, reviewer) from server
						const localSurveyRecords = await internalApiClient.getSurveys(tvClient.accessToken, sort, filter, page, perPage, locationId);
						const winners = await internalApiClient.getWinningSurveys(tvClient.accessToken);
						var pagination = {};

						if(localSurveyRecords.length>0){
								pagination.num_pages = Math.ceil(localSurveyRecords[0].full_count / perPage);
						}
						else{
								pagination.num_pages = Math.ceil(localSurveyRecords.length / perPage);
						}
						pagination.current_page = page;

						dispatch(surveyListSuccess({surveys: localSurveyRecords, info: pagination, winners: winners}));
				} catch (error) {
						dispatch(surveyListError(error));
				}
		};
}


const surveyIntakeListStart = createAction('SURVEY_INTAKE_LIST_START');
const surveyIntakeListError = createAction('SURVEY_INTAKE_LIST_ERROR');
const surveyIntakeListLocationChange = createAction('SURVEY_INTAKE_LIST_LOCATION_CHANGE');
const surveyIntakeListSuccess = createAction('SURVEY_INTAKE_LIST_SUCCESS');
const surveyIntakeLocationListSuccess = createAction('SURVEY_LOCATION_INTAKE_LIST_SUCCESS');

function findIntakes(tvClient, type, dispatch){

	let headers = internalApiClient.headers(tvClient);
	let perPage = 25;
	let page = 1;
	const xhr = new XMLHttpRequest();
	
	xhr.open("GET", `https://api.welliq.co/api/v2/intake/surveys?type=${type}`); 
	for (const headerName in headers) {
		if (headers.hasOwnProperty(headerName)) { 
			xhr.setRequestHeader(headerName, headers[headerName]);
		}
	}

	xhr.onreadystatechange = async function() {
		if (xhr.readyState === 4) { // Request completed
		  if (xhr.status === 200) { // Success
			  // Process the received data from the server
			  var theSurveys = JSON.parse(xhr.responseText); 
			  
		  	  let tvList = [];
		      let needsNewTruevaultCall = false;
			  let currentIntakeRecords = internalApiClient.getCurrentIntakes();
			  let localSurveyRecords = [];

			  if(currentIntakeRecords.intakes!==undefined && currentIntakeRecords.intakes.length>0){
				var newIntakes = theSurveys;
				for(var n=0; n<newIntakes.intakes.length;n++){
					var foundSurvey=false;
					for(var o=0; o<currentIntakeRecords.intakes.length;o++){
						if(newIntakes.intakes[n].survey_id===currentIntakeRecords.intakes[o].survey_id){
							newIntakes.intakes[n].name=currentIntakeRecords.intakes[o].name;
							foundSurvey=true;
							break;
						}
					}
					if(!foundSurvey){
						// this means we have a new survey
						tvList.push(newIntakes.intakes[n].tv_survey_id);
						needsNewTruevaultCall=true;
					}
				}
				localSurveyRecords = newIntakes;
			}
			else {
				localSurveyRecords = theSurveys;
				needsNewTruevaultCall=true;
				internalApiClient.setCurrentIntakes(theSurveys);
				for(var i=0; i<localSurveyRecords.intakes.length; i++){
					tvList.push(localSurveyRecords.intakes[i].tv_survey_id);
				} 
			}

			if(tvList.length>0 || needsNewTruevaultCall){
				let uniqueTvDocs = [...new Set(tvList)];
				var tvReply = await tvClient.getDocuments(process.env.REACT_APP_PATIENT_VAULT_ID, uniqueTvDocs);
				for(var z=0; z<localSurveyRecords.intakes.length; z++){
					for(var j=0; j<tvReply.length;j++){
						if(localSurveyRecords.intakes[z].tv_survey_id===tvReply[j].id){
							localSurveyRecords.intakes[z].name = tvReply[j].document.name;
							break;
						}
					}
				}
			}
			
			  internalApiClient.setCurrentIntakes(localSurveyRecords);
			  var pagination = {};
			  if(localSurveyRecords.intakes.length>0){
				  pagination.num_pages = Math.ceil(localSurveyRecords.intakes[0].full_count / perPage);
			  }
			  else{
				  pagination.num_pages = Math.ceil(localSurveyRecords.intakes.length / perPage);
			  }
			  pagination.current_page = page;
			  
			  dispatch(surveyIntakeListSuccess({'surveys': localSurveyRecords.intakes, 'info': pagination, 'locationId': internalApiClient.getCurrentIntakeLocation()  }));
			  
		  } else {
			// Handle errors (optional: retry logic)
			console.error("Short polling request failed:", xhr.statusText);
		  }
		}
	  };
	
	  xhr.send();

	  var timeOutRef = setTimeout(function(fnToken1, fnType1, dispatch1){ 
			findIntakes(fnToken1, fnType1, dispatch1);
		}, 
		30000, 
		tvClient, 'all', dispatch);
		internalApiClient.setIntakeInstance(timeOutRef);
}

export function listIntakeSurveys(tvClient, filterType, filter, sort, page, perPage, locationId) {
		return async dispatch => {
				dispatch(surveyIntakeListStart());
				try {
						//currentIntakes
						if(internalApiClient.getIntakeInstance()===null){
									findIntakes(tvClient, 'all', dispatch);
						}
						else {
							
								var defaultSurveys = internalApiClient.getCurrentIntakes();
								if(internalApiClient.getCurrentIntakeLocation()!==parseInt(locationId)){
									internalApiClient.setIntakeLocation(locationId);
								}
								var pagination = {};
								if(defaultSurveys.length>0){
									pagination.num_pages = Math.ceil(defaultSurveys[0].full_count / perPage);
								}
								else{
									pagination.num_pages = Math.ceil(defaultSurveys.length / perPage);
								}
								pagination.current_page = page;
								dispatch(surveyIntakeListSuccess({'surveys': defaultSurveys.intakes, 'info': pagination, 'locationId': locationId  }));
						}
				} catch (error) {
						dispatch(surveyIntakeListError(error));
				}
		};
}

const viewPeerLocationDetailsStart = createAction('PEER_LOCATION_DETAILS_VIEW_START');
const viewPeerLocationDetailsError = createAction('PEER_LOCATION_DETAILS_VIEW_ERROR');
const viewPeerLocationDetailsSuccess = createAction('PEER_LOCATION_DETAILS_VIEW_SUCCESS');
const viewPeerLocationBadgeDetailsSuccess = createAction('PEER_LOCATION_DETAILS_BADGES_SUCCESS');
const viewPeerLocationBadgeDetailsStart = createAction('PEER_LOCATION_DETAILS_BADGES_START');
const viewPeerLocationStaffActivitySuccess = createAction('PEER_LOCATION_DETAILS_ACTIVITY_SUCCESS');
const viewPeerLocationStaffActivityStart = createAction('PEER_LOCATION_DETAILS_ACTIVITY_START');
const viewPeerLocationFeedbackSuccess = createAction('PEER_LOCATION_DETAILS_FEEDBACK_SUCCESS');
const viewPeerLocationFeedbackStart = createAction('PEER_LOCATION_DETAILS_FEEDBACK_START');


export function viewPeerLocationDetails(tvClient, locationId){
 return async dispatch => {
				try{
						dispatch(viewPeerLocationDetailsStart());
						dispatch(viewPeerLocationDetailsSuccess({ locationId: locationId }));
						 dispatch(push('/reports/peer/location/view/'+locationId));
				} catch(error){
						dispatch(viewPeerLocationDetailsError(error));
				}

		};
}

export function viewPeerLocationEndorsementDetail(tvClient, location, filter, fromDate=null, toDate=null){
		return async dispatch => {
				try {
						dispatch(viewPeerLocationBadgeDetailsStart());
						const badges = await internalApiClient.getPeerLocationEndorsementDetail(tvClient.accessToken, filter, location, fromDate, toDate);
						dispatch(viewPeerLocationBadgeDetailsSuccess({badges: badges}));
				} catch (error) {
						dispatch(viewPeerLocationDetailsError(error));
				}
		};
}

export function viewPeerLocationStaffActivityDetail(tvClient, location, filter, fromDate=null, toDate=null){
		return async dispatch => {
				try {
						dispatch(viewPeerLocationStaffActivityStart());
						const staffList = await internalApiClient.getPeerLocationStaffActivityDetail(tvClient.accessToken, filter, location, fromDate, toDate);
						dispatch(viewPeerLocationStaffActivitySuccess({staffList: staffList}));
				} catch (error) {
						dispatch(viewPeerLocationDetailsError(error));
				}
		};
}

export function viewPeerLocationFeedbackDetail(tvClient, location, filter, fromDate=null, toDate=null){
		return async dispatch => {
				try {
						dispatch(viewPeerLocationFeedbackStart());
						const feedbackDetail = await internalApiClient.getPeerLocationFeedbackDetail(tvClient.accessToken, filter, location, fromDate, toDate);
						dispatch(viewPeerLocationFeedbackSuccess({feedbackDetails: feedbackDetail}));
				} catch (error) {
						dispatch(viewPeerLocationDetailsError(error));
				}
		};
}



const viewSurveyStart = createAction('SURVEY_VIEW_START');
const viewSurveyError = createAction('SURVEY_VIEW_ERROR');
const viewSurveySuccess = createAction('SURVEY_VIEW_SUCCESS');

export function viewSurvey(tvClient, id, from=null, date=null, location=null) {
		return async dispatch => {
				try{
						dispatch(viewSurveyStart());

						const localSurveyRecord = await internalApiClient.getSpecificSurvey(tvClient.accessToken, id);

						var currentSurvey = {};
						if(localSurveyRecord.length>0){

								if(localSurveyRecord[0].tv_survey_id!==null && localSurveyRecord[0].tv_survey_id.toString().length > 11 ){
										// need to retrieve our TV details
										var documentRequest = localSurveyRecord[0].tv_survey_id;
										const surveyPerson = await tvClient.getDocuments(process.env.REACT_APP_PATIENT_VAULT_ID, [documentRequest]);
										if(surveyPerson.length===0){
											currentSurvey = { 'data': localSurveyRecord[0], 'person': undefined };
										} else{
											currentSurvey = { 'data': localSurveyRecord[0], 'person': surveyPerson };
										}

								} else{
										// This case means an HR utilization so we need to see if a person is associated with the URL
										const localSurveyPersonData = await internalApiClient.getPersonSpecificSurvey(tvClient.accessToken, id);


										if(localSurveyPersonData.length>0){
												var personDocument = {};
												var isAnon = false;
												if(localSurveyRecord[0].survey_data.anonymous!==undefined){
														isAnon = localSurveyRecord[0].survey_data.anonymous;
														personDocument.document = { 'name': 'Anonymous', 'email': '-', 'phone': '-' };
												}
												if(!isAnon){
														personDocument.document = localSurveyPersonData[0];
												}

												currentSurvey = { 'data': localSurveyRecord[0], 'person': [personDocument] };
										} else{
												currentSurvey = { 'data': localSurveyRecord[0], 'person': undefined };
										}

								}
						}

						dispatch(viewSurveySuccess({ currentSurvey }));
						if(from===null){
								dispatch(push('/surveys/view/'+id));
						} else{
								dispatch(push('/surveys/view/'+id+'?from='+from+'&date='+date+'&location='+location));
						}

				} catch(error){
						dispatch(viewSurveyError(error));
				}

		};
}

const viewIntakeStart = createAction('INTAKE_VIEW_START');
const viewIntakeError = createAction('INTAKE_VIEW_ERROR');
const viewIntakeSuccess = createAction('INTAKE_VIEW_SUCCESS');

export function viewIntake(tvClient, id, from=null, date=null, location=null) {
		return async dispatch => {
				try{
						dispatch(viewIntakeStart());

						const localSurveyRecord = await internalApiClient.getSpecificSurvey(tvClient.accessToken, id);

						var currentSurvey = {};
						if(localSurveyRecord.length>0){

								if(localSurveyRecord[0].tv_survey_id!==null && localSurveyRecord[0].tv_survey_id.toString().length > 11 ){
										// need to retrieve our TV details
										var documentRequest = localSurveyRecord[0].tv_survey_id;
										const surveyPerson = await tvClient.getDocuments(process.env.REACT_APP_PATIENT_VAULT_ID, [documentRequest]);
										if(surveyPerson.length===0){
												currentSurvey = { 'data': localSurveyRecord[0], 'person': undefined };
										} else{
												currentSurvey = { 'data': localSurveyRecord[0], 'person': surveyPerson };
										}

								}
						}

						dispatch(viewIntakeSuccess({ currentSurvey }));
						if(from===null){
								dispatch(push('/intake/view/'+id));
						}

				} catch(error){
						dispatch(viewIntakeError(error));
				}

		};
}



const updateClientSettingsStart = createAction('SETTINGS_UPDATE_START');
const updateClientSettingsError = createAction('SETTINGS_UPDATE_ERROR');
const updateClientSettingsSuccess = createAction('SETTINGS_UPDATE_SUCCESS');

export function updateClientSettings(tvClient, companyName, textMessage, emailMessage, employerMessage, logo, publicReviewEmail, intakeForm, intakeDept, peerPointConfig={}) {
		return async dispatch => {
				try{
						dispatch(updateClientSettingsStart());
						const updateSettingsRecord = await internalApiClient.updateClientSettings(tvClient.accessToken, companyName, textMessage, emailMessage, employerMessage, logo[0], publicReviewEmail, intakeForm, intakeDept, peerPointConfig);
						dispatch(updateClientSettingsSuccess({ }));
						dispatch(displayFlashMessage('success', 'Updated Your Settings'));
				} catch(error){
						dispatch(updateClientSettingsError(error));
				}
		};
}

const updateIntakeResolutionStart = createAction('INTAKE_RESOLUTION_START');
const updateIntakeResolutionError = createAction('INTAKE_RESOLUTION_ERROR');
const updateIntakeResolutionSuccess = createAction('INTAKE_RESOLUTION_SUCCESS');

export function updateIntakeResolution(tvClient, survey_id){
		return async dispatch => {
				try{
						dispatch(updateIntakeResolutionStart());
						const updateIntakeRecord = await internalApiClient.updateIntakeResolution(tvClient.accessToken, survey_id);
						dispatch(updateIntakeResolutionSuccess({ }));
						dispatch(displayFlashMessage('success', 'Intake Form Has Been Marked As Resolved'));
				} catch(error){
						dispatch(updateIntakeResolutionError(error));
				}
		};
}

const updateSpecificEventStart = createAction('EVENT_UPDATE_START');
const updateSpecificEventError = createAction('EVENT_UPDATE_ERROR');
const updateSpecificEventSuccess = createAction('EVENT_UPDATE_SUCCESS');

export function updateSpecificEvent(tvClient, eventId, action, resolved, adminAttr, category) {
		return async dispatch => {
				try{
						dispatch(updateSpecificEventStart());
						let adminName = 'Administrator'
						if(adminAttr!==undefined && adminAttr!==null){
								adminName = adminAttr.name;
								if(adminName===undefined){
										adminName = 'Administrator';
								}
						}
						const updateEventRecord = await internalApiClient.updateSpecificEvent(tvClient.accessToken, eventId, action, resolved, adminName, category);
						dispatch(updateSpecificEventSuccess({ }));
						dispatch(push('/reports/events?refresh=0&eventId='+eventId+'&resolved='+resolved));
				} catch(error){
						dispatch(updateSpecificEventError(error));
				}
		};
}

export function reopenSpecificEvent(tvClient, eventId, action, resolved, adminAttr, category) {
		return async dispatch => {
				try{
						dispatch(updateSpecificEventStart());
						let adminName = 'Administrator'
						if(adminAttr!==undefined && adminAttr!==null){
								adminName = adminAttr.name;
								if(adminName===undefined){
										adminName = 'Administrator';
								}
						}
						const updateEventRecord = await internalApiClient.updateSpecificEvent(tvClient.accessToken, eventId, action, resolved, adminName, category);
						dispatch(updateSpecificEventSuccess({ }));
				} catch(error){
						dispatch(updateSpecificEventError(error));
				}
		};
}


const viewSpecificEventStart = createAction('EVENT_VIEW_START');
const viewSpecificEventError = createAction('EVENT_VIEW_ERROR');
const viewSpecificEventSuccess = createAction('EVENT_VIEW_SUCCESS');

export function viewSpecificEvent(tvClient, id, history='none', searching=0) {
		return async dispatch => {
				try{
						dispatch(viewSpecificEventStart());
						var currentSurvey = {};
						const localEventRecord = await internalApiClient.getSpecificEvent(tvClient.accessToken, id);

						if(localEventRecord.length>0){
								if(localEventRecord[0].tv_survey_id!==null && (localEventRecord[0].tv_survey_id.toString().length>12)){
										// need to retrieve our TV details
										const surveyPerson = await tvClient.getDocuments(process.env.REACT_APP_PATIENT_VAULT_ID, [localEventRecord[0].tv_survey_id]);
										currentSurvey = { 'data': localEventRecord[0], 'person': surveyPerson };
								} else{
										currentSurvey = { 'data': localEventRecord[0], 'person': undefined };
								}
						}

						dispatch(viewSpecificEventSuccess({ currentSurvey }));
						dispatch(push('/events/view/'+id+'?last='+history+'&searching='+searching));
				} catch(error){
						dispatch(viewSpecificEventError(error));
				}
		};
}

export function viewCustomSpecificEvent(tvClient, id, history='none', searching=0) {
		return async dispatch => {
				try{
						dispatch(viewSpecificEventStart());
						var currentSurvey = {};
						const localEventRecord = await internalApiClient.getSpecificCustomEvent(tvClient.accessToken, id);

						dispatch(viewSpecificEventSuccess({ currentSurvey: localEventRecord[0] }));
						dispatch(push('/events/custom/view/'+id+'?last='+history+'&searching='+searching));
				} catch(error){
						dispatch(viewSpecificEventError(error));
				}
		};
}
