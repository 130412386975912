import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    Col,
    FormLabel,
    Form,
    FormControl,
    FormGroup,
    Image,
    ProgressBar,
    Row
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {internalApiClient, editDepartment, listDepartments} from "../../actions";
import "./EditDepartment.scss";

class EditDepartment extends Component {
    constructor(props) {
        
        super(props);
        //if(this.props.departments===undefined || this.props.departments===null || this.props.departments.length===0){
            
        //} 
        this.state = {
            currentDetails: [],
            departmentFiles: [],
            departmentFileUrls: [],
            editLoading: true,
            departmentId: 0,
            primaryDepartment: ''
        };
    }

    componentDidMount() { 
        window.scrollTo(0, 0);
        this.props.listDepartments(this.props.tvClient, 'and', null, 'asc', 1, 100).then(()=>{
            internalApiClient.getDepartment(this.props.tvClient, this.props.match.params.departmentId).then((departmentData) => {
                this.name.value = departmentData[0].name;
                this.esname.value = departmentData[0].name_locale_es;
                this.primaryDepartment.value = departmentData[0].parent_dept!==null ? departmentData[0].parent_dept : '';
                this.setState({
                    editLoading: false,
                    departmentId: departmentData[0].department_id
                })
               /* this.state.editLoading = false;
                this.state.departmentId = departmentData[0].department_id; */
                let departmentFile = '';
            if (departmentData[0].icon) {
                departmentFile = departmentData[0].icon.startsWith('http') ? 
                                 departmentData[0].icon : 
                                 process.env.PUBLIC_URL + '/department_assets/' + departmentData[0].icon;
            }
            this.setState({departmentFileUrls: departmentFile ? [departmentFile] : []});
        });
             });
        
        
    }

    editDepartment(e) {
        e.preventDefault();

        this.props.editDepartment(
            this.props.tvClient,
            this.name.value,
            this.esname.value,
            this.state.departmentFiles,
            this.state.departmentId,
            this.primaryDepartment.value
        );
    }

    handleBrowseImagesClick(e) {
        e.preventDefault();

        this.fileInput.click();
    }

    handleDepartmentList(e){
            e.preventDefault();

            var options = e.target.options;
            var value = [];
            for (var i = 0, l = options.length; i < l; i++) {
                if (options[i].selected) {
                  value.push(options[i].value);
                }
            }   

            this.setState({
                primaryDepartment: value,
                changed: true
            });
        }    


    handleFileChange(e) {
        e.preventDefault();

        this.setState({
            departmentFiles: [...e.target.files],
            // Set object URLs one time so that images don't re-render when state changes
            departmentFileUrls: [...e.target.files].map(departmentFile => URL.createObjectURL(departmentFile))
        });
    }

    getProgressPercentage(bytesLoaded, bytesTotal) {
        if (bytesTotal === 0) {
            // Total set to 0 means 100 percent is complete
            return 100;
        } else if (!bytesTotal) {
            // Total not set, so 0 percent is complete
            return 0;
        }
        return Math.min((bytesLoaded / bytesTotal) * 100, 100);
    }

    render() {
        return <div>
            <h1 className="page-header">
                Edit Department
                <small>Please enter the following department information</small>
                <small className="backLink"><button className="simulateAnchor" onClick={() => window.history.back()}>Go Back</button></small>
            </h1>
            
            {this.state.editLoading && <Spinner/>}

             <Form onSubmit={this.editDepartment.bind(this)} className="new-case-form">

                {this.props.editDepartmentError && <Alert variant="danger">
                    {this.props.editDepartmentError.message}
                </Alert>}

                <FormGroup controlId="name">
                    <FormLabel>Department Name</FormLabel>
                    <FormControl type="text" placeholder="Family Medicine, Radiology, etc..." ref={ref => this.name = ref}/>
                </FormGroup>
                <FormGroup controlId="esname">
                    <FormLabel>Department Name (Spanish)</FormLabel>
                    <FormControl type="text" placeholder="Family Medicine, Radiology, etc..." ref={ref => this.esname = ref}/>
                </FormGroup>

                <FormGroup controlId="primaryDepartment">
                    <FormLabel>Parent Department / Team (Optional)</FormLabel>
                   <FormControl as="select" placeholder="select" onChange={this.handleDepartmentList.bind(this) } ref={ref => this.primaryDepartment = ref}>
                        <option value="">None</option>
                        {Object.values(this.props.departments).map(d => {
                            if(d.name!=='Facility'){
                                return <option value={d.key} key={d.key}>{d.name}</option>
                            }
                        })}
                    </FormControl>
                </FormGroup>                

                <legend>Thumbnail</legend>

                <Row>
                {
                    this.state.departmentFileUrls.map(url => {
                        return <Col className="thumbnail-col" md={4} key={url}>
                            <Image src={url} thumbnail/>
                        </Col>
                    })
                }
                </Row>

                <div className="progress-box">
                    {this.props.editingDepartment ? (
                        <ProgressBar now={this.getProgressPercentage(this.props.bytesLoaded, this.props.bytesTotal)}/>
                    ) : (
                        <div>
                            <input type="file" accept="image/*" style={{display: 'none'}}
                                   onChange={this.handleFileChange.bind(this)} ref={ref => this.fileInput = ref}/>
                            <button className="simulateAnchor" onClick={this.handleBrowseImagesClick.bind(this)}>Browse For Image</button>
                        </div>
                    )}

                </div>

                <FormGroup className="submit-buttons">
                    <input type="hidden" value={this.state.departmentId} name="departmentId" />
                    <Button type="submit" disabled={this.props.editingDepartment} className="float-right">
                        Submit Changes
                    </Button>
                </FormGroup>


            </Form> 
        </div>
    }
}

const mapStateToProps = state => {
    return {
        editingDepartment: state.editDepartment.editingDepartment,
        editDepartmentError: state.editDepartment.editDepartmentError,
        bytesLoaded: state.editDepartment.bytesLoaded,
        bytesTotal: state.editDepartment.bytesTotal,
        tvClient: state.login.tvClient,
        departments: state.deptList.departments,
        deptsLoading: state.deptList.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        editDepartment: (...params) => dispatch(editDepartment(...params)),
        listDepartments: (...params) => dispatch(listDepartments(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDepartment);
