import React, {Component} from "react";
import {connect} from "react-redux";
import {createAdmin, getPwdRestrictions, listLocations} from "../../actions";
import {
    Alert,
    FormLabel,
    Form,
    FormControl,
    FormGroup
} from "react-bootstrap";
import Spinner from 'react-spinner';

class NewUser extends Component {

    constructor(props){
        super(props);
       
        this.state = {
          errors: {},
          primaryLocation: [],
          primarySimpleLocation: [],
          showLocations: false,
          showSimpleLocations: false,
          fields: { username: '', mobileno: '', emailid: '', role: '' }
        };

        this.props.listLocations(this.props.tvClient).then(() => {
            let defaultValue = [];
            for (var i = this.props.locations.length - 1; i >= 0; i--) {
                defaultValue.push(this.props.locations[i].location_id);
            }
            this.setState({
              primaryLocation: defaultValue,
              primarySimpleLocation: defaultValue
            });
        });

        this.handleFormChange = this.handleFormChange.bind(this);
    }

    componentDidMount() { 
      return this.props.getPwdRestrictions(this.props.tvClient);
    }

    handleFormChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      let showLocations = this.state.showLocations;
      let showSimpleLocations = this.state.showSimpleLocations;
      if(e.target.value==='locationonly'){
        showLocations = true;
        showSimpleLocations = false;
      } else if(e.target.value==='surveysreportsonly'){
        showSimpleLocations = true;
        showLocations = false;
      } else{
        showLocations = false;
        showSimpleLocations = false;
      }
      this.setState({
        fields: fields,
        showLocations: showLocations,
        showSimpleLocations: showSimpleLocations
      });
    }    

    validateForm() {

      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;

      if (!fields["username"] || fields["username"].length===0) {
        formIsValid = false;
        errors["username"] = "*Please enter your name.";
      }

      if (typeof fields["username"] !== "undefined" && fields["username"].length>0) {
        if (!fields["username"].match(/^[-' a-z\u4e00-\u9eff]{1,20}$/i)) {
          formIsValid = false;
          errors["username"] = "*Please enter letters only.";
        }
      }

      if (!fields["emailid"]) {
        formIsValid = false;
        errors["emailid"] = "*Please enter your email address.";
      }

      if (typeof fields["emailid"] !== "undefined") {
        //regular expression for email validation
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(fields["emailid"])) {
          formIsValid = false;
          errors["emailid"] = "*Please enter a valid email address.";
        }
      }

      if (!fields["mobileno"]) {
        formIsValid = false;
        errors["mobileno"] = "*Please enter your mobile no.";
      }

      if (typeof fields["mobileno"] !== "undefined") {

        var mobileNoTest = fields["mobileno"];
        var replacement = "-";
        var dashRegEx = new RegExp(replacement, "g");
        mobileNoTest = mobileNoTest.replace(dashRegEx, '');
        mobileNoTest = mobileNoTest.replace('(', '');
        mobileNoTest = mobileNoTest.replace(')', '');
        replacement = " ";
        dashRegEx = new RegExp(replacement, "g");
        mobileNoTest = mobileNoTest.replace(dashRegEx, '');
        
        if (!mobileNoTest.match(/^[0-9]{10}$/)) {
          formIsValid = false;
          errors["mobileno"] = "*Please enter a valid mobile no.";
        } 
      }

        var upperRegex = new RegExp("(?=.*[A-Z])"),
        lowerRegex = new RegExp("(?=.*[a-z])"),
        numberRegex = new RegExp("(?=.*[0-9])");
        // eslint-disable-next-line
        var specialRegex = new RegExp("(?=.[!@#\$%\^&\(\))\?\*\+])");

        if(this.newPassword.value.length < this.props.pwdRestrictions.minlengthreq ){
          formIsValid = false;
          errors["password"] = "Your password must be at least "+ this.props.pwdRestrictions.minlengthreq +" characters long";
        }
        else if(this.props.pwdRestrictions.uppercasereq.toString()==='true' && !upperRegex.test(this.newPassword.value)){
          formIsValid = false;
          errors["password"] = "*Your password must have an uppercase character";
        }
        else if(this.props.pwdRestrictions.lowercasereq.toString()==='true' && !lowerRegex.test(this.newPassword.value)){
          formIsValid = false;
          errors["password"] = "*Your password must have a lowercase character"; 
        }
        else if(this.props.pwdRestrictions.numberreq.toString()==='true' && !numberRegex.test(this.newPassword.value)){
            formIsValid = false;
            errors["password"] = "*Your password must have a number"; 
        }
        else if(this.props.pwdRestrictions.specialreq.toString()==='true' && !specialRegex.test(this.newPassword.value)){
          formIsValid = false;
          errors["password"] = "*Your password must have a special character [!@#$%^&()?*+]"; 
        }
        else if(this.newPassword.value!==this.newPasswordConfirm.value){
                formIsValid = false;
                errors["password"] = "*Your passwords must match"; 
        }


      if (!fields["role"]) {
        formIsValid = false;
        errors["role"] = "*Please choose a role.";
      }

      this.setState({
        errors: errors
      });
      return formIsValid;

    }

    handleLocationList(e){
        e.preventDefault();

        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
        }   

        this.setState({
            primaryLocation: value
        });
    }    

    handleSimpleLocationList(e){
      e.preventDefault();

      var options = e.target.options;
      var value = [];
      for (var i = 0, l = options.length; i < l; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
      }   

      this.setState({
          primarySimpleLocation: value
      });
    }   

    finalSubmit(e){
        e.preventDefault();

        if(this.validateForm()) {
            var mobileNoTest = this.state.fields["mobileno"];
            var replacement = "-";
            var dashRegEx = new RegExp(replacement, "g");
            mobileNoTest = mobileNoTest.replace(dashRegEx, '');
            mobileNoTest = mobileNoTest.replace('(', '');
            mobileNoTest = mobileNoTest.replace(')', '');
            replacement = " ";
            dashRegEx = new RegExp(replacement, "g");
            mobileNoTest = mobileNoTest.replace(dashRegEx, '');        

            this.props.createAdmin(this.props.tvClient, this.state.fields["username"], this.state.fields["emailid"], mobileNoTest, this.state.fields["role"], this.newPassword.value, this.newPasswordConfirm.value, this.state.primaryLocation, this.state.primarySimpleLocation);

            let fields = {};
            fields["username"] = "";
            fields["mobileno"] = "";
            fields["emailid"] = "";
            this.setState({fields:fields});              
        }

    }

    render() {
        return  <div className="container">
          <h1 className="page-header">New User</h1>
          <div className="case-list-container">

            <Form onSubmit={this.finalSubmit.bind(this)} >
              <div className="form-group">
              {this.props.processing && <Spinner/>}
                <label htmlFor="nameEntry">Name: <span className="text-danger">*</span></label>
                <input type="textfield" className="form-control" id="nameEntry"  value={this.state.fields.username} name="username" placeholder="Name" onChange={this.handleFormChange}/>
                <div className="errorMsg">{this.state.errors.username}</div>
              </div>
              <div className="form-group">
                <label htmlFor="phoneEntry">Email: <span className="text-danger">*</span></label>
                <input type="tel" className="form-control" id="emailid" placeholder="john@doe.com" value={this.state.fields.emailid} name="emailid" onChange={this.handleFormChange}/>  
                <div className="errorMsg">{this.state.errors.emailid}</div>            
              </div>
              <div className="form-group">
                <label htmlFor="phoneEntry">Mobile: </label>
                <input type="tel" className="form-control" autoComplete="tel-national" id="phoneEntry" placeholder="##########" value={this.state.fields.mobileno} name="mobileno" onChange={this.handleFormChange}/>  
                <div className="errorMsg">{this.state.errors.mobileno}</div>            
              </div>
              <FormGroup controlId="newPassword">
                    <FormLabel>Password *</FormLabel>
                    <FormControl type="password" autoComplete="new-password" ref={ref => this.newPassword = ref}/>
                </FormGroup>    
                <FormGroup controlId="newPasswordConfirm">
                    <FormLabel>Confirm *</FormLabel>
                    <FormControl type="password" autoComplete="new-password" ref={ref => this.newPasswordConfirm = ref}/>
                </FormGroup> 
                <div className="errorMsg">{this.state.errors.password}</div> 
              <div className="form-group">
                <label htmlFor="role">Role: <span className="text-danger">*</span></label>      
                <div className="radio">
                  <label>
                    <input type="radio" name="role" value="admin" onChange={this.handleFormChange}/>
                    Full Admin
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="role" value="frontdesk" onChange={this.handleFormChange}/>
                    SMS Only
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="role" value="viewonly" onChange={this.handleFormChange}/>
                    View Only
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="role" value="eventsonly" onChange={this.handleFormChange}/>
                    Addressable Events Only
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="role" value="surveysreportsonly" onChange={this.handleFormChange}/>
                    Surveys & Reports Only
                  </label>
                  {this.state.showSimpleLocations &&  <FormGroup controlId="primarySimpleLocation" className="adminLocationSelection">
                    <FormLabel>Choose Locations *</FormLabel>
                    <FormControl as="select" placeholder="select" defaultValue={[0]} onChange={this.handleSimpleLocationList.bind(this)} multiple>
                        <option value="0">All Locations</option>
                        {Object.values(this.props.locations).map(d => {
                            return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                        })}
                    </FormControl>                    

                    {this.state.errors.primarySimpleLocation && <Alert variant="danger">
                        A location is required.
                    </Alert>}
                </FormGroup>
              }
                </div> 
                <div className="radio">
                  <label>
                    <input type="radio" name="role" value="locationonly" onChange={this.handleFormChange}/>
                    Location Specific
                  </label>
                </div>                
                <div className="errorMsg">{this.state.errors.role}</div>
              </div>

              {this.state.showLocations &&  <FormGroup controlId="primaryLocation" className="adminLocationSelection">
                    <FormLabel>Choose Locations *</FormLabel>
                    <FormControl as="select" placeholder="select" defaultValue={[0]} onChange={this.handleLocationList.bind(this)} multiple>
                        <option value="0">All Locations</option>
                        {Object.values(this.props.locations).map(d => {
                            return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                        })}
                    </FormControl>                    


                    {this.state.errors.primaryLocation && <Alert variant="danger">
                        A location is required.
                    </Alert>}
                </FormGroup>
              }

              <div className="form-group container">
                <button type="submit" className="btn btn-primary" id="yourInfoButton" disabled={this.props.processing}>Submit</button>
              </div>
              </Form>
              <div id="errorMsg"></div>   
              </div>                
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        tvClient: state.login.tvClient,
        pwdRestrictions: state.pwdRestrictions.pwdRestrictions,
        processing: state.createAdmin.loading,
        locations: state.locationList.locations
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      createAdmin: (...params) => dispatch(createAdmin(...params)),
      getPwdRestrictions: (...params) => dispatch(getPwdRestrictions(...params)),
      listLocations: (...params) => dispatch(listLocations(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
