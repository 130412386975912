import React, {Component} from "react";
import {
    Dropdown
} from "react-bootstrap";
import { listLocations, listLocationMappingGroups, viewAdminDashboardEndorsementsReports } from "../../../actions";
import "./../ReportsPage/ReportsPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import dateFormat from "dateformat";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Link } from 'react-router-dom';
// Include the locale utils designed for moment
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';
import ExportTopBehaviorsCSV from './../../Utilities/ExportTopBehaviorsCSV';

class TopBehaviorsReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
          activeKey: 1,
          currentFilter: 8,
          filter: 'thismonth',
          locationFilter: 0,
          currentFilterName: '  This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ',
          currentFilterLocation: ' All Locations ',
          showCustomDate: false,
          showLocations: true,
          reportFrom: null,
          reportTo: null,
          businessLine: null
        };

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.runReport = this.runReport.bind(this);

        this.props.listLocations(this.props.tvClient).then(()=>{
                if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
                    if(this.props.roleLocations!==undefined){
                        var newLocation = null;
                        var newLocationName = '';
                        for(var i=0; i<this.props.roleLocations.length;i++){
                            newLocation = this.props.roleLocations[i];
                            break;
                        }
                        if(newLocation!==null){
                            for (var i = this.props.locations.length - 1; i >= 0; i--) {
                              if(this.props.locations[i].location_id.toString()===newLocation.toString()){
                                    newLocationName = ' ' + this.props.locations[i].name + ' ';     
                                    break;
                              }
                            }
                        }
                        if(newLocation!==null && newLocationName!==''){
                            this.setState({ locationFilter: newLocation, currentFilterLocation: newLocationName }, ()=>{
                                this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null);
                                this.props.listLocationMappingGroups(this.props.accessToken);
                            });     
                        }
                    } 
                } 
        });


    }   

    handleFromChange(from) {
      this.setState({ reportFrom: from });
    }
    handleToChange(to) {
      this.setState({ reportTo: to });
    }   

    runReport(){
      this.setState({ filter: 'custom', currentFilter: 6, currentFilterName: ' Date Range (' + dateFormat(this.state.reportFrom, "shortDate") + ' to ' + dateFormat(this.state.reportTo, "shortDate") + ') ' });
      this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.businessLine);
    } 

    handleLocationFilter(selectedKey){
      
      if(parseInt(selectedKey)===0){
        this.setState({ locationFilter: 0, currentFilterLocation: ' All Locations '});
        this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, selectedKey, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.businessLine);
      }
      else{
        for (var i = this.props.locations.length - 1; i >= 0; i--) {
          if(this.props.locations[i].location_id===parseInt(selectedKey)){
            this.setState({ locationFilter: selectedKey, currentFilterLocation: ' ' + this.props.locations[i].name + ' ' });
            this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, selectedKey, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.businessLine);
            break;
          }
        }
        
      }
    }

    handleLocationMappingFilter(selectedKey){
      if(selectedKey.toString()==='-1'){
              this.setState({ businessLine: null}, ()=>{
                if(this.state.filter==='custom'){
                  this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.businessLine);
                } else{
                  this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null, this.state.businessLine); 
                }
              });

      } else{

          for(var i=0; i<this.props.locationMapping.length; i++){
            if(parseInt(i)===parseInt(selectedKey)){
              this.setState({ businessLine: this.props.locationMapping[i].location_resource_alt}, ()=>{
                if(this.state.filter==='custom'){
                    this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.businessLine);
                } else{
                    this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null, this.state.businessLine); 
                }
              });
              break;
            }
          }
      }
      
    }


    handleFilter(selectedKey) {
      if(selectedKey==1){
        this.setState({ filter: 'alltime', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' All Time ' }, () => {
          this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null, this.state.businessLine);         
        });
      } else if(selectedKey==2){
        this.setState({ filter: 'thismonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null, this.state.businessLine);       
        });
      } else if(selectedKey==3){
        this.setState({ filter: 'lastmonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Month (' + new Date(new Date().getFullYear(), new Date().getMonth()-1).toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null, this.state.businessLine); 
        });
      } else if(selectedKey==4){
        this.setState({ filter: 'yeartodate', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Year To Date (' + new Date().toLocaleString('en-us', { year: 'numeric' }) + ') ' }, () => {
          this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null, this.state.businessLine); 
        });
      } else if(selectedKey==5){
        this.setState({ filter: 'lastyear', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Year (' + new Date(new Date().getFullYear()-1, 1).toLocaleString('en-us', { year: 'numeric' }) + ') ' } , () => {
          this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null, this.state.businessLine); 
        });
      } else if(selectedKey==6){
        this.setState({ showCustomDate: true });
      }
      if(selectedKey==8){
        this.setState({ filter: 'alltime', showCustomDate: false, currentFilterName: ' All Time ', currentFilter: selectedKey }, () => {
          this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null, this.state.businessLine);
        });
        
      }
    }

    renderScoreToName(score){
        let text = '-'
        if(score.toString()==='5'){
            text = 'Excellent';
        } else if(score.toString()==='4'){
            text = 'Very Good';
        } else if(score.toString()==='3'){
            text = 'Average';
        } else if(score.toString()==='2'){
            text = 'Dissatisfied';
        } else if(score.toString()==='1'){
            text = 'Very Dissatisfied';
        }
        return text;
    }

    componentDidMount(){
        //this.props.viewAdminDashboardEndorsements(this.props.accessToken);
        if(!this.props.isLocationOnly && !this.props.isSurveysReportsOnly){
          this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null);
          this.props.listLocationMappingGroups(this.props.accessToken);  
        }
        
    } 

    tableHeader(sortKey, label) {
        return <th className="sortable"
                   onClick={this.toggleSortDirection(sortKey)}>{label} {this.sortIndicator(sortKey)}</th>;
    }

    render() {
       return <div className="reportingPages">
            <h1 className="extraDropDown page-header">
                Endorsed Behavior Report
                    <div className="float-right">
                        <Dropdown
                          title='Filter Report By Date'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            {this.state.currentFilterName}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>                    
                            <Dropdown.Item eventKey="1">All Time</Dropdown.Item>
                            <Dropdown.Item eventKey="2">This Month</Dropdown.Item>
                            <Dropdown.Item eventKey="3">Last Month</Dropdown.Item>
                            <Dropdown.Item eventKey="4">YTD</Dropdown.Item>
                            <Dropdown.Item eventKey="5">Last Year</Dropdown.Item>
                            <Dropdown.Item eventKey="6">Custom</Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item eventKey="8">Reset Filter</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      {(this.props.locationMapping!==null && this.props.locationMapping.length>0) && <div className="float-right extraRightMargin">
                            <Dropdown
                              title='Choose Business Line'
                              id='filterSurvey'
                              drop="down" onSelect={this.handleLocationMappingFilter.bind(this)}
                            >
                              <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                                 {this.state.businessLine!==null ? this.state.businessLine : ' Choose Business Line '}
                              </Dropdown.Toggle>
                              <Dropdown.Menu> 
                              <Dropdown.Item eventKey={-1}>All Business Lines</Dropdown.Item>
                                {Object.values(this.props.locationMapping).map((d,counter) => {
                                return  <Dropdown.Item eventKey={counter} key={d.location_resource_alt}>{d.location_resource_alt}</Dropdown.Item>
                              })}
                                </Dropdown.Menu>
                            </Dropdown>
                    </div> } 

              {this.state.showCustomDate && <div className="customDateRange">
                  <p className="prizeDrawTitle">Select the range to run the report for:</p>
                  <label className="dayPickerLabel">From:</label><DayPickerInput formatDate={formatDate} id="fromDate" name="fromDate" onDayChange={this.handleFromChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                      },
                    }} />

                <label className="dayPickerLabel">To:</label><DayPickerInput formatDate={formatDate} id="toDate" name="toDate" onDayChange={this.handleToChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                        before: this.state.prizeFrom
                      },
                    }} />

              <button className="btn btn-primary" onClick={this.runReport}>{this.props.runningReport ? 'Running...' : 'Run Report'}</button>
              </div>
            }
            {(this.state.showLocations && this.props.locations) && <div className="customLocationRange">
                      <Dropdown
                          title='Choose a Location'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleLocationFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            {this.state.currentFilterLocation}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>  

                          {(!this.props.isLocationOnly && !this.props.isSurveysReportsOnly) && <Dropdown.Item eventKey={0}>All Locations</Dropdown.Item> }
                      {Object.values(this.props.locations).map(d => {
                         if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
                                if(this.props.roleLocations!==undefined){
                                    for(var i=0; i<this.props.roleLocations.length;i++){
                                        if(this.props.roleLocations[i].toString()===d.location_id.toString()){
                                            return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
                                        }
                                    }
                                } else{
                                    return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>    
                                }
                            } else{
                                return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>    
                            }
                      })}
                      </Dropdown.Menu>
                    </Dropdown>
            </div> }    
            </h1>

            <p className="case-list-container stand-out-line float-right">Showing data for <span className="text-underline">{(this.state.businessLine!==null && this.state.businessLine!=='') ? this.state.businessLine + ' - ' : ''}{this.state.currentFilterName}</span> at <span className="text-underline">{this.state.currentFilterLocation}</span></p>
                        <div className="case-list-container">
            <h2 className="headerSpacer">Most Used Badges</h2>
            </div>
            <div className="case-list-container">
                    {this.props.endorsementsLoading && <Spinner/>}
                     {this.props.endorsementsData && <div>

                            <table className="col-md-12 addBottomMargin">
                              <tbody>
                                <tr className="reportBadgeContainer table-headings">
                                  <th></th>
                                  <th>Name</th>
                                  <th className="text-center">Total</th>
                                </tr>
                              </tbody>
                              <tbody>
                            {
                                this.props.endorsementsData.comboBadgeData.map(c => {
                                    return <tr className="reportBadgeContainer" key={c.endorsement_id}>
                                              <td><Link to={`/reports/specificbehavior/${c.endorsement_id}`}><img alt="" className="img-responsive" src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/></Link></td>
                                              <td>{c.description}</td>
                                              <td className="text-center totalTdValue">{c.total}</td>
                                          </tr>
                                })
                            }  
                            </tbody>
                            </table>
                     {(this.props.endorsementsData && this.props.endorsementsData.comboBadgeData.length>0) &&                              <div className="float-right">
                  <ExportTopBehaviorsCSV csvData={this.props.endorsementsData} fileName='TopBehaviorsReport.csv' />
              </div>}
                    </div> 
                    }
                    {(this.props.endorsementsData && this.props.endorsementsData.comboBadgeData.length===0) && <p>No data to display.</p>}

            </div><br/><br/>  

            {(this.props.endorsementsData && this.props.endorsementsData.notUsed.length > 0) &&             <div className="case-list-container">
            <h2 className="headerSpacer">Unused Badges</h2>
            </div> }

            <div className="case-list-container">
                    {this.props.endorsementsLoading && <Spinner/>}
                     {(this.props.endorsementsData && this.props.endorsementsData.notUsed.length > 0) && <div>
                            <table className="col-md-12 addBottomMargin">
                              <tbody>
                                <tr className="reportBadgeContainer table-headings">
                                  <th></th>
                                  <th>Name</th>
                                  <th className="text-center">Total</th>
                                </tr>
                              </tbody>
                              <tbody>
                            { this.props.endorsementsData.notUsed.map(c => {
                                    return <tr className="reportBadgeContainer" key={c.endorsement_id}>
                                              <td><Link to={`/reports/specificbehavior/${c.endorsement_id}`}><img alt="" className="img-responsive" src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/></Link></td>
                                              <td>{c.description}</td>
                                              <td className="text-center totalTdValue">-</td>
                                          </tr>
                                })
                            }  
                            </tbody>
                            </table>

                    </div> 
                    }
                    {(this.props.endorsementsData && this.props.endorsementsData  .notUsed.length===0) && <p>No data to display.</p>}

            </div>


        </div>
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        accessToken: state.login.tvClient.accessToken,
        endorsementsLoading: state.endorsementsCpt.endorsementsLoading,
        endorsementsData: state.endorsementsCpt.endorsementsData,
        locations: state.locationList.locations,
        locationMapping: state.locationMappingGroupList.mappings,
        isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
        isSurveysReportsOnly: state.login.user && state.login.user.attributes.role === 'surveysreportsonly',
        roleLocations: state.login.user.attributes.locations
    };
};

const mapDispatchToProps = dispatch => {
    return {
        listLocations: (...params) => dispatch(listLocations(...params)),
        viewAdminDashboardEndorsementsReports: (...params) => dispatch(viewAdminDashboardEndorsementsReports(...params)),
        listLocationMappingGroups: (...params) => dispatch(listLocationMappingGroups(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBehaviorsReport);
