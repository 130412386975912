import React, {Component} from "react";
import {
    Alert,
    Table, 
    Dropdown,
    Navbar,
    Nav,
    FormLabel,
    Button
} from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import {listIntakeSurveys, listLocations, viewIntake, updateIntakeResolution} from "../../actions";
import "./IntakeList.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import PaginationArea from "./../Utilities/PaginationArea";
import dateFormat from "dateformat";
// Include the locale utils designed for moment
import {formatDate} from 'react-day-picker/moment';
import queryString from 'query-string';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';

class IntakeList extends Component {
    constructor(props) {
        super(props);

        this.props.listLocations(this.props.tvClient);
        
        this.state = {
            perpage: 50,
            filter: '24open',
            sort: null,
            sortDirection: 'desc',
            currentFilter: 8,
            currentFilterName: ' 12 Hours - Open',
            currentFilterLocation: ' All Locations ',
            locationFilter: this.props.intakeLocation,
            currentIntakes: []
        };

    }

    componentDidMount() {
        window.scrollTo(0,0);
        
        const queryStringValues = queryString.parse(this.props.location.search);
         if(queryStringValues.refresh!==null && queryStringValues.refresh!==undefined){
             if(queryStringValues.refresh==1){
                 this.changeListPage(1);
             } else {
                 if(this.props.surveys.length===0){
                     this.changeListPage(1);
                 }
             }
         } else{
             this.changeListPage(1);    
         }

         for (var i = this.props.locations.length - 1; i >= 0; i--) {
             if(this.props.locations[i].location_id===parseInt(this.state.locationFilter)){
                 this.setState({ currentFilterLocation: this.props.locations[i].name });
             }
         }

    }

    viewIntake(id){
        this.props.viewIntake(this.props.tvClient, id);
    }

    handleLocationFilter(selectedKey){
      if(parseInt(selectedKey)===0){
        this.setState({ currentFilterLocation: ' All Locations '});
        this.props.listIntakeSurveys(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, 0);
      }
      else{
        for (var i = this.props.locations.length - 1; i >= 0; i--) {
          if(this.props.locations[i].location_id===parseInt(selectedKey)){
            this.props.listIntakeSurveys(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.props.locations[i].location_id).then(()=>{
                this.setState({ locationFilter: this.props.locations[i].location_id, currentFilterLocation: ' ' + this.props.locations[i].name + ' ' });     
            });
            break;
          }
        }
      }
    }  

    updateIntake(e, survey_id){
        e.preventDefault();
        e.nativeEvent.srcElement.outerHTML = "<small>Marked For Removal</small>";
        this.props.updateIntakeResolution(this.props.tvClient, survey_id);
    } 

    changeListPage(page) {
        this.props.listIntakeSurveys(this.props.tvClient, 'and', this.state.filter, this.state.sort, page, this.state.perpage, this.state.locationFilter);
    }

    toggleSortDirection(key) {
        return () => {
            let newSortDirection = 'asc';
            if (this.state.sortKey === key && this.state.sortDirection === 'asc') {
                newSortDirection = 'desc';
            }

            this.setState({
                sortKey: key,
                sortDirection: newSortDirection
            }, () => this.changeListPage(this.props.paginationInfo.current_page));
        };
    }

    sortIndicator(key) {
        if (this.state.sortKey === key && this.state.sortDirection === 'asc') {
            return <FontAwesomeIcon icon={faArrowUp}/>;
        } else if (this.state.sortKey === key && this.state.sortDirection === 'desc') {
            return <FontAwesomeIcon icon={faArrowDown}/>;
        }
    }

    dueBeforeChange(event) {
        this.setState({
            dueBefore: event.target.value || null
        }, () => this.changeListPage(this.props.paginationInfo.current_page));
    }

    tableHeader(sortKey, label) {
        return <th className="sortable" onClick={this.toggleSortDirection(sortKey)}>{label} {this.sortIndicator(sortKey)}</th>;
    }

    handleFilter(selectedKey) {
      if(parseInt(selectedKey)===1){
        this.setState({ filter: '24open', currentFilter: selectedKey, currentFilterName: ' 12 Hours - Open ' });
        this.props.listIntakeSurveys(this.props.tvClient, 'and', '24open', this.state.sort, 1, this.state.perpage, this.state.locationFilter);
      } else if(selectedKey==2){
        this.setState({ filter: '48open', currentFilter: selectedKey, currentFilterName: ' 48 Hours - Open ' });
        this.props.listIntakeSurveys(this.props.tvClient, 'and', '48open', this.state.sort, 1, this.state.perpage, this.state.locationFilter);
      } else if(selectedKey==3){
        this.setState({ filter: '72open', currentFilter: selectedKey, currentFilterName: ' 72 Hours - Open ' });
        this.props.listIntakeSurveys(this.props.tvClient, 'and', '72open', this.state.sort, 1, this.state.perpage, this.state.locationFilter);
      } else if(selectedKey==4){
        this.setState({ filter: '24all', currentFilter: selectedKey, currentFilterName: ' 12 Hours - All ' });
        this.props.listIntakeSurveys(this.props.tvClient, 'and', '24all', this.state.sort, 1, this.state.perpage, this.state.locationFilter);
      } else if(selectedKey==5){
        this.setState({ filter: '48all', currentFilter: selectedKey, currentFilterName: ' 48 Hours - All ' });
        this.props.listIntakeSurveys(this.props.tvClient, 'and', '48all', this.state.sort, 1, this.state.perpage, this.state.locationFilter);
      } else if(selectedKey==6){
        this.setState({ filter: '72all', currentFilter: selectedKey, currentFilterName: ' 72 Hours - All ' });
        this.props.listIntakeSurveys(this.props.tvClient, 'and', '72all', this.state.sort, 1, this.state.perpage, this.state.locationFilter);
      } 

      if(selectedKey==8){
        this.setState({ filter: '24open', currentFilter: selectedKey }, () => {
            this.changeListPage(1);    
        });
        
      }
    }



    render() {
        return <div>
            <h1 className="page-header">
                Intake Form Results
            </h1>

            <div className="case-list-container">
                {this.props.listError && <Alert variant="danger">{this.props.listError.message}</Alert>}
               
                {(this.props.locations) && <div className="customLocationRange float-right mt-0 mr-5">
                        <FormLabel>Filter by Location:</FormLabel>
                       <Dropdown
                          title='Choose a Location'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleLocationFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            {this.state.currentFilterLocation}
                          </Dropdown.Toggle>
                          <Dropdown.Menu> 
                    <Dropdown.Item eventKey={0}>All Locations</Dropdown.Item>
                      {Object.values(this.props.locations).map(d => {
                        return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
                      })}
                        </Dropdown.Menu>
                    </Dropdown>
            </div> }  

                {!this.props.surveysLoading && <div className="case-list-summary clearBoth">Listing for <b>{this.state.currentFilterLocation}</b> over the last <b>{this.state.currentFilterName}</b>
                </div>}

                {  this.props.surveys && <Table className="case-list sortable">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Location</th>
                        <th className="text-center">Sent</th>
                        <th className="text-center">Action</th>
                        <th className="text-center">Result</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.surveys.map((c, i) => {

                            return <tr className={(i % 2)===0 ? "surveyRow statDeptContainer reportBadgeContainer intakeListRow" : "surveyRow intakeListRow altRowColor statDeptContainer reportBadgeContainer"}  key={c.survey_id}>
                                <td onClick={()=> this.viewIntake(c.survey_id)}>{c.survey_id}</td>
                                <td onClick={()=> this.viewIntake(c.survey_id)}>{c.name}</td>
                                {(c.survey_data.intakeResults!==undefined && c.survey_data.intakeData!==null) &&
                                <td onClick={()=> this.viewIntake(c.survey_id)}>
                                    
                                    {
                                        c.survey_data.intakeResults.intakeData.badges.map(c => {
                         

                                            var localeValue = '';
                                            if(c.localization_options!==undefined && c.localization_options!==null){
                                              let tempValue = c.localization_options["EN"];
                                              if(tempValue!==undefined){
                                                localeValue = tempValue;
                                              }
                                            }

                                            return <div className="nopad isBadge text-center intakeBadgeC" key={c.endorsement_id}>
                                                    {c.image!==undefined && <label>
                                                      <img alt="" className={"img-responsive"} src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
                                                      <p className="intakeBadgeName">{localeValue}</p>
                                                    </label>}
                                                  </div>
                                        
                                          
                                        })
                                    }      

                                    {c.survey_data.intakeResults.intakeData.badges.length===0 ? 'None' : ''}
                                    
                                </td>}
                                 {(c.survey_data.intakeResults===undefined || c.survey_data.intakeResults===null) &&
                                <td>
                                    Intake Not Completed.
                                </td>}
                                <td>{c.location_name}</td>
                                <td className="text-center">{dateFormat(c.created, "shortDate")}<br/><small>{dateFormat(c.created, "shortTime")}</small></td>
                                <td className="text-center"> <Button variant="primary" size="sm" className="clearBoth" onClick={(e) => this.updateIntake(e, c.survey_id)}>
                                        Mark As Resolved
                                    </Button></td>
                                <td className="text-center totalTdValue">
                                    {(c.survey_data.intakeResults!==undefined && c.survey_data.intakeResults.intakeData.badges.length>0) ? 'OPEN' : 'None'}
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </Table> }
                {this.props.surveysLoading && <Spinner/>}                

            </div>
        

            <footer className="adminFooter">&copy; Well iQ {new Date().getFullYear()} | <a href="mailto:support@welliq.org">support@welliq.org</a> | <a target="_blank" href="https://www.welliq.org/privacy.html">Privacy Policy</a></footer> 
        </div>;
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        surveys: state.surveyIntakeList.surveys,
        intakeLocation: state.surveyIntakeList.location_id,
        pagination: state.surveyIntakeList.paginationInfo,
        surveysLoading: state.surveyIntakeList.loading,
        paginationInfo: state.surveyIntakeList.paginationInfo,
        listError: state.surveyIntakeList.error,
        paginationItems: state.surveyIntakeList.paginationInfo.pageList,
        locations: state.locationList.locations,
        isViewOnly: state.login.user && state.login.user.attributes.role === 'viewonly'
    };
};

const mapDispatchToProps = dispatch => {
    return {
        listIntakeSurveys: (...params) => dispatch(listIntakeSurveys(...params)),
        viewIntake: (...params) => dispatch(viewIntake(...params)),
        listLocations: (...params) => dispatch(listLocations(...params)),
        updateIntakeResolution: (...params) => dispatch(updateIntakeResolution(...params))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IntakeList));
