import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    FormLabel,
    Form,
    FormControl,
    FormGroup,
    Navbar,
    Nav
} from "react-bootstrap";
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import Spinner from "react-spinner";
import {createCustomEvent, listLocations, findStaffByNameOnly } from "../../actions";
import StaffProfileMenuItem from './../Utilities/StaffProfileMenuItem.js';
import {asyncContainer, Typeahead} from 'react-bootstrap-typeahead';
const AsyncTypeahead = asyncContainer(Typeahead);

class NewCustomEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: { 'patientName': false, 'eventDetails': false  },
            primaryLocation: 0,
            searchoptions: [],
            isLoading: false
        };
        this.inlineError = false;

        this.props.listLocations(this.props.tvClient);
        this.selectStaffFromSearch = this.selectStaffFromSearch.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    handleSearch(query) {
      this.setState({isLoading: true});
      this.props.findStaffByNameOnly(this.props.tvClient.accessToken, query).then((staff)=>{
        this.setState({isLoading: false, searchoptions: staff });
      })
    }

    removeStaffFilter(){
      this.typeahead.clear();
      this.setState({filter: ''}, () => {
        console.log('removed the staff');
      });
    }

    selectStaffFromSearch(person) {
      if(person[0]!==undefined){
        this.setState({ filter: person[0].staff_id });
      }
    } 

    handleLocationList(e){
        e.preventDefault();

        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
        }   

        this.setState({
            primaryLocation: value[0]
        });
    } 

    newCustomEvent(e) {
        e.preventDefault();

        if(!this.patientName.value){
            this.setState({ errors: {'patientName': true, 'eventDetails': false }});
        } else if(!this.eventDetails.value){
            this.setState({ errors: {'patientName': false, 'eventDetails': true }});
        } else{
            var staffId = null;
            if(this.state.filter!==undefined && this.state.filter !== null && this.state.filter!==''){
                staffId = this.state.filter;
            }

            var mobileNoTest = this.phoneNumber.value;
            if(mobileNoTest!==undefined && mobileNoTest!==null){
                var replacement = "-";
                var dashRegEx = new RegExp(replacement, "g");
                mobileNoTest = mobileNoTest.replace(dashRegEx, '');
                mobileNoTest = mobileNoTest.replace('(', '');
                mobileNoTest = mobileNoTest.replace(')', '');
                replacement = " ";
                dashRegEx = new RegExp(replacement, "g");
                mobileNoTest = mobileNoTest.replace(dashRegEx, '');    
            }
            

            this.props.createCustomEvent(
                this.props.tvClient,
                this.patientName.value,
                this.state.primaryLocation,
                this.eventDetails.value,
                mobileNoTest,
                this.props.adminAttr,
                this.category.value,
                staffId
            );           
        }
    }

    render() {
        return <div>
            <h1 className="page-header">
                Add New Event
                <small className="backLink">Please enter the following information to create a custom event unrelated to a patient survey. (<button className="simulateAnchor" onClick={() => window.history.back()} >Cancel</button>)</small>
            </h1>
            <Form onSubmit={this.newCustomEvent.bind(this)} className="case-list-container col-md-8">

                <legend>Event Details</legend>

                {this.props.addCustomEventError && <Alert variant="danger">
                    {this.props.addCustomEventError.message}
                </Alert>}

                <FormGroup>
                    <FormLabel>Patient Name *</FormLabel>
                    <FormControl type="text" placeholder="Jane Doe" ref={ref => this.patientName = ref}/>
                    {this.state.errors.patientName && <Alert variant="danger">
                        Patient Name is required.
                    </Alert>}
                </FormGroup>
                <FormGroup>
                    <FormLabel>Phone Number (Numbers Only)</FormLabel>
                    <FormControl type="text" placeholder="555-555-5555" ref={ref => this.phoneNumber = ref}/>
                </FormGroup>

                <FormGroup>
                    <FormLabel>Choose Locations</FormLabel>
                    <FormControl as="select" placeholder="select" defaultValue={0} onChange={this.handleLocationList.bind(this)}>
                        <option value="0">No Location</option>
                        {Object.values(this.props.locations).map(d => {
                             if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
                              if(this.props.roleLocations!==undefined){
                                  for(var i=0; i<this.props.roleLocations.length;i++){
                                      if(this.props.roleLocations[i].toString()===d.location_id.toString()){
                                          return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                                      }
                                  }
                              } else{
                                  return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                              }
                            } else{
                                return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                            }
                        })}
                    </FormControl>                    

                    {this.state.errors.primaryLocation && <Alert variant="danger">
                        A location is required.
                    </Alert>}
                </FormGroup>
                <FormGroup>
                    <FormLabel>Category</FormLabel>
                    <FormControl type="text" placeholder="Provider, Wait Time, etc" ref={ref => this.category = ref}/>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Details</FormLabel>
                    <FormControl as="textarea" id="action" name="action" ref={ref => this.eventDetails = ref} placeholder="Enter all of the details here including patient contact information." />
                      {this.state.errors.eventDetails && <Alert variant="danger">
                        Event Details are required.
                    </Alert>}
                </FormGroup>
                <FormGroup>
                    <div className="searchEmployeeContainer">
            

                      <div className="customLocationRange searchEmployeeBoxHolder">
                        <label htmlFor="searchByNameInput">Associate One Staff By Name:{this.state.filter!=='' && <a className="removeStaffFilter" onClick={this.removeStaffFilter.bind(this)}><h3> (Reset)</h3></a>}</label>
                        
                     <AsyncTypeahead
                     id="searchByNameInput"
                      isLoading={this.state.isLoading}
                       labelKey="name"
                      onSearch={this.handleSearch}
                      onChange={this.selectStaffFromSearch}
                      options={this.state.searchoptions} 
                      placeholder="Search by Name"
                      ref={(typeahead) => this.typeahead = typeahead}
                      renderMenuItemChildren={(option, props) => (
                        <StaffProfileMenuItem key={option.staff_id} user={option} />
                      )}
                    /> 

                      </div>

              
            </div>
                </FormGroup>
                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.addingCustomEvent} className="float-right">
                        Add
                    </Button>
                </FormGroup>
            </Form> 

        </div>
    }
}

const mapStateToProps = state => {
    return {
        addingCustomEvent: state.addLocation.addingLocation,
        addCustomEventError: state.addLocation.addLocationError,
        locations: state.locationList.locations,
        tvClient: state.login.tvClient,
        adminAttr: state.login.user.attributes,
        isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
        isSurveysReportsOnly: state.login.user && state.login.user.attributes.role === 'surveysreportsonly',
        roleLocations: state.login.user.attributes.locations
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createCustomEvent: (...params) => dispatch(createCustomEvent(...params)),
        listLocations: (...params) => dispatch(listLocations(...params)),
        findStaffByNameOnly: (...params) => dispatch(findStaffByNameOnly(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomEvent);
