import React, {Component} from "react";
import {
		Alert,
		Modal,
		Button,
		Table,
		FormGroup,
		FormLabel,
		Nav,
		Navbar,
		Dropdown
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { listStaff, listLocations, listDepartments, findStaffByNameOnly, viewStaffFromSearch, sendIndFeedback, sendDeptFeedback, sendAllFeedback, sendIndFeedbackReminder, sendDeptFeedbackReminder, sendAllFeedbackReminder } from "../../actions";
import "./StaffList.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import PaginationArea from "./../Utilities/PaginationArea";
import DeleteStaffOverlay from "./../Overlays/DeleteStaffOverlay";
import StaffStatus from "./../Utilities/StaffStatus";
import DepartmentDropdown from "./../Utilities/DepartmentDropdown";
import StaffProfileMenuItem from './../Utilities/StaffProfileMenuItem.js';
import {asyncContainer, Typeahead} from 'react-bootstrap-typeahead';
import ExportStaffListCSV from './../Utilities/ExportStaffListCSV';
import queryString from 'query-string';
import { CSVLink } from 'react-csv';
const AsyncTypeahead = asyncContainer(Typeahead);

class StaffList extends Component {
		constructor(props) {
				super(props);

				this.handleSendAllFeedback = this.handleSendAllFeedback.bind(this);
				this.handleSendAllFeedbackReminder = this.handleSendAllFeedbackReminder.bind(this);
				this.handleFilterFeedbackByDepartment = this.handleFilterFeedbackByDepartment.bind(this);
				this.sendIndFeedback = this.sendIndFeedback.bind(this);
				this.sendIndFeedbackReminder = this.sendIndFeedbackReminder.bind(this);
				this.sendAllFeedback = this.sendAllFeedback.bind(this);
				this.filterByDepartment = this.filterByDepartment.bind(this);
				this.selectStaffFromSearch = this.selectStaffFromSearch.bind(this);
				this.handleSearch = this.handleSearch.bind(this);
				this.handleFeedbackSearch = this.handleFeedbackSearch.bind(this);
				this.selectStaffFromFeedbackSearch = this.selectStaffFromFeedbackSearch.bind(this);
				this.filterFeedbackByDepartment = this.filterFeedbackByDepartment.bind(this);
				let activeTab = 1;
				let peerActiveTab = 1;
				let showStaffList=true;
				let showPeerFeedback=false;
				const queryStringValues = queryString.parse(props.location.search);

				if(queryStringValues.active!==null && queryStringValues.active !== undefined){
						activeTab = parseInt(queryStringValues.active, 10);
						if(activeTab===1){
								showStaffList=true; showPeerFeedback=false;
						}
						if(activeTab===2){
								showStaffList=false; showPeerFeedback=true;
						}
				}

				this.state = {
						sortKey: 'name',
						sortDirection: 'asc',
						searchoptions: [],
						feedbackSearchoptions: [],
						isLoading: false,
						primaryDepartment: '0',
						primaryDepartmentName: 'All Departments',
						activeKey: activeTab,
						peerActiveKey: peerActiveTab,
						showStaffListTab: showStaffList,
						showPeerFeedbackTab: showPeerFeedback,
						currentFilterLocation: ' All Locations ',
						selectedFeedbackPerson: null,
						showSendAllPeersTab: true,
						showSendToDeptTab: false,
						showSendToIndTab: false,
						confirmSendAllOpen: false,
						confirmSendDeptOpen: false,
						locationFilter: 0
				};
				let filter = {
						staffId: {
								type: 'wildcard',
								value: '*'
						}
				};

				this.props.listDepartments(this.props.tvClient, 'and', filter, 'asc', 1, 100);
				this.props.listLocations(this.props.tvClient).then(()=>{
						 if(this.props.isLocationOnly){
										if(this.props.roleLocations!==undefined){
												var newLocation = null;
												var newLocationName = '';
												for(var i=0; i<this.props.roleLocations.length;i++){
														newLocation = this.props.roleLocations[i];
														break;
												}
												if(newLocation!==null){
														for (var i = this.props.locations.length - 1; i >= 0; i--) {
															if(this.props.locations[i].location_id.toString()===newLocation.toString()){
																		newLocationName = ' ' + this.props.locations[i].name + ' ';
																		break;
															}
														}
												}
												if(newLocation!==null && newLocationName!==''){
														this.setState({ locationFilter: parseInt(newLocation), currentFilterLocation: newLocationName }, ()=>{
																this.changeListPage(1);
														});
												}
										}
								}
				});
		}

		componentDidMount() {
				window.scrollTo(0,0);
				const queryStringValues = queryString.parse(this.props.location.search);
				if(queryStringValues.refresh!==null && queryStringValues.refresh!==undefined){
						if(queryStringValues.refresh==1 && !this.props.isLocationOnly){
								this.changeListPage(1);
						}
				} else{
						if(!this.props.isLocationOnly){
								this.changeListPage(1);
						}

				}
				if(this.props.isLocationOnly){
						this.setState({peerActiveKey: 3, showSendToIndTab: true})
				}
		}

		handleSearch(query) {
					this.setState({isLoading: true});
					this.props.findStaffByNameOnly(this.props.tvClient.accessToken, query).then((staff)=>{
						this.setState({isLoading: false, searchoptions: staff });
					})
		}

		handleFeedbackSearch(query) {
					this.setState({isLoading: true});
					this.props.findStaffByNameOnly(this.props.tvClient.accessToken, query).then((staff)=>{
						this.setState({isLoading: false, feedbackSearchoptions: staff });
					})
		}

		selectStaffFromFeedbackSearch(person) {
			if(person[0]!==undefined){
				this.setState({selectedFeedbackPerson: person[0]});
			}
		}

		handleSendAllFeedback(e){
				e.stopPropagation();
				this.setState({confirmSendAllOpen: true});
		}

		handleSendAllFeedbackReminder(e){
				e.stopPropagation();
				this.props.sendAllFeedbackReminder(
						this.props.tvClient.accessToken
				);
		}

		handleFilterFeedbackByDepartment(e){
				e.stopPropagation();
				this.setState({confirmSendDeptOpen: true });
		}

	handleSendAllClose(e) {
		this.setState({ confirmSendAllOpen: false });
	}

	handleSendAllConfirm(e){
		this.setState({ confirmSendAllOpen: false });
		this.sendAllFeedback(e);
	}

	handleSendDeptClose(e) {
		this.setState({ confirmSendDeptOpen: false });
	}

	handleSendDeptConfirm(e){
		this.setState({ confirmSendDeptOpen: false });
		this.filterFeedbackByDepartment(e);
	}

		selectStaffFromSearch(person) {
			if(person[0]!==undefined){
				this.props.viewStaffFromSearch(person[0].staff_id);
			}
		}

		changeListPage(page) {
				let filter = null;

				if(this.state.primaryDepartment!==undefined && this.state.primaryDepartment!=='0'){
						filter =  this.state.primaryDepartment;
				}

				let sort = [];
				if (this.state.sortKey) {
						let sortObj = {};
						sortObj[this.state.sortKey] = this.state.sortDirection;
						sort.push(sortObj);
				}

			 this.props.listStaff(this.props.tvClient, 'and', filter, this.state.sortDirection, page, 10, this.state.locationFilter);
		}

		toggleSortDirection(key) {
				return () => {
						let newSortDirection = 'desc';
						if (this.state.sortKey === key && this.state.sortDirection === 'desc') {
								newSortDirection = 'asc';
						}

						this.setState({
								sortKey: key,
								sortDirection: newSortDirection
						}, () => this.changeListPage(this.props.paginationInfo.current_page));
				};
		}

		sendAllFeedback(e){
				 e.preventDefault();

				this.props.sendAllFeedback(
						this.props.tvClient.accessToken
				);
		}

		sendIndFeedback(e){
				 e.preventDefault();

				if(!this.state.selectedFeedbackPerson!==null){
						this.props.sendIndFeedback(
								this.props.tvClient.accessToken,
								this.state.selectedFeedbackPerson.staff_id
						);
				}
		}

		sendIndFeedbackReminder(e){
				 e.preventDefault();

				if(!this.state.selectedFeedbackPerson!==null){
						this.props.sendIndFeedbackReminder(
								this.props.tvClient.accessToken,
								this.state.selectedFeedbackPerson.staff_id
						);
				}
		}

		filterFeedbackByDepartment(e){
				e.preventDefault();
				var selectedDept = this.primaryFeedbackDepartment.value;
				if(selectedDept!==undefined && selectedDept!=='noSelection'){
						this.props.sendDeptFeedback(
								this.props.tvClient.accessToken,
								this.primaryFeedbackDepartment.value
						);
				}

		}

		handleLocationFilter(selectedKey){
			if(parseInt(selectedKey)===0){
				this.props.listStaff(this.props.tvClient, 'and', this.state.primaryDepartment, this.state.sortDirection, 1, 10, 0)
				this.setState({ locationFilter: 0, currentFilterLocation: ' All Locations '});
			}
			else{
				for (var i = this.props.locations.length - 1; i >= 0; i--) {
					if(this.props.locations[i].location_id===parseInt(selectedKey)){
						this.props.listStaff(this.props.tvClient, 'and', this.state.primaryDepartment, this.state.sortDirection, 1, 10, this.props.locations[i].location_id).then(()=>{
								this.setState({ locationFilter: this.props.locations[i].location_id, currentFilterLocation: ' ' + this.props.locations[i].name + ' ' });
						})
						break;
					}
				}
			}
		}


		filterByDepartment(selectedKey){

				if(selectedKey!=='0'){
						let filter =  selectedKey
						let deptName = 'This Department';
						let sort = [];
						if (this.state.sortKey) {
								let sortObj = {};
								sortObj[this.state.sortKey] = this.state.sortDirection;
								sort.push(sortObj);
						}

						for(var i=0; i<this.props.deptList.length; i++){
								if(this.props.deptList[i].key===selectedKey){
										deptName = this.props.deptList[i].name;
										break;
								}
						}

						this.props.listStaff(this.props.tvClient, 'or', filter, this.state.sortDirection, 1, 10, this.state.locationFilter);
						this.setState({'primaryDepartment': selectedKey, 'primaryDepartmentName': deptName});
				} else{
						 let filter = null;

						let sort = [];
						if (this.state.sortKey) {
								let sortObj = {};
								sortObj[this.state.sortKey] = this.state.sortDirection;
								sort.push(sortObj);
						}

						this.props.listStaff(this.props.tvClient, 'and', filter, this.state.sortDirection, 1, 10, this.state.locationFilter);
						this.setState({'primaryDepartment': selectedKey, 'primaryDepartmentName': 'All Departments'});
				}
		}

		handleSelect(selectedKey) {
			if(parseInt(selectedKey)===1){
				this.setState({ showStaffListTab:true, showPeerFeedbackTab:false, activeKey: selectedKey });
			} else if(parseInt(selectedKey)===2){
				this.setState({ showStaffListTab:false, showPeerFeedbackTab:true, activeKey: selectedKey });
			}
		}

		handlePeerSelect(selectedKey) {
			if(parseInt(selectedKey)===1){
				this.setState({ showSendAllPeersTab:true, showSendToDeptTab:false, showSendToIndTab:false, peerActiveKey: selectedKey });
			} else if(parseInt(selectedKey)===2){
				this.setState({ showSendAllPeersTab:false, showSendToDeptTab:true, showSendToIndTab:false, peerActiveKey: selectedKey });
			} else if(parseInt(selectedKey)===3){
				this.setState({ showSendAllPeersTab:false, showSendToDeptTab:false, showSendToIndTab:true, peerActiveKey: selectedKey });
			}
		}

		sortIndicator(key) {
				if (this.state.sortKey === key && this.state.sortDirection === 'asc') {
						return <FontAwesomeIcon icon={faArrowUp}/>;
				} else if (this.state.sortKey === key && this.state.sortDirection === 'desc') {
						return <FontAwesomeIcon icon={faArrowDown}/>;
				}
		}

		dueBeforeChange(event) {
				this.setState({
						dueBefore: event.target.value || null
				}, () => this.changeListPage(this.props.paginationInfo.current_page));
		}

		tableHeader(sortKey, label) {
				return <th className="sortable"
									 onClick={this.toggleSortDirection(sortKey)}>{label} {this.sortIndicator(sortKey)}</th>;
		}

		refreshHandler(staffId){
			let staffRow = 'staffrow_' + staffId;
			const rowToHide = document.getElementById(staffRow);
	  
			// Check if the element exists before hiding
			if (rowToHide) {
			  rowToHide.style.display = 'none';
			}
		}

		render() {
				return <div>
						<h1 className="page-header">
								Staff                { (this.state.showStaffListTab && !this.props.isViewOnly) && <Link to="/staff/new" className="btn btn-primary float-right">+ Add Staff Member</Link>}
								<Navbar className="submenuNav" variant="light" expand="lg">
									<Nav activeKey={this.state.activeKey} onSelect={this.handleSelect.bind(this)}>
										<Nav.Link eventKey={1} href="#">
											List
										</Nav.Link>
										{ (this.props.displayPeerFeedback===true && !this.props.isViewOnly) && <Nav.Link eventKey={2} href="#">
											Peer Feedback
											</Nav.Link>
										}
									</Nav>
								</Navbar>
						</h1>

						{ (this.state.showStaffListTab) && <div className="case-list-container">

								{this.props.listError && <Alert variant="danger">{this.props.listError.message}</Alert>}
								<div className="elementSpacer">
								{(this.props.deptList) && <div className="customLocationRange float-left">

										<FormLabel>Filter by Department:</FormLabel>
											 <Dropdown
													title='Choose a Department'
													id='filterDeptSurvey'
													drop="down" onSelect={this.filterByDepartment}
												>
													<Dropdown.Toggle className="btn-default" id="dropdown-basic">
														{this.state.primaryDepartmentName}
													</Dropdown.Toggle>
													<Dropdown.Menu>
										<Dropdown.Item eventKey={0}>All Departments</Dropdown.Item>
											{Object.values(this.props.deptList).map(d => {
												return  <Dropdown.Item eventKey={d.key} key={d.key}>{d.name}</Dropdown.Item>
											})}
												</Dropdown.Menu>
										</Dropdown>
								</div> }
								{(this.props.locations) && <div className="customLocationRange float-left ml-2">
												<FormLabel>Filter by Location:</FormLabel>
											 <Dropdown
													title='Choose a Location'
													id='filterSurvey'
													drop="down" onSelect={this.handleLocationFilter.bind(this)}
												>
													<Dropdown.Toggle className="btn-default" id="dropdown-basic">
														{this.state.currentFilterLocation}
													</Dropdown.Toggle>
													<Dropdown.Menu>
										{(!this.props.isLocationOnly) && <Dropdown.Item eventKey={0}>All Locations</Dropdown.Item>}
											{Object.values(this.props.locations).map(d => {
												if(this.props.isLocationOnly){
														if(this.props.roleLocations!==undefined){
																for(var i=0; i<this.props.roleLocations.length;i++){
																		if(this.props.roleLocations[i].toString()===d.location_id.toString()){
																				return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
																		}
																}
														} else{
																return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
														}
												} else{
														return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
												}

											})}
												</Dropdown.Menu>
										</Dropdown>
						</div> }

								{!this.props.isViewOnly && <div className="col-md-3 float-right">

									 <div>
										<label htmlFor="searchByNameInput">Search Results By Name:</label>

										 <AsyncTypeahead
										 id="searchByNameInput"
											isLoading={this.state.isLoading}
											 labelKey="name"
											onSearch={this.handleSearch}
											onChange={this.selectStaffFromSearch}
											options={this.state.searchoptions}
											placeholder="Search"
											ref={(typeahead) => this.typeahead = typeahead}
											renderMenuItemChildren={(option, props) => (
												<StaffProfileMenuItem key={option.staff_id} user={option} />
											)}
										/>
											</div>


							</div>}
								</div>
								<br/><br/><br/><br/><br/>
								<div className="case-list-summary">{this.props.staff.length}
										{' '}staff member{this.props.staff.length === 1 ? '' : 's'} {' '}of {this.props.staff.length>0 ? this.props.staff[0].full_count : '0'} listed for <span className="text-underline">{this.state.primaryDepartmentName}</span> at <span className="text-underline">{this.state.currentFilterLocation}</span>.
								</div>

								<Table className="case-list sortable">
										<thead>
										<tr>
												<th/>
												{this.tableHeader('name', 'Name')}
												<th>Title</th>
												<th>Email</th>
												<th>Phone</th>
												<th>Active</th>
												{!this.props.isViewOnly && <th/> }
										</tr>
										</thead>
										<tbody>
										{
												this.props.staff.map((c,i) => {
														return <tr id={'staffrow_'+c.staff_id} key={c.staff_id} className={(i % 2)===0 ? "" : "altRowColor"}>
																<td className="staffImage">
												{c.staffimageid && <img alt={c.staffimageid} src={c.staffimageid.startsWith('http') ? c.staffimageid : process.env.PUBLIC_URL + '/staff_assets/' + c.staffimageid} /> || <img alt="default" src={process.env.PUBLIC_URL + '/staff_assets/default-employee-avatar.png'} />}</td>
																<td>{c.name}<br/>
                                									{ (c.reviews!==undefined && c.reviews!==null && c.reviews!=='' && c.reviews.google!==undefined) && <small><a target="_blank" className="alignLeft15" href={'https://www.google.com/maps/search/?api=1&query=Google&query_place_id=' + c.reviews.google}>View on Google</a></small>}
                               									 </td>
																<td>{c.title || '-'}</td>
																<td>{c.email || '-'}</td>
																<td>{c.phone || '-'}</td>
																<td><StaffStatus bullet={true} status={c.active}/></td>
																{!this.props.isViewOnly && <td>
																<Link to={'/staff/edit/' + c.staff_id}>Edit</Link>    {c.staff_id && <DeleteStaffOverlay staffDetail={c} parentRefresh={this.refreshHandler.bind(this)}/>}<br/><Link to={'/staff/report/' + c.staff_id}>Analyze</Link></td>}
														</tr>;
												})
										}
										</tbody>
								</Table>
								{this.props.staffLoading && <Spinner/>}

								<PaginationArea currentPage={this.props.paginationInfo.current_page} parentRefresh={this.changeListPage.bind(this)} numberOfPages={this.props.paginationInfo.num_pages} items={this.props.paginationItems} />
								 {!this.props.staffLoading  && <div>
												<ExportStaffListCSV locationId={this.state.locationFilter} locationName={this.state.currentFilterLocation} token={this.props.tvClient.accessToken} />
											</div> }
						</div>}

						{ (this.state.showPeerFeedbackTab && !this.props.isViewOnly) && <div className="case-list-container">

								<p>Please choose how you would like to distribute your staff peer feedback from the tabs below.</p>

								<Navbar className="submenuNav" variant="light" expand="lg">
									<Nav activeKey={this.state.peerActiveKey} onSelect={this.handlePeerSelect.bind(this)}>
										{!this.props.isLocationOnly && <Nav.Link eventKey={1} className='employerTabs' href="#">
											Send All
										</Nav.Link>   }
										{!this.props.isLocationOnly && <Nav.Link eventKey={2} className='employerTabs' href="#">
											Send By Department
										</Nav.Link>  }
										<Nav.Link eventKey={3} className='employerTabs' href="#">
											Send Individually
										</Nav.Link>
									</Nav>
								</Navbar>

								{ (this.state.showSendAllPeersTab && !this.props.isLocationOnly) && <div>
										<br/>
										<h6>Send your entire staff their peer review link:</h6>
										<br/>
										<div className="col-md-8">
												<Button variant="primary" disabled={this.props.peerFeedbackSending} onClick={this.handleSendAllFeedback}>{(this.props.peerFeedbackSending) ? 'Sending Feedback Request To All' : 'Send Feedback Request To All'}</Button>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary" disabled={this.props.peerFeedbackSending} onClick={this.handleSendAllFeedbackReminder}>{(this.props.peerFeedbackSending) ? 'Sending Feedback Reminder To All' : 'Send Feedback Reminder To All'}</Button>
										</div>
										<Modal size="sm" show={this.state.confirmSendAllOpen} onHide={this.handleSendAllClose.bind(this)}>
											<Modal.Header className="modalPadding" >
												<Modal.Title>Confirm Sending Feedback</Modal.Title>
											</Modal.Header>
											<Modal.Body>This will send <b>ALL</b> of your staff a peer feedback request.  Are you sure you want to proceed?</Modal.Body>
											<Modal.Footer>
												<Button  onClick={this.handleSendAllClose.bind(this)}>
													No
												</Button>
												<Button onClick={this.handleSendAllConfirm.bind(this)}>
													Yes
												</Button>
											</Modal.Footer>
										</Modal>

								</div>}
								{ (this.state.showSendToDeptTab && !this.props.isLocationOnly) && <div>
										<br/>
										<h6>Select the department to send peer review requests:</h6>
										<div className="col-md-4">
												<DepartmentDropdown inputRef={ref => this.primaryFeedbackDepartment = ref}/><br/>
													 <Button variant="primary" disabled={this.props.peerFeedbackSending} onClick={this.handleFilterFeedbackByDepartment}>{(this.props.peerFeedbackSending) ? 'Sending Feedback Request' : 'Send Feedback Request'}</Button>
										<Modal size="sm" show={this.state.confirmSendDeptOpen} onHide={this.handleSendDeptClose.bind(this)}>
											<Modal.Header className="modalPadding" >
												<Modal.Title>Confirm Sending Feedback</Modal.Title>
											</Modal.Header>
											<Modal.Body>This will send all of the staff in this department a peer feedback request.  Are you sure you want to proceed?</Modal.Body>
											<Modal.Footer>
												<Button  onClick={this.handleSendDeptClose.bind(this)}>
													No
												</Button>
												<Button onClick={this.handleSendDeptConfirm.bind(this)}>
													Yes
												</Button>
											</Modal.Footer>
										</Modal>
										</div>
								</div>}
								{ (this.state.showSendToIndTab) && <div>
										<br/>
										<h6>Search and select the staff member:</h6>
										<div className="col-md-4">
										 <AsyncTypeahead
										 id="searchFeedbackByNameInput"
											isLoading={this.state.isLoading}
											 labelKey="name"
											onSearch={this.handleFeedbackSearch}
											onChange={this.selectStaffFromFeedbackSearch}
											options={this.state.feedbackSearchoptions}
											placeholder="Search"
											ref={(typeahead) => this.typeahead = typeahead}
											renderMenuItemChildren={(option, props) => (
												<StaffProfileMenuItem key={option.staff_id} user={option} />
											)}
										/>
											</div>

												{ (this.state.selectedFeedbackPerson !== null) && <div>
																<br/>
																<Table className="case-list">
																		<tbody>
																				<tr key={this.state.selectedFeedbackPerson.staff_id}>
																								<td className="staffImage">
																								{this.state.selectedFeedbackPerson.staffimageid && <img alt={this.state.selectedFeedbackPerson.staffimageid} src={this.state.selectedFeedbackPerson.staffimageid.startsWith('http') ? this.state.selectedFeedbackPerson.staffimageid : process.env.PUBLIC_URL + '/staff_assets/' + this.state.selectedFeedbackPerson.staffimageid} /> || <img alt="default" src={process.env.PUBLIC_URL + '/staff_assets/default-employee-avatar.png'} />}</td>
																								<td><span className="font-weight-bold">{this.state.selectedFeedbackPerson.name}</span></td>
																								<td><Button variant="primary" disabled={this.props.peerFeedbackSending} onClick={this.sendIndFeedback}>{(this.props.peerFeedbackSending) ? 'Sending Feedback Request' : 'Send Feedback Request'}</Button>&nbsp; <Button variant="primary" disabled={this.props.peerFeedbackSending} onClick={this.sendIndFeedbackReminder}>{(this.props.peerFeedbackSending) ? 'Sending Feedback Reminder' : 'Send Feedback Reminder'}</Button></td>
																						</tr>
																		</tbody>
																</Table>
												</div>}

								</div>}

								</div>
						}
						<footer className="adminFooter">&copy; Well iQ {new Date().getFullYear()} | <a href="mailto:support@welliq.org">support@welliq.org</a> | <a target="_blank" href="https://www.welliq.org/privacy.html">Privacy Policy</a></footer>
				</div>
		}
}

const mapStateToProps = state => {
		return {
				tvClient: state.login.tvClient,
				staff: state.staffList.staff,
				staffLoading: state.staffList.loading,
				paginationInfo: state.staffList.paginationInfo,
				displayPeerFeedback: state.staffList.showPeerReview,
				paginationItems: state.staffList.paginationInfo.pageList,
				peerFeedbackSending: state.peerFeedback.sending,
				listError: state.staffList.error,
				deptList: state.deptList.departments,
				locations: state.locationList.locations,
				isViewOnly: state.login.user && state.login.user.attributes.role === 'viewonly',
				isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
				roleLocations: state.login.user.attributes.locations
		};
};

const mapDispatchToProps = dispatch => {
		return {
				listDepartments: (...params) => dispatch(listDepartments(...params)),
				listLocations: (...params) => dispatch(listLocations(...params)),
				listStaff: (...params) => dispatch(listStaff(...params)),
				findStaffByNameOnly: (...params) => dispatch(findStaffByNameOnly(...params)),
				viewStaffFromSearch: (...params) => dispatch(viewStaffFromSearch(...params)),
				sendIndFeedback: (...params) => dispatch(sendIndFeedback(...params)),
				sendDeptFeedback: (...params) => dispatch(sendDeptFeedback(...params)),
				sendAllFeedback: (...params) => dispatch(sendAllFeedback(...params)),
				sendIndFeedbackReminder: (...params) => dispatch(sendIndFeedbackReminder(...params)),
				sendDeptFeedbackReminder: (...params) => dispatch(sendDeptFeedbackReminder(...params)),
				sendAllFeedbackReminder: (...params) => dispatch(sendAllFeedbackReminder(...params))
		};
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffList);
