import React, {Component} from "react";
import {connect} from "react-redux";
import {
		Alert,
		Button,
		FormLabel,
		Form,
		FormControl,
		FormGroup
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {updateLocation, viewLocation } from "../../actions";
import DeleteLocationOverlay from "./../Overlays/DeleteLocationOverlay";

class EditLocation extends Component {

		componentDidMount() {
				return this.props.viewLocation(this.props.tvClient, this.props.match.params.documentId);
		}

		constructor(props) {

				super(props);

				this.state = {
						errors: { 'locationName': false, 'locationAddress1': false, 'locationCity': false, 'locationState': false, 'locationZip': false  },
						editLoading: true,
						changed: false
				};

		}

		deleteLocation(e){
				console.log('deleting this location');
		}

		editLocation(e) {
				e.preventDefault();


				if(!this.locationName.value){
						this.setState({ errors: {'locationName': true, 'locationAddress1': false, 'locationCity': false, 'locationState': false, 'locationZip': false }});
				} else if(!this.locationAddress1.value){
						this.setState({ errors: {'locationName': false, 'locationAddress1': true, 'locationCity': false, 'locationState': false, 'locationZip': false }});
				} else if(!this.locationCity.value){
						this.setState({ errors: {'locationName': false, 'locationAddress1': false, 'locationCity': true, 'locationState': false, 'locationZip': false }});
				} else if(!this.locationState.value){
						this.setState({ errors: {'locationName': false, 'locationAddress1': false, 'locationCity': false, 'locationState': true, 'locationZip': false }});
				} else if(!this.locationZip.value){
						this.setState({ errors: {'locationName': false, 'locationAddress1': false, 'locationCity': false, 'locationState': false, 'locationZip': true }});
				} else{
					 this.props.updateLocation(
								this.props.tvClient,
								this.props.match.params.documentId,
								this.locationName.value,
								this.locationAddress1.value,
								this.locationAddress2.value,
								this.locationCity.value,
								this.locationState.value,
								this.locationZip.value,
								this.locationPhone.value,
								this.notificationPhone.value,
								this.notificationEmail1.value,
								this.notificationEmail2.value,
								this.google_id.value,
								this.yelp_id.value,
								this.facebook_id.value,
								this.healthgrades_id.value
						);
				}
		}

		render() {
				return <div>
						<h1 className="page-header">
								Edit Location
								<small>Please update any of the following location information</small>
								<small className="backLink"><button className="simulateAnchor" onClick={() => window.history.back()} >Go Back</button> | <DeleteLocationOverlay locationDetail={this.props.match.params.documentId} /></small>
						</h1>

						{this.state.viewingLocation && <Spinner/>}

						{this.props.locationData && <Form onSubmit={this.editLocation.bind(this)} className="new-case-form">

								{this.props.viewLocationError && <Alert variant="danger">
										{this.props.viewLocationError.message}
								</Alert>}

								<FormGroup controlId="locationName">
										<FormLabel>Name *</FormLabel>
										<FormControl type="text" placeholder="North County Office"  defaultValue={this.props.locationData.name} ref={ref => this.locationName = ref}/>
										{this.state.errors.locationName && <Alert variant="danger">
												Name is required.
										</Alert>}
								</FormGroup>

								<FormGroup controlId="locationAddress1">
										<FormLabel>Address Line 1 *</FormLabel>
										<FormControl type="text" placeholder="5555 Main st."  defaultValue={this.props.locationData.address1} ref={ref => this.locationAddress1 = ref}/>
										{this.state.errors.locationAddress1 && <Alert variant="danger">
												Address Line 1 is required.
										</Alert>}
								</FormGroup>

								<FormGroup controlId="locationAddress2">
										<FormLabel>Address Line 2</FormLabel>
										<FormControl type="text" placeholder="Suite / Office #"  defaultValue={this.props.locationData.address2} ref={ref => this.locationAddress2 = ref}/>
								</FormGroup>

								<FormGroup controlId="locationCity">
										<FormLabel>City *</FormLabel>
										<FormControl type="text" placeholder="City Name"  defaultValue={this.props.locationData.city} ref={ref => this.locationCity = ref}/>
										{this.state.errors.locationCity && <Alert variant="danger">
												City is required.
										</Alert>}
								</FormGroup>

								<FormGroup controlId="locationState">
								<FormLabel>State *</FormLabel>
								<FormControl as="select" placeholder="Select"  defaultValue={this.props.locationData.state} ref={ref => this.locationState = ref}>
										<option value="AL">Alabama</option>
										<option value="AK">Alaska</option>
										<option value="AZ">Arizona</option>
										<option value="AR">Arkansas</option>
										<option value="CA">California</option>
										<option value="CO">Colorado</option>
										<option value="CT">Connecticut</option>
										<option value="DE">Delaware</option>
										<option value="DC">District Of Columbia</option>
										<option value="FL">Florida</option>
										<option value="GA">Georgia</option>
										<option value="HI">Hawaii</option>
										<option value="ID">Idaho</option>
										<option value="IL">Illinois</option>
										<option value="IN">Indiana</option>
										<option value="IA">Iowa</option>
										<option value="KS">Kansas</option>
										<option value="KY">Kentucky</option>
										<option value="LA">Louisiana</option>
										<option value="ME">Maine</option>
										<option value="MD">Maryland</option>
										<option value="MA">Massachusetts</option>
										<option value="MI">Michigan</option>
										<option value="MN">Minnesota</option>
										<option value="MS">Mississippi</option>
										<option value="MO">Missouri</option>
										<option value="MT">Montana</option>
										<option value="NE">Nebraska</option>
										<option value="NV">Nevada</option>
										<option value="NH">New Hampshire</option>
										<option value="NJ">New Jersey</option>
										<option value="NM">New Mexico</option>
										<option value="NY">New York</option>
										<option value="NC">North Carolina</option>
										<option value="ND">North Dakota</option>
										<option value="OH">Ohio</option>
										<option value="OK">Oklahoma</option>
										<option value="OR">Oregon</option>
										<option value="PA">Pennsylvania</option>
										<option value="RI">Rhode Island</option>
										<option value="SC">South Carolina</option>
										<option value="SD">South Dakota</option>
										<option value="TN">Tennessee</option>
										<option value="TX">Texas</option>
										<option value="UT">Utah</option>
										<option value="VT">Vermont</option>
										<option value="VA">Virginia</option>
										<option value="WA">Washington</option>
										<option value="WV">West Virginia</option>
										<option value="WI">Wisconsin</option>
										<option value="WY">Wyoming</option>
											<option value="AB">Alberta</option>
											<option value="BC">British Columbia</option>
											<option value="MB">Manitoba</option>
											<option value="NB">New Brunswick</option>
											<option value="NL">Newfoundland and Labrador</option>
											<option value="NS">Nova Scotia</option>
											<option value="NT">Northwest Territories</option>
											<option value="NU">Nunavut</option>
											<option value="ON">Ontario</option>
											<option value="PE">Prince Edward Island</option>
											<option value="QC">Quebec</option>
											<option value="SK">Saskatchewan</option>
											<option value="YT">Yukon</option>
								</FormControl>
										{this.state.errors.locationState && <Alert variant="danger">
												State/Province is required.
										</Alert>}
								</FormGroup>
								<FormGroup controlId="locationZip">
										<FormLabel>Zip/Postal Code *</FormLabel>
										<FormControl type="text" placeholder="Zip"  defaultValue={this.props.locationData.zip} ref={ref => this.locationZip = ref}/>
										{this.state.errors.locationZip && <Alert variant="danger">
												Zip Code is required.
										</Alert>}
								</FormGroup>

								<FormGroup controlId="locationPhone">
										<FormLabel>Office Phone</FormLabel>
										<FormControl type="text" placeholder="555-555-5555"  defaultValue={this.props.locationData.phone} ref={ref => this.locationPhone = ref}/>
								</FormGroup>

								<FormGroup controlId="google_id">
										<FormLabel>Google Place ID</FormLabel>
										<FormControl type="text" defaultValue={this.props.locationData.google_id} ref={ref => this.google_id = ref}/>
								</FormGroup>
								<FormGroup controlId="yelp_id">
										<FormLabel>Yelp! URL</FormLabel>
										<FormControl type="text" defaultValue={this.props.locationData.yelp_id} ref={ref => this.yelp_id = ref}/>
								</FormGroup>

								<FormGroup controlId="facebook_id">
										<FormLabel>Facebook Review URL</FormLabel>
										<FormControl type="text" defaultValue={this.props.locationData.facebook_id} ref={ref => this.facebook_id = ref}/>
								</FormGroup>

								<FormGroup controlId="healthgrades_id">
										<FormLabel>Health Grades URL</FormLabel>
										<FormControl type="text" defaultValue={this.props.locationData.healthgrades} ref={ref => this.healthgrades_id = ref}/>
								</FormGroup>


								<legend>Notification Information</legend>

								<FormGroup controlId="notificationPhone">
										<FormLabel>Admin Phone</FormLabel>
										<FormControl type="text" placeholder="555-555-5555"  defaultValue={this.props.locationData.notify_phone} ref={ref => this.notificationPhone = ref}/>
								</FormGroup>

								<FormGroup controlId="notificationEmail1">
										<FormLabel>Admin Email</FormLabel>
										<FormControl type="text" placeholder="name@hospital.com"  defaultValue={this.props.locationData.notify_email1} ref={ref => this.notificationEmail1 = ref}/>
										<p><i><small>Separate multiple emails with a semicolon ;</small></i></p>
								</FormGroup>

								<FormGroup controlId="notificationEmail2">
										<FormLabel>Admin Email</FormLabel>
										<FormControl type="text" placeholder="name@hospital.com"  defaultValue={this.props.locationData.notify_email2} ref={ref => this.notificationEmail2 = ref}/>
										<p><i><small>Separate multiple emails with a semicolon ;</small></i></p>
								</FormGroup>

								<FormGroup className="submit-buttons">
										<Button type="submit" disabled={this.props.viewingLocation} className="float-right">
												Update
										</Button>
								</FormGroup>


						</Form>}
				</div>
		}
}

const mapStateToProps = state => {
		return {
				viewingLocation: state.viewLocation.viewingLocation,
				viewLocationError: state.viewLocation.viewLocationError,
				tvClient: state.login.tvClient,
				locationData: state.viewLocation.locationData
		};
};

const mapDispatchToProps = dispatch => {
		return {
				viewLocation: (...params) => dispatch(viewLocation(...params)),
				updateLocation: (...params) => dispatch(updateLocation(...params))
		};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLocation);

