import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";
import "./bootstrap-theme.scss";
import App from './components/AdminApp/App';
import SurveyApp from "./components/SurveyApp/SurveyApp";
import {Route, Switch } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './configurestore.js'
import VersionInfo from './components/ViewVersion/VersionInfo.js';

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
    	<ConnectedRouter history={history}>
      		<Switch>
                <Route path="/s" component={SurveyApp} />
                <Route path="/j" component={SurveyApp} />
                <Route path="/e" component={SurveyApp} />
                <Route path="/d" component={SurveyApp} />
                <Route path="/hr" component={SurveyApp} />
                <Route path="/me" component={SurveyApp} />
                <Route path="/photo" component={SurveyApp} />
                <Route path="/version" component={VersionInfo} />
                <Route path="/" component={App} />
      		</Switch>
      	</ConnectedRouter>
    </Provider>
  ,
  document.getElementById('root')
);
