import React, { useEffect, useState } from 'react';

const VersionInfo = () => {
    const [versionInfo, setVersionInfo] = useState(null);
    const [error, setError] = useState(null);
    const internalApiClient = process.env.REACT_APP_API_DOMAIN;

    useEffect(() => {
        // Fetch frontend version.json file
        const fetchFrontendVersion = fetch('/version.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch frontend version info');
                }
                return response.json();
            })
            .then(data => ({ frontendVersion: data }))
            .catch(error => {
                setError(error.message);
                return { frontendVersion: null }; // Return null to keep consistency
            });

        // Fetch backend version from /api/version endpoint
        const fetchBackendVersion = fetch(`${internalApiClient}/api/version`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch backend version info');
                }
                return response.json();
            })
            .then(data => ({ backendVersion: data }))
            .catch(error => {
                setError(error.message);
                return { backendVersion: null }; // Return null to keep consistency
            });

        // Combine both fetches
        Promise.all([fetchFrontendVersion, fetchBackendVersion])
            .then(([frontendResult, backendResult]) => {
                setVersionInfo({
                    clientVersion: frontendResult.frontendVersion,
                    serverVersion: backendResult.backendVersion
                });
            })
            .catch(error => setError(error.message));
    }, [internalApiClient]);

    return (
                   
            <div>
                {versionInfo ? (
                    <pre>{JSON.stringify(versionInfo, null, 2)}</pre>
                ) : (
                    <p>Loading version information...</p>
                )}
            </div>
        
    );
};

export default VersionInfo;
