import React, {Component} from "react";
import {connect} from "react-redux";
import {
    FormControl,
    Table
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {reopenSpecificEvent, updateSpecificEvent, viewSpecificEvent } from "../../actions";
import "./ViewEvent.scss";
import dateFormat from "dateformat";
import queryString from 'query-string'
import { Link } from 'react-router-dom';

class ViewEvent extends Component {
    constructor(props) {
        
        super(props);

        this.state = {
            currentDetails: [],
            openClosedEvent: false,
            fields: { 'resolved': 0, 'action': '', 'category': ''},
            eventerror: '',
            historyField: queryString.parse(this.props.location.search)
        };

        this.handleFormChange = this.handleFormChange.bind(this);
    }

    componentDidMount() { 
        
        let isRefresh =this.props.currentEvent === null;
        if(isRefresh){
            this.props.viewSpecificEvent(this.props.tvClient, this.props.match.params.eventId);
        }
        window.scrollTo(0, 0);
    }

    handleFormChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
    }    

    determineStatus(status, action){
        if(status===true || status==='true'){
            return 'Closed';
        } else{
            if(action!==undefined && action!==null && action.toString().length>0){
                return 'Pending';
            } else {
                return 'Open';
            }
        }
    }    

    handleReopenEvent(){
        this.props.reopenSpecificEvent(this.props.tvClient, this.props.match.params.eventId, this.props.currentEvent.data.action, 0, this.props.adminAttr, this.props.currentEvent.data.category).then(()=>{
            this.props.viewSpecificEvent(this.props.tvClient, this.props.match.params.eventId);
            window.scrollTo(0, 0);
        })
    }    

    handleEventUpdate(e){
        this.setState({ eventerror: ''});

        var theCategory = this.state.fields.category;
        if(theCategory===''){
            if(this.props.currentEvent.data.category!==undefined){
                theCategory = this.props.currentEvent.data.category;
            }
        }

        var tempDate = new Date();
        // eslint-disable-next-line no-useless-concat
        var dateAddedText = ' ['+tempDate.toDateString()+' '+tempDate.getHours()+':'+tempDate.getMinutes()+']'+'\n';

        if(this.state.fields.resolved===undefined){
            this.setState({ eventerror: 'You must choose Yes or No to resolve this.'});
        } else if(this.state.fields.resolved.toString()==="1"){
            if(this.state.fields.action===undefined || this.state.fields.action.trim()===''){
                this.setState({ eventerror: 'You must provide a reason/action for resolving this event.'});
            } else{
                // update
                e.target.style.visibility = 'hidden';
                var actionText =  this.props.currentEvent.data.action!==null ? this.props.currentEvent.data.action.trim() : '';
                this.props.updateSpecificEvent(this.props.tvClient, this.props.match.params.eventId, (this.state.fields.action.trim() + dateAddedText + actionText), this.state.fields.resolved,this.props.adminAttr, theCategory);
            }
        } else{
            
            if(this.state.fields.action===undefined || this.state.fields.action.trim()===''){
                this.setState({ eventerror: 'You must provide a reason/action for resolving this event.'});
            } else{
                // update
                e.target.style.visibility = 'hidden';
                 var actionText1 =  this.props.currentEvent.data.action!==null ? this.props.currentEvent.data.action.trim() : '';
                this.props.updateSpecificEvent(this.props.tvClient, this.props.match.params.eventId, (this.state.fields.action.trim() + dateAddedText + actionText1), this.state.fields.resolved,this.props.adminAttr, theCategory);
            }
        }
    }

    render() {
        return <div>
            <h1 className="page-header">
               View Event Data
               {(this.state.historyField.last && this.state.historyField.last!=='none') && <small className="backLink"><Link to={`/reports/events?refresh=0&searching=${this.state.historyField.searching}`}>Go Back</Link></small>}
            </h1>
            {this.props.currentEvent===null && <Spinner/>}
            {this.props.currentEvent!==null && <div className="case-list-container">
                   
                <Table className="case-list surveyReportView eventData">
                    <tbody>
                            <tr>
                                <td className="reportCell">Status</td>
                                <td className="reportValue">{this.determineStatus(this.props.currentEvent.data.addressed, this.props.currentEvent.data.action)} &nbsp; &nbsp;
                                { (!this.props.isViewOnly && this.props.currentEvent.data.addressed) && <button onClick={this.handleReopenEvent.bind(this)} className="simulateAnchor small">(Re-open / Update)</button> }  
                                </td>
                            </tr>
                        <tr>
                            <td className="reportCell">Event ID</td>
                            <td className="reportValue">{this.props.currentEvent.data.event_id}</td>
                        </tr>
                        <tr>
                            <td className="reportCell">Event Type</td>
                            <td className="reportValue">{this.props.currentEvent.data.event_type} {(this.props.currentEvent.data!==undefined && this.props.currentEvent.data.respondent_type==='Employer') ? ' - FROM EMPLOYER' : ''}</td>
                        </tr>
                        <tr>
                            <td className="reportCell">Date Created</td>
                            <td className="reportValue">{dateFormat(this.props.currentEvent.data.created, "fullDate") + ' at ' + dateFormat(this.props.currentEvent.data.created, "shortTime")}</td>                            
                        </tr> 
                        <tr>
                            <td className="reportCell">Location</td>
                            <td className="reportValue">{this.props.currentEvent.data.name} {(this.props.currentEvent.data!==undefined && this.props.currentEvent.data.respondent_type==='Employer') ? 'All Locations' : ''}</td>
                        </tr> 

                        <tr>
                            <td className="reportCell">Event Category</td>
                            {(!this.props.isViewOnly && this.props.currentEvent.data.addressed!==true) && 
                            <td className="reportValue"><FormControl type="text" onChange={this.handleFormChange} defaultValue={(this.props.currentEvent.data.category!==undefined && this.props.currentEvent.data.category!=='undefined' && this.props.currentEvent.data.category!=='null') ? this.props.currentEvent.data.category : 'None'} name="category" /> </td>}
                            {(!this.props.isViewOnly && this.props.currentEvent.data.addressed===true) && 
                            <td className="reportValue">{(this.props.currentEvent.data.category==='' || this.props.currentEvent.data.category===null || this.props.currentEvent.data.category==='null' || this.props.currentEvent.data.category===undefined) ? 'None' : this.props.currentEvent.data.category} </td>}

                        </tr>

                            { !this.props.isViewOnly && <tr>
                                <td className="reportCell">New Action Taken:</td>
                                <td className="reportValue textEntryArea"> <FormControl as="textarea" id="action" name="action" onChange={this.handleFormChange} placeholder="Enter your new actions to resolve here..." /> </td>
                            </tr> }
                            <tr>
                                <td className="reportCell">Previous Actions</td>
                                <td className="reportValue textEntryArea"><FormControl as="textarea" id="previousaction" name="previousaction" defaultValue={this.props.currentEvent.data.action} disabled="disabled" /> </td>
                            </tr>
                            { (!this.props.isViewOnly && this.props.currentEvent.data.addressed!==true) && <tr>
                                <td className="reportCell">Mark as Resolved?</td>
                                <td className="reportValue">
                                <div className="radio">
                                  <label>
                                    <input type="radio" name="resolved" value="1" onChange={this.handleFormChange} />
                                    Yes
                                  </label>
                                </div>
                                <div className="radio">
                                  <label>
                                    <input type="radio" name="resolved" value="0"  onChange={this.handleFormChange} />
                                    No
                                  </label>
                                </div>
                                </td>
                            </tr>}
                            { (!this.props.isViewOnly && !this.props.currentEvent.data.addressed) && <tr>
                                <td colSpan="2" className="reportValue buttonAction"><button onClick={this.handleEventUpdate.bind(this)} className="btn btn-primary">UPDATE</button>
                                <p className="errorMsg">{this.state.eventerror}</p></td>
                            </tr> }                        
                    </tbody>
                </Table>                          

                <h2>Survey Data</h2>
                <Table className="case-list surveyReportView">
                    <tbody>
                        <tr>
                            <td className="reportCell">Overall Score</td>
                            <td className="reportValue">{this.props.currentEvent.data.survey_data.nps}</td>
                        </tr>
                       
                           <tr>
                                <td className="reportCell">Name</td>
                                <td className="reportValue">{(this.props.currentEvent.person!==undefined && this.props.currentEvent.person[0]!==undefined) ? this.props.currentEvent.person[0].document.name : 'Anonymous'}</td>
                            </tr>
                            <tr>
                                <td className="reportCell">Email</td>
                                <td className="reportValue">{(this.props.currentEvent.person!==undefined && this.props.currentEvent.person[0]!==undefined) ? this.props.currentEvent.person[0].document.email : 'Unavailable'}</td>
                            </tr>
                            <tr>
                                <td className="reportCell">Phone</td>
                                <td className="reportValue">{(this.props.currentEvent.person!==undefined && this.props.currentEvent.person[0]!==undefined)  ? this.props.currentEvent.person[0].document.phone : 'Unavailable'}</td>
                            </tr>  

 {(this.props.currentEvent.data!==undefined && this.props.currentEvent.data.survey_data.intakeResults!==undefined) && <tr>
                            <td className="reportCell">Intake Form</td>
                            <td className="reportValue">
                            {
                                        this.props.currentEvent.data.survey_data.intakeResults.intakeData.badges.map(c => {
                         

                                            var localeValue = '';
                                            if(c.localization_options!==undefined && c.localization_options!==null){
                                              let tempValue = c.localization_options["EN"];
                                              if(tempValue!==undefined){
                                                localeValue = tempValue;
                                              }
                                            }

                                            return <div className="nopad isBadge text-center intakeBadgeC" key={c.endorsement_id}>
                                                    <label>
                                                      <img alt="" className={"img-responsive"} src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
                                                      <p className="intakeBadgeName">{localeValue}</p>
                                                    </label>
                                                  </div>
                                        
                                          
                                        })
                                    }      

                                    {this.props.currentEvent.data.survey_data.intakeResults.intakeData.badges.length===0 ? 'No Badges Selected' : ''}
                            </td>   
                        </tr>}
                                                
                        <tr>
                        <td className="reportCell">Status</td>
                            <td className="reportValue">{this.props.currentEvent.data.completed ? 'Complete' : 'In Progress'}</td>
                        </tr>     
                    </tbody>
                </Table>                                          

                    {
                        this.props.currentEvent.data.survey_data.departments.map(c => {
                            var totalBadges = 0;
                            var feedback = undefined;
                            if(c.badges!==undefined){
                                totalBadges = c.badges.length;
                            }
                            if(c.feedback!==undefined){
                                feedback = c.feedback;
                            }
                            return <Table key={c.key} className="case-list surveyReportView"><tbody>
                                    <tr><td className="reportCell">Department</td><td className="reportValue">{c.name}</td></tr>
                                    <tr className="spanningReportCell"><td colSpan="2">{totalBadges}{' '}Endorsement{' '}{totalBadges>1 ? 's' : ''} {feedback===undefined ? '& No written feedback provided' : '& Written feedback provided below'}</td></tr>
                                    {totalBadges>0 ? <tr className="spanningReportCell"><td colSpan="2">{ c.badges.map(b => { return <div className="nopad isBadge text-center" key={b.endorsement_id}><label className="adminBadgeView"><img alt={b.description} src={b.image.startsWith('http') ? b.image : process.env.PUBLIC_URL + '/endorsement_assets/' + b.image}/><p>{b.description}</p></label></div>})}</td></tr> : null}
                                    {feedback!==undefined ? <tr><td className="reportCell">Feedback</td><td className="reportValue">{c.feedback}</td></tr> : null}
                                </tbody></Table>
                        })
                    }

                    {
                        this.props.currentEvent.data.survey_data.staff.map(c => {
                            var totalBadges = 0;
                            var feedback = undefined;
                            if(c.badges!==undefined){
                                totalBadges = c.badges.length;
                            }
                            if(c.feedback!==undefined){
                                feedback = c.feedback;
                            }
                            return <Table key={c.staff_id} className="case-list surveyReportView"><tbody>
                                    <tr><td className="reportCell staffImageCell"><img alt={c.description} src={c.staffimageid.startsWith('http') ? c.staffimageid : process.env.PUBLIC_URL + '/staff_assets/' + c.staffimageid}/></td><td className="reportValue">{c.name}<p className="reportStaffTitle">{c.title}</p><p className="reportStaffTitle">{c.bio}</p></td></tr>
                                    <tr className="spanningReportCell"><td colSpan="2">{totalBadges}{' '}Endorsement{' '}{totalBadges>1 ? 's' : ''} {feedback===undefined ? '& No written feedback provided' : '& Written feedback provided below'}</td></tr>
                                    {totalBadges>0 ? <tr className="spanningReportCell"><td colSpan="2">{ c.badges.map(b => { return <div className="nopad isBadge text-center" key={b.endorsement_id}><label className="adminBadgeView"><img alt={b.description} src={b.image.startsWith('http') ? b.image : process.env.PUBLIC_URL + '/endorsement_assets/' + b.image}/><p>{b.description}</p></label></div>})}</td></tr> : null}
                                    {feedback!==undefined ? <tr><td className="reportCell">Feedback</td><td className="reportValue">{c.feedback}</td></tr> : null}
                                </tbody></Table>
                        })
                    }               


                       

                </div>
            }

           
        </div>
    }
}

const mapStateToProps = state => {
    return {
        currentEvent: state.eventView.currentEvent,
        viewEventError: state.eventView.error,
        tvClient: state.login.tvClient,
        adminAttr: state.login.user.attributes,
        isViewOnly: state.login.user && state.login.user.attributes.role === 'viewonly'
    };
};

const mapDispatchToProps = dispatch => {
    return {
        viewSpecificEvent: (...params) => dispatch(viewSpecificEvent(...params)),
        updateSpecificEvent: (...params) => dispatch(updateSpecificEvent(...params)),
        reopenSpecificEvent: (...params) => dispatch(reopenSpecificEvent(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewEvent);
