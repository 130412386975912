import React, {Component} from "react";
import "./ReportsPage.scss";
import "react-spinner/react-spinner.css";
import { Link } from 'react-router-dom';
import {connect} from "react-redux";
import { getAdminFlags } from "../../../actions";

class ReportsPage extends Component {

		constructor(props) {

				super(props);
				this.state = {
					 activeKey: 1,
					 employerEnabled: false,
					 peerReview: false,
					 reviewsEnabled: false
				};
		}

		componentDidMount(){
				this.props.getAdminFlags(this.props.tvClient).then(()=>{
						var truth=false;
						var peerTruth=false;
						var reviewTruth=false;
						if(this.props.flags!==null){
							truth = (this.props.flags.employerFlag.toString()==='true' && !this.props.isSurveysReportsOnly) ? true : false;  
							peerTruth = (this.props.flags.peerReview.toString()==='true' && !this.props.isSurveysReportsOnly) ? true : false;
							reviewTruth = ((this.props.flags.reviewsEnabled.toString()==='true' || this.props.flags.reputationEnabled.toString()==='true') && !this.props.isSurveysReportsOnly) ? true : false;						
						}
						this.setState({employerEnabled: truth, peerReview: peerTruth, reviewsEnabled: reviewTruth });
				});
		}

		tableHeader(sortKey, label) {
				return <th className="sortable"
									 onClick={this.toggleSortDirection(sortKey)}>{label} {this.sortIndicator(sortKey)}</th>;
		}

		render() {
			 return <div>
						<h1 className="page-header">
								Available Reports
						</h1>

						<div className="case-list-container">
								<div className="row">
										<div className="col-md-4">
												<h5 className="reportHeader"><Link to="/reports/nps">Overall Patient Satisfaction</Link></h5>
												<p>A high level snapshot of the overall patient satsifaction data broken down by all locations.</p>
										</div>
										<div className="col-md-4">
												<h5 className="reportHeader"><Link to="/reports/deptendorsements">Most Engaged Departments</Link></h5>
												<p>View departmental engagement through endorsement and feedback.</p>
										</div>
										<div className="col-md-4">
												<h5 className="reportHeader"><Link to="/reports/events">Addressable Events</Link></h5>
												<p>View addressable events that have been configured to require interaction with a patient or staff member.</p>
										</div>
								</div>
								<div className="row">
										<div className="col-md-4">
												<h5 className="reportHeader"><Link to="/reports/behaviors">Top Endorsement Behaviors</Link></h5>
												<p>View the most used behavior endorsements for the entire organization including staff.</p>
										</div>
										<div className="col-md-4">
												<h5 className="reportHeader"><Link to="/reports/staffendorsements">Most Engaged Staff</Link></h5>
												<p>Recognize and reward your most engaged staff members using this easy to read report of top performers.</p>
										</div>
										<div className="col-md-4">
												<h5 className="reportHeader"><Link to="/reports/locationreport">Location Snapshot</Link></h5>
												<p>View the details for a specific location on a single page including patient satisfaction, staff engagement, and more</p>
										</div>
								</div>
								<div className="row">
										<div className="col-md-4">
												<h5 className="reportHeader"><Link to="/reports/stafffeedback">Written Staff Feedback</Link></h5>
												<p>View the written feedback that has been left for your staff from patient surveys</p>
										</div>
										<div className="col-md-4">
												<h5 className="reportHeader"><Link to="/reports/deptfeedback">Written Facility Feedback</Link></h5>
												<p>View the written feedback that has been left for your facility from patient surveys</p>
										</div>
										{this.state.employerEnabled && <div className="col-md-4">
												<h5 className="reportHeader"><Link to="/reports/employerfeedback">Employer Feedback</Link></h5>
												<p>View details on the feedback provider from the Employer specific surveys</p>
										</div>    }
								</div>
								<div className="row">
										{(this.props.showIntake && !this.props.isSurveysReportsOnly) &&
												<div className="col-md-4">
														<h5 className="reportHeader"><Link to="/reports/intakereport">Intake Report</Link></h5>
														<p>View the details of the intake forms you have sent.</p>
												</div>
										 }
										 {this.state.employerEnabled &&
												<div className="col-md-4">
														<h5 className="reportHeader"><Link to="/reports/campaigns">Campaign Overview</Link></h5>
														<p>View the high level open details of the campaigns that you have sent.</p>
												</div>
										}
								</div>

						</div>

								{(this.state.reviewsEnabled && !this.props.isLocationOnly) && <div className="case-list-container">
												<h3 className="peerReportTitle">Review Reports</h3>
												<div className="row">
														<div className="col-md-4">
																<h5 className="reportHeader"><Link to="/reports/yearlypublicratings">Recent Public Ratings</Link></h5>
																<p>View and compare the ratings of your Public Locations over time.</p>
														</div>
												</div>
										</div>
								}

												{this.state.peerReview && <div className="case-list-container">
												<h3 className="peerReportTitle">Peer to Peer Reports</h3>
												<div className="row">
														<div className="col-md-4">
																<h5 className="reportHeader"><Link to="/reports/peerdeptendorsements">Most Engaged Departments</Link></h5>
																<p>View which department groups have the most engaged peer to peer feedback for all locations</p>
														</div>
														<div className="col-md-4">
																<h5 className="reportHeader"><Link to="/reports/peerstaffendorsements">Most Engaged Staff</Link></h5>
																<p>Recognize and reward your most engaged staff members based on peer to peer feedback</p>
														</div>
														<div className="col-md-4">
																<h5 className="reportHeader"><Link to="/reports/peerstafffeedback">Written Staff Feedback</Link></h5>
																<p>View the written feedback that has been left for your staff from peer to peer feedback</p>
														</div>
												</div>
												<div className="row">
														<div className="col-md-4">
																<h5 className="reportHeader"><Link to="/reports/peerstaffpoints">Points By Staff</Link></h5>
																<p>View staff by their current point totals based on recognition</p>
														</div>
														<div className="col-md-4">
																<h5 className="reportHeader"><Link to="/reports/peerlocationreport">Location Specific Report</Link></h5>
																<p>Summarized staff recognition by location.</p>
														</div>
												</div>
										</div>
								}
								<footer className="adminFooter">&copy; Well iQ {new Date().getFullYear()} | <a href="mailto:support@welliq.org">support@welliq.org</a> | <a target="_blank" rel="noopener noreferrer" href="https://www.welliq.org/privacy.html">Privacy Policy</a></footer>
				</div>


		}
}

const mapStateToProps = state => {
		return {
				tvClient: state.login.tvClient,
				flags: state.adminFlags.flags,
				showIntake: state.login.user && state.login.intakeFlag,
				isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
        		isSurveysReportsOnly: state.login.user && state.login.user.attributes.role === 'surveysreportsonly'
		};
};

const mapDispatchToProps = dispatch => {
		return {
				getAdminFlags: (...params) => dispatch(getAdminFlags(...params))
		};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
