export const handleResponseError = (status, customMessages = {}) => {
    const defaultMessages = {
        400: 'Bad Request: The server could not understand the request due to invalid syntax.',
        401: 'Unauthorized: Please log in to continue.',
        403: 'Forbidden: You do not have permission to access this resource.',
        404: 'Not Found: The requested resource could not be found.',
        405: 'Method Not Allowed: This request method is not supported for the requested resource.',
        409: 'Conflict: The request could not be completed due to a conflict with the current state of the target resource.',
        500: 'Internal Server Error: An error occurred on the server.',
        502: 'Bad Gateway: The server received an invalid response from the upstream server.',
        503: 'Service Unavailable: The server is currently unable to handle the request.',
        504: 'Gateway Timeout: The server did not receive a timely response from the upstream server.',
        default: 'An unexpected error has occurred.'
    };

    return customMessages[status] || defaultMessages[status] || defaultMessages['default'];
};