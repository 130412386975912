import React, {Component} from "react";
import {
		Table,
		Dropdown
} from "react-bootstrap";
import { viewPeerLocationDetails, listAllStaffEngagements, listAllStaffEngagementsReport, listLocations, viewPeerSummaryByLocation, viewAdminLocationOverviewData, viewLocationNpsData, viewCustomLocationNpsDataByLocation, viewAdminDashboardEndorsementsReports } from "../../../actions";
import "./../ReportsPage/ReportsPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import StaffBadges from "./../../Utilities/StaffBadges";
import dateFormat from "dateformat";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {PieChart, Pie, Legend, Cell, ResponsiveContainer, Label} from 'recharts';
// Include the locale utils designed for moment
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';

class PeerLocationSpecificReport extends Component {

		constructor(props) {
				super(props);
				this.props.listLocations(this.props.tvClient);
				this.state = {
						emptySet: [{'name':'No Data','value': 1}],
						perpage: 10,
						filter: '',
						sort: null,
						sortDirection: 'desc',
						currentFilter: 8,
						activeKey: 1,
						dateFilter: 'thismonth',
						locationFilter: 0,
						currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ',
						currentFilterLocation: ' Choose One ',
						showCustomDate: false,
						reportFrom: null,
						reportTo: null,
						currentDate: new Date().toLocaleString('en-us', { month: 'long' })
				};

				this.handleFromChange = this.handleFromChange.bind(this);
				this.handleToChange = this.handleToChange.bind(this);
				this.runReport = this.runReport.bind(this);

		}

		viewPeerLocationDetails(locationId){
			this.props.viewPeerLocationDetails(this.props.tvClient, locationId);
		}

		handleFromChange(from) {
			this.setState({ reportFrom: from });
		}
		handleToChange(to) {
			this.setState({ reportTo: to });
		}

		runReport(){
			this.setState({ dateFilter: 'custom', currentFilter: 6, currentFilterName: ' Date Range (' + dateFormat(this.state.reportFrom, "shortDate") + ' to ' + dateFormat(this.state.reportTo, "shortDate") + ') ' });
			//this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
			//this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
			//this.props.viewCustomLocationNpsDataByLocation(this.props.accessToken, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.locationFilter);
			//this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
			//this.props.viewAdminLocationOverviewData(this.props.accessToken, 'custom', this.state.locationFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
			this.props.viewPeerSummaryByLocation(this.props.accessToken, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
		}

		handleFilter(selectedKey) {
			if(selectedKey==1){
				this.setState({ dateFilter: 'alltime', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' All Time ' }, () => {
					if(this.state.locationFilter===0){
						this.props.viewPeerSummaryByLocation(this.props.accessToken, this.state.dateFilter);
					} else{
						this.props.viewLocationNpsData(this.props.accessToken, this.state.dateFilter);
						this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.dateFilter, null, null);
						this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
						this.props.viewAdminLocationOverviewData(this.props.accessToken, this.state.dateFilter, this.state.locationFilter, null, null);
					}
				});
			} else if(selectedKey==2){
				this.setState({ dateFilter: 'thismonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
					if(this.state.locationFilter===0){
						this.props.viewPeerSummaryByLocation(this.props.accessToken, this.state.dateFilter);
					} else{
						this.props.viewLocationNpsData(this.props.accessToken, this.state.dateFilter);
						this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.dateFilter, null, null);
						this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
						this.props.viewAdminLocationOverviewData(this.props.accessToken, this.state.dateFilter, this.state.locationFilter, null, null);
					}
				});
			} else if(selectedKey==3){
				this.setState({ dateFilter: 'lastmonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Month (' + new Date(new Date().getFullYear(), new Date().getMonth()-1).toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
					if(this.state.locationFilter===0){
						this.props.viewPeerSummaryByLocation(this.props.accessToken, this.state.dateFilter);
					} else{
						this.props.viewLocationNpsData(this.props.accessToken, this.state.dateFilter);
						this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.dateFilter, null, null);
						this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null,null);
						this.props.viewAdminLocationOverviewData(this.props.accessToken, this.state.dateFilter, this.state.locationFilter, null, null);
					}
				});
			} else if(selectedKey==4){
				this.setState({ dateFilter: 'yeartodate', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Year To Date (' + new Date().toLocaleString('en-us', { year: 'numeric' }) + ') ' }, () => {
					if(this.state.locationFilter===0){
						this.props.viewPeerSummaryByLocation(this.props.accessToken, this.state.dateFilter);
					} else{
						this.props.viewLocationNpsData(this.props.accessToken, this.state.dateFilter);
						this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.dateFilter, null, null);
						this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
						this.props.viewAdminLocationOverviewData(this.props.accessToken, this.state.dateFilter, this.state.locationFilter, null, null);
					}
				});
			} else if(selectedKey==5){
				this.setState({ dateFilter: 'lastyear', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Year (' + new Date(new Date().getFullYear()-1, 1).toLocaleString('en-us', { year: 'numeric' }) + ') ' } , () => {
					if(this.state.locationFilter===0){
						this.props.viewPeerSummaryByLocation(this.props.accessToken, this.state.dateFilter);
					} else{
						this.props.viewLocationNpsData(this.props.accessToken, this.state.dateFilter);
						this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.dateFilter, null, null);
						this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
						this.props.viewAdminLocationOverviewData(this.props.accessToken, this.state.dateFilter, this.state.locationFilter, null, null);
					}
				});
			} else if(selectedKey==6){
				this.setState({ showCustomDate: true });
			}
			if(selectedKey==8){
				this.setState({ dateFilter: 'thismonth', showCustomDate: false, currentFilterName: ' This Month ', currentFilter: selectedKey }, () => {
					if(this.state.locationFilter===0){
						this.props.viewPeerSummaryByLocation(this.props.accessToken, this.state.dateFilter);
					} else{
						this.props.viewLocationNpsData(this.props.accessToken, this.state.dateFilter);
						this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.dateFilter, null, null);
						this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
						this.props.viewAdminLocationOverviewData(this.props.accessToken, this.state.dateFilter, this.state.locationFilter, null, null);
					}
				});

			}
		}

		componentDidMount() {
				this.props.viewPeerSummaryByLocation(this.props.accessToken, this.state.dateFilter);
		}


		render() {
			 return <div>
						<h1 className="page-header">
								Peer-To-Peer Location Specific Report

										<div className="float-right">
										<Dropdown
													title='Filter Report By Date'
													id='filterSurvey'
													drop="down" onSelect={this.handleFilter.bind(this)}
												>
													<Dropdown.Toggle className="btn-default" id="dropdown-basic">
														{this.state.currentFilterName}
													</Dropdown.Toggle>
													<Dropdown.Menu>
													<Dropdown.Item eventKey="1">All Time</Dropdown.Item>
													<Dropdown.Item eventKey="2">This Month</Dropdown.Item>
													<Dropdown.Item eventKey="3">Last Month</Dropdown.Item>
													<Dropdown.Item eventKey="4">YTD</Dropdown.Item>
													<Dropdown.Item eventKey="5">Last Year</Dropdown.Item>
													<Dropdown.Item eventKey="6">Custom</Dropdown.Item>
													<Dropdown.Divider />
													<Dropdown.Item eventKey="8">Reset Filter</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</div>


							{this.state.showCustomDate && <div className="customDateRange">
									<p className="prizeDrawTitle">Select the range to run the report for:</p>
									<label className="dayPickerLabel">From:</label><DayPickerInput formatDate={formatDate} id="fromDate" name="fromDate" onDayChange={this.handleFromChange}
										dayPickerProps={{
											selectedDays: this.state.selectedDay,
											disabledDays: {
												after: new Date(),
											},
										}} />

								<label className="dayPickerLabel">To:</label><DayPickerInput formatDate={formatDate} id="toDate" name="toDate" onDayChange={this.handleToChange}
										dayPickerProps={{
											selectedDays: this.state.selectedDay,
											disabledDays: {
												after: new Date(),
												before: this.state.prizeFrom
											},
										}} />

							<button className="btn btn-primary" onClick={this.runReport}>{this.props.runningReport ? 'Running...' : 'Run Report'}</button>
							</div>
						}

						</h1>


						{this.props.peerLocationSummaryLoading && <Spinner/>}
						{(this.state.locationFilter===0 && !this.props.peerLocationSummaryLoading) && <div className="case-list-container">
								 <Table className="case-list sortable reportBadgeContainer">
									<thead>
										<tr className="reportBadgeContainer table-headings">
											<th>Location</th>
											<th className="text-center">Total Badges</th>
											<th className="text-center">Total Written Feedback</th>
											<th className="text-center">Total Recognitions</th>
											<th className="text-center">Staff Participating</th>
											<th className="text-center">Participation %</th>
										</tr>
									</thead>
									<tbody>
									 {Object.values(this.props.locations).map(d => {
											let badges = 0;
											let feedback = 0;
											let totalParticipants = 0;
											let totalStaff = 0;
											for(let i=0;i<this.props.peerLocationSummaryBadges.length; i++){
												if(d.location_id.toString()===this.props.peerLocationSummaryBadges[i].location_id.toString()){
													badges = this.props.peerLocationSummaryBadges[i].badges;
													break;
												}
											}

											for(let j=0;j<this.props.peerLocationSummaryFeedback.length; j++){
												if(d.location_id.toString()===this.props.peerLocationSummaryFeedback[j].location_id.toString()){
													feedback = this.props.peerLocationSummaryFeedback[j].writtenfeedback;
													break;
												}
											}

											for(let z=0; z<this.props.peerLocationSummaryParticpating.length;z++){
												if(d.location_id.toString()===this.props.peerLocationSummaryParticpating[z].location_id.toString()){
													totalParticipants = this.props.peerLocationSummaryParticpating[z].total;
													break;
												}
											}

											for(let x=0; x<this.props.peerLocationSummaryTotalStaff.length;x++){
												if(d.location_id.toString()===this.props.peerLocationSummaryTotalStaff[x].location_id.toString()){
													totalStaff = this.props.peerLocationSummaryTotalStaff[x].total;
													break;
												}
											}

											let participation = 0;
											if(totalStaff!==undefined && totalStaff>0){
												participation = parseInt((parseInt(totalParticipants) / parseInt(totalStaff))*100);
											}


											var totalFeedback = parseInt(badges) + parseInt(feedback);

											return <tr className='isClickable' key={d.location_id} onClick={()=> this.viewPeerLocationDetails(d.location_id)}><td>{d.name}</td><td className="text-center">{badges}</td><td className="text-center">{feedback}</td><td className="text-center totalTdValue">{totalFeedback}</td><td className="text-center">{totalParticipants}</td><td className="text-center totalTdValue">{participation}%</td></tr>
									 })}
								 </tbody>
								 </Table>

						</div>
					}



				</div>
		}
}

const mapStateToProps = state => {
		return {
				tvClient: state.login.tvClient,
				accessToken: state.login.tvClient.accessToken,
				staffEngagementDataLoading: state.staffEngagementData.loading,
				staffEngagementData: state.staffEngagementData.engagementData,
				locations: state.locationList.locations,
				locationNpsData: state.locationNpsCpt.locationNpsData,
				locationNpsLoading: state.locationNpsCpt.locationNpsLoading,
				endorsementsLoading: state.endorsementsCpt.endorsementsLoading,
				endorsementsData: state.endorsementsCpt.endorsementsData,
				locationOverviewData: state.locationOverviewCpt.locationOverviewData,
				locationOverviewLoading: state.locationOverviewCpt.locationOverviewLoading,
				isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
				roleLocations: state.login.user.attributes.locations,
				peerLocationSummaryLoading: state.peerLocationSummary.loading,
				peerLocationSummaryBadges: state.peerLocationSummary.badges,
				peerLocationSummaryFeedback: state.peerLocationSummary.feedback,
				peerLocationSummaryParticpating: state.peerLocationSummary.totalParticipation,
				peerLocationSummaryTotalStaff: state.peerLocationSummary.totalLocationStaff
		};
};

const mapDispatchToProps = dispatch => {
		return {
				listLocations: (...params) => dispatch(listLocations(...params)),
				listAllStaffEngagementsReport: (...params) => dispatch(listAllStaffEngagementsReport(...params)),
				listAllStaffEngagements: (...params) => dispatch(listAllStaffEngagements(...params)),
				viewLocationNpsData: (...params) => dispatch(viewLocationNpsData(...params)),
				viewCustomLocationNpsDataByLocation: (...params) => dispatch(viewCustomLocationNpsDataByLocation(...params)),
				viewAdminDashboardEndorsementsReports: (...params) => dispatch(viewAdminDashboardEndorsementsReports(...params)),
				viewAdminLocationOverviewData: (...params) => dispatch(viewAdminLocationOverviewData(...params)),
				viewPeerSummaryByLocation: (...params) => dispatch(viewPeerSummaryByLocation(...params)),
				viewPeerLocationDetails: (...params) => dispatch(viewPeerLocationDetails(...params))
		};
};

export default connect(mapStateToProps, mapDispatchToProps)(PeerLocationSpecificReport);
