import React, {Component} from "react";
import {connect} from "react-redux";
import localizedData from "./../Localization/Localization";
import {surveyBegin, rewardPrizeTickets, findAllLocations,restartPatientSurvey, beginNewSurvey, getDeptStaff, cachedResults, executePersonalSurvey, updateSurvey, createPatient, getUserDetails} from "../../survey-actions";
import {Form} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import DayPicker from 'react-day-picker';
import ReactTurntable from "react-turntable"
import "react-turntable/assets/index.css"
// Include the locale utils designed for moment
import MomentLocaleUtils from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';
import "./../ServeSurvey.scss";

class ServeEmployerSurvey extends Component {

    constructor(props){
        super(props);

        let language = 'EN';
        if(props.location && props.location.query && props.location.query.lang!==null && props.location.query.lang !== undefined){
          if(props.location.query.lang.toUpperCase()==='ES' || props.location.query.lang.toUpperCase() === 'EN' || props.location.query.lang.toUpperCase() === 'FR'){
            localizedData.setLanguage(props.location.query.lang.toUpperCase());
            language = localizedData.getLanguage();
          }
        }


        var prizes = ['1 Raffle Entry', '2 Raffle Entries', '1 Raffle Entry', '2 Raffle Entries', '3 Raffle Entries', '2 Raffle Entries', '2 Raffle Entries','1 Raffle Entry']; 
        this.saveSurveyStatus = this.saveSurveyStatus.bind(this);
        this.executeSurvey = this.executeSurvey.bind(this);
        
        this.state = {
          showNextButton: true,
          currentQuestion: 0,
          showDepartmentJourneyScreen: false,
          showStaffJourneyScreen: false,
          selectedDepartments: [],
          selectedStaff: [],
          currentStaffNumber: null,
          currentStaffDetail: {},
          currentDeptNumber: null,
          currentDeptDetail: {},
          currentDeptStaffList: null,
          completedSurvey: false,
          notYourSurvey: false,
          staff: null,
          restartedSurvey: false,
          executed: false,
          cyclingDept: false,
          cachedStaffRequests : [],
          fullyLoaded: false,
          syncSurvey: false,
          newSurvey: false,
          spun: false,
          prizeWinnings: null,
          language: language,
          showReviewPage: true,
          savedSurvey: { nps: null, selectedDay: null, departments: [], staff: [], completed: false},
          errors: {}
        };

        this.props.surveyBegin(this.props.tvClient, this.props.match.params.survey_url).then(() => {

            if(this.props.surveyData.existingSurvey!==undefined){
                if(this.props.surveyData.language!==undefined&&this.props.surveyData.language!==null){
                  localizedData.setLanguage(this.props.surveyData.language);
                  this.setState({ savedSurvey: this.props.surveyData.existingSurvey, fullyLoaded: true, language: this.props.surveyData.language });
                } else{
                  this.setState({ savedSurvey: this.props.surveyData.existingSurvey, fullyLoaded: true });
                }

            } else{
              
                var newLanguage = this.props.surveyData.urlInfo[0].survey_lang;
                if(newLanguage !== undefined&& newLanguage !== null){
                  localizedData.setLanguage(newLanguage);
                  this.setState({language: newLanguage});
                }

                if(this.props.surveyData.recentlyCompleted===true){
                  this.setState({ completedSurvey: true });
                } else if(this.props.surveyData.notYourSurvey===true){
                  this.setState({ notYourSurvey: true });
                }else{
                  var currentSurvey = this.state.savedSurvey;
                  currentSurvey.selectedDay = new Date(this.props.surveyData.urlInfo[0].created);
                  
                  if(this.state.savedSurvey.departments.length===0 || this.state.savedSurvey.departments === undefined){
                    currentSurvey.departments = this.props.surveyData.departments;
                  }
                 
                  this.setState({ fullyLoaded: true, newSurvey: false, savedSurvey: currentSurvey });  
                }
                
            }
        });

    }

    componentDidMount(){
      this.props.findAllLocations();
    }

    finishPrizeDrawing(prize){
      if(this.state.spun===true && (this.state.savedSurvey.nps === 4 || this.state.savedSurvey.nps === 5)){
        this.state.setState({ showReviewPage: true });
        //this.state.setState({ showReviewPage: false });
      }
      else{
        this.setState({ spun: true, prizeWinnings: prize });
        this.props.rewardPrizeTickets(this.props.tvClient, prize, this.props.surveyData.urlInfo[0].url_id).then(()=>{
          if(this.state.savedSurvey.nps === 4 || this.state.savedSurvey.nps === 5){
            this.setState({showReviewPage: true});  
            //this.setState({ showReviewPage: false, completedSurvey: true });
          } else{
            this.setState({ showReviewPage: false, completedSurvey: true });
          }
          
        });
      }
    }

    executeSurvey(){
      if(this.state.executed===false){
        this.props.executePersonalSurvey(this.props.tvClient, this.props.surveyData.urlInfo[0]).then(()=>{
          this.setState({executed: true }); 
        }); 
      }
    }

    saveSurveyStatus(){
        if(this.state.syncSurvey){
            this.props.updateSurvey(this.props.tvClient, this.state.savedSurvey, this.props.surveyData.urlInfo[0], this.state.currentQuestion, false, localizedData.getLanguage()); 
            this.setState({syncSurvey: false, newSurvey: false}); 
        }
    } 

   restartSurvey(e){
    this.props.restartPatientSurvey(this.props.tvClient, this.props.surveyData.urlInfo[0], this.props.surveyData).then( () => {
      this.setState({ restartedSurvey: true, fullyLoaded: true });
    });
   }

  handleNpsClick(rating){
      let survey = this.state.savedSurvey;
      let sync = false;
      let originalNps = this.state.savedSurvey.nps;
      survey.nps = rating;
      survey.completed = true;

      if(originalNps!==survey.nps){
          sync = true;
      }

      this.setState({ syncSurvey: sync, savedSurvey: survey }, () => { this.advanceSurveyFrom(this); });   
  }

  retrieveUserDetails(){
      this.props.getUserDetails(this.props.surveyData).then(() => {
          var theQuestion = this.props.surveyData.questionInfo.length-2;
          this.setState({ fullyLoaded: true, currentQuestion: theQuestion });
      });
  }

    localizeDepartment(selectedDept){
      let finalName = selectedDept.name;
      if(localizedData.getLanguage()==='ES'){
        if(selectedDept.name_locale_es!==null && selectedDept.name_locale_es!==undefined){
          finalName = selectedDept.name_locale_es;
        }
      }

      return finalName;
    }

    chooseDepartments(selectedDept, e){
         e.stopPropagation()
        var currentDepartments = this.state.savedSurvey.departments;
        var originalDepts = this.state.savedSurvey.departments.slice(); 
        let sync = false;
        
        var foundIt = false;
        for (var i = 0, l = currentDepartments.length; i < l; i++) {
            if(currentDepartments[i].key === selectedDept.key) {
              foundIt=true;
              e.target.classList.remove("activeDept");
              e.target.parentElement.classList.remove("addCheckMark");
              currentDepartments.splice(i, 1);
              break;
            }
        }   

        if(!foundIt){
            e.target.classList.add("activeDept");
            e.target.parentElement.classList.add("addCheckMark");
            currentDepartments.push(selectedDept);
        }

        if(currentDepartments!==originalDepts){
            sync = true;
        }

        var currentSurvey = this.state.savedSurvey;
        currentSurvey.departments = currentDepartments;
        this.setState({
            savedSurvey: currentSurvey,
            syncSurvey: sync
        });

    }

    chooseStaff(selectedStaff, e){
        e.stopPropagation();
        var originalStaff= this.state.savedSurvey.staff.slice(); 
        let sync = false;
        var currentStaff = this.state.savedSurvey.staff;
        var foundIt = false;
        for (var i = 0, l = currentStaff.length; i < l; i++) {
            if(currentStaff[i].staff_id === selectedStaff.staff_id) {
              foundIt=true;
              e.target.classList.remove("activeDept");
              e.target.parentElement.classList.remove("addCheckMark");
              currentStaff.splice(i, 1);
              break;
            }
        }   

        if(!foundIt){
            e.target.classList.add("activeDept");
            e.target.parentElement.classList.add("addCheckMark");
            currentStaff.push(selectedStaff);
        }

        if(currentStaff!==originalStaff){
            sync = true;
        }

        var currentSurvey = this.state.savedSurvey;
        currentSurvey.staff = currentStaff;        

        this.setState({
            savedSurvey: currentSurvey,
            syncSurvey: sync
        });

    }

    chooseStaffEndorsement(selectedEndorsement, person, e){
        e.stopPropagation();
        let originalStaffEndorsements = this.state.savedSurvey.staff.splice();

        for (var i = this.state.savedSurvey.staff.length - 1; i >= 0; i--) {
            if(this.state.savedSurvey.staff[i].staff_id===person.staff_id){
                if(person.badges!==undefined){
                    var foundOne = false;
                    for (var j = person.badges.length - 1; j >= 0; j--) {
                        if(person.badges[j].endorsement_id===selectedEndorsement.endorsement_id){
                            // remove it
                            foundOne = true;
                            person.badges.splice(j, 1);
                            this.setState({ currentStaffDetail: person });
                            e.target.classList.remove("activeDept");
                            e.target.parentElement.classList.remove("addCheckMark");
                            break;
                        }
                    }
                    if(!foundOne){
                        person.badges.push(selectedEndorsement);
                        this.setState({ currentStaffDetail: person });
                        e.target.classList.add("activeDept");
                        e.target.parentElement.classList.add("addCheckMark");
                    }
                    
                }
                else{
                    person.badges=[];
                    person.badges.push(selectedEndorsement);
                    this.setState({ currentStaffDetail: person });
                    e.target.classList.add("activeDept");
                    e.target.parentElement.classList.add("addCheckMark");
                }
                break;
            }
        }

        if(originalStaffEndorsements!==this.state.savedSurvey.staff){
            this.setState({ syncSurvey: true })
        }
    }

    createNewSurvey(){
        this.props.beginNewSurvey(this.props.surveyData).then(() => {
            this.setState({ fullyLoaded: true, newSurvey: true });
        });
    }

    chooseDepartmentEndorsement(selectedEndorsement, department, e){
        e.stopPropagation();

        let originalDeptEndorsements = this.state.savedSurvey.departments.splice();
        for (var i = this.state.savedSurvey.departments.length - 1; i >= 0; i--) {
            if(this.state.savedSurvey.departments[i].key===department.key){
                if(department.badges!==undefined){
                    var foundOne = false;
                    for (var i = department.badges.length - 1; i >= 0; i--) {
                        if(department.badges[i].endorsement_id===selectedEndorsement.endorsement_id){
                            // remove it
                            foundOne = true;
                            department.badges.splice(i, 1);
                            this.setState({ currentDeptDetail: department });
                            e.target.classList.remove("activeDept");
                            e.target.parentElement.classList.remove("addCheckMark");
                            break;
                        }
                    }
                    if(!foundOne){
                        department.badges.push(selectedEndorsement);
                        this.setState({ currentDeptDetail: department });
                        e.target.classList.add("activeDept");
                        e.target.parentElement.classList.add("addCheckMark");
                    }
                    
                }
                else{
                    department.badges=[];
                    department.badges.push(selectedEndorsement);
                    this.setState({ currentDeptDetail: department });
                    e.target.classList.add("activeDept");
                    e.target.parentElement.classList.add("addCheckMark");
                }
                break;
            }
        }
        if(originalDeptEndorsements!==this.state.savedSurvey.departments){
            this.setState({ syncSurvey: true })
        }
    }


    generateClassNames(endorsement, entity){
        if(entity.badges!==undefined){
            var foundIt = false;
            for (var i = entity.badges.length - 1; i >= 0; i--) {
                if(entity.badges[i].endorsement_id===endorsement.endorsement_id){
                    foundIt=true;
                    break;
                }
            }
            if(foundIt){
                return true;
            }
            else{
                return false;
            }
        } else{
            return false;
        }
    }


    findNextPage(){
        let newSurveyData = this.props.surveyData;
        
        for (var i = this.props.surveyData.questionInfo.length - 1; i >= 0; i--) {
            if(this.props.surveyData.questionInfo[i].page_id === newSurveyData.currentPage){
                newSurveyData.currentPage = this.props.surveyData.questionInfo[i+1].page_id;
                newSurveyData.firstPage = false;
                break;
            }
        }

        return newSurveyData;
    }

    findPreviousPage(){
        let newSurveyData = this.props.surveyData;
        
        for (var i = this.props.surveyData.questionInfo.length - 1; i >= 0; i--) {
            if(this.props.surveyData.questionInfo[i].page_id === newSurveyData.currentPage){
                newSurveyData.currentPage = this.props.surveyData.questionInfo[i-1].page_id;
                if(this.props.surveyData.questionInfo[i-2]===undefined){
                    newSurveyData.firstPage = true;
                    this.setState({ showDepartmentJourneyScreen: false, showStaffJourneyScreen: false })
                }
                break;
            }
        }

        return newSurveyData;
    }

    handleStaffFeedbackChange(event){
        //event.stopPropagation();
        if(event.target.value!==undefined && event.target.value!==null){
            var person = this.state.currentStaffDetail;
            person.feedback = event.target.value;
            this.setState({ currentStaffDetail: person, syncSurvey: true });            
        }
    }

    handleDeptFeedbackChange(event){
        //event.stopPropagation();
        if(event.target.value!==undefined && event.target.value!==null){
            var dept = this.state.currentDeptDetail;
            dept.feedback = event.target.value;
            this.setState({ currentDeptDetail: dept, syncSurvey: true });            
        }
    }

    isDepartmentSelected(dept){
        var isSelected = false;
        for (var i = this.state.savedSurvey.departments.length - 1; i >= 0; i--) {
           if(this.state.savedSurvey.departments[i].key === dept){
            isSelected=true;
           }
        }
        return isSelected;
    }

    isStaffSelected(staff){
        var isSelected = false;
        for (var i = this.state.savedSurvey.staff.length - 1; i >= 0; i--) {
           if(this.state.savedSurvey.staff[i].staff_id === staff){
            isSelected=true;
           }
        }
        return isSelected;
    }

    staffCached(){
        var strDepts = '';
        for (var i = this.state.savedSurvey.departments.length - 1; i >= 0; i--) {
            strDepts += this.state.savedSurvey.departments[i].key;
        }  

        var foundIt = false;

        for (var j = this.state.cachedStaffRequests.length - 1; j >= 0; j--) {
            if(this.state.cachedStaffRequests[j].depts===strDepts){
                foundIt = true;
                break;
            }
        }
    

        return foundIt;

    }

    backwardDepartmentJourney(currentStep, from){
      if(from==='nps'){
          this.setState({
              surveyData: this.findPreviousPage(),
              currentQuestion: this.state.currentQuestion-1
          });  
      } else {
          var orderedDepts = this.props.surveyData.urlInfo[0].dept_list;
          var departmentDetails = this.props.surveyData.departments;
          var currentDeptDetail = this.state.currentDeptDetail;
          var currentOrderedPos = 0;
          var currentJourneyDept = currentDeptDetail.key===undefined ? orderedDepts[0] : currentDeptDetail.key;

          for(var i=0; i<orderedDepts.length; i++){
              if(currentJourneyDept.toString()===orderedDepts[i].toString()){
                currentOrderedPos = i;
                break;
              }
          }

          if(currentOrderedPos===0){
            this.setState({
                surveyData: this.findPreviousPage(),
                currentQuestion: this.state.currentQuestion-1
            });                         
          } else{
            //Need to configure the state for current details
            if(currentOrderedPos-1 >= 0){
              for(var j=0; j<departmentDetails.length; j++){
                if(departmentDetails[j].key.toString()===orderedDepts[currentOrderedPos-1].toString()){
                  currentDeptDetail = departmentDetails[j];
                  break;
                }
              }
              this.setState({currentDeptDetail: currentDeptDetail}
              );
              this.saveSurveyStatus();   
            } else{
              this.setState({surveyData: this.findPreviousPage(), currentQuestion: this.state.currentQuestion-1});
            }

          }
      }


    }

    advanceDepartmentJourney(currentStep){

          var orderedDepts = this.props.surveyData.urlInfo[0].dept_list;
          var departmentDetails = this.props.surveyData.departments;
          var currentDeptDetail = this.state.currentDeptDetail;
          var currentOrderedPos = 0;
          var currentJourneyDept = currentDeptDetail.key===undefined ? orderedDepts[0] : currentDeptDetail.key;

          for(var i=0; i<orderedDepts.length; i++){
              if(currentJourneyDept.toString()===orderedDepts[i].toString()){
                currentOrderedPos = i;
                break;
              }
          }

          if(this.props.surveyData.firstPage===true){
           //Need to configure the state for current details
            for(var j=0; j<departmentDetails.length; j++){
              if(departmentDetails[j].key.toString()===orderedDepts[currentOrderedPos].toString()){
                currentDeptDetail = departmentDetails[j];
                break;
              }
            }
            this.setState({
                        surveyData: this.findNextPage(),
                        currentDeptDetail: currentDeptDetail,
                        showStaffJourneyScreen: false,
                        showDepartmentJourneyScreen: false,
                        currentQuestion: this.state.currentQuestion+1}
            );        
            this.executeSurvey();                    
          } else{
            //Need to configure the state for current details


            if(currentOrderedPos+1 < orderedDepts.length){
              for(var j=0; j<departmentDetails.length; j++){
                if(departmentDetails[j].key.toString()===orderedDepts[currentOrderedPos+1].toString()){
                  currentDeptDetail = departmentDetails[j];
                  break;
                }
              }
              this.setState({currentDeptDetail: currentDeptDetail}
              );
              this.saveSurveyStatus();   
            } else{

              this.setState({surveyData: this.findNextPage(), currentQuestion: this.state.currentQuestion+1});
            }


          }
          window.scrollTo(0,0);
    }

    advanceStaffEndorsementsJourney(currentStep, from){

        var originalStaff= this.state.savedSurvey.staff.slice();
        var currentStaffIs = this.state.currentStaffDetail;
        var currStaffOrderNumber = -1;

        var allDepartmentStaff = [];
        for(var i=0; i<originalStaff.length; i++){
          if(originalStaff[i].department_id.toString()===this.state.currentDeptStaffList.details.key){
            allDepartmentStaff.push(originalStaff[i]);
          }
        }

        for(var j=0; j<allDepartmentStaff.length; j++){
          if(currentStaffIs.staff_id!==undefined){
              if(currentStaffIs.staff_id===allDepartmentStaff[j].staff_id){
                currStaffOrderNumber = j+1;
              }
            }
        }

        if(from==='first' && allDepartmentStaff.length!==0){
            this.setState({ currentStaffDetail: allDepartmentStaff[0], showDepartmentJourneyScreen: false, showStaffJourneyScreen: true });
        } else if(currStaffOrderNumber>=allDepartmentStaff.length || allDepartmentStaff.length===0){
          // Means that we need to go to the next department
          this.advanceDepartmentJourney(currentStep);
        }
        else{
          var newStaffToShow = (currentStaffIs.staff_id!==undefined) ? allDepartmentStaff[currStaffOrderNumber] : allDepartmentStaff[0];
          this.setState({ currentStaffDetail: newStaffToShow, showDepartmentJourneyScreen: false, showStaffJourneyScreen: true });
        }

        window.scrollTo(0,0);

    }

    backwardStaffEndorsementJourney(currentStep, from){

        if(from==='facility'){

          this.setState({
            surveyData: this.findPreviousPage(),
            currentQuestion: this.state.currentQuestion-1
          })

        }
        var originalStaff= this.state.savedSurvey.staff.slice();
        var currentStaffIs = this.state.currentStaffDetail;
        var currStaffOrderNumber = -1;

        var allDepartmentStaff = [];
        for(var i=0; i<originalStaff.length; i++){
          if(originalStaff[i].department_id.toString()===this.state.currentDeptStaffList.details.key){
            allDepartmentStaff.push(originalStaff[i]);
          }
        }

        for(var j=0; j<allDepartmentStaff.length; j++){
           if(currentStaffIs.staff_id!==undefined){
              if(currentStaffIs.staff_id===allDepartmentStaff[j].staff_id){
                currStaffOrderNumber = j-1;
              }
            }
        }

        if( (from==='last' || from==='facility') && allDepartmentStaff.length!==0){
          this.setState({ currentStaffDetail: allDepartmentStaff[allDepartmentStaff.length-1], showDepartmentJourneyScreen: false, showStaffJourneyScreen: true });
        } else if(currStaffOrderNumber<0 || allDepartmentStaff.length===0){
          // Means that we need to go to the last department
          this.backwardDepartmentJourney(currentStep, 'staff');
        }
        else{
          var newStaffToShow = (currentStaffIs.staff_id!==undefined) ? allDepartmentStaff[currStaffOrderNumber] : allDepartmentStaff[0];
          this.setState({ currentStaffDetail: newStaffToShow, showDepartmentJourneyScreen: false, showStaffJourneyScreen: true });
        }
        window.scrollTo(0,0);
    }


    advanceSurveyFrom(currentStep, requirements){

        if(requirements==='staff'){
            var strDepts = '';
            for (var i = this.state.savedSurvey.departments.length - 1; i >= 0; i--) {
                strDepts += this.state.savedSurvey.departments[i].key;
            }

            if(!this.staffCached()){
                this.props.getDeptStaff(this.props.tvClient, this.state.savedSurvey.departments).then((response)=> {
                    var newRequest = { 'depts': strDepts, 'staff': this.props.staffData };
                    var currStaffRequests = this.state.cachedStaffRequests;
                    currStaffRequests.push(newRequest);
                    this.setState({ cachedStaffRequests: currStaffRequests });
                });                
            }
            else{
                for (var i = this.state.cachedStaffRequests.length - 1; i >= 0; i--) {
                    if(this.state.cachedStaffRequests[i].depts === strDepts){
                        this.props.cachedResults(this.state.cachedStaffRequests[i].staff);
                    }
                }
            }

        } 

        this.setState({
            surveyData: this.findNextPage(),
            currentQuestion: this.state.currentQuestion+1
        });

        if(requirements!=="submit"){
            this.saveSurveyStatus();    
        }
        
    }


    backwardSurveyFrom(currentStep){
        this.setState({
            surveyData: this.findPreviousPage(),
            currentQuestion: this.state.currentQuestion-1
        }); 
    }

    changeLocale(ev, param){
        localizedData.setLanguage(param);
        this.setState({language: param});
    }

    render() {
        return  <div className="container actualSurvey">

      {this.props.surveyData && (this.state.fullyLoaded && this.props.surveyData.currentPage==="page-1") ?  <div> <div className="navbar row text-center firstPage">
        {this.props.surveyData && !this.props.surveyData.firstPage ? <button onClick={this.backwardSurveyFrom.bind(this)} className="simulateAnchor backButton">&lt; {localizedData.back}</button> : null }
      </div><div className="row cardholder serveSurvey">
          {(this.props.surveyData.urlInfo[0].logo===null || this.props.surveyData.urlInfo[0].logo===undefined) ? <img className="inSurveyLogo" src="/newlogo.png" alt="Logo"/> : <img className="inSurveyLogo" src={this.props.surveyData.urlInfo[0].logo} alt="Logo"/>}
          <p className="surveyPadTop">{localizedData.instruction_line_1}!</p>
          <p>{localizedData.employer_instruction_line_3}</p>
          <p>{localizedData.thank_you}</p>
          <div className="row cardInstructions text-right" ><p>Language: <a onClick={(ev) => this.changeLocale(this, 'EN')}>English</a> / <a onClick={(ev) => this.changeLocale(this, 'ES')}>Español</a></p></div>
          <br/><br/><br/>
          <p className="inlineNextButton">{this.props.surveyData && (this.state.showNextButton) ? <button type="button" onClick={ (ev) => this.advanceDepartmentJourney(this)} className="btn btn-primary nextBtn employerBtn">{localizedData.next}></button> : null }</p>          
       </div>

       </div> : null
   }
  
    {this.props.surveyData && (this.state.fullyLoaded && this.props.surveyData.currentPage==="page-2") ?   <div className="innerContent"> <div className="navbar row text-center">
        {this.props.surveyData && !this.props.surveyData.firstPage ? <button onClick={(ev)=> this.backwardDepartmentJourney(ev, 'department')} className="simulateAnchor backButton">&lt; {localizedData.back}</button> : null }
        <p>{process.env.CLIENT_NAME}</p>
      </div>
      <h5 className="surveyPageTitle separator">{this.localizeDepartment(this.state.currentDeptDetail).endsWith('s') ? localizedData.how_were : localizedData.how_was} {this.localizeDepartment(this.state.currentDeptDetail)}?</h5>    
        <div id="staffList">
            <div className="staffHeader">
              <div className="staffImage">

                <img className="img-responsive deptImageFix" alt={this.state.currentDeptDetail.name} src={this.state.currentDeptDetail.icon.startsWith('http') ? this.state.currentDeptDetail.icon : process.env.PUBLIC_URL + '/department_assets/' + this.state.currentDeptDetail.icon} />
                <div className="staffDetails">
                  <p className="name">{this.localizeDepartment(this.state.currentDeptDetail)}</p>
                  <div className="mostFrequentBadge">
                    <div className="theBadge"></div>
                    <div className="badgeDetails">
                      <p className="badgeTitle"><span className="totalNumberEndorsements"></span>{localizedData.endorsements}</p>
                      <p className="badgeDescription">{localizedData.badge_instruction_dept}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            { this.props.surveyData.deptEndorsements.length>0 && <div className="menu">
              <p>{localizedData.choose_badges} <span className="extraInfo">( {localizedData.scroll_for_more} )</span></p>
            </div>}
            { this.props.surveyData.deptEndorsements.length>0 && <div className="badgeSelector">
                    {
                        this.props.surveyData.deptEndorsements.map(c => {
                          if(c.department!==undefined && c.department!==null && c.department!=='' && (c.department.toString()!==this.state.currentDeptDetail.key.toString())){
                            return;
                          } else {
                            var localeValue = localizedData[c.localization_key];
                            if(c.localization_options!==undefined && c.localization_options!==null){
                              let tempValue = c.localization_options[this.state.language];
                              if(tempValue!==undefined){
                                localeValue = tempValue;
                              }
                            }
                            return <div className="nopad isBadge text-center" key={c.endorsement_id}>
                                    <label className={"badge-checkbox" + (this.generateClassNames(c, this.state.currentDeptDetail) ? ' addCheckMark' : '')}>
                                      <img onClick={(ev) => this.chooseDepartmentEndorsement(c, this.state.currentDeptDetail, ev) } className={"img-responsive" + (this.generateClassNames(c, this.state.currentDeptDetail) ? ' activeDept' : '')} src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
                                      <p>{localeValue}</p>
                                    </label>
                                  </div>
                          }
                        })
                    }      

            </div> }

            <div className="menu">
              <p>{localizedData.write_feedback}</p>
            </div>
            <textarea placeholder={localizedData.feedback_placeholder} id="deptFeedback" onChange={this.handleDeptFeedbackChange.bind(this)} value={this.state.currentDeptDetail.feedback!==undefined ? this.state.currentDeptDetail.feedback : ''} />
            <div className="container inlineNextButton">{this.props.surveyData && (this.state.showNextButton || this.state.savedSurvey.departments.length>0) ? <button type="button" onClick={this.advanceDepartmentJourney.bind(this)} className="btn btn-primary nextBtn employerBtn employerBtnFeedback">{localizedData.next}></button> : null }</div>
        </div>

      </div> : null
   }

      {this.props.surveyData && (this.state.fullyLoaded && this.props.surveyData.currentPage==="page-3") ?  <div> <div className="navbar row text-center">
        {this.props.surveyData && !this.props.surveyData.firstPage ? <a onClick={(ev)=> this.backwardDepartmentJourney(ev, 'nps')} className="backButton">&lt; {localizedData.back}</a> : null }
        <p>{process.env.CLIENT_NAME}</p>        
      </div><div className="row cardholder">
          <h5 className="surveyPageTitle">{localizedData['demo_page_1']}</h5>
            <div className="card mx-auto">
              <a onClick={this.handleNpsClick.bind(this, 1)} className="card-img-sad overallCard"></a>
          </div>
                    <div className="card mx-auto">
              <a onClick={this.handleNpsClick.bind(this, 2)} className="card-img-meh overallCard"></a>
          </div> 
                    <div className="card mx-auto">
              <a onClick={this.handleNpsClick.bind(this, 3)} className="card-img-okay overallCard"></a>
          </div>  
                    <div className="card mx-auto" >
              <a onClick={this.handleNpsClick.bind(this, 4)} className="card-img-good overallCard"></a>
          </div> 

            <div className="card mx-auto">
              <a onClick={this.handleNpsClick.bind(this, 5)} className="card-img-great overallCard"></a>
          </div> 

          <div className="row cardInstructions"><p>{localizedData.nps_choose}</p></div>         
          <br/><br/>
          <div className="row cardInstructions text-right" ><p>Language: <a onClick={(ev) => this.changeLocale(this, 'EN')}>English</a> / <a onClick={(ev) => this.changeLocale(this, 'ES')}>Español</a></p></div>
       </div></div> : null
   }


 {this.props.surveyData && (this.props.surveyData.currentPage==="page-4" && this.state.fullyLoaded && this.state.showReviewPage) ?   <div> <div className="row cardholder serveSurvey">
          {(this.props.surveyData.urlInfo[0].logo===null || this.props.surveyData.urlInfo[0].logo===undefined) ? <img className="inSurveyLogo" src="/newlogo.png" alt="Logo"/> : <img className="inSurveyLogo" src={this.props.surveyData.urlInfo[0].logo} alt="Logo"/>}
          <h5 className="surveyPageTitle">{localizedData.completed_survey}</h5> 
           {(this.props.locationList!==undefined && this.props.locationList.length>0) && <p className="employerReviewPrompt">{localizedData.reviewPromptText}</p>}
                    {
                        this.props.locationList.map(c => {
                          if((c.google_id!==undefined && c.google_id!=='') || (c.yelp_id!==undefined && c.yelp_id!=='')){
                            return <div className="container employerReviewSection" key={c.location_id}>
                                      <p><b>{c.name}</b></p>
                                      <p>{c.address1}{(c.address2 !== undefined && c.address2 !== '' ) ? (', ' + c.address2) : ''}</p>
                                      <p>{c.city}, {c.state}, {c.zip}</p>
                                      {(c.google_id!==undefined && c.google_id!=='') && <a href={'https://search.google.com/local/writereview?placeid=' + c.google_id} className="reviewSite" ><img src="./google-reviews.png" /></a>}
                                      {(c.yelp_id!==undefined && c.yelp_id!=='') && <a href={c.yelp_id} className="reviewSite" ><img src="./yelp_fullcolor.png" /></a>}
                                    </div>
                          } else {
                              return;
                          }
                        })
                    }      
       </div></div> : null
   }

 {this.props.surveyData && (this.state.completedSurvey) ?   <div> <div className="row cardholder serveSurvey">
          {(this.props.surveyData.urlInfo[0].logo===null || this.props.surveyData.urlInfo[0].logo===undefined) ? <img className="inSurveyLogo" src="/newlogo.png" alt="Logo"/> : <img className="inSurveyLogo" src={this.props.surveyData.urlInfo[0].logo} alt="Logo"/>}
          <h5 className="surveyPageTitle">{localizedData.completed_survey}</h5> 
           
       </div></div> : null
   }

 {this.props.surveyData && (this.state.notYourSurvey && !this.state.restartedSurvey) ?   <div> <div className="row cardholder serveSurvey">
          {(this.props.surveyData.urlInfo[0].logo===null || this.props.surveyData.urlInfo[0].logo===undefined) ? <img className="inSurveyLogo" src="/newlogo.png" alt="Logo"/> : <img className="inSurveyLogo" src={this.props.surveyData.urlInfo[0].logo} alt="Logo"/>}
          <h5 className="surveyPageTitle">{localizedData.another_device}</h5> 
          <button className="btn btn-primary centerButton" onClick={this.restartSurvey.bind(this)}>{localizedData.start}</button>
       </div></div> : null
   }

    </div>

    }
}

const mapStateToProps = (state) => {
    return {
        surveyData: state.survey.surveyData,
        existingSurvey: state.survey.existingSurvey,
        tvClient: state.login.tvClient,
        staffData: state.staff.staffData,
        locationList: state.surveyLocations.locations
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      surveyBegin: (...params) => dispatch(surveyBegin(...params)),
      getDeptStaff: (...params) => dispatch(getDeptStaff(...params)),
      cachedResults: (...params) => dispatch(cachedResults(...params)),
      updateSurvey: (...params) => dispatch(updateSurvey(...params)),
      createPatient: (...params) => dispatch(createPatient(...params)),
      beginNewSurvey: (...params) => dispatch(beginNewSurvey(...params)),
      getUserDetails: (...params) => dispatch(getUserDetails(...params)),
      executePersonalSurvey: (...params) => dispatch(executePersonalSurvey(...params)),
      rewardPrizeTickets: (...params) => dispatch(rewardPrizeTickets(...params)),
      restartPatientSurvey: (...params) => dispatch(restartPatientSurvey(...params)),
      findAllLocations: (...params) => dispatch(findAllLocations(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServeEmployerSurvey);
