import React, {Component} from "react";
import {connect} from "react-redux";
import {
		Alert,
		Button,
		FormLabel,
		Form,
		FormControl,
		FormGroup,
		Navbar,
		Nav
} from "react-bootstrap";
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import Spinner from "react-spinner";
import {createLocation, addMultipleLocations} from "../../actions";

class NewLocation extends Component {
		constructor(props) {
				super(props);
				this.state = {
						errors: { 'locationName': false, 'locationAddress1': false, 'locationCity': false, 'locationState': false, 'locationZip': false  },
						showSingleAddTab: true,
						showBatchAddTab: false,
						activeKey: 1,
						cols:  null,
						rows: null,
						dataLoaded: false
				};
				this.inlineError = false;
		}

		handleSelect(selectedKey) {
			if(parseInt(selectedKey)===1){
				this.setState({ showSingleAddTab:true, showBatchAddTab:false, activeKey: selectedKey });
			} else if(parseInt(selectedKey)===2){
				this.setState({ showSingleAddTab:false, showBatchAddTab:true, activeKey: selectedKey });
			}
		}

		newBatchLocations(e){
				e.preventDefault();
				this.props.addMultipleLocations(this.props.tvClient, this.state.rows).then(() => {
						this.setState({ rows: null, cols: null, dataLoaded: false });
						window.scrollTo(0,0);
				});
		}

		fileHandler(event) {
				let fileObj = event.target.files[0];
				//just pass the fileObj as parameter
				ExcelRenderer(fileObj, (err, resp) => {
					if(err){
						console.log(err);
					}
					else{
						this.setState({
							cols: resp.cols,
							rows: resp.rows,
							dataLoaded: true
						});
					}
				});
		}

		newLocation(e) {
				e.preventDefault();

				if(!this.locationName.value){
						this.setState({ errors: {'locationName': true, 'locationAddress1': false, 'locationCity': false, 'locationState': false, 'locationZip': false }});
				} else if(!this.locationAddress1.value){
						this.setState({ errors: {'locationName': false, 'locationAddress1': true, 'locationCity': false, 'locationState': false, 'locationZip': false }});
				} else if(!this.locationCity.value){
						this.setState({ errors: {'locationName': false, 'locationAddress1': false, 'locationCity': true, 'locationState': false, 'locationZip': false }});
				} else if(!this.locationState.value){
						this.setState({ errors: {'locationName': false, 'locationAddress1': false, 'locationCity': false, 'locationState': true, 'locationZip': false }});
				} else if(!this.locationZip.value){
						this.setState({ errors: {'locationName': false, 'locationAddress1': false, 'locationCity': false, 'locationState': false, 'locationZip': true }});
				} else{
						this.props.createLocation(
								this.props.tvClient,
								this.locationName.value,
								this.locationAddress1.value,
								this.locationAddress2.value,
								this.locationCity.value,
								this.locationState.value,
								this.locationZip.value,
								this.locationPhone.value,
								this.notificationPhone.value,
								this.notificationEmail1.value,
								this.notificationEmail2.value,
								this.google_id.value,
								this.yelp_id.value,
								this.facebook_id.value,
								this.healthgrades_id.value
						);
				}


		}

		render() {
				return <div>
						<h1 className="page-header">
								Add New Location
								<small className="backLink">Please enter the following location information ( <button className="simulateAnchor" onClick={() => window.history.back()} >Cancel</button>)</small>
								<Navbar className="submenuNav" variant="light" expand="lg">
									<Nav activeKey={this.state.activeKey} onSelect={this.handleSelect.bind(this)}>
										<Nav.Link eventKey={1} href="#">
											Single
										</Nav.Link>
										<Nav.Link eventKey={2} href="#">
											Batch
										</Nav.Link>
									</Nav>
								</Navbar>
						</h1>
						{ (this.state.showSingleAddTab===true) && <Form onSubmit={this.newLocation.bind(this)} className="new-case-form">

								<legend>Address Information</legend>

								{this.props.addLocationError && <Alert variant="danger">
										{this.props.addLocationError.message}
								</Alert>}

								<FormGroup controlId="locationName">
										<FormLabel>Name *</FormLabel>
										<FormControl type="text" placeholder="North County Office" ref={ref => this.locationName = ref}/>
										{this.state.errors.locationName && <Alert variant="danger">
												Name is required.
										</Alert>}
								</FormGroup>

								<FormGroup controlId="locationAddress1">
										<FormLabel>Address Line 1 *</FormLabel>
										<FormControl type="text" placeholder="5555 Main st." ref={ref => this.locationAddress1 = ref}/>
										{this.state.errors.locationAddress1 && <Alert variant="danger">
												Address Line 1 is required.
										</Alert>}
								</FormGroup>

								<FormGroup controlId="locationAddress2">
										<FormLabel>Address Line 2</FormLabel>
										<FormControl type="text" placeholder="Suite / Office #" ref={ref => this.locationAddress2 = ref}/>
								</FormGroup>

								<FormGroup controlId="locationCity">
										<FormLabel>City *</FormLabel>
										<FormControl type="text" placeholder="City Name" ref={ref => this.locationCity = ref}/>
										{this.state.errors.locationCity && <Alert variant="danger">
												City is required.
										</Alert>}
								</FormGroup>

								<FormGroup controlId="locationState">
								<FormLabel>State *</FormLabel>
								<FormControl as="select" placeholder="Select" ref={ref => this.locationState = ref}>
										<option value="AL">Alabama</option>
										<option value="AK">Alaska</option>
										<option value="AZ">Arizona</option>
										<option value="AR">Arkansas</option>
										<option value="CA">California</option>
										<option value="CO">Colorado</option>
										<option value="CT">Connecticut</option>
										<option value="DE">Delaware</option>
										<option value="DC">District Of Columbia</option>
										<option value="FL">Florida</option>
										<option value="GA">Georgia</option>
										<option value="HI">Hawaii</option>
										<option value="ID">Idaho</option>
										<option value="IL">Illinois</option>
										<option value="IN">Indiana</option>
										<option value="IA">Iowa</option>
										<option value="KS">Kansas</option>
										<option value="KY">Kentucky</option>
										<option value="LA">Louisiana</option>
										<option value="ME">Maine</option>
										<option value="MD">Maryland</option>
										<option value="MA">Massachusetts</option>
										<option value="MI">Michigan</option>
										<option value="MN">Minnesota</option>
										<option value="MS">Mississippi</option>
										<option value="MO">Missouri</option>
										<option value="MT">Montana</option>
										<option value="NE">Nebraska</option>
										<option value="NV">Nevada</option>
										<option value="NH">New Hampshire</option>
										<option value="NJ">New Jersey</option>
										<option value="NM">New Mexico</option>
										<option value="NY">New York</option>
										<option value="NC">North Carolina</option>
										<option value="ND">North Dakota</option>
										<option value="OH">Ohio</option>
										<option value="OK">Oklahoma</option>
										<option value="OR">Oregon</option>
										<option value="PA">Pennsylvania</option>
										<option value="RI">Rhode Island</option>
										<option value="SC">South Carolina</option>
										<option value="SD">South Dakota</option>
										<option value="TN">Tennessee</option>
										<option value="TX">Texas</option>
										<option value="UT">Utah</option>
										<option value="VT">Vermont</option>
										<option value="VA">Virginia</option>
										<option value="WA">Washington</option>
										<option value="WV">West Virginia</option>
										<option value="WI">Wisconsin</option>
										<option value="WY">Wyoming</option>
											<option value="AB">Alberta</option>
											<option value="BC">British Columbia</option>
											<option value="MB">Manitoba</option>
											<option value="NB">New Brunswick</option>
											<option value="NL">Newfoundland and Labrador</option>
											<option value="NS">Nova Scotia</option>
											<option value="NT">Northwest Territories</option>
											<option value="NU">Nunavut</option>
											<option value="ON">Ontario</option>
											<option value="PE">Prince Edward Island</option>
											<option value="QC">Quebec</option>
											<option value="SK">Saskatchewan</option>
											<option value="YT">Yukon</option>
								</FormControl>
										{this.state.errors.locationState && <Alert variant="danger">
												State/Province is required.
										</Alert>}
								</FormGroup>
								<FormGroup controlId="locationZip">
										<FormLabel>Zip/Postal Code *</FormLabel>
										<FormControl type="text" placeholder="Zip" ref={ref => this.locationZip = ref}/>
										{this.state.errors.locationZip && <Alert variant="danger">
												Zip Code is required.
										</Alert>}
								</FormGroup>

								<FormGroup controlId="locationPhone">
										<FormLabel>Office Phone</FormLabel>
										<FormControl type="text" placeholder="555-555-5555" ref={ref => this.locationPhone = ref}/>
								</FormGroup>

								<FormGroup controlId="google_id">
										<FormLabel>Google Place ID</FormLabel>
										<FormControl type="text" ref={ref => this.google_id = ref}/>
								</FormGroup>
								<FormGroup controlId="yelp_id">
										<FormLabel>Yelp! URL</FormLabel>
										<FormControl type="text" ref={ref => this.yelp_id = ref}/>
								</FormGroup>
								<FormGroup controlId="facebook_id">
										<FormLabel>Facebook Reviews URL</FormLabel>
										<FormControl type="text" ref={ref => this.facebook_id = ref}/>
								</FormGroup>
								<FormGroup controlId="healthgrades_id">
										<FormLabel>Health Grades URL</FormLabel>
										<FormControl type="text" ref={ref => this.healthgrades_id = ref}/>
								</FormGroup>

								<legend>Notification Information</legend>

								<FormGroup controlId="notificationPhone">
										<FormLabel>Admin Phone</FormLabel>
										<FormControl type="text" placeholder="555-555-5555" ref={ref => this.notificationPhone = ref}/>
								</FormGroup>

								<FormGroup controlId="notificationEmail1">
										<FormLabel>Admin Email</FormLabel>
										<FormControl type="text" placeholder="name@hospital.com" ref={ref => this.notificationEmail1 = ref}/>
										<p><i><small>Separate multiple emails with a semicolon ;</small></i></p>
								</FormGroup>

								<FormGroup className="hidden" controlId="notificationEmail2">
										<FormLabel>Admin Email</FormLabel>
										<FormControl type="text" placeholder="name@hospital.com" ref={ref => this.notificationEmail2 = ref}/>
								</FormGroup>


								<FormGroup className="submit-buttons">
										<Button type="submit" disabled={this.props.addingLocation} className="float-right">
												Add
										</Button>
								</FormGroup>


						</Form> }

						{ (this.state.showBatchAddTab===true) &&  <Form onSubmit={this.newBatchLocations.bind(this)} className="case-list-container">

								<legend>Batch Addition <small>(Download the <a href="/WelliQ-AddBatchLocations.xlsx">Excel Template</a>)</small></legend>
								<p>Rows Processed: {this.props.peopleAdded} / {this.props.totalPeople>0 ? this.props.totalPeople : '-'}</p>

								<FormGroup controlId="locationName">
										<FormLabel>Upload *</FormLabel>
										<input type="file" onChange={this.fileHandler.bind(this)} style={{"padding":"10px"}} />
								</FormGroup>
								<FormGroup className="submit-buttons">
										<Button type="submit" disabled={this.props.addingMultipleLocations} className="pull-left">
												{this.props.addingMultipleLocations ? 'Adding' : 'Add'}
										</Button>
										{this.props.addingMultipleLocations && <Spinner/>}
								</FormGroup>
								{this.state.dataLoaded &&
										<div className="case-list-container">
												<OutTable data={this.state.rows} columns={this.state.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
										</div>
								}


						</Form>

						}

				</div>
		}
}

const mapStateToProps = state => {
		return {
				addingLocation: state.addLocation.addingLocation,
				addLocationError: state.addLocation.addLocationError,
				locationsAdded: state.addMultipleLocations.locationsAdded,
				totalLocations: state.addMultipleLocations.totalLocations,
				addingMultipleLocations: state.addMultipleLocations.addingMultipleLocations,
				tvClient: state.login.tvClient
		};
};

const mapDispatchToProps = dispatch => {
		return {
				createLocation: (...params) => dispatch(createLocation(...params)),
				addMultipleLocations: (...params) => dispatch(addMultipleLocations(...params))
		};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLocation);
