import React, {Component} from "react";
import { CSVLink } from 'react-csv';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import dateFormat from "dateformat";
import { listAllStaffFeedbackWithDetailsReport  } from "../../actions"

class ExportWrittenStaffFeedbackCSV extends Component {

    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false
        }
        this.csvLinkEl = React.createRef();
    }

    downloadReport = async () => {
        this.setState({ loading: true });
        let exportData = [];

        // This needs to be with patient info returned
        const primeData = await this.props.listAllStaffFeedbackWithDetailsReport(this.props.tvClient, this.props.token, this.props.filterType, this.props.filter, this.props.locationId, this.props.dateFilter, this.props.fromDate, this.props.toDate, this.props.businessLine);
        var dirtyData = this.props.staffFeedbackData;

        for (var i = dirtyData.length - 1; i >= 0; i--) {
            var thisLine = dirtyData[i].response;
            // eslint-disable-next-line no-useless-escape
            thisLine.feedback = thisLine.feedback.replace(/(\r\n|\n|\r|\")/gm,"");
            exportData.push({ 'Name': thisLine.name, 'Title': thisLine.title, 'Date': dateFormat(dirtyData[i].created, "shortDate"), 'Score': dirtyData[i].nps !==null ? dirtyData[i].nps : 'Not Available','Feedback': thisLine.feedback, 'Patient Name': (dirtyData[i].patientName !== null && dirtyData[i].patientName!==undefined) ? dirtyData[i].patientName : 'Not Available', 'Patient Phone': (dirtyData[i].patientPhone !== null && dirtyData[i].patientPhone!==undefined) ? dirtyData[i].patientPhone : 'Not Available', 'Patient Email': (dirtyData[i].patientEmail !== null && dirtyData[i].patientEmail!==undefined) ? dirtyData[i].patientEmail : 'Not Available'   });
        }

        exportData = exportData.reverse()


        this.setState({ data: exportData, loading: false }, () => {
            this.csvLinkEl.current.link.click();
        });
    }

    render() {
        const { data } = this.state;
        return (

      <div style={{display: 'inline-block', paddingRight: '15px'}}>
        <input type="button" disabled={this.state.loading} className="float-right btn btn-primary exportButton" value={this.state.loading ? "Preparing Download" : "Export With Patient Data"} onClick={this.downloadReport} />
        <CSVLink
          headers={[{ label: "Provider Name", key: "Name" },{ label: "Title", key: "Title" },{ label: "Date", key: "Date" },{ label: "Score", key: "Score" },{ label: "Feedback", key: "Feedback" },{ label: "Patient Name", key: "Patient Name" },{ label: "Patient Email", key: "Patient Email" },{ label: "Patient Phone", key: "Patient Phone" }]}
          filename={"Written Staff Feedback List" +this.props.locationName+".csv"}
          data={data}
          ref={this.csvLinkEl}
          className="hidden"
        >Download Staff</CSVLink>
      </div>

          
        )

    }
}

ExportWrittenStaffFeedbackCSV.propTypes = {
    locationId: PropTypes.number.isRequired,
    locationName: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    filterType: PropTypes.string,
    filter: PropTypes.string.isRequired, 
    dateFilter: PropTypes.string.isRequired, 
    fromDate: PropTypes.string,
    toDate: PropTypes.string, 
    businessLine: PropTypes.string
};

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        staffFeedbackData: state.staffFeedbackWithDetailsData.staffFeedbackData
    };
};

export default connect(mapStateToProps, {listAllStaffFeedbackWithDetailsReport})(ExportWrittenStaffFeedbackCSV);

