import React, {Component} from "react";
import {
		Table
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import {getAdminFlags,getAuthTokens, getLocationReviews, refreshGoogleLocationReviews} from "../../actions";
import "./ReviewPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import PaginationArea from "./../Utilities/PaginationArea";
import queryString from 'query-string';
import dateFormat from "dateformat";

class ViewLocationReviews extends Component {
		constructor(props) {
				super(props);
				this.state = {
						fields: { 'role': null},
						reputationEnabled: false
				};
		}

		renderRatingPercent(param) {
				switch(param){
						case 'FIVE': return (5*20).toString() + '%';
						case 'FOUR': return (4*20).toString() + '%';
						case 'THREE': return (3*20).toString() + '%';
						case 'TWO': return (2*20).toString() + '%';
						case 'ONE': return (1*20).toString() + '%';
						default: return '0%';
				}
		}

		renderRating(param) {
				switch(param){
						case 'FIVE': return 5;
						case 'FOUR': return 4;
						case 'THREE': return 3;
						case 'TWO': return 2;
						case 'ONE': return 1;
						default: return 0;
				}
		}

		refreshGoogleReviews(){
				this.props.refreshGoogleLocationReviews(this.props.tvClient, this.props.match.params.locationId, 'GOOGLE', 1, 50);
		}

		changeListPage(page) {
				this.props.getLocationReviews(this.props.tvClient, this.props.match.params.locationId, 'GOOGLE', page, 50);
		}


		componentDidMount() {
				this.props.getAdminFlags(this.props.tvClient).then(()=>{
						this.props.getLocationReviews(this.props.tvClient, this.props.match.params.locationId, 'GOOGLE', 1, 50);
						var reviewTruth=false;
						if(this.props.flags!==null){
							reviewTruth = (this.props.flags.reputationEnabled.toString()==='true') ? true : false;
						}

						this.setState({reputationEnabled: reviewTruth });
				});

				/*this.props.getAuthTokens(this.props.tvClient).then(()=>{
						this.props.getReviewLocations(this.props.tvClient, 'GOOGLE');
				});*/
		}

		render() {
				return <div>
						<h1 className="page-header">
								Location Reviews {!this.state.reputationEnabled && <button className="btn btn-primary reviewRefreshBtn" disabled={this.props.reviewsLoading} onClick={this.refreshGoogleReviews.bind(this)}><FontAwesomeIcon icon={faRedo}/></button>}
								<small className="backLink"><button className="simulateAnchor" onClick={() => window.history.back()} >Go Back</button></small>
						</h1>
						<div className="case-list-container">
								{(this.props.locationReviews && !this.props.reviewsLoading && this.props.locationReviews.length > 0) && <div className="float-right">
										<PaginationArea currentPage={this.props.paginationInfo.current_page} parentRefresh={this.changeListPage.bind(this)} numberOfPages={this.props.paginationInfo.num_pages} items={this.props.paginationItems} />
								</div>}
								{(this.props.reviewsLoading || this.props.flagsLoading) && <Spinner/>}
								{(this.props.locationReviews && !this.props.reviewsLoading && !this.props.flagsLoading && this.props.locationReviews.length === 0) && <h4>No reviews given</h4> }
								{(this.props.locationReviews && !this.props.reviewsLoading && this.props.locationReviews.length > 0) && <Table className="case-list reviewLocationList">
												<thead>
														<tr>
																<th className="text-center">Rating</th>
																<th>Author</th>
																<th>Created / Updated</th>
																<th>Replied</th>
																<th>Comment</th>
														</tr>
												</thead>
												<tbody>
												{
														this.props.locationReviews.map(c => {
																		return <tr key={c.uid}>
																				<td className="text-center">{c.review.reviewReply===undefined && <div>

																								<div className="rating">
																										<div className="rating-upper" style={{width: this.renderRatingPercent(c.rating) }}>
																												<span>★</span>
																												<span>★</span>
																												<span>★</span>
																												<span>★</span>
																												<span>★</span>
																										</div>
																										<div className="rating-lower">
																												<span>★</span>
																												<span>★</span>
																												<span>★</span>
																												<span>★</span>
																												<span>★</span>
																										</div>
																								</div>

																						<p className="averageRatingScore">
																								{this.renderRating(c.rating)} / 5
																						</p>
																						{!this.state.reputationEnabled && <Link to={'/reviews/reply/' + c.uid}>
																								Reply to Review
																						</Link>} </div>}

																						{c.review.reviewReply!==undefined && <div>

																								<div className="rating">
																										<div className="rating-upper" style={{width: this.renderRatingPercent(c.rating) }}>
																												<span>★</span>
																												<span>★</span>
																												<span>★</span>
																												<span>★</span>
																												<span>★</span>
																										</div>
																										<div className="rating-lower">
																												<span>★</span>
																												<span>★</span>
																												<span>★</span>
																												<span>★</span>
																												<span>★</span>
																										</div>
																								</div>

																						<p className="averageRatingScore">
																								{this.renderRating(c.rating)} / 5
																						</p> </div>}
																				</td>
																				<td className="text-center">{(c.review.reviewer!==undefined) ? c.review.reviewer.displayName : ''}</td>
																				<td className="text-center">{dateFormat(c.review.createTime, "shortDate")}</td>
																				<td className="text-center">{c.review.reviewReply===undefined ? 'No' : 'Yes'}</td>
																				<td>
																						<span>{c.review.comment!==undefined ? c.review.comment : 'Rating Only'}</span>
																						{c.review.reviewReply!==undefined && <p className="reviewReply"><span className="yourReplyTitle">Your Reply:</span>{c.review.reviewReply.comment}<small className="dateInReply"> -{dateFormat(c.review.reviewReply.updateTime, "shortDate")}</small></p>}
																				</td>

																		</tr>
														})
												}
												</tbody>
										</Table>}
								{(this.props.locationReviews && !this.props.reviewsLoading && this.props.locationReviews.length > 0) && <div className="float-right">
										<PaginationArea currentPage={this.props.paginationInfo.current_page} parentRefresh={this.changeListPage.bind(this)} numberOfPages={this.props.paginationInfo.num_pages} items={this.props.paginationItems} />
								</div>}
						</div>

				</div>
		}
}

const mapStateToProps = state => {
		return {
				tvClient: state.login.tvClient,
				locationReviews: state.locationReviewList.reviews,
				flags: state.adminFlags.flags,
				flagsLoading: state.adminFlags.loading,
				reviewsLoading: state.locationReviewList.loading,
				paginationInfo: state.locationReviewList.paginationInfo,
				paginationItems: state.locationReviewList.paginationInfo.pageList,
				isViewOnly: state.login.user && state.login.user.attributes.role === 'viewonly'
		};
};


const mapDispatchToProps = dispatch => {
		return {
				getLocationReviews: (...params) => dispatch(getLocationReviews(...params)),
				refreshGoogleLocationReviews: (...params) => dispatch(refreshGoogleLocationReviews(...params)),
				getAdminFlags: (...params) => dispatch(getAdminFlags(...params))
		};
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewLocationReviews);
